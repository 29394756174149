/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * AppIcons.jsx
 * Created by Stephanie Condrey on 11/28/18.
 */

import { BaseIcon, BaseIconsProps } from '@fp/sarsaparilla';

// eslint-disable-next-line max-classes-per-file
import AppIconSingleton from './appIconSingleton';

type IconProps = Omit<BaseIconsProps, 'iconName' | 'iconClass' | 'alt' | 'title'>;

class AppIcon extends BaseIcon {
    constructor(props: BaseIconsProps) {
        super(props);

        this.iconSingleton = AppIconSingleton;
    }
}
export function AppIconGooglePlay(props: IconProps) {
    return (
        <AppIcon
            iconName="rec-icon-app-google-play"
            iconClass="rec-icon-app-google-play"
            title="Download on the Google Play Store"
            {...props}
        />
    );
}

export function AppIconAppleStore(props: IconProps) {
    return (
        <AppIcon
            iconName="rec-icon-app-apple-store"
            iconClass="rec-icon-app-apple-store"
            title="Download on the App Store"
            {...props}
        />
    );
}

export function AppIconRecGov(props: IconProps) {
    return (
        <AppIcon
            // eslint-disable-next-line no-restricted-syntax
            iconName="rec-icon-app-rec-gov"
            // eslint-disable-next-line no-restricted-syntax
            iconClass="rec-icon-app-rec-gov"
            // eslint-disable-next-line no-restricted-syntax
            alt="Recreation.gov App"
            {...props}
        />
    );
}
