/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import { Heading, DividerNew, useWindowSize } from 'sarsaparilla';
import CommonFilter from '../common/CommonFilter';

import {
    AMENITIES_TYPE,
    KEY_CHARACTERISTICS_TYPE,
    ACCESS_TYPE,
    SPECIAL_SITE_TYPE,
    SEARCH_FILTER_CAMPSITE_ELECTRICAL,
    SEARCH_FILTER_CAMPSITE_ELECTRICAL_REORDERED,
    FCFS_TYPE,
    OCCUPANTS_TYPE,
} from '../../../constants/filters/camping';

import { getGroupCount } from '../../../utils/filters';
import { UiFiltersProps } from '../../../types';
import FirstComeFirstServedFilters from './FirstComeFirstServed';
import NumberFieldFilter from '../common/NumberFieldFilter';

export type MustHaveProps = {
    search: UiFiltersProps;
    aggregations?: { [key: string]: Number };
    onChange?: (v: React.ChangeEvent<HTMLInputElement>, k: any) => void;
    onFilterToggle?: (v: string, k: any) => void;
    onApplyFilters?: () => void;
    onFilterRemove?: (v: string, k: any) => void;
    onCriteriaUpdate?: (v: string, k: string[]) => void;
};

function MustHaveFilters({
    search,
    aggregations,
    onChange,
    onApplyFilters,
    onCriteriaUpdate,
}: MustHaveProps) {
    const filterKeys = [
        ...AMENITIES_TYPE,
        ...SEARCH_FILTER_CAMPSITE_ELECTRICAL,
        ...KEY_CHARACTERISTICS_TYPE,
        ...ACCESS_TYPE,
        ...SPECIAL_SITE_TYPE,
        ...FCFS_TYPE,
        OCCUPANTS_TYPE,
    ].map((item) => item.key);
    const mustHavesCount = getGroupCount(filterKeys, search);

    const { width = 0 } = useWindowSize();

    const electricalHookupList =
        width > 1240
            ? SEARCH_FILTER_CAMPSITE_ELECTRICAL_REORDERED
            : SEARCH_FILTER_CAMPSITE_ELECTRICAL;
    return (
        <div>
            <div className="search-filters-section-header rounded-layout mb-1 mt-4">
                <Heading headingLevel={2} appearance="h5">
                    Narrow your requirements. What are your must-have&apos;s?
                </Heading>
                <span>
                    {mustHavesCount}{' '}
                    {mustHavesCount > 1 || mustHavesCount === 0 ? 'Filters' : 'Filter'}{' '}
                    Selected
                </span>
            </div>
            <div className="search-filters-filter-container">
                <CommonFilter
                    aggregations={aggregations}
                    search={search}
                    filters={AMENITIES_TYPE}
                    onChange={onChange}
                    title="Amenities"
                />
                <CommonFilter
                    aggregations={aggregations}
                    search={search}
                    onChange={onChange}
                    filters={electricalHookupList}
                    title="Electrical Hookup"
                />
                <CommonFilter
                    aggregations={aggregations}
                    search={search}
                    onChange={onChange}
                    filters={KEY_CHARACTERISTICS_TYPE}
                    title="Key Characteristics"
                    hasDivider={false}
                />
                <div className="pt-2">
                    <DividerNew />
                </div>
                <div className="pt-2">
                    <NumberFieldFilter
                        label={OCCUPANTS_TYPE.value}
                        units="guests"
                        max={99}
                        id={OCCUPANTS_TYPE.key}
                        onApplyFilters={onApplyFilters}
                        filter={OCCUPANTS_TYPE.key}
                        onCriteriaUpdate={onCriteriaUpdate}
                        search={search}
                    />
                </div>
                <CommonFilter
                    aggregations={aggregations}
                    search={search}
                    onChange={onChange}
                    filters={ACCESS_TYPE}
                    title="Access"
                />
                <CommonFilter
                    aggregations={aggregations}
                    search={search}
                    onChange={onChange}
                    filters={SPECIAL_SITE_TYPE}
                    title="Special Site Types"
                />
                <FirstComeFirstServedFilters
                    aggregations={aggregations}
                    onChange={onChange}
                />
            </div>
        </div>
    );
}

export default MustHaveFilters;
