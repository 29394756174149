/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * RentalsAlert.jsx
 * Created by Destin Frasier on 10/07/20
 * Created by Destin Frasier on 03/24/23
 */

import React from 'react';
import { ContentContainer, FlexRow, FlexCol, Alert } from 'sarsaparilla';

export default function RentalsAlert() {
    return (
        <section className="nav-rv-rentals-section">
            <ContentContainer className="my-6">
                <FlexRow>
                    <FlexCol lgOffset={1} lg={10} xlOffset={2} xl={8}>
                        <Alert type="info">
                            <p>
                                These links have been provided because they contain
                                relevant information that may be useful or interesting.
                                This does not constitute endorsement, recommendation, or
                                favoring by {process.env.SITE_NAME}. These external links
                                are for reference only, and {process.env.SITE_NAME} cannot
                                attest to the accuracy of information provided by
                                third-party sites or any other linked site outside its
                                control.
                            </p>
                            <p>
                                For questions or concerns with rentals, please contact the
                                vendor directly. Contact information for customer support
                                with RV rentals can be found on that vendor&#39;s website
                                or in your rental confirmation. The{' '}
                                {process.env.SITE_NAME} Contact Center is unable to help
                                with any RV rental reservations. You can find more
                                information in the{' '}
                                <a
                                    href={process.env.SN_RV_RENTALS_FAQ_URL}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    {' '}
                                    RV Rental FAQs
                                </a>{' '}
                                section in the Help Center.
                            </p>
                        </Alert>
                    </FlexCol>
                </FlexRow>
            </ContentContainer>
        </section>
    );
}
