/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { shuffle } from 'lodash';

import { InventoryPromoLink, MapHelper, Carousel } from 'sarsaparilla';
import HeroCarouselPlaceholder from './HeroCarouselPlaceholder.jsx';

export default function PermitsCarousel({ itemWidth, data }) {
    const isMountedRef = useRef(false);
    const { isLoading, hasErrors, locations } = data;
    const gaEventCategory = 'Homepage';
    const gaEventLabel = 'Hero';

    const shuffledLocations = useMemo(() => {
        let items = [];

        if (!isMountedRef.current && !!locations.length) {
            items = shuffle(locations);
            isMountedRef.current = true;
        } else {
            items = locations;
        }

        return items;
    }, [locations]);

    if (isLoading) {
        return <HeroCarouselPlaceholder />;
    }

    if (hasErrors || (locations?.length === 0 && !isLoading)) {
        return null;
    }

    const getClickTagAction = (item) => {
        const baseAction = `Permits Card - ${item.name}`;

        if (!item.preview_image_url) {
            return `${baseAction} - Photo Unavailable`;
        }

        return baseAction;
    };

    const locationSlides = shuffledLocations.map((item, index) => {
        const hasCity = item.city === undefined ? '' : `${item.city}`;
        const hasStateCode = item.state_code === undefined ? '' : item.state_code;
        const hasComma = hasCity && hasStateCode ? ',' : '';

        return (
            <InventoryPromoLink
                shouldTranslateHeading={false}
                shouldTranslateLocation={false}
                imagePath={item.preview_image_url}
                imageHeight="80px"
                href={MapHelper.getTargetUri(item)}
                heading={item.name}
                location={`${hasCity}${hasComma} ${hasStateCode}`}
                mobileCoverage={null}
                key={index}
                clickTagCategory={gaEventCategory}
                clickTagAction={getClickTagAction(item)}
                clickTagLabel={gaEventLabel}
            />
        );
    });

    return (
        <Carousel
            className="nav-hero-search-carousel"
            slideTargetWidth={itemWidth}
            hasGutters
        >
            {locationSlides}
        </Carousel>
    );
}

PermitsCarousel.propTypes = {
    itemWidth: PropTypes.number,
    data: PropTypes.shape({
        locations: PropTypes.array,
        isLoading: PropTypes.bool,
        hasErrors: PropTypes.bool,
    }),
};

PermitsCarousel.defaultProps = {
    itemWidth: 175,
    data: {
        locations: [],
        isLoading: true,
        hasErrors: false,
    },
};
