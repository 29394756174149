/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'lodash';

export default class SearchFilterAvailable extends React.Component {
    static propTypes = {
        search: PropTypes.object,
        onFilterChange: PropTypes.func,
        onApplyFilters: PropTypes.func,
        hasDatesInfoModal: PropTypes.func,
        onShowAvailableModalChange: PropTypes.func,
    };

    static displayName = 'SearchFilterAvailable';

    handleChange(event) {
        if (this.props.hasDatesInfoModal(event)) {
            this.props.onShowAvailableModalChange(true);
            return;
        }
        if (this.props.onFilterChange) {
            this.props.onFilterChange(
                'include_unavailable',
                !event.currentTarget.checked
            );
        }
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    renderCheckbox() {
        const disabled = false;
        const checked = !get(this.props.search, 'include_unavailable', true);
        const label = 'Hide Unavailable';
        return (
            <div className="rec-form-check-wrap" key={'include_unavailable'}>
                <label htmlFor={'include_unavailable'} className="rec-label-checkbox">
                    <input
                        onChange={(e) => this.handleChange(e)}
                        type="checkbox"
                        id={'include_unavailable'}
                        disabled={disabled}
                        value={'true'}
                        className="rec-input-hide includeunavailablecheckbox"
                        checked={checked}
                        name="include_unavailable"
                    />
                    <span className="rec-input-checkbox" />
                    {label}
                </label>
            </div>
        );
    }

    render() {
        return this.renderCheckbox();
    }
}
