/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * ProductOfferingSection.jsx
 * Created by Destin Frasier on 02/04/22
 */

import { ContentContainer, Heading, FlexRow, FlexCol } from 'sarsaparilla';

export default function ProductOfferingSection() {
    return (
        <section className="nav-ni-product-offering-wrap">
            <ContentContainer>
                <FlexRow>
                    <FlexCol>
                        <Heading headingLevel={2} appearance="h4" className="mb-3">
                            Product Offering
                        </Heading>
                    </FlexCol>
                </FlexRow>

                <FlexRow alignItems="center" justifyContent="center">
                    <FlexCol className="nav-ni-product-offering-video-wrap" lg="variable">
                        <iframe
                            width="672"
                            height="378"
                            src="https://www.youtube.com/embed/WWve-5MN9Uk?cc_load_policy=1"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        />
                    </FlexCol>
                </FlexRow>
            </ContentContainer>
        </section>
    );
}
