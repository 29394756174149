/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import { Pagination } from 'sarsaparilla';
import SearchPaginationText from './SearchPaginationText';

interface SearchPaginationProps {
    /** This is the index of the first item on the current page. For example, if the page size is 5, the start value for the third page would be 15 */
    start: number | null;
    /** The total number of items being paginated (not the total number of pages) */
    total: number | null;
    /** The number of items per page */
    pageSize: number | null;
    /** change handler called with the index of the next active page index */
    onPage: (page: number) => void;
}

export default function SearchPagination({
    start: startProp,
    total: totalProp,
    pageSize: pageSizeProp,
    onPage,
}: SearchPaginationProps) {
    // Because some silly people are sending null values ...
    const start = startProp ?? 0;
    const total = totalProp ?? 0;
    const initialPageSize = pageSizeProp ?? 20;

    // Calculate pagination values from the raw prop data
    const pageSize = total < initialPageSize ? total : initialPageSize;
    const pageCount = Math.ceil(total / pageSize);
    const activePage = Math.floor(start / pageSize);

    return (
        <div
            className="search-pagination-wrap"
            aria-describedby="search-pagination-results"
        >
            <div className="search-pagination" id="search-pagination-results">
                <SearchPaginationText start={start} pageSize={pageSize} total={total} />

                {pageCount > 1 ? (
                    <Pagination
                        aria-label="Search Results Pagination"
                        pages={pageCount}
                        activePage={activePage}
                        onChange={(newPage) => {
                            if (onPage) {
                                onPage(newPage + 1);
                            }
                        }}
                    />
                ) : null}
            </div>
        </div>
    );
}

SearchPagination.propTypes = {
    start: PropTypes.number,
    total: PropTypes.number,
    pageSize: PropTypes.number,
    onPage: PropTypes.func,
};
