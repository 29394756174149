/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { MerchItem, MerchSection } from '../../../types/merchandise';

export const merchFeaturedSection: MerchSection[] = [];

export const merchNewArrivalSection: MerchSection[] = [];

export const merchHomeItems: MerchItem[] = [];

export const merchSections: MerchSection[] = [];

export const merchCategoryItems: MerchItem[] = [];
