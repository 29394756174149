/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox, InventoryTag, Heading } from 'sarsaparilla';

export const options = [
    {
        key: 'campsite-use-overnight',
        value: 'Camping',
        icon: <InventoryTag.InventoryCamping isSpanItem />,
        filters: [
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'Overnight',
            },
        ],
    },
    {
        key: 'campsite-use-day',
        value: 'Day',
        icon: <InventoryTag.InventoryDayUse isSpanItem />,
        filters: [
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'Day,Day Use',
            },
        ],
    },
];

export default class SearchFilterCampsiteUseTypes extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        headingLevel: PropTypes.number,
        uiFilters: PropTypes.object,
        aggregations: PropTypes.object,
        onFilterToggle: PropTypes.func,
        isRequired: PropTypes.bool,
        onApplyFilters: PropTypes.func,
    };

    static defaultProps = {
        isRequired: false,
    };

    static displayName = 'SearchFilterCampsiteUseTypes';

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange(event, item) {
        if (this.props.onFilterToggle) {
            this.props.onFilterToggle('campsiteUseTypes', item);
        }
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    processAggregations(searchAggregations) {
        const counts = {};
        // find aggregated counts for item attribute
        options.forEach((item) => {
            const filter = item.filters[0];
            counts[filter.attribute] = {};
            if (
                searchAggregations.campsiteUseTypes &&
                searchAggregations.campsiteUseTypes[filter.attribute]
            ) {
                const aggs = searchAggregations.campsiteUseTypes[filter.attribute];
                for (let i = 0; i < aggs.length; i += 1) {
                    counts[filter.attribute][aggs[i].name] = aggs[i].count;
                }
            }
        });

        // aggregate comma separate filter counts into one
        options.forEach((item) => {
            const filter = item.filters[0];
            const parts = filter.filterValue.split(',');
            let count = 0;
            parts.forEach((part) => {
                const cnt = counts[filter.attribute];
                if (cnt && cnt[part]) {
                    count += cnt[part];
                }
            });
            counts[filter.attribute][filter.filterValue] = count;
        });
        return counts;
    }

    renderCheckbox(item, counts) {
        const filter = item.filters[0];
        const attCounts = counts[filter.attribute];
        const selections = this.props.uiFilters.campsiteUseTypes;
        const isChecked = selections.hasOwnProperty(item.key);
        let count = 0;
        if (attCounts) {
            count = attCounts[filter.filterValue];
        }
        let label;
        if (count || isChecked) {
            label = `${item.value} (${count})`;
        } else {
            label = item.value;
        }
        return (
            <div
                htmlFor={item.key}
                key={item.key}
                className="search-filter-check-item hasIcon"
            >
                <Checkbox
                    onChange={(e) => this.handleChange(e, item)}
                    id={item.key}
                    label={label}
                    value={item.value}
                    className={count === 0 && !isChecked ? 'disabled' : ''}
                    isRequired={this.props.isRequired}
                    isChecked={isChecked}
                />
                {item.icon}
            </div>
        );
    }

    render() {
        const { headingLevel = 3 } = this.props;
        const className = `${this.props.className}`;
        const counts = this.processAggregations(this.props.aggregations);

        return (
            <div className={className}>
                <Heading headingLevel={headingLevel} appearance="h6">
                    Site Use Type
                </Heading>
                <div
                    className="search-filters-check-wrap"
                    role="group"
                    aria-label="Site use type filters"
                >
                    <legend className="rec-sr-only">
                        Select site use types to filter on
                    </legend>
                    {options.map((item) => this.renderCheckbox(item, counts))}
                </div>
            </div>
        );
    }
}
