/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import * as turf from '@turf/turf';
import moment from 'moment';
import { Icon, MapHelper } from 'sarsaparilla';
import {
    CAMPING_PIN,
    DAY_USE_PIN,
    PASSES_PIN,
    PERMITS_PIN,
    REC_AREA_PIN,
    TICKET_FACILITY_PIN,
    TOURS_PIN,
    VENUE_ATHLETIC_ACTIVITY_PIN,
    VENUE_GOLFING_PIN,
    VENUE_HUNTING_PIN,
    VENUE_LARGE_RECEPTION_PIN,
    VENUE_OTHER_PIN,
    VENUE_SHOOTING_PIN,
    VENUE_SMALL_GATHERING_PIN,
    VENUE_WEDDING_PIN,
} from '../constants';

export const createEmptyCollection = (props = {}) => {
    const layerSource = {
        data: turf.featureCollection([]),
        type: 'geojson',
    };

    if (props.cluster) layerSource.cluster = props.cluster;
    if (props.clusterMaxZoom) layerSource.clusterMaxZoom = props.clusterMaxZoom;

    return layerSource;
};

export const setupClusteredSource = (props) => {
    return {
        type: 'geojson',
        data: turf.featureCollection([]),
        cluster: props.cluster,
        clusterMaxZoom: props.clusterMaxZoom || 16,
        clusterRadius: props.clusterRadius || 50,
    };
};

export const setupTerrainSource = () => {
    return {
        type: 'raster-dem',
        url: 'mapbox://mapbox.terrain-rgb',
        tileSize: 512,
    };
};

export const getGeojsonBounds = (features) => {
    return features ? turf.bbox(features) : [];
};

/**
 * LEGACY - FOR USE WITH OLD <Icons.XXXXXX /> component
 * This object defines a mapping between camping site types and their corresponding
 * icons.
 * It is intended for use in various components, including maps, where icons
 * representing different types of camping sites need to be displayed.
 *
 * The spelling and case of each value in this object are critical, as they
 * directly correlate to the names of the icons used for rendering.
 * Any discrepancies in spelling or case may result in incorrect rendering or
 * failure to render the intended icons.
 */
export const CAMPING_TYPED_ICONS = {
    ANCHORAGE: 'BoatSite',
    'BALL FIELD': 'Ball',
    'BOAT IN': 'BoatSite',
    'CABIN ELECTRIC': 'Cabin',
    'CABIN NONELECTRIC': 'Cabin',
    COURT: 'Court',
    'EQUESTRIAN ELECTRIC': 'HorseSite',
    'EQUESTRIAN NONELECTRIC': 'HorseSite',
    'GROUP EQUESTRIAN': 'HorseSite',
    'GROUP HIKE TO': 'Trailhead',
    'GROUP PICNIC AREA': 'PicnicTable',
    'GROUP RV AREA NONELECTRIC': 'Rv',
    'GROUP SHELTER ELECTRIC': 'Shelter',
    'GROUP SHELTER NONELECTRIC': 'Shelter',
    'GROUP STANDARD AREA ELECTRIC': 'GroupCampsite',
    'GROUP STANDARD AREA NONELECTRIC': 'GroupCampsite',
    'GROUP STANDARD ELECTRIC': 'GroupCampsite',
    'GROUP STANDARD NONELECTRIC': 'GroupCampsite',
    'GROUP TENT ONLY AREA NONELECTRIC': 'Tent',
    'GROUP WALK TO': 'Walk',
    'HIKE TO': 'Trailhead',
    LOOKOUT: 'Cabin',
    MANAGEMENT: 'Flag',
    MOORING: 'BoatSite',
    'OVERNIGHT SHELTER ELECTRIC': 'Shelter',
    'OVERNIGHT SHELTER NONELECTRIC': 'Shelter',
    PARKING: 'Parking',
    PICNIC: 'PicnicTable',
    'RV ELECTRIC': 'Rv',
    'RV NONELECTRIC': 'Rv',
    'SHELTER ELECTRIC': 'Shelter',
    'SHELTER NONELECTRIC': 'Shelter',
    'STANDARD ELECTRIC': 'Tent',
    'STANDARD NONELECTRIC': 'Tent',
    'TENT ONLY ELECTRIC': 'Tent',
    'TENT ONLY NONELECTRIC': 'Tent',
    'WALK TO': 'Walk',
    YURT: 'Yurt',
};

export const MODERN_CAMPING_TYPED_ICONS = {
    ANCHORAGE: 'boat-site',
    'BALL FIELD': 'ball',
    'BOAT IN': 'boat-site',
    'CABIN ELECTRIC': 'cabin',
    'CABIN NONELECTRIC': 'cabin',
    COURT: 'court',
    'EQUESTRIAN ELECTRIC': 'horse-site',
    'EQUESTRIAN NONELECTRIC': 'horse-site',
    'GROUP EQUESTRIAN': 'horse-site',
    'GROUP HIKE TO': 'trailhead',
    'GROUP PICNIC AREA': 'picnic-table',
    'GROUP RV AREA NONELECTRIC': 'rv',
    'GROUP SHELTER ELECTRIC': 'shelter',
    'GROUP SHELTER NONELECTRIC': 'shelter',
    'GROUP STANDARD AREA ELECTRIC': 'group-campsite',
    'GROUP STANDARD AREA NONELECTRIC': 'group-campsite',
    'GROUP STANDARD ELECTRIC': 'group-campsite',
    'GROUP STANDARD NONELECTRIC': 'group-campsite',
    'GROUP TENT ONLY AREA NONELECTRIC': 'tent',
    'GROUP WALK TO': 'walk',
    'HIKE TO': 'trailhead',
    LOOKOUT: 'cabin',
    MANAGEMENT: 'flag',
    MOORING: 'boat-site',
    'OVERNIGHT SHELTER ELECTRIC': 'shelter',
    'OVERNIGHT SHELTER NONELECTRIC': 'shelter',
    PARKING: 'parking',
    PICNIC: 'picnic-table',
    'RV ELECTRIC': 'rv',
    'RV NONELECTRIC': 'rv',
    'SHELTER ELECTRIC': 'shelter',
    'SHELTER NONELECTRIC': 'shelter',
    'STANDARD ELECTRIC': 'tent',
    'STANDARD NONELECTRIC': 'tent',
    'TENT ONLY ELECTRIC': 'tent',
    'TENT ONLY NONELECTRIC': 'tent',
    'WALK TO': 'walk',
    YURT: 'yurt',
};

export const getIconIdByIconName = (iconName) => {
    /**
     * Split @iconName by capital letters, join them in a single string by a
     * hyphen and make it lower case
     */
    return `rec-icon-${iconName
        .split(/(?=[A-Z])/)
        .join('-')
        .toLocaleLowerCase()}`;
};

/**
 * Creates an array of camping site types and their corresponding icon name/id
 * to be used for displaying icons on the map.
 * Example Usage:
 * - For each camping site type defined in CAMPING_TYPED_ICONS, a corresponding
 *   array entry is created with the site type and its associated icon name/id
 */
export const campingIconTypes = Object.entries(CAMPING_TYPED_ICONS).map(
    ([type, icon]) => [type, getIconIdByIconName(icon)]
);

// export const campingIconTypes = Object.entries(CAMPING_TYPED_ICONS)
//     .map(([type, icon]) => ([type, icon()?.props?.iconName]));

export const formatDate = (date) => {
    if (!date) return date;
    return moment.utc(date, 'MM/DD/YYYY').startOf('day').format();
};

export const getBiggestPolygon = (multipolygon) => {
    const subpolygons = turf.flatten(multipolygon);
    if (!subpolygons.features.length) return null;
    const biggestPolygon = subpolygons.features.reduce((acc, feature) => {
        const accArea = acc ? turf.area(acc) : null;
        if (!accArea) return feature;

        const featureArea = turf.area(feature);
        return featureArea > accArea ? feature : acc;
    }, null);
    return biggestPolygon;
};

export const getMapIcon = (item) => {
    let mapIcon;
    if (item.entity_type === 'campground') {
        mapIcon = CAMPING_PIN;
        // use camping pin for all campgrounds but those with exclusively day-use campsites
        if (item.campsite_type_of_use) {
            mapIcon = DAY_USE_PIN;
            item.campsite_type_of_use.forEach((type) => {
                if (type !== 'Day') {
                    mapIcon = CAMPING_PIN;
                }
            });
        }
    } else if (item.campsite_id) {
        mapIcon = CAMPING_PIN;
        if (item.type_of_use === 'Day') {
            mapIcon = DAY_USE_PIN;
        }
    } else if (item.entity_type === 'permit') {
        mapIcon = PERMITS_PIN;
    } else if (item.entity_type === 'vehiclepermit') {
        mapIcon = PERMITS_PIN;
    } else if (item.entity_type === 'tour') {
        mapIcon = TOURS_PIN;
    } else if (item.entity_type === 'ticketfacility') {
        mapIcon = TICKET_FACILITY_PIN;
    } else if (item.entity_type === 'timedentry_tour') {
        mapIcon = TOURS_PIN;
    } else if (item.entity_type === 'timedentry') {
        mapIcon = TICKET_FACILITY_PIN;
    } else if (item.entity_type === 'recarea') {
        mapIcon = REC_AREA_PIN;
    } else if (item.entity_type === 'activitypass') {
        mapIcon = PASSES_PIN;
    } else if (item.entity_type === 'treepermit') {
        mapIcon = PERMITS_PIN;
    } else if (
        ['venuereservations', 'venuereservations_venue'].includes(item.entity_type)
    ) {
        const venuePins = {
            'wedding-ceremony': VENUE_WEDDING_PIN,
            'small-gathering': VENUE_SMALL_GATHERING_PIN,
            shooting: VENUE_SHOOTING_PIN,
            other: VENUE_OTHER_PIN,
            'large-reception': VENUE_LARGE_RECEPTION_PIN,
            hunting: VENUE_HUNTING_PIN,
            golfing: VENUE_GOLFING_PIN,
            'athletic-activity': VENUE_ATHLETIC_ACTIVITY_PIN,
        };

        const tag = item.venue_tags?.[0];
        mapIcon = venuePins[tag] || VENUE_OTHER_PIN;
    } else {
        mapIcon = 'poi-pin';
    }
    if (item.availability === 'unavailable') {
        mapIcon += '-disabled';
    }

    return mapIcon;
};

function AmenitiesIcon() {
    return <Icon iconName="amenities" />;
}

export const getAmenityIcon = (category, subcategory) => {
    if (!category) {
        return null;
    }
    const normalizedCategory = category.toLowerCase();
    const normalizedSubcategory = subcategory.toLowerCase();
    const mapIcon =
        MapHelper.amenityCategoryIcons[normalizedCategory]?.[normalizedSubcategory] ||
        MapHelper.amenityCategoryIcons[normalizedCategory]?.default ||
        AmenitiesIcon;
    return mapIcon || null;
};
