/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom-v5-compat';

import { FlexCol, FlexRow, InventoryPromoLinkTall } from 'sarsaparilla';
import { quickPromos } from 'site-kit';

interface ExploreAllQuickPromosProps {
    search: any;
}

// TODO: Get this from sarsa
type FlexColBreakpoint = 3 | 4 | 5;

export default function ExploreAllQuickPromos({ search }: ExploreAllQuickPromosProps) {
    const navigate = useNavigate();

    const searchInventory = (type: string[]) => () => {
        // For merchandise store navigate directly to it
        if (type.includes('merchandise')) {
            navigate('/merchandise');
            return;
        }

        let { searchAcrossUS } = process.env.HOMEPAGE_OPTIONS;
        const { bounds } = process.env.SEARCH;

        if (!searchAcrossUS && !bounds) {
            // default to search across us for some inventory types
            searchAcrossUS =
                type.length > 0 &&
                (type[0] === 'permits' ||
                    type[0] === 'tours' ||
                    type[0] === 'timedentry_tours');
        }

        search('', type, null, null, null, searchAcrossUS, bounds);
    };

    return (
        <FlexRow className="explore-all-cards">
            {quickPromos.map((promo, index) => {
                const handleClick = searchInventory(promo?.inventoryType);
                const clickTagCategory = 'Homepage';
                const clickTagLabel = 'Hero';
                const clickTagAction = `${promo?.inventoryType} - Explore All Card`;

                const mediumBreakpoint = Math.ceil(
                    12 / quickPromos.length
                ) as FlexColBreakpoint;

                return (
                    <FlexCol sm={12} md={mediumBreakpoint} key={index}>
                        <InventoryPromoLinkTall
                            imagePath={promo.image}
                            mobileImagePath={promo.mobileImage}
                            imageAlt={promo.imageAlt}
                            heading={promo.title}
                            headingLevel={2}
                            onClick={handleClick}
                            role="link"
                            height="194px"
                            hasReducedHeightForMobile
                            headingPosition="headingBottom"
                            clickTagCategory={clickTagCategory}
                            clickTagAction={clickTagAction}
                            clickTagLabel={clickTagLabel}
                        />
                    </FlexCol>
                );
            })}
        </FlexRow>
    );
}

ExploreAllQuickPromos.propTypes = {
    search: PropTypes.func,
};

// cSpell:ignore activitypass, dayuse, timedentry
