/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * ArticleLandingBlocks.jsx
 * Created by Destin Frasier on 03/21/18.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FlexRow, FlexCol, Button } from 'sarsaparilla';

import { ARTICLE } from '../../../constants/propTypes';
import ArticleLandingBlockItem from './ArticleLandingBlockItem';

const propTypes = {
    articles: PropTypes.arrayOf(ARTICLE),
    handleLoadMore: PropTypes.func,
    isLoading: PropTypes.bool,
    limitReached: PropTypes.bool,
};

export default function ArticleLandingBlocks(props) {
    const { articles, handleLoadMore, limitReached, isLoading } = props;

    return (
        <div>
            <FlexRow className="article-landing-article-blocks-wrap">
                {articles.map((article) => (
                    <ArticleLandingBlockItem article={article} key={article.id} />
                ))}
            </FlexRow>

            {!limitReached && (
                <FlexRow
                    className="article-landing-article-blocks-wrap"
                    justifyContent="center"
                >
                    <FlexCol sm={12} md={6}>
                        <Button
                            shouldFitContainer
                            onClick={handleLoadMore}
                            isLoading={isLoading}
                            isDisabled={isLoading}
                            aria-label="Load More Articles"
                        >
                            Load More
                        </Button>
                    </FlexCol>
                </FlexRow>
            )}
        </div>
    );
}

ArticleLandingBlocks.propTypes = propTypes;
