/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * stateHelper.js
 * Created by michaelbray on 10/16/17
 */

/* eslint-disable import/prefer-default-export */
export const states = [
    {
        label: 'Alabama',
        abbreviation: 'AL',
        value: 'Alabama',
    },
    {
        label: 'Alaska',
        abbreviation: 'AK',
        value: 'Alaska',
    },
    {
        label: 'Arizona',
        abbreviation: 'AZ',
        value: 'Arizona',
    },
    {
        label: 'Arkansas',
        abbreviation: 'AR',
        value: 'Arkansas',
    },
    {
        label: 'California',
        abbreviation: 'CA',
        value: 'California',
    },
    {
        label: 'Colorado',
        abbreviation: 'CO',
        value: 'Colorado',
    },
    {
        label: 'Connecticut',
        abbreviation: 'CT',
        value: 'Connecticut',
    },
    {
        label: 'Delaware',
        abbreviation: 'DE',
        value: 'Delaware',
    },
    {
        label: 'District of Columbia',
        abbreviation: 'DC',
        value: 'District of Columbia',
    },
    {
        label: 'Florida',
        abbreviation: 'FL',
        value: 'Florida',
    },
    {
        label: 'Georgia',
        abbreviation: 'GA',
        value: 'Georgia',
    },
    {
        label: 'Hawaii',
        abbreviation: 'HI',
        value: 'Hawaii',
    },
    {
        label: 'Idaho',
        abbreviation: 'ID',
        value: 'Idaho',
    },
    {
        label: 'Illinois',
        abbreviation: 'IL',
        value: 'Illinois',
    },
    {
        label: 'Indiana',
        abbreviation: 'IN',
        value: 'Indiana',
    },
    {
        label: 'Iowa',
        abbreviation: 'IA',
        value: 'Iowa',
    },
    {
        label: 'Kansas',
        abbreviation: 'KS',
        value: 'Kansas',
    },
    {
        label: 'Kentucky',
        abbreviation: 'KY',
        value: 'Kentucky',
    },
    {
        label: 'Louisiana',
        abbreviation: 'LA',
        value: 'Louisiana',
    },
    {
        label: 'Maine',
        abbreviation: 'ME',
        value: 'Maine',
    },
    {
        label: 'Maryland',
        abbreviation: 'MD',
        value: 'Maryland',
    },
    {
        label: 'Massachusetts',
        abbreviation: 'MA',
        value: 'Massachusetts',
    },
    {
        label: 'Michigan',
        abbreviation: 'MI',
        value: 'Michigan',
    },
    {
        label: 'Minnesota',
        abbreviation: 'MN',
        value: 'Minnesota',
    },
    {
        label: 'Mississippi',
        abbreviation: 'MS',
        value: 'Mississippi',
    },
    {
        label: 'Missouri',
        abbreviation: 'MO',
        value: 'Missouri',
    },
    {
        label: 'Montana',
        abbreviation: 'MT',
        value: 'Montana',
    },
    {
        label: 'Nebraska',
        abbreviation: 'NE',
        value: 'Nebraska',
    },
    {
        label: 'Nevada',
        abbreviation: 'NV',
        value: 'Nevada',
    },
    {
        label: 'New Hampshire',
        abbreviation: 'NH',
        value: 'New Hampshire',
    },
    {
        label: 'New Jersey',
        abbreviation: 'NJ',
        value: 'New Jersey',
    },
    {
        label: 'New Mexico',
        abbreviation: 'NM',
        value: 'New Mexico',
    },
    {
        label: 'New York',
        abbreviation: 'NY',
        value: 'New York',
    },
    {
        label: 'North Carolina',
        abbreviation: 'NC',
        value: 'North Carolina',
    },
    {
        label: 'North Dakota',
        abbreviation: 'ND',
        value: 'North Dakota',
    },
    {
        label: 'Ohio',
        abbreviation: 'OH',
        value: 'Ohio',
    },
    {
        label: 'Oklahoma',
        abbreviation: 'OK',
        value: 'Oklahoma',
    },
    {
        label: 'Oregon',
        abbreviation: 'OR',
        value: 'Oregon',
    },
    {
        label: 'Pennsylvania',
        abbreviation: 'PA',
        value: 'Pennsylvania',
    },
    {
        label: 'Rhode Island',
        abbreviation: 'RI',
        value: 'Rhode Island',
    },
    {
        label: 'South Carolina',
        abbreviation: 'SC',
        value: 'South Carolina',
    },
    {
        label: 'South Dakota',
        abbreviation: 'SD',
        value: 'South Dakota',
    },
    {
        label: 'Tennessee',
        abbreviation: 'TN',
        value: 'Tennessee',
    },
    {
        label: 'Texas',
        abbreviation: 'TX',
        value: 'Texas',
    },
    {
        label: 'Utah',
        abbreviation: 'UT',
        value: 'Utah',
    },
    {
        label: 'Vermont',
        abbreviation: 'VT',
        value: 'Vermont',
    },
    {
        label: 'Virginia',
        abbreviation: 'VA',
        value: 'Virginia',
    },
    {
        label: 'Washington',
        abbreviation: 'WA',
        value: 'Washington',
    },
    {
        label: 'West Virginia',
        abbreviation: 'WV',
        value: 'West Virginia',
    },
    {
        label: 'Wisconsin',
        abbreviation: 'WI',
        value: 'Wisconsin',
    },
    {
        label: 'Wyoming',
        abbreviation: 'WY',
        value: 'Wyoming',
    },
    {
        label: 'American Samoa',
        abbreviation: 'AS',
        value: 'American Samoa',
    },
    {
        label: 'Guam',
        abbreviation: 'GU',
        value: 'Guam',
    },
    {
        label: 'Northern Mariana Islands',
        abbreviation: 'MP',
        value: 'Northern Mariana Islands',
    },
    {
        label: 'Puerto Rico',
        abbreviation: 'PR',
        value: 'Puerto Rico',
    },
    {
        label: 'United States Virgin Islands',
        abbreviation: 'VI',
        value: 'United States Virgin Islands',
    },
];

export const isWithinUS = (latitude, longitude) => {
    // Continental United States Bounding Box
    const usMinLat = 24.396308;
    const usMaxLat = 49.384358;
    const usMinLng = -125.0;
    const usMaxLng = -66.93457;

    // Alaska Bounding Box
    const akMinLat = 51.2;
    const akMaxLat = 71.3;
    const akMinLng = -179.1;
    const akMaxLng = 179.9;

    // Puerto Rico Bounding Box
    const prMinLat = 17.9;
    const prMaxLat = 18.5;
    const prMinLng = -67.5;
    const prMaxLng = -65.8;

    // Guam Bounding Box
    const guamMinLat = 13.2;
    const guamMaxLat = 13.8;
    const guamMinLng = 144.6;
    const guamMaxLng = 145.0;

    // American Samoa Bounding Box
    const samoaMinLat = -14.6;
    const samoaMaxLat = -10.8;
    const samoaMinLng = -171.1;
    const samoaMaxLng = -168.1;

    // Northern Mariana Islands Bounding Box
    const cnmiMinLat = 14.0;
    const cnmiMaxLat = 20.6;
    const cnmiMinLng = 144.9;
    const cnmiMaxLng = 146.1;

    // U.S. Virgin Islands Bounding Box
    const usviMinLat = 17.6;
    const usviMaxLat = 18.4;
    const usviMinLng = -65.2;
    const usviMaxLng = -64.5;

    return (
        // Check if coordinates are within any of the bounding boxes
        (latitude >= usMinLat &&
            latitude <= usMaxLat &&
            longitude >= usMinLng &&
            longitude <= usMaxLng) ||
        (latitude >= akMinLat &&
            latitude <= akMaxLat &&
            longitude >= akMinLng &&
            longitude <= akMaxLng) ||
        (latitude >= prMinLat &&
            latitude <= prMaxLat &&
            longitude >= prMinLng &&
            longitude <= prMaxLng) ||
        (latitude >= guamMinLat &&
            latitude <= guamMaxLat &&
            longitude >= guamMinLng &&
            longitude <= guamMaxLng) ||
        (latitude >= samoaMinLat &&
            latitude <= samoaMaxLat &&
            longitude >= samoaMinLng &&
            longitude <= samoaMaxLng) ||
        (latitude >= cnmiMinLat &&
            latitude <= cnmiMaxLat &&
            longitude >= cnmiMinLng &&
            longitude <= cnmiMaxLng) ||
        (latitude >= usviMinLat &&
            latitude <= usviMaxLat &&
            longitude >= usviMinLng &&
            longitude <= usviMaxLng)
    );
};

/* eslint-enable import/prefer-default-export */
