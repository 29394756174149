/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox, Heading } from 'sarsaparilla';
import { SEARCH_FILTER_CAMPSITE_ELECTRICAL as electricalFiltersOptions } from '../constants';

// '50,30,15,20,50/30,100,15/30/50,5'

export default class SearchFilterCampsiteElectrical extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        headingLevel: PropTypes.number,
        uiFilters: PropTypes.object,
        aggregations: PropTypes.object,
        isRequired: PropTypes.bool,
        onFilterToggle: PropTypes.func,
        onApplyFilters: PropTypes.func,
        filterEmptyFilters: PropTypes.bool,
    };

    static defaultProps = {
        isRequired: false,
    };

    static displayName = 'SearchFilterCampsiteElectrical';

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange(event, item) {
        if (this.props.onFilterToggle) {
            this.props.onFilterToggle('campsiteElectricityHookup', item);
        }
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    processAggregations(searchAggregations) {
        const counts = {};
        // find aggregated counts for item attribute
        electricalFiltersOptions.forEach((item) => {
            const filter = item.filters[0];
            counts[filter.attribute] = {};
            if (
                searchAggregations.campsiteElectricityHookup &&
                searchAggregations.campsiteElectricityHookup[filter.attribute]
            ) {
                const aggs =
                    searchAggregations.campsiteElectricityHookup[filter.attribute];
                for (let i = 0; i < aggs.length; i++) {
                    counts[filter.attribute][aggs[i].name] = aggs[i].count;
                }
            }
        });
        // aggregate comma separate filter counts into one
        electricalFiltersOptions.forEach((item) => {
            const filter = item.filters[0];
            const parts = filter.filterValue.split(',');
            let count = 0;
            parts.forEach((part) => {
                const cnt = counts[filter.attribute];
                if (cnt && cnt[part]) {
                    count += cnt[part];
                }
            });
            counts[filter.attribute][filter.filterValue] = count;
        });
        return counts;
    }

    renderCheckbox(item, counts) {
        const { filterEmptyFilters } = this.props;
        const filter = item.filters[0];
        const attCounts = counts[filter.attribute];
        const selections = this.props.uiFilters.campsiteElectricityHookup;
        const isChecked = selections.hasOwnProperty(item.key);
        let count = 0;
        if (attCounts) {
            count = attCounts[filter.filterValue];
        }
        let label;
        if (count || isChecked) {
            label = `${item.value} (${count})`;
        } else {
            label = item.value;
        }

        if (filterEmptyFilters && count === 0 && !isChecked) {
            return null;
        }

        return (
            <div className="search-filter-check-item" htmlFor={item.key} key={item.key}>
                <Checkbox
                    onChange={(e) => this.handleChange(e, item)}
                    id={item.key}
                    label={label}
                    value={item.value}
                    className={count === 0 && !isChecked ? 'disabled' : ''}
                    isRequired={this.props.isRequired}
                    isChecked={isChecked}
                />
            </div>
        );
    }

    render() {
        const { headingLevel = 3 } = this.props;
        const className = `${this.props.className}`;
        const counts = this.processAggregations(this.props.aggregations);
        return (
            <div className={`${className}`}>
                <Heading headingLevel={headingLevel} appearance="h6">
                    Electrical Hookup
                </Heading>
                <div
                    className="search-filters-check-wrap"
                    role="group"
                    aria-label="Electrical hookup filters"
                >
                    <legend className="rec-sr-only">
                        Select electrical hookup to filter on
                    </legend>
                    {electricalFiltersOptions.map((item) =>
                        this.renderCheckbox(item, counts)
                    )}
                </div>
            </div>
        );
    }
}
