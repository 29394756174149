/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import {
    Icons,
    Button,
    FlexRow,
    DividerNew as Divider,
    FlexCol,
    Heading,
    LEGACY_ToggleSwitch,
    Switch,
    withLDConsumer,
} from 'sarsaparilla';
import { isEmpty, get, isNil } from 'lodash';

import SearchFilterStartTime from 'ui-search/src/components/SearchFilterStartTime';
import * as searchActions from '../actions/search';
import SearchFilterInventoryType from '../components/SearchFilterInventoryType';
import SearchFilterAgencies from '../components/SearchFilterAgencies';
import SearchFilterActivities from '../components/SearchFilterActivities';
import SearchFilterCampsiteTypes from '../components/SearchFilterCampsiteTypes';
import SearchFilterCampsiteAmenities from '../components/SearchFilterCampsiteAmenities';
import SearchFilterCampsiteElectrical from '../components/SearchFilterCampsiteElectrical';
import SearchFilterCampsiteEquipment from '../components/SearchFilterCampsiteEquipment';
import SearchFilterTourType from '../components/SearchFilterTourType';
import SearchFilterTourDuration from '../components/SearchFilterTourDuration';
import SearchFilterTourPrice from '../components/SearchFilterTourPrice';
import SearchFilterTourTime from '../components/SearchFilterTourTime';
import SearchFilterAvailable from '../components/SearchFilterAvailable';
import SearchFilterReservable from '../components/SearchFilterReservable';
import SearchFilterOccupants from '../components/SearchFilterOccupants';
import SearchFilterPartiallyAvailable from '../components/SearchFilterPartiallyAvailable';
import SearchFilterCampsiteVehicleLength from '../components/SearchFilterCampsiteVehicleLength';
import SearchFilterAccessibleCampsites from '../components/SearchFilterAccessibleCampsites';
import CampingFilters from '../components/filters/camping';
import AdditionalPreferences from '../components/filters/camping/AdditionalPreferences';
import InventoryTypeFilters from '../components/filters/inventoryTypes';
import { getFgActiveFiltersForGa } from '../utils/filters';

class SearchFilters extends React.Component {
    static propTypes = {
        account: PropTypes.object,
        uiFilters: PropTypes.object,
        search: PropTypes.object,
        searchAggregations: PropTypes.object,
        dispatch: PropTypes.func,
        onApplyFilters: PropTypes.func,
        searchResults: PropTypes.object,
        hasDatesInfoModal: PropTypes.func,
        onShowAvailableModalChange: PropTypes.func,
        onPreferencesModal: PropTypes.func,
        loadingPreferences: PropTypes.func,
        onApplyPreferences: PropTypes.func,
        flags: PropTypes.func,
    };

    static defaultProps = {};

    static displayName = 'SearchFilters';

    constructor(props) {
        super(props);
        this.onApplyFilter = this.onApplyFilter.bind(this);
        this.onApplyFilters = this.onApplyFilters.bind(this);
        this.onFilterToggle = this.onFilterToggle.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onFilterRemove = this.onFilterRemove.bind(this);
        this.onResetFilters = this.onResetFilters.bind(this);
        this.onHideFilters = this.onHideFilters.bind(this);
        this.onUpdateSearchCriteria = this.onUpdateSearchCriteria.bind(this);
    }

    onResetFilters() {
        this.props.dispatch(
            searchActions.updateSearchCriterias({
                checkin_time: '',
                checkout_time: '',
                avail_nights: '',
                avail_months: [],
                tripPreferences: false,
                fg: [],
            })
        );

        this.props.dispatch(searchActions.resetSearchFilters());
        this.props.dispatch(searchActions.fetchInventoryTypesAggregations());
        this.props.dispatch(searchActions.fetchSearchFiltersAggregations());
    }

    onApplyFilters() {
        this.props.dispatch(searchActions.updateSearchCriteria('filtersVisible', false));
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    onApplyFilter() {
        setTimeout(() => {
            if (this.props.onApplyFilters) {
                this.props.onApplyFilters();
            }
        }, 200);
    }

    onFilterToggle(filterName, item) {
        this.props.dispatch(searchActions.toggleSearchFilter(filterName, item));
    }

    onFilterChange(filterName, item) {
        this.props.dispatch(searchActions.setSearchFilter(filterName, item));
    }

    onFilterRemove(filterName, item) {
        this.props.dispatch(searchActions.removeSearchFilter(filterName, item));
    }

    onUpdateSearchCriteria(name, value) {
        this.props.dispatch(searchActions.updateSearchCriteria(name, value));
    }

    onHideFilters() {
        this.props.dispatch(searchActions.updateSearchCriteria('filtersVisible', false));
    }

    getInventoryFiltersCount = () => {
        let ret = 0;
        if (this.props.search && this.props.search.uiFilters) {
            ret += Object.keys(this.props.search.uiFilters.inventoryTypes).length;
        }
        return ret;
    };

    getCampsiteFilterCount = () => {
        let campsiteFilterCount = 0;
        if (
            this.props.flags.useNewCampingFilters &&
            this.props.search.fg &&
            this.props.search.fg.length > 0
        ) {
            campsiteFilterCount += this.props.search.fg.length;
        }
        if (this.props.search && this.props.search.uiFilters) {
            if (!this.props.flags.useNewCampingFilters) {
                campsiteFilterCount += Object.keys(
                    this.props.search.uiFilters.campsiteUseTypes
                ).length;
                campsiteFilterCount += Object.keys(
                    this.props.search.uiFilters.campsiteTypes
                ).length;
                campsiteFilterCount += Object.keys(
                    this.props.search.uiFilters.campsiteAmenities
                ).length;
                campsiteFilterCount += Object.keys(
                    this.props.search.uiFilters.campsiteEquipment
                ).length;
                campsiteFilterCount += Object.keys(
                    this.props.search.uiFilters.campsiteElectricityHookup
                ).length;
                let fltValue = get(this.props.search, 'include_unavailable', true);
                if (fltValue === false) {
                    campsiteFilterCount += 1;
                }
                fltValue = get(this.props.search, 'include_notreservable', true);
                if (fltValue === false) {
                    campsiteFilterCount += 1;
                }
                fltValue = get(this.props.search, 'include_partially_available', true);
                if (fltValue === false) {
                    campsiteFilterCount += 1;
                }
            }
            campsiteFilterCount += Object.keys(
                this.props.search.uiFilters.campsiteVehicleLength
            ).length;
            campsiteFilterCount += Object.keys(
                this.props.search.uiFilters.occupants
            ).length;
        }
        return campsiteFilterCount;
    };

    getTourFilterCount = () => {
        let tourFilterCount = 0;
        if (this.props.search && this.props.search.uiFilters) {
            tourFilterCount += Object.keys(this.props.search.uiFilters.tourTypes).length;
            tourFilterCount += Object.keys(
                this.props.search.uiFilters.tourAccessible
            ).length;
            tourFilterCount += Object.keys(
                this.props.search.uiFilters.tourDifficulty
            ).length;
            tourFilterCount += Object.keys(
                this.props.search.uiFilters.tourDuration
            ).length;
            tourFilterCount += Object.keys(this.props.search.uiFilters.tourPrice).length;
            tourFilterCount += Object.keys(this.props.search.uiFilters.tourTime).length;
            tourFilterCount += Object.keys(this.props.search.uiFilters.tourTimes).length;
        }
        return tourFilterCount;
    };

    getActivitiesFilterCount = () => {
        let activitiesFilterCount = 0;

        if (this.props.search && this.props.search.uiFilters) {
            activitiesFilterCount += Object.keys(
                this.props.search.uiFilters.activities
            ).length;
        }
        return activitiesFilterCount;
    };

    getPermitFilterCount = () => {
        let permitFilterCount = 0;

        if (this.props.search && this.props.search.uiFilters) {
            permitFilterCount += Object.keys(
                this.props.search.uiFilters.permitTypes
            ).length;
        }
        return permitFilterCount;
    };

    getAgenciesFiltersCount = () => {
        let ret = 0;

        if (this.props.search && this.props.search.uiFilters) {
            ret += Object.keys(this.props.search.uiFilters.agencies).length;
        }
        return ret;
    };

    checkInventoryType = (value) => {
        const { search, flags } = this.props;
        if (flags?.useNewCampingFilters && search.fg) {
            return search.fg?.indexOf(value) !== -1;
        }
        return false;
    };

    // Send Selected Filter Info to GA
    getActiveFilters = () => {
        const ShowFilter = (visibleFilterHeader, filterCategoryName, hasPipe = true) => {
            let filterSegment = '';

            if (Object.keys(this.props.search.uiFilters[filterCategoryName]).length > 0) {
                filterSegment = `${
                    hasPipe ? '|' : ''
                } ${visibleFilterHeader}: ${Object.keys(
                    this.props.search.uiFilters[filterCategoryName]
                )} `;
            }

            return filterSegment;
        };

        const ShowAvailabilityFilters = (visibleFilterHeader, filterType) => {
            let filterSegment = '';

            if (get(this.props.search, filterType, false) === false) {
                filterSegment = `| ${visibleFilterHeader} `;
            }

            return filterSegment;
        };

        const ShowSizeFilters = (visibleFilterHeader, filterType) => {
            let filterSegment = '';

            const getSize =
                this?.props?.search?.uiFilters[filterType][filterType]?.filters[0]
                    ?.filterValue || [];

            if (getSize.length > 0) {
                filterSegment = `| ${visibleFilterHeader}: ${filterType}: ${getSize} `;
            }

            return filterSegment;
        };

        let activeFilters = '';

        if (this.props.search && this.props.search.uiFilters) {
            // Camping
            activeFilters += ShowFilter('Booking Types', 'inventoryTypes', false);

            if (process.env?.SEARCH?.showFederalAgencies) {
                activeFilters += ShowFilter(
                    'Federal Agencies Offering Reservations',
                    'agencies',
                    false
                );
            }

            activeFilters += ShowFilter('Allowable Equipment', 'campsiteEquipment');
            activeFilters += ShowFilter('Recreation Site Type', 'campsiteTypes');
            activeFilters += ShowFilter('Amenities', 'campsiteAmenities');
            activeFilters += ShowFilter('Electrical Hookup', 'campsiteElectricityHookup');

            // Tours
            activeFilters += ShowFilter('Tour Types', 'tourTypes');
            activeFilters += ShowFilter('Tour Accessible', 'tourAccessible');
            activeFilters += ShowFilter('Level of Effort', 'tourDifficulty');

            // Permits
            if (process.env?.ALLOWED_INVENTORY_TYPES?.permits) {
                activeFilters += ShowFilter('Permits', 'permitTypes');
            }

            // Activities
            activeFilters += ShowFilter('Activities', 'activities');

            activeFilters += ShowAvailabilityFilters(
                'Hide Unavailable',
                'include_unavailable'
            );
            activeFilters += ShowAvailabilityFilters(
                'Hide First-come, First-served',
                'include_notreservable'
            );
            activeFilters += ShowAvailabilityFilters(
                'Hide Partially Available',
                'include_partially_available'
            );

            // Group & Vehicle Size
            activeFilters += ShowSizeFilters('Group Size', 'occupants');
            activeFilters += ShowSizeFilters(
                'Vehicle Length (ft)',
                'campsiteVehicleLength'
            );
        }

        const fg = get(this.props, 'search.fg', []);

        if (!isEmpty(fg)) {
            activeFilters += getFgActiveFiltersForGa(fg);
        }

        return activeFilters;
    };

    openPreferencesModal = () => {
        this.props.onPreferencesModal(true);
    };

    renderSearchFilters = (
        campsiteFilterCount,
        tourFilterCount,
        activitiesFilterCount
    ) => {
        const { flags } = this.props;
        const useNewCampingFilters = flags?.useNewCampingFilters;
        const isCampingSearch = this.checkInventoryType('camping');
        const isDayUseSearch = this.checkInventoryType('dayuse');
        const isLegacyTicketSearch =
            isEmpty(this.props.search.uiFilters.inventoryTypes) ||
            this.props.search.uiFilters.inventoryTypes.tours;
        const isLegacyPermitsSearch =
            isEmpty(this.props.search.uiFilters.inventoryTypes) ||
            this.props.search.uiFilters.inventoryTypes.permits;
        const isTicketsSearch =
            this.checkInventoryType('tickets') ||
            (!useNewCampingFilters && isLegacyTicketSearch);
        const isPermitsSearch =
            this.checkInventoryType('permits') ||
            (!useNewCampingFilters && isLegacyPermitsSearch);
        const isPoiSearch = this.checkInventoryType('poi');
        const isVenueSearch = this.checkInventoryType('venues');
        const isActivityPassSearch = this.checkInventoryType('activitypass');
        const isTreePermitsSearch = this.checkInventoryType('treepermits');
        const isRecAreaSearch = this.checkInventoryType('recarea');
        const hasNoInventory =
            !isCampingSearch &&
            !isDayUseSearch &&
            !isTicketsSearch &&
            !isPermitsSearch &&
            !isPoiSearch &&
            !isVenueSearch &&
            !isActivityPassSearch &&
            !isTreePermitsSearch &&
            !isRecAreaSearch;
        const applyPreferences =
            this.props.account &&
            !isNil(this.props.search?.tripPreferences) &&
            this.props.search?.tripPreferences;

        return (
            <div
                className={cx('search-filters-dropdown-body', {
                    'rounded-layout': useNewCampingFilters,
                })}
            >
                <section>
                    <div
                        className={cx('search-filters-section-header', {
                            'rounded-layout': useNewCampingFilters,
                            'mb-5': !useNewCampingFilters,
                            'mb-2': useNewCampingFilters,
                        })}
                    >
                        <div className="search-filter-section__trip-preferences">
                            {!useNewCampingFilters && (
                                <Heading
                                    headingLevel={2}
                                    appearance="h5"
                                    id="apply-trip-preferences"
                                >
                                    Apply Trip Preferences
                                </Heading>
                            )}
                            <div className="search-filters-section-preferences my-2">
                                {!useNewCampingFilters && (
                                    <LEGACY_ToggleSwitch
                                        labelledBy="apply-trip-preferences"
                                        isOn={applyPreferences}
                                        isDisabled={this.props.loadingPreferences}
                                        onClick={this.props.onApplyPreferences}
                                        id="toggle-preferences"
                                        clickTagCategory="Search Results Filters"
                                        clickTagLabel="Apply Trip Preferences Toggle"
                                    />
                                )}
                                {useNewCampingFilters && (
                                    <div>
                                        <Switch
                                            isSelected={applyPreferences}
                                            label="Apply Trip Preferences"
                                            isDisabled={this.props.loadingPreferences}
                                            onChange={this.props.onApplyPreferences}
                                            id="toggle-preferences"
                                            clickTagCategory="Search Results Filters"
                                            clickTagLabel="Apply Trip Preferences Toggle"
                                        />
                                    </div>
                                )}
                                <div>
                                    <Button
                                        onClick={this.openPreferencesModal}
                                        appearance="link"
                                        clickTagCategory="Search Results Filters"
                                        clickTagAction="Open Trip Preferences Modal"
                                        clickTagLabel="Manage Trip Preferences"
                                    >
                                        Manage Trip Preferences
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="search-filters-type-wrap">
                    <div
                        className={cx('', {
                            'search-filters-filter-container pb-2': useNewCampingFilters,
                        })}
                    >
                        {!useNewCampingFilters && (
                            <SearchFilterInventoryType
                                uiFilters={this.props.search.uiFilters}
                                aggregations={this.props.searchAggregations}
                                onFilterToggle={this.onFilterToggle}
                                onApplyFilters={this.onApplyFilter}
                                useNewCampingFilters={useNewCampingFilters}
                            />
                        )}
                        {useNewCampingFilters && (
                            <InventoryTypeFilters
                                aggregations={this.props.searchAggregations}
                                onApplyFilters={this.onApplyFilter}
                            />
                        )}
                    </div>
                </section>
                {useNewCampingFilters &&
                    (isCampingSearch || isDayUseSearch || hasNoInventory) && (
                        <CampingFilters
                            uiFilters={this.props.search.uiFilters}
                            aggregations={this.props.searchAggregations}
                            onFilterToggle={this.onFilterToggle}
                            onApplyFilters={this.onApplyFilter}
                            onFilterRemove={this.onFilterRemove}
                            onFilterChange={this.onFilterChange}
                            search={this.props.search}
                        />
                    )}
                {!useNewCampingFilters && (
                    <>
                        <section className="search-filters-type-wrap">
                            <SearchFilterAgencies
                                uiFilters={this.props.search.uiFilters}
                                aggregations={this.props.searchAggregations}
                                onFilterToggle={this.onFilterToggle}
                                onApplyFilters={this.onApplyFilter}
                            />
                        </section>
                        {(isEmpty(this.props.search.uiFilters.inventoryTypes) ||
                            this.props.search.uiFilters.inventoryTypes.camping ||
                            this.props.search.uiFilters.inventoryTypes.dayuse) && (
                            <section className="search-filters-type-wrap">
                                <div
                                    className={cx('search-filters-section-header', {
                                        'rounded-layout mb-1 mt-3': useNewCampingFilters,
                                        'mt-4': !useNewCampingFilters,
                                    })}
                                >
                                    <Heading headingLevel={2} appearance="h5">
                                        Camping/Lodging and Day Use
                                    </Heading>
                                    <span>
                                        {`${this.getCampsiteFilterCount(campsiteFilterCount)} ${
                                            this.getCampsiteFilterCount(
                                                campsiteFilterCount
                                            ) === 1
                                                ? 'Filter'
                                                : 'Filters'
                                        } Selected`}
                                    </span>
                                </div>

                                <div className="search-filters-special-options-wrap">
                                    <div className="search-filters-special-check-options">
                                        <SearchFilterAvailable
                                            search={this.props.search}
                                            aggregations={this.props.searchAggregations}
                                            onFilterChange={this.onUpdateSearchCriteria}
                                            onApplyFilters={this.onApplyFilter}
                                            hasDatesInfoModal={
                                                this.props.hasDatesInfoModal
                                            }
                                            onShowAvailableModalChange={
                                                this.props.onShowAvailableModalChange
                                            }
                                        />

                                        <SearchFilterReservable
                                            search={this.props.search}
                                            aggregations={this.props.searchAggregations}
                                            onFilterChange={this.onUpdateSearchCriteria}
                                            onApplyFilters={this.onApplyFilter}
                                            hasDatesInfoModal={
                                                this.props.hasDatesInfoModal
                                            }
                                            onShowAvailableModalChange={
                                                this.props.onShowAvailableModalChange
                                            }
                                        />

                                        <SearchFilterPartiallyAvailable
                                            search={this.props.search}
                                            aggregations={this.props.searchAggregations}
                                            onFilterChange={this.onUpdateSearchCriteria}
                                            onApplyFilters={this.onApplyFilter}
                                            hasDatesInfoModal={
                                                this.props.hasDatesInfoModal
                                            }
                                            onShowAvailableModalChange={
                                                this.props.onShowAvailableModalChange
                                            }
                                        />

                                        <SearchFilterAccessibleCampsites
                                            search={this.props.search}
                                            aggregations={this.props.searchAggregations}
                                            onFilterToggle={this.onFilterToggle}
                                            onApplyFilters={this.onApplyFilter}
                                        />
                                    </div>
                                </div>

                                <SearchFilterOccupants
                                    className="occupants"
                                    uiFilters={this.props.search.uiFilters}
                                    aggregations={this.props.searchAggregations}
                                    onFilterChange={this.onFilterChange}
                                    onFilterRemove={this.onFilterRemove}
                                    onApplyFilters={this.onApplyFilter}
                                />

                                <SearchFilterCampsiteVehicleLength
                                    className="rec-form-inline-item"
                                    uiFilters={this.props.search.uiFilters}
                                    aggregations={this.props.searchAggregations}
                                    onFilterChange={this.onFilterChange}
                                    onFilterRemove={this.onFilterRemove}
                                    onApplyFilters={this.onApplyFilter}
                                />

                                <SearchFilterCampsiteTypes
                                    uiFilters={this.props.search.uiFilters}
                                    aggregations={this.props.searchAggregations}
                                    onFilterToggle={this.onFilterToggle}
                                    onFilterChange={this.onFilterChange}
                                    onApplyFilters={this.onApplyFilter}
                                />

                                <SearchFilterCampsiteEquipment
                                    uiFilters={this.props.search.uiFilters}
                                    aggregations={this.props.searchAggregations}
                                    onFilterToggle={this.onFilterToggle}
                                    onApplyFilters={this.onApplyFilter}
                                />

                                <SearchFilterCampsiteAmenities
                                    uiFilters={this.props.search.uiFilters}
                                    aggregations={this.props.searchAggregations}
                                    onFilterToggle={this.onFilterToggle}
                                    onApplyFilters={this.onApplyFilter}
                                />

                                <SearchFilterCampsiteElectrical
                                    uiFilters={this.props.search.uiFilters}
                                    aggregations={this.props.searchAggregations}
                                    onFilterToggle={this.onFilterToggle}
                                    onApplyFilters={this.onApplyFilter}
                                />
                            </section>
                        )}
                    </>
                )}

                {(isTicketsSearch || hasNoInventory) && (
                    <section className="search-filters-type-wrap">
                        <div
                            className={cx('search-filters-section-header', {
                                'rounded-layout mb-1 mt-3': useNewCampingFilters,
                            })}
                        >
                            <Heading headingLevel={2} appearance="h5">
                                Tickets & Tours
                            </Heading>
                            <span>
                                {`${this.getTourFilterCount(tourFilterCount)} ${
                                    this.getTourFilterCount(tourFilterCount) === 1
                                        ? 'Filter'
                                        : 'Filters'
                                } Selected`}
                            </span>
                        </div>
                        <div
                            className={cx('', {
                                'search-filters-filter-container pb-2 mb-4':
                                    useNewCampingFilters,
                            })}
                        >
                            {this.props?.flags?.useTimeTicketFilter && (
                                <SearchFilterStartTime
                                    uiFilters={this.props.search.uiFilters}
                                    aggregations={this.props.searchAggregations}
                                    onFilterToggle={this.onFilterToggle}
                                    onApplyFilters={this.onApplyFilter}
                                    useNewCampingFilters={useNewCampingFilters}
                                />
                            )}
                            {useNewCampingFilters && (
                                <div className="pb-2">
                                    <Divider />
                                </div>
                            )}

                            <SearchFilterTourType
                                uiFilters={this.props.search.uiFilters}
                                aggregations={this.props.searchAggregations}
                                onFilterToggle={this.onFilterToggle}
                                onApplyFilters={this.onApplyFilter}
                                useNewCampingFilters={useNewCampingFilters}
                            />
                            {useNewCampingFilters && (
                                <div className="pb-4">
                                    <Divider />
                                </div>
                            )}

                            <FlexRow className="search-slider-wrap">
                                {/* FlexCol is added in the component for the three items below */}
                                <SearchFilterTourPrice
                                    uiFilters={this.props.search.uiFilters}
                                    aggregations={this.props.searchAggregations}
                                    onFilterChange={this.onFilterChange}
                                    onFilterRemove={this.onFilterRemove}
                                    onApplyFilters={this.onApplyFilter}
                                    useNewCampingFilters={useNewCampingFilters}
                                />

                                <SearchFilterTourDuration
                                    uiFilters={this.props.search.uiFilters}
                                    aggregations={this.props.searchAggregations}
                                    onFilterChange={this.onFilterChange}
                                    onFilterRemove={this.onFilterRemove}
                                    onApplyFilters={this.onApplyFilter}
                                    useNewCampingFilters={useNewCampingFilters}
                                />

                                <SearchFilterTourTime
                                    uiFilters={this.props.search.uiFilters}
                                    aggregations={this.props.searchAggregations}
                                    onFilterChange={this.onFilterChange}
                                    onFilterRemove={this.onFilterRemove}
                                    onApplyFilters={this.onApplyFilter}
                                    useNewCampingFilters={useNewCampingFilters}
                                />
                            </FlexRow>

                            {useNewCampingFilters && (
                                <div className="pb-2">
                                    <Divider />
                                </div>
                            )}
                        </div>
                    </section>
                )}

                {!useNewCampingFilters &&
                    (isEmpty(this.props.search.uiFilters.inventoryTypes) ||
                        this.props.search.uiFilters.inventoryTypes.poi ||
                        this.props.search.uiFilters.inventoryTypes.camping ||
                        this.props.search.uiFilters.inventoryTypes.dayuse) && (
                        <section className="search-filters-type-wrap">
                            <div
                                className={cx('search-filters-section-header', {
                                    'rounded-layout mb-1 mt-3': useNewCampingFilters,
                                })}
                            >
                                <Heading headingLevel={2} appearance="h5">
                                    Activities
                                </Heading>
                                <span>
                                    {`${this.getActivitiesFilterCount(
                                        activitiesFilterCount
                                    )} ${
                                        this.getActivitiesFilterCount(
                                            activitiesFilterCount
                                        ) === 1
                                            ? 'Filter'
                                            : 'Filters'
                                    } Selected`}
                                </span>
                            </div>
                            <div
                                className={cx('', {
                                    'search-filters-filter-container pb-2':
                                        useNewCampingFilters,
                                })}
                            >
                                <SearchFilterActivities
                                    uiFilters={this.props.search.uiFilters}
                                    aggregations={this.props.searchAggregations}
                                    onFilterToggle={this.onFilterToggle}
                                    onApplyFilters={this.onApplyFilter}
                                    useNewCampingFilters={useNewCampingFilters}
                                />
                            </div>
                        </section>
                    )}
                {useNewCampingFilters && (
                    <AdditionalPreferences
                        uiFilters={this.props.search.uiFilters}
                        aggregations={this.props.searchAggregations}
                        onFilterToggle={this.onFilterToggle}
                        onApplyFilters={this.onApplyFilter}
                        onFilterRemove={this.onFilterRemove}
                        onFilterChange={this.onFilterChange}
                        search={this.props.search}
                    />
                )}
            </div>
        );
    };

    render() {
        const filtersCount =
            this.getInventoryFiltersCount() +
            this.getCampsiteFilterCount(this.campsiteFilterCount) +
            this.getActivitiesFilterCount(this.activitiesFilterCount) +
            this.getTourFilterCount(this.tourFilterCount) +
            this.getPermitFilterCount(this.permitFilterCount) +
            this.getAgenciesFiltersCount();

        const searchFilters = this.renderSearchFilters(filtersCount);
        const totalItems = get(this.props.searchResults, 'total', 0);

        // SHOW THE TOTAL NUMBER OF RESULTS ON THE APPLY BUTTON
        const totalItemLabel = totalItems === 1 ? 'Result' : 'Results';
        const totalItemText =
            totalItems > 0 ? `View ${totalItems} ${totalItemLabel}` : 'No Results';

        const clearBtn = (
            <Button
                appearance="link"
                iconAfterElement={<Icons.IconLoop />}
                className="reset"
                id="testhook-reset"
                onClick={this.onResetFilters}
            >
                Clear All Filters
            </Button>
        );

        return (
            <div
                className={`search-filters-dropdown-wrap ${
                    this.props.search.filtersVisible ? 'search-filters-visible' : ''
                }`}
            >
                <h1 className="rec-sr-only">Search Filters</h1>

                {/*  We will be adding this functionality back in at a later date
                    <SearchFiltersHeader
                        uiFilters={this.props.search.uiFilters}
                        aggregations={this.props.searchAggregations}
                        onFilterChange={this.onFilterChange}
                        onFilterRemove={this.onFilterRemove}
                        onFilterToggle={this.onFilterToggle}
                        onApplyFilters={this.onApplyFilter} />
                */}

                <FlexRow hasGutters={false}>
                    <FlexCol>{searchFilters}</FlexCol>
                </FlexRow>

                <div className="search-filters-actions">
                    {filtersCount <= 0 ? '' : clearBtn}

                    <div className="search-close-cancel-wrap">
                        <Button
                            appearance="link"
                            id="testhook-hide"
                            onClick={this.onHideFilters}
                        >
                            Cancel
                        </Button>

                        <Button
                            className="close-btn"
                            iconAfterElement={<Icons.IconArrowUp />}
                            size="lg"
                            id="testhook-apply"
                            onClick={this.onApplyFilters}
                            clickTagCategory="Search Results Filters"
                            clickTagAction={`Filter By - ${
                                filtersCount <= 0
                                    ? 'No Filters Selected'
                                    : this.getActiveFilters()
                            }`}
                            clickTagLabel="View Results Button"
                        >
                            {filtersCount <= 0 ? 'Hide Filters' : totalItemText}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

function select(state) {
    return {
        search: state.search,
        account: get(state, 'auth.account', null),
        searchAggregations: state.searchAggregations,
        searchResults: state.searchResults,
    };
}

export default withLDConsumer()(connect(select)(SearchFilters));
