/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';

import { useAssetImages } from 'sarsaparilla';

import SearchResultCard from './SearchResultCard';

export default function SearchResultCardWithImageFetch({
    assetId,
    campsiteId,
    tourId,
    ...rest
}) {
    const { previewImage } = useAssetImages({ assetId, campsiteId, tourId });
    const imageUrl = previewImage?.url;

    return <SearchResultCard {...rest} imageUrl={imageUrl} />;
}

SearchResultCardWithImageFetch.propTypes = {
    assetId: PropTypes.string,
    campsiteId: PropTypes.string,
    tourId: PropTypes.string,
};
