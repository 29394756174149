/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useRef } from 'react';
import { DividerPromo, ButtonGroup, Button } from 'sarsaparilla';

export default function TreePermitContainer() {
    const targetRef = useRef();

    const href = '/tree-permits/';

    return (
        <DividerPromo
            heading="Christmas Tree Permits"
            backgroundImagePath={`${process.env.CDN}/img/homepage/divider-promo-section/xmas-tree-banner-bg-2021.jpg`}
            copy={`National Forests across the country are now offering permits to cut down a Christmas Tree through ${process.env.SITE_NAME}. Buy your permit online before you arrive, gather your family and friends, and create memories that help you #BringHomeaStory this holiday season!`}
            height="md"
            refForIntersection={targetRef}
        >
            <ButtonGroup isFullWidthOnMobile={false}>
                <Button
                    appearance="tertiary-white"
                    href={href}
                    gaReportName={'Christmas Tree Permit Container Promotion'}
                    gaReportCreative={'christmas tree promo'}
                    gaReportPosition={'HomePage'}
                    shouldReportToGA
                >
                    Learn More
                </Button>
            </ButtonGroup>
        </DividerPromo>
    );
}
