/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * BringHomeAStory.jsx
 * Created by Destin Frasier on 03/27/18
 */

import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { HtmlParser } from 'sarsaparilla';

import { BRING_HOME_A_STORY_ITEM } from '../../../../constants/propTypes';

const propTypes = {
    stories: PropTypes.arrayOf(BRING_HOME_A_STORY_ITEM),
};

const defaultProps = {
    stories: [],
};

function BringHomeStoryItems({ stories }) {
    return (
        <div className="nav-article-left-rail-item">
            <div className="nav-article-left-rail-item-title">Bring Home a Story</div>
            <ul className="nav-article-img-link">
                {stories.map((story) => (
                    <li key={`${uniqueId(story.bring_home_a_story_title)}`}>
                        <a
                            href={story.bring_home_a_story_link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <HtmlParser html={story.bring_home_a_story_title} />
                        </a>
                        <span className="nav-article-subtext">
                            <HtmlParser html={story.bring_home_a_story_location} />
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

BringHomeStoryItems.propTypes = propTypes;
BringHomeStoryItems.defaultProps = defaultProps;

export default BringHomeStoryItems;
