/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { FlexRow, FlexCol, Heading } from 'sarsaparilla';

const photoItems = [
    {
        title: 'National Park Service Multimedia Search',
        url: 'https://www.nps.gov/media/multimedia-search.htm',
    },
    {
        title: 'US Forest Service Webcams, Photos & Videos',
        url: 'https://www.fs.usda.gov/visit/webcams-photos-videos',
    },
    {
        title: 'US Forest Service Flickr',
        url: 'https://www.flickr.com/photos/usforestservice/',
    },
    {
        title: 'US Fish and Wildlife Service National Digital Library',
        url: 'https://digitalmedia.fws.gov/',
    },
    {
        title: 'US Army Corps of Engineers Image Library',
        url: 'https://www.usace.army.mil/Media/Images/',
    },
    {
        title: 'Bureau of Land Management Flickr',
        url: 'https://www.flickr.com/photos/91981596@N06/',
    },
    {
        title: 'NOAA Photo Library',
        url: 'https://photolib.noaa.gov/',
    },
    {
        title: 'Bureau of Reclamation Photo Database',
        url: 'https://www.usbr.gov/lc/region/g5000/photolab/photostart.cfm',
    },
];

export default function MediaCenterPhotosVideo() {
    return (
        <section className="nav-media-center-photos px-3 py-3 px-md-5 py-md-4">
            <FlexRow justifyContent="center">
                <FlexCol xl={10} lg={12}>
                    <div className="nav-media-center-photos-call-out p-md-5 mt-1">
                        <FlexRow>
                            <FlexCol lg={7}>
                                <Heading
                                    headingLevel={2}
                                    appearance="h4"
                                    className="mb-2"
                                >
                                    Photos and Video Usage
                                </Heading>
                                <p className="pr-md-4">
                                    Many of the images used on Recreation.gov are from the
                                    Share the Experience photo contest; these photos may
                                    not be reused without permission from the
                                    photographer.
                                </p>
                                <p className="pr-md-4">
                                    Images are also provided by Recreation.gov
                                    participating agencies. To find photos that are part
                                    of public domain, please visit these links and follow
                                    the guidelines provided there.
                                </p>
                            </FlexCol>
                            <FlexCol lg={5}>
                                <ul className="nav-media-center-photos-list">
                                    {photoItems.map((photoItem) => (
                                        <li
                                            className="nav-media-center-photo-item"
                                            key={photoItem.title}
                                        >
                                            <a
                                                className="nav-media-center-photo-item-title"
                                                href={photoItem.url}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                            >
                                                {photoItem.title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </FlexCol>
                        </FlexRow>
                    </div>
                </FlexCol>
            </FlexRow>
        </section>
    );
}

// cSpell:ignore Flickr, NOAA
