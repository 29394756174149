/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

export const BOUNDARY_WATERS_PERMIT_ID = '233396';

// Permit Types
export const TYPE_OVERNIGHT_PADDLE = 'OvernightPaddle';
export const TYPE_OVERNIGHT_HIKE = 'OvernightHike';
export const TYPE_OVERNIGHT_MOTOR = 'OvernightMotor';
export const TYPE_DAYUSE_MOTOR = 'DayUseMotor';
export const TYPE_CANADA_MOTOR = 'CanadaMotor';
export const TYPE_CANADA_PADDLE = 'CanadaPaddle';

// permit
export const GET_PERMIT = 'BW_GET_PERMIT';
export const GET_PERMIT_ERROR = 'BW_GET_PERMIT_ERROR';
export const SET_PERMIT_TYPE = 'BW_SET_PERMIT_TYPE';
export const SET_PERMIT_DATE = 'BW_SET_PERMIT_DATE';
export const SET_SELECTED_DIVISION = 'BW_SET_SELECTED_DIVISION';
export const SET_SELECTED_START_DATE = 'BW_SET_SELECTED_START_DATE';
export const SET_SELECTED_END_DATE = 'BW_SET_SELECTED_END_DATE';
export const SET_SELECTED_DIVISION2 = 'BW_SET_SELECTED_DIVISION2';
export const SET_SELECTED_START_DATE2 = 'BW_SET_SELECTED_START_DATE2';
export const SET_SELECTED_END_DATE2 = 'BW_SET_SELECTED_END_DATE2';
export const GET_COOPERATORS = 'BW_GET_COOPERATORS';
export const GET_COOPERATORS_ERROR = 'BW_GET_COOPERATORS_ERROR';
export const GET_COOPERATOR = 'BW_GET_COOPERATOR';
export const GET_COOPERATOR_ERROR = 'BW_GET_COOPERATOR_ERROR';
export const SET_QUESTIONNAIRE_ANSWERS = 'BW_SET_QUESTIONNAIRE_ANSWERS';
export const SET_ACTIVE_TAB = 'BW_SET_ACTIVE_TAB';
export const SET_DIV_SEARCH_CRITERIA = 'BW_SET_DIV_SEARCH_CRITERIA';
export const SET_VALIDATION_ERROR = 'BW_SET_VALIDATION_ERROR';
export const GET_RECAREA = 'BW_GET_RECAREA';
export const GET_RECAREA_ERROR = 'BW_GET_RECAREA_ERROR';
export const SET_EXISTING_ISSUANCE = 'BW_SET_EXISTING_ISSUANCE';
export const SET_CLEAR_ISSUANCE_ERRORS = 'BW_CLEAR_ISSUANCE_ERRORS';
export const GET_PERMIT_GALLERY = 'BW_GET_PERMIT_GALLERY';

// availability
export const GET_AVAILABILITY = 'BW_GET_AVAILABILITY';
export const GET_AVAILABILITY_ERROR = 'BW_GET_AVAILABILITY_ERROR';
export const CLEAR_AVAILABILITIES = 'BW_CLEAR_AVAILABILITIES';
export const GET_AGGREGATE_SEASON_RULES = 'BW_GET_AGGREGATE_SEASON_RULES';
export const GET_AGGREGATE_SEASON_ERROR = 'BW_GET_AGGREGATE_SEASON_ERROR';

// issuance
export const POST_ISSUANCE = 'BW_POST_ISSUANCE';
export const SET_ISSUANCE_LOADING = 'BW_SET_ISSUANCE_LOADING';
export const POST_ISSUANCE_ERROR = 'BW_POST_ISSUANCE_ERROR';
export const POST_ISSUANCE_CALCULATE_FEES = 'BW_POST_ISSUANCE_CALCULATE_FEES';
export const POST_ISSUANCE_CALCULATE_FEES_ERROR = 'BW_POST_ISSUANCE_CALCULATE_FEES_ERROR';
export const POST_ISSUANCE_CARTLESS = 'BW_POST_ISSUANCE_CARTLESS';
export const POST_ISSUANCE_CARTLESS_ERROR = 'BW_POST_ISSUANCE_CARTLESS_ERROR';
export const UPDATE_ISSUANCE = 'UPDATE_ISSUANCE';
export const UPDATE_ISSUANCE_ERROR = 'UPDATE_ISSUANCE_ERROR';
export const GET_ISSUANCE = 'BW_GET_ISSUANCE';
export const GET_ISSUANCE_ERROR = 'BW_GET_ISSUANCE_ERROR';
export const GET_ISSUANCE_LIST = 'BW_GET_ISSUANCE_LIST';
export const CLEAR_ISSUANCE_LIST = 'BW_CLEAR_ISSUANCE_LIST';
export const GET_ISSUANCE_LIST_ERROR = 'BW_GET_ISSUANCE_LIST_ERROR';
export const GET_ISSUANCE_BY_STATUS = 'BW_GET_ISSUANCE_BY_STATUS';
export const GET_ISSUANCE_BY_STATUS_ERROR = 'BW_GET_ISSUANCE_BY_STATUS_ERROR';
export const GET_ISSUANCE_PDF = 'BW_GET_ISSUANCE_PDF';
export const GET_ISSUANCE_PDF_ERROR = 'BW_GET_ISSUANCE_PDF_ERROR';
export const CANCEL_ISSUANCE = 'BW_CANCEL_ISSUANCE';
export const CANCEL_ISSUANCE_ERROR = 'BW_CANCEL_ISSUANCE_ERROR';
export const SAVE_FILTERS = 'BW_SAVE_FILTERS';
export const GET_USER_ISSUANCES = 'BW_GET_USER_ISSUANCES';
export const GET_USER_ISSUANCES_ERROR = 'BW_GET_USER_ISSUANCES_ERROR';
export const OPEN_DIVISION_DETAILS_MODAL = 'BW_OPEN_DIVISION_DETAILS_MODAL';
export const CLOSE_DIVISION_DETAILS_MODAL = 'BW_CLOSE_DIVISION_DETAILS_MODAL';

export const ISSUANCE_UPDATE_REQUIRES_PAYMENT = 'UpdateRequiresPayment';
export const ISSUANCE_UPDATE_SUCCESSFUL = 'UpdateSuccessful';
export const ISSUANCE_UPDATE_REQUIRES_REFUND = 'UpdateRequiresRefund'; // go to confirmation page / populate redux
export const ISSUANCE_UPDATE_VALIDATION_ERROR = 'ValidationError';

// lottery
export const GET_LOTTERY = 'GET_LOTTERY';
export const GET_LOTTERY_ERROR = 'GET_LOTTERY_ERROR';
export const POST_LOTTERY = 'POST_LOTTERY';
export const POST_LOTTERY_ERROR = 'POST_LOTTERY_ERROR';
export const NOTIFY_LOTTERY_RESULTS = 'NOTIFY_LOTTERY_RESULTS';
export const NOTIFY_LOTTERY_RESULTS_ERROR = 'NOTIFY_LOTTERY_RESULTS_ERROR';

// campsite
export const GET_CAMPSITES = 'GET_CAMPSITES';
export const GET_CAMPSITES_ERROR = 'GET_CAMPSITES_ERROR';

// reservation detail
export const PERMIT = 'PERMIT';
export const LOTTERY = 'LOTTERY';

export const SEND_CURRENT_DATE = 'SEND_CURRENT_DATE';

export const EPOCH_MULTIPLIER = 1000;

// fee display models
export const ONE_FEE = 'OneFee';
export const ADD_ON_FEE = 'AddOnFee';

// fee calculation models
export const NO_USE_FEE = 'NoUseFee';
export const FLAT_USE_FEE = 'FlatUseFee';
export const PER_GROUP_PER_NIGHT_FEE = 'PerGroupPerNight';
export const PER_GROUP_PER_DAY_FEE = 'PerGroupPerDay';
export const PER_MEMBER_FEE = 'PerMember';
export const PER_MEMBER_PER_NIGHT_FEE = 'PerMemberPerNight';
export const PER_MEMBER_PER_DAY_FEE = 'PerMemberPerDay';
export const PER_WATERCRAFT_FEE = 'PerWatercraft';
export const PER_GROUP_PER_NIGHT_CUSTOM_RANGE_HIGH_USE_ONLY_FEE =
    'PerGroupPerNightCustomRangeHighUseOnly';

// fee types
export const RESERVATION_FEE = 'ReservationFee';
export const ADULT_USE_FEES = 'AdultUseFee';
export const CHILD_USE_FEES = 'ChildUseFee';
export const INTER_ACCESS_USE_FEES = 'IAPUseFee';
export const INTER_SENIOR_USE_FEES = 'ISPUseFee';
export const INTER_YOUTH_USE_FEES = 'IYAPUseFee';
export const SPH_USE_FEES = 'SeasonPassUseFee';
export const TCH_USE_FEES = 'TreatyPassUseFee';
export const TOTAL_USE_FEE = 'TotalUseFee';
export const MIN_BALANCE_FEE = 'MinBalanceUseFee';
export const POS_FEE = 'POSFee';
export const TAX_FEE = 'TaxFee';

export const SKU_ADULT_USE_FEE = 'PERUSE233396ADULT';
export const SKU_CHILD_USE_FEE = 'PERUSE233396CHILD';
export const SKU_IAP_USE_FEE = 'PERUSE233396IAP';
export const SKU_ISP_USE_FEE = 'PERUSE233396ISP';
export const SKU_IYAP_USE_FEE = 'PERUSE233396IYAP';
export const SKU_SPH_USE_FEE = 'PERUSE233396SPH';
export const SKU_TCH_USE_FEE = 'PERUSE233396TCH';
export const SKU_MIN_BALANCE_FEE = 'PERMIN233396';
export const SKU_TX_FEE = 'PERRES233396';

export const EQUIP_TYPE_MOTORBOAT = 18;

export const DIV_TYPE_OP = 'OP';
export const DIV_TYPE_OH = 'OH';
export const DIV_TYPE_DM = 'DM';
export const DIV_TYPE_OM = 'OM';
export const DIV_TYPE_DC = 'DC';

export const ADULT_FEE_TYPE = '1';
export const CHILD_FEE_TYPE = '2';
export const INTER_SENIOR_FEE_TYPE = '3';
export const INTER_ACCESS_FEE_TYPE = '4';
export const INTER_YOUTH_ACCESS_FEE_TYPE = '5';
export const SEASON_CARD_FEE_TYPE = '6';
export const TREATY_CARD_FEE_TYPE = '7';

export const RES_ID_DELIMITER = '__';

// cart
export const GET_CART = 'GET_CART';
export const GET_CART_ERROR = 'GET_CART_ERROR';
export const CANCEL_RESERVATION = 'CANCEL_RESERVATION';
export const CANCEL_RESERVATION_ERROR = 'CANCEL_RESERVATION_ERROR';

// issuance statuses
export const PERMIT_PRELIMINARY = 'PermitPreliminary';
export const PERMIT_IN_PROGRESS = 'PermitInProgress';
export const LOTTERY_IN_PROGRESS = 'LotteryInProgress';
export const LOTTERY_SUBMITTED = 'LotterySubmitted';
export const LOTTERY_WON = 'LotteryWon';
export const LOTTERY_WON_IN_PROGRESS = 'LotteryWonInProgress';
export const LOTTERY_LOST = 'LotteryLost';
export const LOTTERY_DECLINED = 'LotteryDeclined';
export const LOTTERY_ACCEPTED = 'LotteryAccepted';
export const LOTTERY_NO_RESPONSE = 'LotteryNoResponse';
export const PERMIT_COMPLETE = 'PermitComplete';
export const LOTTERY_AWARDED = undefined; // Error in PermitRegistrationPage
export const LOTTERY_AWARDED_READABLE = 'Awarded';
export const LOTTERY_AWARDED_IN_PROGRESS_READABLE = 'Awarded And Being Booked';
export const PERMIT_ISSUED = 'PermitIssued';
export const CANCELLED_NO_RELEASE = 'CancelledNoRelease';
export const PERMIT_CANCELLED_BY_USER = 'PermitCancelledByUser';
export const LOTTERY_CANCELLED = 'LotteryCancelled';
export const PERMIT_VOIDED = 'PermitVoided';
export const LOTTERY_VOIDED = 'LotteryVoided';
export const LOTTERY_REJECTED = 'LotteryRejected';

// issuance readable statuses
export const PERMIT_PRELIMINARY_READABLE = 'Preliminary';
export const PERMIT_IN_PROGRESS_READABLE = 'In Progress';
export const PERMIT_COMPLETE_READABLE = 'Reserved';
export const PERMIT_ISSUED_READABLE = 'Issued';
export const PERMIT_NO_SHOW_READABLE = 'No Show';
export const PERMIT_CANCELLED_READABLE = 'Cancelled';
export const PERMIT_VOIDED_READABLE = 'Voided';

// issuance types
export const PRIVATE = 'Private';
export const COMMERCIAL = 'Commercial';
export const ADMIN = 'Admin';

// internal validations
export const IS_LOTTERY_NAME_EMPTY = 'IsLotteryNameEmpty';
export const IS_LOTTERY_START_DATE_EMPTY = 'IsLotteryStartDateEmpty';
export const IS_LOTTERY_CLOSE_DATE_EMPTY = 'IsLotteryCloseDateEmpty';
export const IS_LOTTERY_RUN_DATE_EMPTY = 'IsLotteryRunDateEmpty';
export const IS_LOTTERY_DEADLINE_DATE_EMPTY = 'IsLotteryDeadlineDateEmpty';
export const IS_LOTTERY_INFO_DISPLAY_DATE_EMPTY = 'IsLotteryInfoDisplayDateEmpty';
export const IS_LOTTERY_NOTIFY_DATE_EMPTY = 'IsLotteryNotifyDateEmpty';
export const IS_SEASON_START_DATE_EMPTY = 'IsSeasonStartDateEmpty';
export const IS_SEASON_END_DATE_EMPTY = 'IsSeasonEndDateEmpty';

// external validations
export const IS_LAUNCH_LOCATION_EMPTY = 'IsLaunchLocationEmpty';
export const IS_TAKEOUT_LOCATION_EMPTY = 'IsTakeoutLocationEmpty';
export const IS_ISSUE_STATION_EMPTY = 'IsIssueStationEmpty';
export const IS_ENTRY_DATE_EMPTY = 'IsEntryDateEmpty';
export const IS_EXIT_DATE_EMPTY = 'IsExitDateEmpty';
export const IS_GROUP_MEMBER_EMPTY = 'IsGroupMemberEmpty';
export const IS_EQUIPMENT_EMPTY = 'IsEquipmentEmpty';
export const IS_NEED_TO_KNOW_CHECKED = 'IsNeedToKnowChecked';

// validation targets
export const ENTRY_POINT = 'entryPoint';
export const EXIT_POINT = 'exitPoint';
export const ISSUE_STATION = 'issueStation';
export const GROUP_MEMBERS = 'groupMembers';
export const WATERCRAFTS = 'watercrafts';
export const EXIT_DATE = 'exitDate';
export const LOTTERY_OPEN_DATE = 'lotteryOpenDate';
export const LOTTERY_CLOSE_DATE = 'lotteryCloseDate';
export const LOTTERY_RUN_DATE = 'lotteryRunDate';
export const LOTTERY_DEADLINE_DATE = 'lotteryDeadlineDate';
export const LOTTERY_INFO_DISPLAY_DATE = 'lotteryInfoDisplayDate';
export const LOTTERY_NOTIFY_DATE = 'lotteryNotifyDate';
export const SEASON_START_DATE = 'lotterySeasonStartDate';
export const SEASON_END_DATE = 'lotterySeasonEndDate';
export const NEED_TO_KNOW_CHECKBOX = 'needToKnowChecked';
export const DIVISION_ID = 'divisionId';
export const SELECTED_DATES = 'selectedDates';

// validation types
export const REQUIRED_BOOLEAN_FIELD = 'REQUIRED_BOOLEAN_FIELD';
export const REQUIRED_STRING_FIELD = 'REQUIRED_STRING_FIELD';
export const REQUIRED_DATE_FIELD = 'REQUIRED_DATE_FIELD';
export const REQUIRED_LIST_ENTRY_FIELD = undefined; // error in permitUtils
export const REQUIRED_LIST_FIELD = 'REQUIRED_LIST_FIELD';
export const REQUIRED_NUM_FIELD = 'REQUIRED_NUM_FIELD';

export const PERMIT_REGISTRATION = 'PermitRegistration';
export const LOTTERY_REGISTRATION = 'LotteryRegistration';
export const REGISTRATION = 'Registration';

// detailed availability
export const AVAILABILITY_DAYS_TO_DISPLAY = 10;
export const AVAILABILITY_DAYS_TO_SKIP = 10;
export const AVAILABILITY_DAYS_TO_SKIP_MOBILE = 4;
export const AVAILABILITY_DAYS_TO_DISPLAY_MOBILE = 4;

export const AVAILABILITY_UNSELECTED = 'AVAILABILITY_UNSELECTED';
export const AVAILABILITY_START_MULTIDAY = 'AVAILABILITY_START_MULTIDAY';
export const AVAILABILITY_CONTINUE_MULTIDAY = 'AVAILABILITY_CONTINUE_MULTIDAY';
export const AVAILABILITY_END_MULTIDAY = 'AVAILABILITY_END_MULTIDAY';
export const AVAILABILITY_SINGLE_DAY = 'AVAILABILITY_SINGLE_DAY';

export const GROUP_AVAILABILITY_DAILY_KEY_NAME = 'ConstantQuotaUsageDaily';
export const GROUP_AVAILABILITY_KEY_NAME = 'ConstantQuotaUsageByStartDate';
export const GROUP_AVAILABILITY_WEEKLY_KEY_NAME = 'ConstantWeeklyQuotaUsageByStartDate';
export const MEMBER_AVAILABILITY_KEY_NAME = 'QuotaUsageByMember';
export const AGGREGATE_AVAILABILITY_KEY_NAME = 'AggregateQuota';
export const WATERCRAFT_AVAILABILITY_KEY_NAME = 'QuotaUsageByWatercraft';
export const MAX_STOCK_KEY_NAME = 'MaxStock';
export const FILTER_NUMERICAL = 'numerical';

// common date format strings
export const DATE_FORMAT = 'ddd, MMM D, YYYY';
export const DATE_TIME_FORMAT = 'ddd, MMM D, YYYY | hh:mmA z';
export const MONTH_DAY_YEAR_FORMAT = 'MMM Do YYYY';
export const AVAILABILITY_KEY_MAP_FORMAT = 'MM/DD/YYYY';
export const MONTH_DAY_YEAR_NUM_FORMAT = 'MM/DD/YYYY';

// sales channel types
export const ONLINE = 'ONLINE';
export const FIELD_SALES = 'FIELD_SALES';
export const CALL_CENTER = 'CALL_CENTER';

// division status enum
export const DIVISION_STATUS = {
    CLOSED: 0,
    OPEN: 1,
};

export const FINALIZE_CHECKOUT = 'FINALIZE_CHECKOUT';
export const CLEAR_ISSUANCE = 'CLEAR_ISSUANCE';
export const CLEAR_EXISTING_ISSUANCE = 'CLEAR_EXISTING_ISSUANCE';

// permit types enum
export const PERMIT_TYPE_RIVER = 'PERMIT_TYPE_RIVER';
export const PERMIT_TYPE_HIKING = 'PERMIT_TYPE_HIKING';
export const PERMIT_TYPE_CAMPING = 'PERMIT_TYPE_CAMPING';
export const PERMIT_TYPE_CLIMBING = 'PERMIT_TYPE_CLIMBING';
export const PERMIT_TYPE_WILDLIFE = 'PERMIT_TYPE_WILDLIFE';
export const PERMIT_TYPE_ROADENTRY = 'PERMIT_TYPE_ROADENTRY';
export const PERMIT_TYPE_EVENT = 'PERMIT_TYPE_EVENT';
export const PERMIT_TYPE_BOAT = 'PERMIT_TYPE_BOAT';
export const PERMIT_TYPE_SNOWMOBILE = 'PERMIT_TYPE_SNOWMOBILE';
export const PERMIT_TYPE_WILDERNESS = 'PERMIT_TYPE_WILDERNESS';

// lottery preferences rules constants
export const LOTTERY_PREFERENCES_TYPE = 'LotteryPreferencesType';
export const LOTTERY_PREFERENCE_SELECTION_LIMIT = 'LotteryPreferenceSelectionLimit';

// lottery preferences enum
export const LOTTERY_PREFERENCE_TYPES = {
    DATE: 1,
    DATE_DIVISION: 2,
    DATE_DIVISION_DURATION: 3,
    DATE_DIVISION_GROUP_MIN_MAX: 4,
    DATE_DIVISION_GROUP_MAX: 5,
};

// login
export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL';

// Error Messages
export const ERROR_UNAUTHORIZED = 'You are not authorized to access this page.';
export const ERROR_DEFAULT = 'Something went wrong, please try again later.';

export const EXIT_POINT_NAMES = [
    'Angleworm Lake',
    'Angleworm Lake Trail',
    'Baker Lake',
    'Big Moose Lake Trail',
    'Blandin Trail',
    'Bog Lake',
    'Border Route Trail (center)',
    'Border Route Trail (east)',
    'Border Route Trail (west)',
    'Bower Trout Lake',
    'Brant Lake',
    'Brule Lake',
    'Brule Lake Trail',
    'Clearwater Lake',
    'Crab Lake & Cummings Lake',
    'Crocodile River',
    'Cross Bay Lake',
    'Daniels Lake',
    'Duncan Lake',
    'Eagle Mountain Trail',
    'East Bearskin',
    'Fall Lake',
    'Fall Lake and Beyond',
    'Farm Lake',
    'From Big Lake',
    'Herriman Lake Trail',
    'Hog Creek',
    'Homer Lake',
    'Isabella Lake',
    'Island River',
    'John Lake',
    'Kawishiwi Lake',
    'Kekekabic Trail (east)',
    'Kekekabic Trail (west) /Snowbank',
    'Lake One',
    'Larch Creek',
    'Little Gabbro Lake',
    'Little Indian Sioux North',
    'Little Indian Sioux SOUTH',
    'Little Isabella River',
    'Little Vermilion Lake',
    'Lizz and Swamp Lakes',
    'Magnetic Lake',
    'Meeds Lake',
    'Missing Link Lake',
    'Moose Lake',
    'Moose Newfound Sucker',
    'Moose River South',
    'Moose-Basswood via Prairie Portage',
    'Moose/Portage River North',
    'Morgan Lake',
    'Mudro Lake',
    'North Fowl Lake',
    'North Kawishiwi River',
    'Norway Trail',
    'Partridge Lake Trail',
    'Pine Lake',
    'Pow Wow Trail',
    'Ram Lake',
    'Saganaga Lake',
    'Sawbill Lake',
    'Seagull Lake',
    'Sioux-Hustler Trail',
    'Skipper & Portage Lakes',
    'Slim Lake',
    'Snake River',
    'Snowbank Lake',
    'South Farm Lake',
    'South Hegman Lake',
    'South Kawishiwi River',
    'South Lake',
    'Stuart River',
    'Trout Lake',
    'Wood Lake',
];

export const bwPassTypesToString = new Map();
bwPassTypesToString.set(ADULT_FEE_TYPE, 'Adult'); // 1
bwPassTypesToString.set(CHILD_FEE_TYPE, 'Child/Youth'); // 2
bwPassTypesToString.set(TREATY_CARD_FEE_TYPE, 'Treaty Card Holder'); // 7
bwPassTypesToString.set(INTER_ACCESS_FEE_TYPE, 'Interagency Access'); // 4
bwPassTypesToString.set(INTER_SENIOR_FEE_TYPE, 'Interagency Senior'); // 3
bwPassTypesToString.set(INTER_YOUTH_ACCESS_FEE_TYPE, 'Interagency Youth Access'); // 5
