/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import cx from 'classnames';
import { FlexRow, FlexCol, useWindowSize, HtmlParser } from 'sarsaparilla';

export default function ModifyReservationTable() {
    const { width = 0 } = useWindowSize();

    const modifyTable = [
        {
            colA: 'Modification Type',
            colB: 'Modification Fee Charged?',
        },
        {
            colA: 'Changing dates that are completely outside of the original reservation dates.',
            colB: 'Yes',
        },
        {
            colA: 'Extending or shortening a reservation',
            colB: 'No',
        },
        {
            colA: 'Switching campsites that are of the same type for the same reservation dates',
            colB: 'No',
        },
    ];

    const colA = modifyTable.map((modify) => modify.colA);
    const colB = modifyTable.map((modify) => modify.colB);

    const inventoryListItems = (
        colTitle: string,
        colContent: string,
        setClass?: string
    ) => {
        return (
            <FlexRow className={cx('table-item-list-view', setClass)}>
                <FlexCol sm={4}>
                    <p>
                        <strong>
                            <HtmlParser html={colTitle} />:
                        </strong>
                    </p>
                </FlexCol>
                <FlexCol sm={8}>
                    <p>
                        <HtmlParser html={colContent} />
                    </p>
                </FlexCol>
            </FlexRow>
        );
    };

    return (
        <>
            {/* show the data as a table */}
            {width >= 768 && (
                <FlexRow className="modify-wrap">
                    <FlexCol sm={12}>
                        <table className="rec-table-light mb-4">
                            <thead>
                                <tr>
                                    <th className="first-col">{colA[0]}</th>
                                    <th>{colB[0]}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="first-col">{colA[1]}</td>
                                    <td>{colB[1]}</td>
                                </tr>
                                <tr>
                                    <td className="first-col">{colA[2]}</td>
                                    <td>{colB[2]}</td>
                                </tr>
                                <tr>
                                    <td className="first-col">{colA[3]}</td>
                                    <td>{colB[3]}</td>
                                </tr>
                            </tbody>
                        </table>
                    </FlexCol>
                </FlexRow>
            )}

            {/* show the data as a list */}
            {width <= 769 && (
                <>
                    <div className="fees-info-wrap mb-2">
                        {inventoryListItems(colA[0], colA[1])}
                        {inventoryListItems(colB[0], colB[1])}
                    </div>

                    <div className="fees-info-wrap mb-2">
                        {inventoryListItems(colA[0], colA[2])}
                        {inventoryListItems(colA[0], colB[2])}
                    </div>

                    <div className="fees-info-wrap mb-2">
                        {inventoryListItems(colA[0], colA[3])}
                        {inventoryListItems(colA[0], colB[3])}
                    </div>
                </>
            )}
        </>
    );
}
