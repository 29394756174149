/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { InventoryTag } from 'sarsaparilla';
import type { HomepageInventoryType } from '../../../types/homepageMapInventoryValuesType';
import type { SearchFilterInventoryType } from '../../../types/searchFilterInventoryType';

export const SEARCH_FILTER_INVENTORY_VALUES: SearchFilterInventoryType = [
    {
        key: 'camping',
        value: 'Camping',
        icon: <InventoryTag.InventoryCamping isSpanItem />,
        filters: [
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'Overnight,na',
            },
            {
                attribute: 'entity_type',
                filterValue: 'campground',
            },
        ],
    },
    {
        key: 'dayuse',
        value: 'Day Use',
        icon: <InventoryTag.InventoryDayUse isSpanItem />,
        filters: [
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'Day',
            },
            {
                attribute: 'entity_type',
                filterValue: 'campground',
            },
        ],
    },
    {
        key: 'tours',
        value: 'Tickets & Tours',
        icon: <InventoryTag.InventoryTicket isSpanItem />,
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'ticketfacility,tour,timedentry,timedentry_tour',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
    {
        key: 'permits',
        value: 'Permits',
        icon: <InventoryTag.InventoryPermit isSpanItem />,
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'permit,vehiclepermit',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
    {
        key: 'poi',
        value: 'Points of Interest',
        icon: <InventoryTag.InventoryPOI isSpanItem />,
        filters: [
            {
                attribute: 'entity_type',
                filterValue:
                    'recarea,facility,cemeteryandmemorial,library,archives,museum,kiosk,nationalfishhatchery',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
    {
        key: 'activitypass',
        value: 'Activity Pass',
        icon: <InventoryTag.InventoryActivityPass isSpanItem />,
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'activitypass',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
    {
        key: 'treepermit',
        value: 'Tree Permits',
        icon: <InventoryTag.InventoryTreePermits isSpanItem />,
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'treepermit',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
    {
        key: 'venuereservations',
        value: 'Venues',
        icon: <InventoryTag.InventoryVenueOther isSpanItem />,
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'venuereservations,venuereservations_venue',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
    {
        key: 'recarea',
        value: 'Rec Areas',
        icon: <InventoryTag.InventoryRecArea isSpanItem />,
        filters: [
            {
                attribute: 'entity_type',
                filterValue: 'recarea',
            },
            {
                attribute: 'campsite_type_of_use',
                filterValue: 'na',
            },
        ],
    },
];

export const HOMEPAGE_MAP_INVENTORY: HomepageInventoryType = {
    camping: {
        key: 'camping',
        value: 'Camping',
        icon: <InventoryTag.InventoryCamping isSpanItem />,
        campsite_type_of_use: 'Overnight,na',
        entity_type: 'campground',
    },
    dayuse: {
        key: 'dayuse',
        value: 'Day Use',
        icon: <InventoryTag.InventoryDayUse isSpanItem />,
        campsite_type_of_use: 'Day',
        entity_type: 'campground',
    },
    tour: {
        key: 'tour',
        value: 'Tickets & Tours',
        icon: <InventoryTag.InventoryTicket isSpanItem />,
        entity_type: 'ticketfacility,tour,timedentry,timedentry_tour',
        campsite_type_of_use: 'na',
    },
    permit: {
        key: 'permit',
        value: 'Permits',
        icon: <InventoryTag.InventoryPermit isSpanItem />,
        entity_type: 'permit,vehiclepermit',
        campsite_type_of_use: 'na',
    },
    poi: {
        key: 'poi',
        value: 'Points of Interest',
        icon: <InventoryTag.InventoryPOI isSpanItem />,
        entity_type:
            'facility,cemeteryandmemorial,library,archives,museum,kiosk,nationalfishhatchery',
        campsite_type_of_use: 'na',
    },
    activitypass: {
        key: 'activitypass',
        value: 'Activity Pass',
        icon: <InventoryTag.InventoryActivityPass isSpanItem />,
        entity_type: 'activitypass',
        campsite_type_of_use: 'na',
    },
    treepermit: {
        key: 'treepermit',
        value: 'Tree Permits',
        icon: <InventoryTag.InventoryTreePermits isSpanItem />,
        entity_type: 'treepermit',
        campsite_type_of_use: 'na',
    },
    recarea: {
        key: 'recarea',
        value: 'Recreation Areas',
        icon: <InventoryTag.InventoryRecArea isSpanItem />,
        entity_type: 'recarea',
        campsite_type_of_use: 'na',
    },
    venuereservations: {
        key: 'venuereservations',
        value: 'Venues',
        icon: <InventoryTag.InventoryVenueReservations isSpanItem />,
        entity_type: 'venuereservations',
        campsite_type_of_use: 'na',
    },
};

export const HOMEPAGE_HERO_INVENTORY = {
    CAMPING_TAB: {
        camping: {
            key: 'camping',
            value: 'Camping',
            filters: [
                {
                    attribute: 'campsite_type_of_use',
                    filterValue: 'Overnight,na',
                },
                {
                    attribute: 'entity_type',
                    filterValue: 'campground',
                },
            ],
        },
    },
    PERMITS_TAB: {
        permits: {
            key: 'permits',
            value: 'Permits',
            filters: [
                {
                    attribute: 'entity_type',
                    filterValue: 'permit',
                },
            ],
        },
        recarea: {
            key: 'recarea',
            value: 'Rec Areas',
            filters: [
                {
                    attribute: 'entity_type',
                    filterValue: 'recarea',
                },
                {
                    attribute: 'campsite_type_of_use',
                    filterValue: 'na',
                },
            ],
        },
    },
    TICKETS_TOURS_TAB: {
        tours: {
            key: 'tours',
            value: 'Tours',
            filters: [
                {
                    attribute: 'entity_type',
                    filterValue: 'ticketfacility',
                },
            ],
        },
        activitypass: {
            key: 'activitypass',
            value: 'Activity Pass',
            filters: [
                {
                    attribute: 'entity_type',
                    filterValue: 'activitypass',
                },
            ],
        },
    },
    DAY_USE_VENUES_TAB: {
        venuereservations: {
            key: 'venuereservations',
            value: 'Venues',
            filters: [
                {
                    attribute: 'entity_type',
                    filterValue: 'venuereservations,venuereservations_venue',
                },
                {
                    attribute: 'campsite_type_of_use',
                    filterValue: 'na',
                },
            ],
        },
        dayuse: {
            key: 'dayuse',
            value: 'Day Use',
            filters: [
                {
                    attribute: 'campsite_type_of_use',
                    filterValue: 'Day',
                },
                {
                    attribute: 'entity_type',
                    filterValue: 'campground',
                },
            ],
        },
    },
};

// cSpell:ignore activitypass, cemeteryandmemorial, nationalfishhatchery, recarea, dayuse, ticketfacility, timedentry, treepermit, vehiclepermit, venuereservations
