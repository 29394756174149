/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { Children, useReducer } from 'react';
import PropTypes from 'prop-types';

import { Inline, useWindowSize, Icon } from 'sarsaparilla';

import FacilityFilterBarModal from './FacilityFilterBarModal';
import FacilityFilterBarButton from './FacilityFilterBarButton';

const initialState = {
    isModalOpen: false,
    activeIndex: null,
};

function reducer(state, action) {
    switch (action.type) {
        case 'openAtIndex':
            return {
                isModalOpen: true,
                activeIndex: action.index,
            };
        case 'close':
            return {
                ...state,
                isModalOpen: false,
            };
        case 'switchToIndex':
            return {
                ...state,
                activeIndex: action.index,
            };
        default:
            throw new Error('FacilityFilterBar says: Not a valid reducer action type');
    }
}

export default function FacilityFilterBar({
    className,
    children,
    numResults,
    resultsWordSingular = 'result',
    resultsWordPlural = 'results',
    onOpen,
    onClose,
    resultsText = '',
    onMapViewClick,
    showButtonLoading,
    contextState,
    contextDispatch,
    modalBarTitle,
    modalBarDescription,
    modalSize = 'xxl',
    secondaryButton,
    modalOnlyFilterBar,
}) {
    const [localState, localDispatch] = useReducer(reducer, initialState);
    const { width } = useWindowSize();
    const isMobile = width < 768;

    const state = contextState || localState;
    const dispatch = contextDispatch || localDispatch;

    const onRequestClose = () => {
        dispatch({ type: 'close' });
        if (onClose) {
            onClose();
        }
    };

    const buttons = Children.map(children, (child, index) => {
        if (!child) return null;
        if (child.props.preserveComponent) {
            return child;
        }

        return (
            <FacilityFilterBarButton
                {...child.props}
                onClick={() => {
                    dispatch({ type: 'openAtIndex', index });
                    if (onOpen) {
                        onOpen();
                    }
                }}
            />
        );
    });

    const modalChildren = Children.toArray(children).filter(
        (child) => child && !child.props.filterBarOnly
    );

    return (
        <>
            {!modalOnlyFilterBar && (
                <Inline space="sm" data-shared-facility-filter-bar className={className}>
                    {buttons}
                    {isMobile && (
                        <button
                            type="button"
                            className="shared-map-view-button"
                            onClick={onMapViewClick}
                        >
                            <div>
                                <Icon iconName="pin" size="sm" />
                                <span>Map View</span>
                            </div>
                        </button>
                    )}
                </Inline>
            )}
            {state.isModalOpen && (
                <FacilityFilterBarModal
                    dispatch={dispatch}
                    showButtonLoading={showButtonLoading}
                    isOpen={state.isModalOpen}
                    onRequestClose={onRequestClose}
                    activeIndex={state.activeIndex}
                    numResults={numResults}
                    resultsWordSingular={resultsWordSingular}
                    resultsWordPlural={resultsWordPlural}
                    resultsText={resultsText}
                    modalBarTitle={modalBarTitle}
                    modalBarDescription={modalBarDescription}
                    modalSize={modalSize}
                    secondaryButton={secondaryButton}
                >
                    {modalChildren}
                </FacilityFilterBarModal>
            )}
        </>
    );
}

FacilityFilterBar.propTypes = {
    className: PropTypes.string,
    numResults: PropTypes.number,
    children: PropTypes.node,
    resultsWordSingular: PropTypes.string,
    resultsWordPlural: PropTypes.string,
    modalBarTitle: PropTypes.string,
    modalBarDescription: PropTypes.string,
    modalSize: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl']),
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    resultsText: PropTypes.string,
    onMapViewClick: PropTypes.func,
    showButtonLoading: PropTypes.bool,
    useNativeModal: PropTypes.bool,
    contextState: PropTypes.object,
    contextDispatch: PropTypes.func,
    secondaryButton: PropTypes.element,
};
