/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    Button,
    ButtonGroup,
    RadioPill,
    FlexRow,
    Text,
    Alert,
    FlexCol,
    Inline,
    useFlags,
    RangeCalendar,
    momentRangeToRangeValue,
    calendarDateToMoment,
    momentToCalendarDate,
    DatePicker,
    DateRangePicker,
    DateRangePickerOnChange,
    localToday,
} from 'sarsaparilla';
import { type DateValue } from 'react-aria';

import cx from 'classnames';
import { FiltersProps } from './FlexDatesMain';

function Icon() {
    return (
        <FlexCol className="pb-half">
            <div className="plus-less-icon">+</div>
            <div className="plus-less-icon">_</div>
        </FlexCol>
    );
}

function RangeInputCount({
    value,
    relative,
    index,
    checked,
    isMobile,
}: {
    isMobile?: boolean;
    value: string;
    relative?: boolean;
    checked?: boolean;
    index?: number;
}) {
    return (
        <div className={cx('range-input-count', { relative })}>
            <Text
                className={cx('range-input-count-text', { checked })}
                fontWeight="semibold"
                size={isMobile ? 'xs' : 'sm'}
            >
                <Icon /> {isMobile && <div className="pr-half" />} {value}{' '}
                {index === 0 ? 'day' : 'days'}
            </Text>
        </div>
    );
}

interface RadioGroupProps {
    values: Array<{ label: React.ReactNode; value: string }>;
    isChecked: string;
    onClick: (v: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
}

function RadioGroup({ values, onClick, isChecked }: RadioGroupProps) {
    return (
        <Inline space="xs">
            {values.map((item) => (
                <RadioPill
                    className="calendar-flex-pills"
                    onClick={onClick}
                    isChecked={isChecked === item.value}
                    value={item.value}
                    aria-label={`plus or minus ${item.value} ${
                        item.value === '1' ? 'day' : 'days'
                    }`}
                >
                    {item.label}
                </RadioPill>
            ))}
        </Inline>
    );
}

interface DatesProps {
    startDate?: moment.Moment | null;
    endDate?: moment.Moment | null;
}

export interface CalendarTabProps {
    filters: FiltersProps;
    isMobile: boolean;
    setError: React.Dispatch<React.SetStateAction<string>>;
    onClear?: () => void;
    onApplyFilters?: () => void;
    dates: DatesProps;
    error: string;
    isSearchPage?: boolean;
    setDates: React.Dispatch<React.SetStateAction<object>>;
    setStartRange: React.Dispatch<React.SetStateAction<string>>;
    startRange: string;
    setEndRange: React.Dispatch<React.SetStateAction<string>>;
    endRange: string;
    generateGaProps: () => {
        clickTagCategory: string;
        clickTagAction: string;
        clickTagLabel: string;
    };
}

function CalendarTab({
    onApplyFilters,
    filters,
    isMobile,
    dates,
    setDates,
    error,
    isSearchPage,
    setError,
    onClear,
    setStartRange,
    startRange,
    setEndRange,
    endRange,
    generateGaProps,
}: CalendarTabProps) {
    const [initialVisibleMonth, setInitialVisibleMonth] = useState<
        string | moment.Moment
    >();

    const { useFlexRangeDates } = useFlags();
    const rangeValues = ['1', '2', '3', '7'];
    const rangeOptionsStart = rangeValues.map((item, index) => ({
        label: (
            <RangeInputCount
                relative
                value={item}
                index={index}
                checked={startRange === item}
                isMobile={isMobile}
            />
        ),
        value: item,
    }));
    const rangeOptionsEnd = rangeValues.map((item, index) => ({
        label: (
            <RangeInputCount
                relative
                value={item}
                index={index}
                checked={endRange === item}
                isMobile={isMobile}
            />
        ),
        value: item,
    }));
    useEffect(() => {
        if (error && dates?.startDate && dates?.endDate) {
            setError('');
        }
    }, [dates, error, setError]);

    const handleClearListener = (v: string) => {
        if (isMobile) {
            setStartRange('');
            setEndRange('');
        } else if (v === 'end') {
            setEndRange('');
        } else {
            setStartRange('');
        }
    };

    const clearInputClass = '[class^="SingleDatePickerInput_clearDate"]';
    const mobileClearInputClass = '[class^="DateRangePickerInput_clearDates"]';

    useEffect(() => {
        const classValue = isMobile ? mobileClearInputClass : clearInputClass;
        const elements = document.querySelectorAll(classValue);
        const startClear = elements[0];
        const endClear = elements[1];
        if (startClear) {
            startClear.addEventListener('click', () => handleClearListener('start'));
        }
        if (endClear) {
            endClear.addEventListener('click', () => handleClearListener('end'));
        }
    });

    const handleInitializeFilters = React.useCallback(() => {
        if (filters.startDate && filters.endDate) {
            setInitialVisibleMonth(filters?.startDate);
            setDates(filters);
            if (filters.startRange) {
                setStartRange(filters.startRange);
            }
            if (filters.endRange) {
                setEndRange(filters.endRange);
            }
        } else {
            setDates({ startDate: null, endDate: null });
            setInitialVisibleMonth(moment());
        }
    }, [filters, setStartRange, setInitialVisibleMonth, setDates, setEndRange]);

    useEffect(() => {
        handleInitializeFilters();
    }, [handleInitializeFilters]);

    const handleClear = () => {
        setError('');
        if (onClear) {
            onClear();
        }
    };

    const handleRangeChange = (v: string, range: string) => {
        if (range === 'mobile') {
            const value = v === startRange || v === endRange ? '' : v;
            setEndRange(value);
            setStartRange(value);
        } else if (range === 'start') {
            const value = v === startRange ? '' : v;
            setStartRange(value);
        } else {
            const value = v === endRange ? '' : v;
            setEndRange(value);
        }
    };

    const gaProps = generateGaProps();

    const isDateUnavailable = (day: DateValue) => {
        return day.compare(localToday) < 0;
    };

    const onDateRangeChange: DateRangePickerOnChange = (v) => {
        const parsedStart = calendarDateToMoment(v?.start);
        const parsedEnd = calendarDateToMoment(v?.end);
        setDates({
            startDate: parsedStart,
            endDate: parsedEnd,
        });
    };

    return (
        <div className={cx('flex-dates-calendar', { desktop: !isMobile })}>
            {initialVisibleMonth && (
                <>
                    {!isMobile && (
                        <div
                            className={cx('flex-dates-calendar-input my-2', {
                                desktop: !isMobile,
                            })}
                        >
                            <div
                                className={cx('flex-dates-input-container', {
                                    desktop: !isMobile,
                                })}
                            >
                                <DatePicker
                                    label="Check In date"
                                    hasCalendar={false}
                                    isDateUnavailable={isDateUnavailable}
                                    onChange={(v) =>
                                        setDates({
                                            ...dates,
                                            startDate: calendarDateToMoment(v),
                                        })
                                    }
                                    value={momentToCalendarDate(dates?.startDate)}
                                />

                                {startRange && (
                                    <RangeInputCount
                                        value={startRange}
                                        index={startRange === '1' ? 0 : 1}
                                    />
                                )}
                            </div>
                            <div className="flex-dates-input-container">
                                <DatePicker
                                    label="Check Out date"
                                    hasCalendar={false}
                                    onChange={(v) =>
                                        setDates({
                                            ...dates,
                                            endDate: calendarDateToMoment(v),
                                        })
                                    }
                                    isDateUnavailable={isDateUnavailable}
                                    value={momentToCalendarDate(dates?.endDate)}
                                />

                                {endRange && <RangeInputCount value={endRange} />}
                            </div>
                        </div>
                    )}
                    {isMobile && (
                        <div className="flex-dates-calendar-input mt-2 mb-2">
                            <div className="flex-input-text">
                                <DateRangePicker
                                    label="Check In and Check Out dates"
                                    isLabelVisible={false}
                                    hasCalendar={false}
                                    value={momentRangeToRangeValue(
                                        dates?.startDate,
                                        dates?.endDate
                                    )}
                                    isDateUnavailable={isDateUnavailable}
                                    onChange={onDateRangeChange}
                                />
                            </div>
                        </div>
                    )}
                    {error && !isMobile && (
                        <Alert
                            className="mt-2 mb-2"
                            role="alert"
                            type="error"
                            aria-label={error}
                            shouldFocusOnMount
                        >
                            {error}
                        </Alert>
                    )}

                    <div className="calendar-wrapper mb-2" id="calendar-wrapper">
                        <RangeCalendar
                            numVisibleMonths={isMobile ? 1 : 2}
                            isDateUnavailable={isDateUnavailable}
                            value={momentRangeToRangeValue(
                                dates?.startDate,
                                dates?.endDate
                            )}
                            onChange={onDateRangeChange}
                        />
                    </div>
                    {!isMobile && useFlexRangeDates && (
                        <div>
                            <FlexRow alignItems="center" className="mt-1 mb-2">
                                <FlexCol>
                                    <RadioGroup
                                        onClick={(e) =>
                                            handleRangeChange(
                                                (e.target as HTMLInputElement).value,
                                                'start'
                                            )
                                        }
                                        values={rangeOptionsStart}
                                        isChecked={startRange}
                                    />
                                </FlexCol>
                                <FlexCol>
                                    <RadioGroup
                                        onClick={(e) =>
                                            handleRangeChange(
                                                (e.target as HTMLInputElement).value,
                                                'end'
                                            )
                                        }
                                        values={rangeOptionsEnd}
                                        isChecked={endRange}
                                    />
                                </FlexCol>
                            </FlexRow>
                        </div>
                    )}
                    {isMobile && useFlexRangeDates && (
                        <FlexRow
                            alignItems="center"
                            justifyContent="center"
                            className="mt-1 mb-2"
                        >
                            <RadioGroup
                                // @ts-ignore
                                onClick={(e) =>
                                    handleRangeChange(
                                        (e.target as HTMLInputElement).value,
                                        'mobile'
                                    )
                                }
                                values={rangeOptionsEnd}
                                isChecked={startRange || endRange}
                            />
                        </FlexRow>
                    )}
                    {!isMobile && (
                        <ButtonGroup align="right" className="mt-2">
                            <Button
                                onClick={handleClear}
                                appearance="tertiary"
                                clickTagCategory={isSearchPage ? 'Search' : 'Homepage'}
                                clickTagAction="Clear Dates"
                                clickTagLabel="Flexible Booking"
                            >
                                Clear Dates
                            </Button>
                            <Button
                                id="calendar-button-handler"
                                onClick={onApplyFilters}
                                {...gaProps}
                            >
                                Apply Dates
                            </Button>
                        </ButtonGroup>
                    )}
                </>
            )}
        </div>
    );
}

export default CalendarTab;
