/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import React from 'react';
import {
    FlexRow,
    FlexCol,
    Heading,
    Icons,
    Button,
    HeroImageSectionFront,
} from 'sarsaparilla';

import lgAvif from './images/hero/media-center-hero-lg.avif';
import lgJpg from './images/hero/media-center-hero-lg.jpg';
import lgWebp from './images/hero/media-center-hero-lg.webp';
import mdAvif from './images/hero/media-center-hero-md.avif';
import mdJpg from './images/hero/media-center-hero-md.jpg';
import mdWebp from './images/hero/media-center-hero-md.webp';
import smAvif from './images/hero/media-center-hero-sm.avif';
import smJpg from './images/hero/media-center-hero-sm.jpg';
import smWebp from './images/hero/media-center-hero-sm.webp';

const imageSrcConfig = {
    avif: {
        lg: lgAvif,
        md: mdAvif,
        sm: smAvif,
    },
    webp: {
        lg: lgWebp,
        md: mdWebp,
        sm: smWebp,
    },
    jpg: {
        lg: lgJpg,
        md: mdJpg,
        sm: smJpg,
    },
};

const heroItems = [
    {
        className: 'promise',
        title: 'Our Promise',
        text: "Recreation.gov empowers people to dream, plan, experience and share our National treasures, as well as share their memorable experiences to inspire others. Our promise is that you'll bring home a story.",
        linkText: 'Learn More',
        linkUrl: '/about-us',
        target: '_top',
        rel: 'noopener noreferrer',
        aria: 'Learn more about Recreation.gov',
    },
    {
        className: 'contact',
        title: 'Get in Touch',
        text: "Our team loves working with journalists to share compelling, unique stories. If you're a member of the media and would like to talk, please send an email to mediacenter@recreation.gov.",
        linkText: 'Contact Us',
        linkUrl: 'mailto:mediacenter@recreation.gov',
        target: '_blank',
        rel: 'noopener noreferrer',
        aria: 'Contact the Recreation.gov media team',
    },
];

export default class MediaCenterHero extends React.PureComponent {
    render() {
        return (
            <>
                <HeroImageSectionFront
                    title="Media Center"
                    imageSrcConfig={imageSrcConfig}
                    backgroundImageAltText="A lake surrounded by trees"
                    backgroundImageColorOverlay="#2F4668"
                />
                <section className="nav-media-center-call-outs mb-5">
                    <FlexRow justifyContent="center">
                        <FlexCol xl={10} lg={12}>
                            <FlexRow>
                                {heroItems.map((heroItem, index) => (
                                    <FlexCol md={6} key={index}>
                                        <div
                                            className={`nav-media-center-call-out-${heroItem.className} p-3 p-md-5`}
                                        >
                                            <Heading
                                                headingLevel={2}
                                                appearance="h4"
                                                className="mb-2"
                                            >
                                                {heroItem.title}
                                            </Heading>
                                            <p>{heroItem.text}</p>
                                            <Button
                                                //appearance="link"
                                                appearance="tertiary-white"
                                                href={heroItem.linkUrl}
                                                target={heroItem.target}
                                                rel={heroItem.rel}
                                                iconAfterElement={
                                                    <Icons.IconArrowForward />
                                                }
                                                aria-labelledby={heroItem.aria}
                                            >
                                                {heroItem.linkText}
                                            </Button>
                                        </div>
                                    </FlexCol>
                                ))}
                            </FlexRow>
                        </FlexCol>
                    </FlexRow>
                </section>
            </>
        );
    }
}
