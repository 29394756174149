/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * Created by michaelbray on 7/2/18.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
    Breadcrumbs,
    DisplayHtmlContent,
    Heading,
    FlexRow,
    FlexCol,
    ShareBtn,
} from 'sarsaparilla';

import { BREADCRUMB, LOCATION_SPOTLIGHT } from '../../../../constants/propTypes';
import { youtubeFormatter, generateContentImage } from '../../../../utils/articlesHelper';

import ArticleBestSpot from '../ArticleBestSpot';
import ArticleQuote from '../ArticleQuote';
import ArticleFunFacts from '../ArticleFunFacts';
import ArticleSource from '../ArticleSource';

export class ArticleLocationBody extends React.PureComponent {
    static propTypes = {
        breadCrumbPath: PropTypes.arrayOf(BREADCRUMB),
        article: LOCATION_SPOTLIGHT,
    };

    generateFunFacts(article) {
        let ff = null;

        if (article?.fun_facts?.length > 0) {
            ff = <ArticleFunFacts facts={article.fun_facts} />;
        }

        return ff;
    }

    generateAdditionalImageOrVideo(article) {
        let aIoV = null;

        if (article.additional_image !== '') {
            aIoV = (
                <figure>
                    {generateContentImage({
                        url: article.additional_image_inv_url,
                        img: article.additional_image,
                        altText: article.additional_image_alt,
                    })}
                    <figcaption>{article.additional_imgvideo_location_source}</figcaption>
                </figure>
            );
        } else if (article.additional_image_video_url !== '') {
            const youtubeSource = youtubeFormatter(article.additional_image_video_url);

            aIoV = (
                <figure className="nav-article-video-container">
                    <iframe
                        className="nav-article-video"
                        width="100%"
                        title={article.additional_image_alt}
                        src={youtubeSource}
                        frameBorder="0"
                        allowFullScreen
                    />
                    <figcaption className="nav-article-video-caption">
                        {article.additional_imgvideo_location_source}
                    </figcaption>
                </figure>
            );
        }

        return aIoV;
    }

    generateAgencyMessage(article) {
        if (article.message_from_agency_description !== '') {
            return (
                <>
                    <Heading headingLevel={3} appearance="h3">
                        A Message from the Agency
                    </Heading>

                    <DisplayHtmlContent html={article.message_from_agency_description} />
                </>
            );
        }

        return null;
    }

    render() {
        const { article } = this.props;
        const funFacts = this.generateFunFacts(article);
        const additionalImageOrVideo = this.generateAdditionalImageOrVideo(article);
        const agencyMessage = this.generateAgencyMessage(article);
        const signatureFeaturesImage = generateContentImage({
            url: article.signature_features_img_inv_url,
            img: article.signature_features_img,
            altText: article.signature_features_img_alt,
        });
        const incredibleExperiencesImage = generateContentImage({
            url: article.incredible_exp_inv_url,
            img: article.incredible_exp_img,
            altText: article.incredible_exp_img_alt,
        });

        return (
            <FlexCol md={8} xl={9} mdOrder={2}>
                <article className="nav-article-body" role="main">
                    <ArticleSource
                        articleLocationSource={article.hero_image_location_and_source}
                    />

                    <FlexRow alignItems="center" className="mb-3">
                        <FlexCol>
                            <Breadcrumbs path={this.props.breadCrumbPath} />
                        </FlexCol>

                        <FlexCol xs={12} lg="variable" className="rec-share-btn-wrap">
                            <ShareBtn />
                        </FlexCol>
                    </FlexRow>

                    <Heading headingLevel={2} appearance="h2">
                        <DisplayHtmlContent html={article.article_subhead} />
                    </Heading>

                    <DisplayHtmlContent html={article.article_overview} />

                    <Heading headingLevel={3} appearance="h3">
                        Signature Features
                    </Heading>
                    <figure>
                        {signatureFeaturesImage}
                        <figcaption>
                            {article.signature_features_img_location_source}
                        </figcaption>
                    </figure>

                    <DisplayHtmlContent html={article.signature_features_description} />

                    <ArticleQuote
                        quoteText={article.quote}
                        quoteAuthor={article.quote_author}
                    />

                    <Heading headingLevel={3} appearance="h3">
                        Incredible Experiences
                    </Heading>
                    <figure>
                        {incredibleExperiencesImage}
                        <figcaption>
                            {article.incredible_exp_img_location_source}
                        </figcaption>
                    </figure>

                    <DisplayHtmlContent html={article.incredible_exp_description} />

                    <ArticleBestSpot
                        imageUrl={article.best_spot_img}
                        alt={article.best_spot_img_alt}
                        location={article.best_spot_img_location_source}
                        inventoryLink={article.best_spot_inv_url}
                        description={article.best_spot_description}
                    />

                    {funFacts}

                    {additionalImageOrVideo}

                    {agencyMessage}
                </article>
            </FlexCol>
        );
    }
}

export default ArticleLocationBody;
