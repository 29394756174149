/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Slider from 'rc-slider';
import { get, isEmpty } from 'lodash';
import { FlexCol } from 'sarsaparilla';

const MIN_VALUE = 6;
const MAX_VALUE = 22;
const initialState = {
    marks: {
        [MIN_VALUE]: '6am',
        [MAX_VALUE]: '10pm',
    },
    value: [MIN_VALUE, MAX_VALUE],
};

export default class SearchFilterTourTime extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        uiFilters: PropTypes.object,
        aggregations: PropTypes.object,
        dispatch: PropTypes.func,
        onFilterChange: PropTypes.func,
        onFilterRemove: PropTypes.func,
        onApplyFilters: PropTypes.func,
        useNewCampingFilters: PropTypes.bool,
    };

    static displayName = 'SearchFilterTourTime';

    constructor(props) {
        super(props);
        this.onSliderChange = this.onSliderChange.bind(this);

        this.state = initialState;
    }

    parseTimeAmPm = (value) => {
        let valStr;
        if (value < 12) {
            valStr = `${value}am`;
        } else if (value > 12) {
            valStr = `${value - 12}pm`;
        } else {
            valStr = `noon`;
        }
        return valStr;
    };

    parseSliderState = (value) => {
        const start = value[0] || MIN_VALUE;
        const end = value[1] || MAX_VALUE;
        return {
            marks: {
                [start]: `${this.parseTimeAmPm(start)}`,
                [end]: `${this.parseTimeAmPm(end)}`,
            },
            value,
        };
    };

    initSlider = (prevSelections) => {
        const selections = this.props.uiFilters.tourTime;
        if (isEmpty(selections) && prevSelections !== selections) {
            this.setState(initialState);
        }
        if (!isEmpty(selections) && selections !== prevSelections) {
            const values = get(selections, 'tour-time.filters[0].filterValue', null);
            if (values) {
                const value = values.replaceAll('>=', '').replaceAll('<=', '').split(',');
                if (value?.length === 2) {
                    const parsed = this.parseSliderState(value);
                    this.setState({ ...parsed });
                }
            }
        }
    };

    componentDidUpdate(prevProps) {
        const prevSelections = prevProps.uiFilters.tourTime;
        this.initSlider(prevSelections);
    }

    onSliderChange(value) {
        const filterOption = {
            key: 'tour-time',
        };
        const isInitialValue = value[0] === 6 && value[1] === 22;
        const hasFilterSelected = get(
            this.props,
            'uiFilters.tourTime["tour-time"]',
            null
        );
        if (value && value.length > 0) {
            const parsed = this.parseSliderState(value);
            this.setState({ ...parsed });
            filterOption.filters = [
                {
                    attribute: 'campsite_equipment_max_length',
                    filterValue: `>=${value[0]} ${value[1]}`,
                },
            ];
            if (this.props.onFilterChange && !isInitialValue) {
                this.props.onFilterChange('tourTime', filterOption);
                this.runSearch();
            }
            if (this.props.onFilterRemove && isInitialValue && hasFilterSelected) {
                this.props.onFilterRemove('tourTime', filterOption);
                this.runSearch();
            }
        } else if (this.props.onFilterRemove) {
            this.props.onFilterRemove('tourTime', filterOption);
            this.runSearch();
        }
    }

    getAriaLabels = () => {
        const { value } = this.state;
        const start = value[0] || MIN_VALUE;
        const end = value[1] || MAX_VALUE;
        const currentElement = document.activeElement.getAttribute('class');
        if (currentElement?.includes('rc-slider-handle-2')) {
            return `${this.parseTimeAmPm(end)}`;
        }
        return `${this.parseTimeAmPm(start)}`;
    };

    runSearch() {
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    render() {
        let disabled = true; // for now disabled completely (until data is indexed)
        if (isEmpty(this.props.aggregations.tourTypes)) {
            disabled = true;
        }
        const colSize = this.props.useNewCampingFilters ? 12 : 4;

        return (
            <FlexCol
                md={colSize}
                className={cx('', {
                    'search-filter-range__new-layout': this.props.useNewCampingFilters,
                })}
            >
                <div className="rec-form-item-wrap">
                    <label htmlFor="tour-start-time">Tour Start Time</label>
                    <Slider
                        range
                        className="search-slider"
                        min={MIN_VALUE}
                        max={MAX_VALUE}
                        step={1}
                        allowCross={false}
                        pushable={false}
                        disabled={disabled}
                        value={this.state.value}
                        onChange={this.onSliderChange}
                        marks={this.state.marks}
                        ariaValueTextFormatterForHandle={this.getAriaLabels}
                        ariaLabelForHandle="Tour Start Time"
                    />
                </div>
            </FlexCol>
        );
    }
}
