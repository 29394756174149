/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';

import { ContentContainerBodyShift, FlexRow, FlexCol, BREAKPOINTS } from 'sarsaparilla';
import agenciesGearLG from './images/services_lg.png';
import agenciesGearSM from './images/services_sm.png';

export default function AboutUsAgencies() {
    const totalAgencies = 14;

    return (
        <ContentContainerBodyShift>
            <FlexRow alignItems="center">
                <FlexCol lg={5} alignSelf="center" className="agencies-image-wrapper">
                    <picture>
                        <source
                            media={`max-width: ${BREAKPOINTS.lg - 1}px`}
                            srcSet={agenciesGearSM}
                        />
                        <source
                            media={`min-width: ${BREAKPOINTS.lg}px`}
                            srcSet={agenciesGearLG}
                        />

                        <img
                            src={agenciesGearLG}
                            alt={`${totalAgencies} participating federal agencies`}
                        />
                    </picture>
                </FlexCol>

                <FlexCol lg={7} className="agencies-description-wrapper">
                    <p className="mb-3">
                        {process.env['SITE_NAME']} is dedicated to equity, fairness,
                        conservation, and customer experience through the delivery of a
                        high-quality facility management solution and visitor reservation
                        system. This valuable government service provides the{' '}
                        {totalAgencies} participating federal agencies access to secure
                        and compliant technical solutions and facility management tools as
                        well as dedicated resources and support with training,
                        communications, technical needs, and daily operations.
                    </p>
                    <p className="m-0">
                        The {totalAgencies} agencies that offer reservations or data
                        through {process.env['SITE_NAME']} provide all the location
                        descriptions and images; set policies, guidelines, business rules,
                        and fees; determine available sites and activities; and share data
                        through the platform. Local agency managers work alongside the{' '}
                        {process.env['SITE_NAME']} government and contractor teams
                        dedicated to providing the best possible visitor experience.
                    </p>
                </FlexCol>
            </FlexRow>
        </ContentContainerBodyShift>
    );
}
