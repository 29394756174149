/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';

import { Alert, Spinner } from 'sarsaparilla';

import {
    useFetchAllArticlesQuery,
    useFetchFeaturedArticlesQuery,
    useAppSelector,
    useAppDispatch,
    incrementPageIndex,
} from '../../redux';
import type { ArticleRecordWithUrl } from '../../types';
import { generateArticleLink } from '../../utils/articlesHelper';

import ArticleLandingHero from '../../components/articles/articleLanding/ArticleLandingHero';
import ArticleLandingBlocks from '../../components/articles/articleLanding/ArticleLandingBlocks';

export function ArticlesPage() {
    const pageIndex = useAppSelector((state) => state.articles.pageIndex);
    const dispatch = useAppDispatch();

    const {
        data: dataAll,
        isLoading: isLoadingAll,
        isFetching: isFetchingAll,
        isError: isErrorAll,
    } = useFetchAllArticlesQuery({ pageIndex, limit: 9 });

    const { data: dataFeatured, isLoading: isLoadingFeatured } =
        useFetchFeaturedArticlesQuery();

    const articles = React.useMemo(() => {
        // If no data, then return an empty array
        if (!dataAll?.result?.records || dataAll.result.records.length === 0) return null;

        const allArticles: ArticleRecordWithUrl[] = [];

        // Step 1: Add urls to all articles
        dataAll.result.records.forEach((item) => {
            allArticles.push({
                ...item,
                url: generateArticleLink(item.content_type, item.article_title, item.id),
            });
        });
        return allArticles;
    }, [dataAll?.result?.records]);

    const hasLoadedAll = dataAll?.result.total_row === dataAll?.result.count_row;

    function handleLoadMore() {
        dispatch(incrementPageIndex());
    }

    if (isLoadingAll || isLoadingFeatured) {
        return <Spinner />;
    }

    return (
        <main className="nav-article-landing-wrap">
            <div className="rec-sr-only">
                <h1>Articles</h1>
            </div>

            {dataFeatured ? <ArticleLandingHero data={dataFeatured} /> : null}

            {isErrorAll ? (
                <Alert type="error">
                    Sorry, an error occurred fetching articles. Please try again later.
                </Alert>
            ) : null}
            <div className="nav-article-landing-wrap-body">
                {articles ? (
                    <ArticleLandingBlocks
                        articles={articles}
                        handleLoadMore={handleLoadMore}
                        isLoading={isFetchingAll}
                        limitReached={hasLoadedAll}
                    />
                ) : null}
            </div>
        </main>
    );
}
