/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import './AppBase.scss';

import * as React from 'react';
import cx from 'classnames';

interface AppBaseProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
}

export default function AppBase({ children, className, ...rest }: AppBaseProps) {
    return (
        <div
            data-component="AppBase"
            className={cx('shared-app-base', className)}
            {...rest}
        >
            {children}
        </div>
    );
}
