/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Button, FeatureFlag } from 'sarsaparilla';

export function PlanTrip() {
    const gaCategory = 'Footer';
    const gaLabel = 'Plan With Us List';

    return (
        <ul className="nav-footer-list">
            <li>
                <Button
                    appearance="link"
                    href="/articles"
                    clickTagCategory={gaCategory}
                    clickTagAction="Find Trip Inspiration link"
                    clickTagLabel={gaLabel}
                >
                    Find Trip Inspiration
                </Button>
            </li>
            <li>
                <Button
                    appearance="link"
                    href="/trips/new"
                    clickTagCategory={gaCategory}
                    clickTagAction="Build a Trip link"
                    clickTagLabel={gaLabel}
                >
                    Build a Trip
                </Button>
            </li>
            <li>
                <Button
                    appearance="link"
                    href="/pass/"
                    clickTagCategory={gaCategory}
                    clickTagAction="Buy a Pass link"
                    clickTagLabel={gaLabel}
                >
                    Buy a Pass
                </Button>
            </li>
            <li>
                <Button
                    appearance="link"
                    href="/lottery/available"
                    clickTagCategory={gaCategory}
                    clickTagAction="Enter a Lottery link"
                    clickTagLabel={gaLabel}
                >
                    Enter a Lottery
                </Button>
            </li>
            <li>
                <Button
                    appearance="link"
                    href="/mobile-app"
                    clickTagCategory={gaCategory}
                    clickTagAction="Checkout our Mobile App link"
                    clickTagLabel={gaLabel}
                >
                    Get the App
                </Button>
            </li>

            <FeatureFlag flag="gearRentalFooterLink">
                <li>
                    <Button
                        appearance="link"
                        href="/rentals"
                        clickTagCategory={gaCategory}
                        clickTagAction="RV Rentals link"
                        clickTagLabel={gaLabel}
                    >
                        RV Rentals
                    </Button>
                </li>
            </FeatureFlag>
        </ul>
    );
}
