/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { HtmlParser } from 'sarsaparilla';

class Content extends React.Component {
    static propTypes = {
        content: PropTypes.string.isRequired,
    };

    render() {
        return (
            <div className="article-content">
                <HtmlParser html={this.props.content} />
            </div>
        );
    }
}

export default Content;
