/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import './css/newImplementations.scss';

import { HeroImageSectionFront, useBreakpoint, Spacer } from 'sarsaparilla';
import PartnersSection from './PartnersSection';
import IntroSection from './IntroSection';
import StatsSection from './StatsSection';
import ProductOfferingSection from './ProductOfferingSection';
import InventoryTypesSection from './InventoryTypesSection';
import MobileSection from './MobileSection';
import FieldPersonnelSection from './FieldPersonnelSection';
import QuoteSection from './QuoteSection';
import LogoGuidelinesAndCampaignAssets from './LogoGuidelinesAndCampaignAssets';
import { ContactUsSection } from './ContactUsSection';

import lgAvif from './images/hero/ni-hero-lg.avif';
import lgJpg from './images/hero/ni-hero-lg.jpg';
import lgWebp from './images/hero/ni-hero-lg.webp';
import mdAvif from './images/hero/ni-hero-md.avif';
import mdJpg from './images/hero/ni-hero-md.jpg';
import mdWebp from './images/hero/ni-hero-md.webp';
import smAvif from './images/hero/ni-hero-sm.avif';
import smJpg from './images/hero/ni-hero-sm.jpg';
import smWebp from './images/hero/ni-hero-sm.webp';

const imageSrcConfig = {
    avif: {
        lg: lgAvif,
        md: mdAvif,
        sm: smAvif,
    },
    webp: {
        lg: lgWebp,
        md: mdWebp,
        sm: smWebp,
    },
    jpg: {
        lg: lgJpg,
        md: mdJpg,
        sm: smJpg,
    },
};

export function NewImplementationsPage() {
    const { isLgAndUp } = useBreakpoint();

    return (
        <div className="nav-ni-page" role="main">
            <HeroImageSectionFront
                className="nav-ni-hero-wrap"
                title="Explore hosting your federal land or water location on Recreation.gov"
                text="Are you a manager with one of these federal agencies? Learn more about your reservation and trip planning service -- Recreation.gov."
                imageSrcConfig={imageSrcConfig}
                backgroundImageAltText="Field of flowers with Mount Rainier in the background"
                backgroundImageColorOverlay="#345D96"
                backgroundImageColorOverlayOpacity={0.8}
            />

            {isLgAndUp ? <PartnersSection /> : null}

            <IntroSection />
            <InventoryTypesSection />
            <StatsSection />
            <ProductOfferingSection />
            <QuoteSection />
            <MobileSection />
            <FieldPersonnelSection />
            <LogoGuidelinesAndCampaignAssets />

            {/* holding off on these sections for now
            <ReferencesResourcesSection />
            <AddFacilitySection />
        */}

            <ContactUsSection />

            <Spacer size="lg" />
        </div>
    );
}
