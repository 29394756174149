/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

export const getList = (item) => {
    const items = [];

    Object.entries(item?.fields_available_on_this_asset)?.forEach(([key, value]) => {
        if (value === 'list') {
            items.push(key);
        }
    });

    return items;
};

export const getMethods = (item) => {
    const items = [];

    Object.entries(item?.fields_available_on_this_asset)?.forEach(([key, value]) => {
        if (value === 'method') {
            items.push(key);
        }
    });

    return items;
};

export const getSex = (item) => {
    const items = [];

    Object.entries(item?.fields_available_on_this_asset)?.forEach(([key, value]) => {
        if (value === 'sex') {
            items.push(key);
        }
    });

    return items;
};

export const getSpecies = (item) => {
    const items = [];

    Object.entries(item?.fields_available_on_this_asset)?.forEach(([key, value]) => {
        if (value === 'species') {
            items.push(key);
        }
    });

    return items;
};
