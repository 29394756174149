/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CampsiteTypeFilters from './CampsiteType';
import MustHaveFilters from './MustHaveType';
import { updateSearchCriteria } from '../../../actions/search';
import { CampingFiltersProps, FiltersTypeProps } from '../../../types';
import { toggleFilterGroups } from '../../../utils/filters';

function CampingFilters({
    aggregations,
    onFilterToggle,
    onApplyFilters,
    onFilterChange,
    onFilterRemove,
    storePath = 'search',
    onCriteriaUpdate,
}: CampingFiltersProps) {
    const dispatch = useDispatch();
    const search = useSelector((state: Record<string, any>) => state[storePath]);

    const onChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        item: FiltersTypeProps
    ) => {
        event.preventDefault();
        const params = toggleFilterGroups(item, search);
        if (onCriteriaUpdate) {
            onCriteriaUpdate('fg', params);
        } else {
            // @ts-ignore
            dispatch(updateSearchCriteria('fg', params));
        }
        if (onApplyFilters) {
            onApplyFilters();
        }
    };
    return (
        <>
            <CampsiteTypeFilters
                aggregations={aggregations?.camping}
                search={search}
                onApplyFilters={onApplyFilters}
                onFilterChange={onFilterChange}
                onFilterRemove={onFilterRemove}
                onChange={onChange}
                onCriteriaUpdate={onCriteriaUpdate}
            />
            <MustHaveFilters
                onFilterToggle={onFilterToggle}
                onApplyFilters={onApplyFilters}
                onFilterRemove={onFilterRemove}
                aggregations={aggregations?.camping}
                search={search}
                onChange={onChange}
                onCriteriaUpdate={onCriteriaUpdate}
            />
        </>
    );
}

export default CampingFilters;
