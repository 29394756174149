/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { Icons, Tooltip, Button, useFlags } from 'sarsaparilla';
import { CAMPSITE_ACCESSIBLE_FILTER as accessibilityFilter } from '../constants';
import * as searchActions from '../actions/search';

function SearchFilterAccessibleCampsites({ title }) {
    const { useNewCampingFilters } = useFlags();
    const dispatch = useDispatch();
    const search = useSelector((state) => state.search);
    const fgValues = get(search, 'fg', []);

    const handleChange = () => {
        if (useNewCampingFilters) {
            dispatch(
                searchActions.updateSearchCriteria('fg', [...fgValues, 'accessible'])
            );
        } else {
            dispatch(
                searchActions.toggleSearchFilter('campsiteAmenities', accessibilityFilter)
            );
        }
        setTimeout(() => {
            dispatch(searchActions.navigateToSearchResults());
        }, 200);
    };
    const renderCheckbox = () => {
        const disabled = false;
        const label = title || 'Only show locations with accessible campsites';
        let isChecked =
            search?.uiFilters?.campsiteAmenities?.hasOwnProperty(
                accessibilityFilter.key
            ) || false;
        if (useNewCampingFilters) {
            isChecked = fgValues.indexOf('accessible') !== -1;
        }
        return (
            <div
                className="search-accessible-camping"
                key={'include_accessible_campsites'}
            >
                <label
                    htmlFor={'include_accessible_campsites'}
                    className="rec-label-checkbox"
                >
                    <input
                        onChange={(e) => handleChange(e)}
                        type="checkbox"
                        id={'include_accessible_campsites'}
                        disabled={disabled}
                        value={'true'}
                        className="rec-input-hide includeaccessiblecampsitescheckbox"
                        checked={isChecked}
                    />
                    <span className="rec-input-checkbox" />
                    <Icons.IconAccessible />
                    <span>{label}</span>
                    <Tooltip content="Accessible Site: This site has features that provide better accessibility than others.  Such features may include clear ground space, a firm surface and other features.  We are in the process of examining features that improve accessibility and welcome your feedback.">
                        <Button
                            appearance="subtle"
                            size="sm"
                            iconBeforeElement={<Icons.IconInfo />}
                            screenReaderTextBefore="Show accessible campsite filter help text"
                        />
                    </Tooltip>
                </label>
            </div>
        );
    };
    return renderCheckbox();
}

SearchFilterAccessibleCampsites.propTypes = {
    title: PropTypes.string,
};

export default SearchFilterAccessibleCampsites;
