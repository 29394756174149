/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { DividerNew, FlexCol, FlexRow, PlaceholderText } from 'sarsaparilla';

export function CommonFilterPlaceholder({
    lines = 3,
    hasDivider,
}: {
    lines: number;
    hasDivider: boolean;
}) {
    const arrayList = Array.from({ length: lines }, (_, i) => i + 1);
    return (
        <>
            <div className="pb-2">
                <FlexRow>
                    <FlexCol md={6}>
                        <PlaceholderText fontSize={30} lineHeight={36} />
                    </FlexCol>
                </FlexRow>
                <FlexRow>
                    {arrayList.map(() => (
                        <FlexCol md={6}>
                            <PlaceholderText lines={1} />
                        </FlexCol>
                    ))}
                </FlexRow>
            </div>
            {hasDivider && (
                <div className="search-common-filter__divider pt-2 pb-2">
                    <DividerNew />
                </div>
            )}
        </>
    );
}
