/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import {
    EditableWrapper,
    DisplayTemplate,
    EditTemplate,
    Icon,
    DisplayHtmlContent,
    Button,
} from 'sarsaparilla';
import { RichTextField } from 'sarsaparilla/RichTextField';
import {
    get,
    set,
    clone,
    cloneDeep,
    isEqual,
    map,
    forEach,
    size,
    includes,
    delay,
    isEmpty,
    isNil,
    uniqueId,
} from 'lodash';

export default class EditableNoticesTextbox extends React.Component {
    static propTypes = {
        onNoticesChange: PropTypes.func.isRequired,
        onNoticesCancelClick: PropTypes.func.isRequired,
        notices: PropTypes.arrayOf(PropTypes.object),
        noticeType: PropTypes.string,
        idSuffix: PropTypes.string,
        id: PropTypes.string,
        label: PropTypes.string,
        reset: PropTypes.bool,
        disabled: PropTypes.bool,
        richEditor: PropTypes.bool,
        disableAdd: PropTypes.bool,
        maxLength: PropTypes.number,
    };

    static defaultProps = {
        notices: [],
        richEditor: true,
        reset: false,
        noticeType: 'warning',
    };

    constructor(props) {
        super(props);
        this.state = {
            notices: props.notices,
            itemListDisplayMode: 'display',
            highlighted: [],
        };

        this.itemListCancelClick = this.itemListCancelClick.bind(this);
        this.itemListConfirmClick = this.itemListConfirmClick.bind(this);
        this.itemListEditClick = this.itemListEditClick.bind(this);
        this.handleNoticeChange = this.handleNoticeChange.bind(this);
        this.addInfo = this.addInfo.bind(this);
        this.deleteInfo = this.deleteInfo.bind(this);
        this.removeEmptyNTK = this.removeEmptyNTK.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.notices, prevProps.notices)) {
            const newState = {
                notices: this.props.notices,
            };
            this.setState(newState);
        }
    }

    getDisplayModeFromState(path) {
        return get(this.state, path, 'display');
    }

    getNoticesLength = (notices = []) => {
        return notices.reduce((accumulator, item) => {
            const text = item?.notice_text;
            const length = text?.replace(/(<([^>]+)>)/gi, '').length;
            return accumulator + length;
        }, 0);
    };

    isMaxLengthReached = (notices = []) => {
        const maxLength = this.props?.maxLength;
        const hasMaxLength = !!maxLength;

        if (!hasMaxLength) {
            return false;
        }

        const noticesLength = this.getNoticesLength(notices);
        const isMaxLengthReached = noticesLength >= maxLength;

        return isMaxLengthReached;
    };

    toggleForm(path) {
        // Anytime using old state's value the following pattern should be used.
        this.setState((prevState) => {
            const newState = { ...prevState };
            const oldValue = get(prevState, path);
            const newValue = oldValue === 'edit' ? 'display' : 'edit';
            set(newState, path, newValue);
            return newState;
        });
    }

    removeEmptyNTK() {
        const notices = [];
        forEach(this.state.notices, (item) => {
            // only save all the none empty NTKs
            if (!isEqual(item.notice_text, '')) {
                notices.push(item);
            }
        });
        this.setState({ notices });

        return notices;
    }

    itemListEditClick() {
        this.toggleForm('itemListDisplayMode');
    }

    itemListConfirmClick() {
        const notices = this.removeEmptyNTK();
        this.props.onNoticesChange(notices);
        this.toggleForm('itemListDisplayMode');
    }

    itemListCancelClick() {
        const { notices, reset } = this.props;
        this.removeEmptyNTK();
        this.props.onNoticesCancelClick();
        this.toggleForm('itemListDisplayMode');
        if (reset) {
            this.setState({ notices });
        }
    }

    handleNoticeChange(value, id) {
        let notices = cloneDeep(this.state?.notices);
        const noticesCloned = cloneDeep(this.state?.notices);
        const index = parseInt(id.slice(15), 10);
        const maxLength = this.props?.maxLength;
        const hasMaxLength = !!maxLength;

        noticesCloned[index].active = true; // make sure it's always active for now
        noticesCloned[index].notice_text = value;

        const noticesLength = this.getNoticesLength(noticesCloned);

        if (!hasMaxLength) {
            this.setState({
                notices: noticesCloned,
            });
            return;
        }

        if (hasMaxLength && noticesLength <= maxLength) {
            notices = noticesCloned;
        } else if (hasMaxLength) {
            notices[index].id = uniqueId();
        }

        this.setState({
            notices,
        });
    }

    addInfo() {
        const notices = clone(this.state.notices);
        // insert an empty NTK object and set some defaults for now
        notices.push({
            active: true,
            notice_text: '',
            notice_type: this.props.noticeType,
        });
        this.setState({ notices });
    }

    deleteInfo(index) {
        const notices = [];
        forEach(this.state.notices, (item, i) => {
            // only save all the NTKs that doesn't match the index
            if (!isEqual(index, i)) {
                notices.push(item);
            }
        });
        this.setState({ notices });
    }

    moveNotice(index, direction) {
        const notices = cloneDeep(this.state.notices);
        const currentNotice = notices[index];
        const replacementIndex = index + direction;
        if (replacementIndex >= 0 && replacementIndex < size(this.state.notices)) {
            const replacementNotice = notices[replacementIndex];
            currentNotice.notice_order = replacementIndex;
            replacementNotice.notice_order = index;

            notices[index] = replacementNotice;
            notices[replacementIndex] = currentNotice;
        }

        this.setState({ highlighted: [] }, () => {
            delay(() => {
                this.setState({ notices, highlighted: [index, replacementIndex] });
            }, 300);
        });
    }

    // Render Notice List Display mode
    renderDisplayNotices() {
        const { notices } = this.state;
        return map(notices, (notice, index) => {
            const key = `facility-notices-${notice.notice_type}-${index}`;
            const evaluateEmptyNotice = notice?.notice_text?.replace('\u21b5', '').trim();
            if (isNil(notice.notice_text) || isEmpty(evaluateEmptyNotice)) {
                return null;
            }
            return (
                <li key={key}>
                    <DisplayHtmlContent html={notice.notice_text} />
                </li>
            );
        });
    }

    renderEditNotices() {
        const { notices } = this.state;

        return map(notices, (notice, index) => {
            const key = `facility-notices-${notice?.notice_type}-${index}-${notice?.id}`;

            let highlightClass = '';

            if (includes(this.state.highlighted, index)) {
                highlightClass = 'rec-ntk-item-highlight';
            }

            return (
                <div className="rec-form-check-wrap" key={key}>
                    <div className="rec-ntk-input">
                        {!this.props.disableAdd && (
                            <span className="rec-ntk-order">
                                <Button
                                    appearance="tertiary"
                                    className="rec-ntk-order-button"
                                    onClick={() => this.moveNotice(index, -1)}
                                    iconBeforeElement={<Icon iconName="chevron-up" />}
                                    isDisabled={index === 0}
                                    screenReaderTextBefore="Move position up"
                                />
                                <Button
                                    appearance="tertiary"
                                    className="rec-ntk-order-button"
                                    onClick={() => this.moveNotice(index, 1)}
                                    iconBeforeElement={<Icon iconName="chevron-down" />}
                                    isDisabled={index === size(notices) - 1}
                                    screenReaderTextBefore="Move position down"
                                />
                            </span>
                        )}
                        {this.props.richEditor ? (
                            <RichTextField
                                className={`rich-text-field ${highlightClass}`}
                                label={`Item ${index + 1}`}
                                isLabelVisible={false}
                                id={`rich-text-item-${index}-${this.props.idSuffix}`}
                                html={notice.notice_text}
                                hasItalicsButton
                                onChange={this.handleNoticeChange}
                                placeholder="Add your content here ..."
                            />
                        ) : (
                            <RichTextField
                                className={`rich-text-field ${highlightClass}`}
                                label={`Item ${index + 1}`}
                                isLabelVisible={false}
                                hasBoldButton={false}
                                hasLinkButton={false}
                                hasItalicsButton={false}
                                hasUnorderedListButton={false}
                                hasOrderedListButton={false}
                                id={`rich-text-item-${index}-${this.props.idSuffix}`}
                                html={notice.notice_text}
                                onChange={this.handleNoticeChange}
                                placeholder="Add your content here ..."
                            />
                        )}
                        {!this.props.disableAdd && (
                            <Button
                                className="rec-close-icon dlt-notice-btn"
                                appearance="subtle-danger"
                                iconBeforeElement={
                                    <Icon iconName="close-circle-outline" />
                                }
                                screenReaderTextBefore="Delete item"
                                onClick={() => this.deleteInfo(index)}
                            />
                        )}
                        <br />
                    </div>
                </div>
            );
        });
    }

    renderAddMoreInfoButton() {
        const isDisabled =
            size(this.state.notices) >= 15 || this.isMaxLengthReached(this.state.notices);

        return this.props.disableAdd ? null : (
            <Button
                appearance="tertiary"
                size="sm"
                onClick={this.addInfo}
                iconBeforeElement={<Icon iconName="add-circle-outline" />}
                isDisabled={isDisabled}
            >
                Add More
            </Button>
        );
    }

    render() {
        const itemListDisplayMode = this.getDisplayModeFromState('itemListDisplayMode');
        const { disabled, maxLength, className = '' } = this.props;
        const { notices } = this.state;
        const noticesLength = this.getNoticesLength(notices);
        const remaining = Number.isNaN(noticesLength)
            ? maxLength
            : maxLength - noticesLength;
        return (
            <div>
                <div
                    className={`rec-edit-multi-section-wrap ${className}`}
                    data-component="EditableNoticeTextbox"
                >
                    <EditableWrapper
                        mode={itemListDisplayMode}
                        label={this.props.label}
                        id={this.props.id}
                    >
                        <DisplayTemplate
                            isDisabled={disabled}
                            onEditClick={this.itemListEditClick}
                        >
                            <ul className="single-column">
                                {this.renderDisplayNotices()}
                            </ul>
                        </DisplayTemplate>
                        <EditTemplate
                            onConfirmClick={this.itemListConfirmClick}
                            onCancelClick={this.itemListCancelClick}
                        >
                            {this.renderEditNotices()}
                            {this.renderAddMoreInfoButton()}
                        </EditTemplate>
                    </EditableWrapper>
                </div>
                {maxLength && (
                    <span className="rec-edit-multi-tooltip">
                        {remaining} characters remaining ({maxLength} characters max)
                    </span>
                )}
            </div>
        );
    }
}
