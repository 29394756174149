/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';

import { Box, ContentBlock, Heading, Carousel, Stack, useWindowSize } from 'sarsaparilla';

import RecommendationCard from './RecommendationCard';

export default function FacilityRecommendations({
    heading,
    headingLevel = 2,
    recommendations = [],
    ...rest
}) {
    const { width } = useWindowSize();

    return (
        <div className="mt-8" data-component="FacilityRecommendations" {...rest}>
            <Box background="gray-1" paddingY="xl">
                <ContentBlock>
                    <Stack>
                        <Heading headingLevel={headingLevel} appearance="h5">
                            {heading}
                        </Heading>
                        <Carousel
                            slideTargetWidth={275}
                            hasGutters
                            hasNegativeMargins={width > 1240}
                        >
                            {recommendations.map((item, index) => (
                                <RecommendationCard
                                    key={index}
                                    headingLevel={headingLevel + 1}
                                    heading={item.heading}
                                    linkUrl={item.linkUrl}
                                    imageUrl={item.imageUrl}
                                    location={item.location}
                                    bonusItemsTitle={item.bonusItemsTitle}
                                    bonusItems={item.bonusItems}
                                    rating={item.rating}
                                    reviewCount={item.reviewCount}
                                />
                            ))}
                        </Carousel>
                    </Stack>
                </ContentBlock>
            </Box>
        </div>
    );
}

FacilityRecommendations.propTypes = {
    heading: PropTypes.string,
    headingLevel: PropTypes.number,
    recommendations: PropTypes.array,
};
