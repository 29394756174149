/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import './css/logoGuidelines.scss';

import * as React from 'react';
import { FlexRow, FlexCol, Heading, Icons, Button } from 'sarsaparilla';
import axios from 'axios';
import { Logo } from '../Logo/Logo';

type Attachment = {
    link: string;
};

type Asset = {
    attachments: Attachment[];
    name: string;
};

type RenderSYSAssets = Asset[];

export default function LogoGuidelinesAndCampaignAssets() {
    const [recLogo, setRecLogo] = React.useState<Asset>();
    const [recGuidelines, setRecLogoGuidelines] = React.useState<Asset>();
    const [SYSAssets, setSYSAssets] = React.useState<RenderSYSAssets>([]);

    const fetchRecLogo = async () => {
        try {
            const resp = await axios.get(
                `${process.env.SN_API}/g_ba2/content/new_inventory?category=Logo`
            );
            if (Array.isArray(resp?.data?.result)) {
                return resp?.data?.result[0];
            }
            return [];
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            return [];
        }
    };

    const fetchRecGuidelines = async () => {
        try {
            const resp = await axios.get(
                `${process.env.SN_API}/g_ba2/content/new_inventory?category=Logo%20Guidelines`
            );
            if (Array.isArray(resp?.data?.result)) {
                return resp?.data?.result[0];
            }
            return [];
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            return [];
        }
    };
    const fetchSYSAssets = async () => {
        try {
            const resp = await axios.get(
                `${process.env.SN_API}/g_ba2/content/new_inventory?category=Logo%20Guidelines`
            );
            if (Array.isArray(resp?.data?.result)) {
                return resp?.data?.result;
            }
            return [];
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            return [];
        }
    };

    React.useEffect(() => {
        fetchRecLogo().then((result) => {
            setRecLogo(result);
        });
        fetchRecGuidelines().then((result) => {
            setRecLogoGuidelines(result);
        });
        fetchSYSAssets().then((result) => {
            setSYSAssets(result);
        });
    }, []);

    const renderRecLogo = (rl: Asset | undefined) => {
        if (rl && rl.attachments && rl.attachments.length > 0) {
            return (
                <Button
                    appearance="link"
                    iconBeforeElement={<Icons.IconFileDownload />}
                    href={rl.attachments[0].link}
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label={`View ${rl.name} Study`}
                >
                    Download logo kit
                </Button>
            );
        }
        return null;
    };

    const renderLogoGuidelines = (logoGuidelines: Asset | undefined) => {
        if (
            logoGuidelines &&
            logoGuidelines.attachments &&
            logoGuidelines.attachments.length > 0
        ) {
            return (
                <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={logoGuidelines.attachments[0].link}
                >
                    Recreation.gov Logo Usage
                </a>
            );
        }

        return null;
    };

    const renderSYSAssets = (sys: RenderSYSAssets) => {
        const sysAssetList = [];
        if (sys && sys.length > 0) {
            for (const asset of sys) {
                sysAssetList.push(
                    <FlexCol xs={12} key={asset.name}>
                        <Button
                            appearance="link"
                            iconBeforeElement={<Icons.IconFileDownload />}
                            href={asset.attachments[0].link}
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label={`View ${asset.name} Study`}
                        >
                            {asset.name}
                        </Button>
                    </FlexCol>
                );
            }
        }
        return sysAssetList;
    };

    return (
        <>
            <section className="nav-media-center-logo-download px-3 py-5 px-md-5 pt-md-6">
                <div className="nav-media-center-logo-download-bgd" />
                <FlexRow justifyContent="center">
                    <FlexCol xl={10} lg={12}>
                        <FlexRow justifyContent="center">
                            <FlexCol xl={6} lg={8} md={12}>
                                <Heading
                                    headingLevel={2}
                                    appearance="h4"
                                    className="mb-2"
                                >
                                    Communication and Outreach Tools
                                </Heading>
                            </FlexCol>
                        </FlexRow>
                        <div className="nav-media-center-logo-call-out p-3 p-md-5 mt-1">
                            <FlexRow>
                                <FlexCol alignSelf="center" md={12} lg={6}>
                                    <div className="nav-media-center-logo-preview">
                                        <Logo />
                                    </div>
                                    <p>
                                        The Recreation.gov logo is available standalone or
                                        with the official tagline. Grey is designed for
                                        lighter backgrounds, while white works best on
                                        dark backgrounds. Please don&#39;t alter the logo
                                        in any way.
                                    </p>
                                    {renderRecLogo(recLogo)}
                                </FlexCol>
                                <FlexCol alignSelf="center" md={12} lg={6}>
                                    <FlexRow>{renderSYSAssets(SYSAssets)}</FlexRow>
                                </FlexCol>
                            </FlexRow>
                        </div>
                    </FlexCol>
                </FlexRow>
            </section>
            <section className="nav-media-center-logo-usage px-3 px-md-5 mb-5">
                <FlexRow justifyContent="center">
                    <FlexCol xl={10} lg={12}>
                        <FlexRow>
                            <FlexCol lg={5}>
                                <Heading
                                    headingLevel={3}
                                    appearance="h5"
                                    className="mb-2"
                                >
                                    Usage Agreement
                                </Heading>
                                <p className="mb-3">
                                    All trademarks, logos, or other brand elements are
                                    subject to {renderLogoGuidelines(recGuidelines)}.
                                </p>
                            </FlexCol>
                            <FlexCol lg={7}>
                                <FlexRow>
                                    <FlexCol md={6}>
                                        <Heading
                                            headingLevel={4}
                                            appearance="h6"
                                            className="mb-2"
                                        >
                                            Do these awesome things
                                        </Heading>
                                        <ul className="nav-media-center-logo-usage-list success mb-3">
                                            <li>
                                                Use the logo to link to Recreation.gov
                                            </li>
                                            <li>
                                                Use the logo to advertise that your
                                                product uses our recreation data
                                            </li>
                                            <li>
                                                Use the logo in a blog post or news
                                                article about Recreation.gov
                                            </li>
                                        </ul>
                                    </FlexCol>
                                    <FlexCol md={6}>
                                        <Heading
                                            headingLevel={4}
                                            appearance="h6"
                                            className="mb-2"
                                        >
                                            Please don&#39;t do these things
                                        </Heading>
                                        <ul className="nav-media-center-logo-usage-list error">
                                            <li>
                                                Use the logo for your application&#39;s
                                                icon
                                            </li>
                                            <li>Create a modified version of the logo</li>
                                            <li>
                                                Use or sell any Recreation.gov artwork
                                                without permission
                                            </li>
                                            <li>
                                                Change the colors, dimensions or add your
                                                own text or images
                                            </li>
                                        </ul>
                                    </FlexCol>
                                </FlexRow>
                            </FlexCol>
                        </FlexRow>
                    </FlexCol>
                </FlexRow>
            </section>
        </>
    );
}
