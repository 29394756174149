/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import {
    ContentContainer,
    Heading,
    FlexRow,
    FlexCol,
    Carousel,
    useBreakpoint,
} from 'sarsaparilla';

import statsImage1 from './images/stats-section/stats-image-01.webp';
import statsImage2 from './images/stats-section/stats-image-02.webp';
import statsImage3 from './images/stats-section/stats-image-03.webp';

const statsBlocks = [
    {
        alt: 'Manage',
        image: statsImage1,
        text: 'Average revenue increase, for facilities with 100% reservable inventory experience.',
        stat: '130%',
    },
    {
        alt: 'Manage',
        image: statsImage2,
        text: 'Average revenue increase, for facilities with a zero-day cut-off window experience.',
        stat: '76%',
    },
    {
        alt: 'Manage',
        image: statsImage3,
        text: 'Decrease of wait times at Red Rock Canyon since implementing Timed Entry.',
        stat: '90%',
    },
];

export default function StatsSection() {
    const { isLgAndUp } = useBreakpoint();

    const statsBlockItem = statsBlocks.map((statsBlock, index) => (
        <div className="nav-ni-stats-section-stats-item" key={index}>
            <img src={statsBlock.image} alt={statsBlock.alt} width="100%" height="auto" />

            <div className="nav-ni-stats-section-list-item-overlay-txt-wrap">
                <div className="stat">{statsBlock.stat}</div>
                <div className="text">{statsBlock.text}</div>
            </div>
        </div>
    ));

    return (
        <section className="nav-ni-stats-section-wrap">
            <ContentContainer className="my-0">
                <FlexRow justifyContent="center" className="mb-3">
                    <FlexCol xs="variable">
                        <Heading headingLevel={2} appearance="h4">
                            Over 4 million transactions processed annually
                        </Heading>
                    </FlexCol>
                </FlexRow>

                {isLgAndUp ? (
                    <Carousel
                        className="nav-ni-stats-section-carousel-wrap mb-6"
                        slidesPerPage={3}
                        hasGutters={false}
                        hasNegativeMargins
                    >
                        {statsBlockItem}
                    </Carousel>
                ) : (
                    <Carousel
                        className="nav-ni-stats-section-carousel-wrap mb-6"
                        slideTargetWidth={500}
                    >
                        {statsBlockItem}
                    </Carousel>
                )}
            </ContentContainer>
        </section>
    );
}
