/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from 'sarsaparilla';
import { SEARCH_FILTER_TOUR_ACCESSIBLE as options } from '../constants';

export default class SearchFilterTourAccessible extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        uiFilters: PropTypes.object,
        aggregations: PropTypes.object,
        onFilterToggle: PropTypes.func,
        onApplyFilters: PropTypes.func,
    };

    static displayName = 'SearchFilterTourAccessible';

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange(event, item) {
        if (this.props.onFilterToggle) {
            this.props.onFilterToggle('tourAccessible', item);
        }
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    processAggregations(searchAggregations) {
        const counts = {};
        // find aggregated counts for item attribute
        options.forEach((item) => {
            const filter = item.filters[0];
            counts[filter.attribute] = {};
            if (
                searchAggregations.tourAccessible &&
                searchAggregations.tourAccessible[filter.attribute]
            ) {
                const aggs = searchAggregations.tourAccessible[filter.attribute];
                for (let i = 0; i < aggs.length; i++) {
                    counts[filter.attribute][aggs[i].name] = aggs[i].count;
                }
            }
        });
        // aggregate comma separate filter counts into one
        options.forEach((item) => {
            const filter = item.filters[0];
            const parts = filter.filterValue.split(',');
            let count = 0;
            parts.forEach((part) => {
                const cnt = counts[filter.attribute];
                if (cnt && cnt[part]) {
                    count += cnt[part];
                }
            });
            counts[filter.attribute][filter.filterValue] = count;
        });
        return counts;
    }

    renderCheckbox(item, counts) {
        const filter = item.filters[0];
        const attCounts = counts[filter.attribute];
        const selections = this.props.uiFilters.tourAccessible;
        const isChecked = selections.hasOwnProperty(item.key);
        let count = 0;
        if (attCounts) {
            count = attCounts[filter.filterValue];
        }
        let disabled;
        let label;
        if (count || isChecked) {
            disabled = count === 0 && !isChecked ? 'disabled' : false;
            label = `${item.value} (${count})`;
        } else {
            disabled = 'disabled';
            label = item.value;
        }
        return (
            <div className="rec-form-check-wrap" key={item.key}>
                <div
                    htmlFor={`tour_accessible_${item.key}`}
                    className={`rec-label-checkbox ${disabled}`}
                >
                    <Checkbox
                        onChange={(e) => this.handleChange(e, item)}
                        id={`tour_accessible_${item.key}`}
                        label={label}
                        value={item.value}
                        className={count === 0 && !isChecked ? 'disabled' : ''}
                        isChecked={isChecked}
                    />
                </div>
            </div>
        );
    }

    render() {
        const className = `${this.props.className}`;
        const counts = this.processAggregations(this.props.aggregations);
        return (
            <div
                className={`search-filters-check-wrap ${className}`}
                role="group"
                aria-label="Accessibility filters"
            >
                <legend className="rec-sr-only">
                    Select tour accessibility to filter on
                </legend>
                {options.map((item) => this.renderCheckbox(item, counts))}
            </div>
        );
    }
}
