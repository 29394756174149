/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';

import Newsletter from '../../components/homepage/newsletter/Newsletter';
import NewsletterModal from '../../components/homepage/newsletter/NewsletterModal';

export default class NewsletterContainer extends React.PureComponent {
    static propTypes = {
        location: PropTypes.shape({
            pathname: PropTypes.string,
            search: PropTypes.string,
            hash: PropTypes.string,
        }),
        isBackgroundLight: PropTypes.bool,
    };

    static defaultProps = {
        isBackgroundLight: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            isNewsletterConfirmationModalOpen: true,
            loadNewsletter: false,
        };
    }

    componentDidMount() {
        if (this.newsLetterWrapper) {
            this.observer = new IntersectionObserver(this.loadNewsletterComponent, {
                rootMargin: '400px',
                threshold: 0,
            });

            this.observer.observe(this.newsLetterWrapper);
        }
    }

    componentWillUnmount() {
        if (this.newsLetterWrapper) {
            this.observer.unobserve(this.newsLetterWrapper);
        }
    }

    loadNewsletterComponent = (entries) => {
        if (
            entries[0] &&
            entries[0].isIntersecting &&
            this.state.loadNewsletter === false
        ) {
            this.setState({ loadNewsletter: true });
        }
    };

    closeModal = () => {
        this.setState({
            isNewsletterConfirmationModalOpen: false,
        });
    };

    render() {
        const newsletterSubscription = this.props.location.search.includes(
            'newsletterSubscription=success'
        );

        // After a successful subscription, ActiveCampaign redirects the user back to the
        // homepage with a "newsletterSubscription=success" URL parameter so that we know the
        // user has just subscribed to the newsletter. In this case, we show a success modal
        // and don't show the previously-visible Newsletter signup form
        if (newsletterSubscription) {
            return (
                <NewsletterModal
                    isModalOpen={this.state.isNewsletterConfirmationModalOpen}
                    closeModal={this.closeModal}
                />
            );
        }

        const newsletterComponent = this.state.loadNewsletter ? (
            <Newsletter isBackgroundLight={this.props.isBackgroundLight} />
        ) : null;

        return (
            <div
                ref={(newsLetterWrapper) => {
                    this.newsLetterWrapper = newsLetterWrapper;
                }}
                id="subscribe"
            >
                {newsletterComponent}
            </div>
        );
    }
}
