/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';

// Max of 3
export const BRING_HOME_A_STORY_ITEM = PropTypes.shape({
    bring_home_a_story_link: PropTypes.string,
    bring_home_a_story_location: PropTypes.string,
    bring_home_a_story_title: PropTypes.string,
});

export const RELATED_TRIP_ITEM = PropTypes.shape({
    explore_related_trips_alt: PropTypes.string, // Hero image alt text
    explore_related_trips_image: PropTypes.string, // Promotional image
    explore_trips_link: PropTypes.string, // Link to article
    explore_trips_link_title: PropTypes.string, // Article title
});

export const PHOTO_GALLERY_EXPORT = PropTypes.shape({
    photo_gallery_image: PropTypes.string,
    photo_gallery_image_alt: PropTypes.string,
    photo_gallery_location: PropTypes.string,
});

// Order by State (list_item_location) ascending
export const LIST_ITEM = PropTypes.shape({
    list_image: PropTypes.string,
    list_image_alt_text: PropTypes.string,
    list_image_inventory_url: PropTypes.string,
    list_image_location_and_source: PropTypes.string,
    list_item_description: PropTypes.string,
    list_item_location: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    list_item_title: PropTypes.string,
});

// Min 2, Max 5, 150 character max
export const FUN_FACT_ITEM = PropTypes.shape({
    fun_facts_description: PropTypes.string,
});

export const ARTICLE = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    article_overview: PropTypes.string,
    article_subhead: PropTypes.string,
    article_title: PropTypes.string,
    bring_home_inventory: PropTypes.arrayOf(BRING_HOME_A_STORY_ITEM),
    content_type: PropTypes.string,
    featured_article: PropTypes.string,
    field_journal_promotional_image: PropTypes.string,
    field_list_promotional_image: PropTypes.string,
    field_location_spotlight_promotional_image: PropTypes.string,
    hero_image: PropTypes.string,
    hero_image_alt_text: PropTypes.string,
    hero_image_location_and_source: PropTypes.string,
    promote_to_home_page: PropTypes.string,
    publish_date_and_time: PropTypes.string,
    url: PropTypes.string,
});

export const FEATURED = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    article_overview: PropTypes.string,
    article_title: PropTypes.string,
    content_type: PropTypes.string,
    hero_image: PropTypes.string,
    field_feature_article: PropTypes.string,
    field_promotional_image: PropTypes.string,
    hero_image_alt_text: PropTypes.string,
    time_changed: PropTypes.string,
    time_created: PropTypes.string,
});

export const FEATURED_EXTENDED = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    article_overview: PropTypes.string,
    article_title: PropTypes.string,
    content_type: PropTypes.string,
    field_feature_article: PropTypes.string,
    field_promotional_image: PropTypes.string,
    hero_image_alt_text: PropTypes.string,
    time_changed: PropTypes.string,
    time_created: PropTypes.string,
    contentType: PropTypes.string,
    url: PropTypes.string,
});

export const JOURNAL = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    about_author_description: PropTypes.string,
    about_author_image: PropTypes.string,
    article_byline: PropTypes.string,
    article_overview: PropTypes.string,
    article_subhead: PropTypes.string,
    article_title: PropTypes.string,
    author_by: PropTypes.string, // Ghostwriter
    bring_home_inventory: PropTypes.arrayOf(BRING_HOME_A_STORY_ITEM),
    check_out_itinerary_link: PropTypes.string,
    check_out_itinerary_title: PropTypes.string,
    featured_article: PropTypes.string, // Yes/No
    hero_image: PropTypes.string,
    hero_image_alt_text: PropTypes.string,
    hero_image_location_and_source: PropTypes.string,
    // User can upload EITHER image or video
    journal_entry_image: PropTypes.string,
    journal_entry_image_alt_text: PropTypes.string,
    journal_entry_image_inv_url: PropTypes.string,
    journal_entry_video_transcript: PropTypes.string,
    journal_entry_video_youtube_url: PropTypes.string,
    journal_entry_above_quote: PropTypes.string,
    journal_entry_below_quote: PropTypes.string,
    media_imgvideo_location_source: PropTypes.string,
    photo_gallery_export: PropTypes.arrayOf(PHOTO_GALLERY_EXPORT),
    promote_to_home_page: PropTypes.string, // Yes/No
    promotional_image: PropTypes.string, // Letterbox preview 4:3
    publish_date_and_time: PropTypes.string, // Not displayed
    quote: PropTypes.string,
    // Tags are internal fields
    tags_activities: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    tags_booking_type: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    tags_state: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
});

export const LIST = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    author_by: PropTypes.string,
    article_overview: PropTypes.string,
    article_subhead: PropTypes.string,
    article_title: PropTypes.string,
    bring_home_inventory: PropTypes.arrayOf(BRING_HOME_A_STORY_ITEM),
    explore_related_trips: PropTypes.arrayOf(RELATED_TRIP_ITEM),
    featured_article: PropTypes.string,
    hero_image: PropTypes.string,
    hero_image_alt_text: PropTypes.string,
    hero_image_location_and_source: PropTypes.string,
    list_item: PropTypes.arrayOf(LIST_ITEM),
    promote_to_home_page: PropTypes.string,
    promotional_image: PropTypes.string,
    publish_date_and_time: PropTypes.string,
    // Tags are internal fields
    tags_activities: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    tags_booking_type: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    tags_state: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
});

export const LOCATION_SPOTLIGHT = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // EITHER image or video
    additional_image: PropTypes.string,
    additional_image_alt: PropTypes.string,
    additional_image_inv_url: PropTypes.string,
    additional_imgvideo_location_source: PropTypes.string,
    additional_image_video_transcript: PropTypes.string,
    additional_image_video_url: PropTypes.string,
    article_byline: PropTypes.string,
    article_overview: PropTypes.string,
    article_subhead: PropTypes.string,
    article_title: PropTypes.string,
    author_by: PropTypes.string,
    best_spot_description: PropTypes.string,
    best_spot_img: PropTypes.string,
    best_spot_img_alt: PropTypes.string,
    best_spot_img_location_source: PropTypes.string,
    best_spot_inv_url: PropTypes.string,
    bring_home_inventory: PropTypes.arrayOf(BRING_HOME_A_STORY_ITEM),
    explore_related_trips_article_links: PropTypes.arrayOf(RELATED_TRIP_ITEM),
    featured_article: PropTypes.string,
    fun_facts: PropTypes.arrayOf(FUN_FACT_ITEM),
    hero_image: PropTypes.string,
    hero_image_alt_text: PropTypes.string,
    hero_image_location_and_source: PropTypes.string,
    incredible_exp_description: PropTypes.string,
    incredible_exp_img: PropTypes.string,
    incredible_exp_img_alt: PropTypes.string,
    incredible_exp_img_location_source: PropTypes.string,
    incredible_exp_inv_url: PropTypes.string,
    message_from_agency_description: PropTypes.string,
    promote_to_home_page: PropTypes.string,
    promotional_image: PropTypes.string,
    publish_date_and_time: PropTypes.string,
    quote: PropTypes.string,
    quote_author: PropTypes.string,
    signature_features_description: PropTypes.string,
    signature_features_img: PropTypes.string,
    signature_features_img_alt: PropTypes.string,
    signature_features_inv_url: PropTypes.string,
    signature_features_img_location_source: PropTypes.string,
    // Tags are internal fields
    tags_activities: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    tags_booking_type: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    tags_state: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
});

export const BREADCRUMB = PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
});

// TODO: This needs flushing out and probably needs to live in the search service
export const UI_FILTERS = PropTypes.shape({
    accommodations: PropTypes.object,
    activities: PropTypes.object,
    campsiteAmenities: PropTypes.object,
    campsiteElectricityHookup: PropTypes.object,
    campsiteEquipment: PropTypes.object,
    campsiteTypes: PropTypes.object,
    campsiteUseTypes: PropTypes.object,
    campsiteVehicleLength: PropTypes.object,
    hasFilters: PropTypes.bool,
    includeUnavailable: PropTypes.bool,
    inventoryTypes: PropTypes.object, // unknown object contents
    lastAppliedFilter: PropTypes.any, // unknown
    occupants: PropTypes.object,
    permitTypes: PropTypes.object,
    reservable: PropTypes.object,
    tourAccessible: PropTypes.object,
    tourDifficulty: PropTypes.object,
    tourDuration: PropTypes.object,
    tourPrice: PropTypes.object,
    tourTime: PropTypes.object,
    tourTypes: PropTypes.object,
});

// TODO: This probably needs to live in the search service
export const SEARCH = PropTypes.shape({
    aggregations: PropTypes.array,
    checkin_time: PropTypes.string,
    checkout_time: PropTypes.string,
    filters: PropTypes.array,
    filtersVisible: PropTypes.bool,
    headerTextQuery: PropTypes.string,
    highlighted_item: PropTypes.number,
    inventoryTypeFiltersVisible: PropTypes.bool,
    lat: PropTypes.number,
    lng: PropTypes.number,
    map_center_lat: PropTypes.number,
    map_center_lng: PropTypes.number,
    placeid: PropTypes.string,
    placename: PropTypes.string,
    radius: PropTypes.number,
    showSearchInput: PropTypes.bool,
    size: PropTypes.number,
    sort: PropTypes.string,
    start: PropTypes.number,
    type: PropTypes.string,
    uiFilters: UI_FILTERS,
    what: PropTypes.string,
    where: PropTypes.string,
});

export const VALIDATION_ACTIONS = PropTypes.objectOf(PropTypes.func);

export const VALIDATIONS = PropTypes.objectOf(PropTypes.objectOf(PropTypes.any));

export const FORM_VALIDATION_ERROR_ITEM = PropTypes.shape({
    errorText: PropTypes.string,
    inputId: PropTypes.string.isRequired,
});

export const FORM_VALIDATION_ERRORS = PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.arrayOf(FORM_VALIDATION_ERROR_ITEM)),
    PropTypes.arrayOf(FORM_VALIDATION_ERROR_ITEM),
]);
