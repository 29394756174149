/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
    ContentContainer,
    FlexCol,
    FlexRow,
    URLHelper,
    StringHelper,
    Heading,
    Breadcrumbs,
    RentalsPromo,
    Pagination,
} from 'sarsaparilla';
import axios from 'axios';
import Items from '../../components/tertiary/whatsNew/Items';

const ITEMS_PER_PAGE = 36;

export default function DiscoverResultsContainer({ titlePrefix, location, state }) {
    const whatsNewRef = React.useRef(null);
    const { query } = useLocation();
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    let pageParam = 1;
    if (query?.page) {
        try {
            pageParam = Number(query?.page);
        } catch (e) {
            // ignore errors
        }
    }
    const [currentPage, setCurrentPage] = useState(pageParam);
    const [hasErrors, setHasErrors] = useState(false);
    const [title, setTitle] = useState('');
    const hiddenStates = ['Alaska', 'Hawaii'];

    const totalPages = React.useMemo(() => {
        return Math.ceil(total / ITEMS_PER_PAGE);
    }, [total]);

    const fetchItems = () => {
        if (items.length === 0) {
            setLoading(true);
        }
        // keep this criterial in sync with what's being served by SEO service /discover end point
        const fqs = ['reservable:1'];
        fqs.push('entity_type:recarea');
        fqs.push('entity_type:campground');
        fqs.push('entity_type:ticketfacility');
        fqs.push('entity_type:timedentry');
        fqs.push('entity_type:permit');
        fqs.push('entity_type:vehiclepermit');
        fqs.push('entity_type:treepermit');
        fqs.push('entity_type:activitypass');
        fqs.push('entity_type:venuereservations');
        if (state) {
            fqs.push(`state_code_s:${StringHelper.toTitleCase(state)}`);
        }
        const params = {
            q: location,
            radius: 80,
            size: 36,
            start: (currentPage - 1) * 36,
            fq: fqs,
        };
        axios
            .get(URLHelper.urlWithParams(`${process.env.API}/search`, params))
            .then((result) => {
                if (
                    result?.data?.results?.length > 0 &&
                    (result?.data?.location || state)
                ) {
                    if (Array.isArray(result?.data?.results)) {
                        setItems(result?.data?.results);
                    } else {
                        setItems([]);
                    }
                    setTotal(result?.data?.total);
                    setLoading(false);
                    let fetchedLocation = result.data.location;
                    if (!fetchedLocation && state) {
                        fetchedLocation = state;
                    }
                    fetchedLocation = fetchedLocation.replace(', United States', '');
                    setTitle(`${titlePrefix}${fetchedLocation}`);
                } else {
                    setTitle('No results');
                    setItems([]);
                    setLoading(false);
                }
            })
            .catch(() => {
                setHasErrors(true);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchItems();
        // eslint-disable-next-line
    }, [currentPage]);

    if (hasErrors) {
        return null;
    }

    // Change page
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);

        if (whatsNewRef.current) {
            whatsNewRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }

        window.history.replaceState(
            {},
            '',
            `${window.location.pathname}?page=${pageNumber}`
        );
    };

    return (
        <main className="nav-discover-page">
            <ContentContainer className="mb-5 mt-6">
                <FlexRow id="whats-new-top" className="mb-3" ref={whatsNewRef}>
                    <FlexCol md="variable">
                        <Breadcrumbs
                            path={[{ title: 'Home', url: '/' }, { title: 'Discover' }]}
                        />
                    </FlexCol>
                </FlexRow>

                <FlexRow id="whats-new-top" className="mb-3">
                    <FlexCol>
                        <Heading headingLevel={1} appearance="h3">
                            {title}
                        </Heading>
                    </FlexCol>
                </FlexRow>

                <Items items={items} isLoading={loading} />

                {!loading && (
                    <Pagination
                        pages={totalPages}
                        activePage={currentPage - 1}
                        onChange={(pageIndex) => paginate(pageIndex + 1)}
                    />
                )}

                {!hiddenStates.includes(state) && (
                    <FlexRow>
                        <FlexCol
                            sm={12}
                            smOffset={0}
                            lg={10}
                            lgOffset={1}
                            xl={8}
                            xlOffset={2}
                            className="mt-4"
                        >
                            <RentalsPromo
                                copy={`Planning a trip with ${process.env.SITE_NAME} is even easier with rental options. Whether it's an RV to hit the road or outdoor gear like tents, packs, and sleeping bags, enjoy the convenience of renting equipment for your next trip.`}
                                buttonCopy="Learn More"
                            />
                        </FlexCol>
                    </FlexRow>
                )}
            </ContentContainer>
        </main>
    );
}

DiscoverResultsContainer.propTypes = {
    titlePrefix: PropTypes.string,
    location: PropTypes.string,
    state: PropTypes.string,
};
