/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import { StyledModal, Text, ModalActions, Button } from '@fp/sarsaparilla';
import { useSelector } from 'react-redux';
import CommonFilter from '../common/CommonFilter';
import { FCFS_TYPE } from '../../../constants/filters/camping';
import { FiltersTypeProps } from '../../../types';

export type FirstComeFirstServedProps = {
    aggregations?: { [key: string]: Number };
    onChange?: (v: React.ChangeEvent<HTMLInputElement>, k: any) => void;
};

function FirstComeFirstServedFilters({
    aggregations,
    onChange,
}: FirstComeFirstServedProps) {
    const [openShowAvailableModal, setOpenShowAvailableModal] = React.useState(false);
    const search = useSelector((state: Record<string, any>) => state.search);
    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        item: FiltersTypeProps
    ) => {
        if ((!search.checkin_time || !search.checkout_time) && item.key === 'fcfs') {
            setOpenShowAvailableModal(true);
            return;
        }
        if (onChange) {
            onChange(event, item);
        }
    };
    return (
        <div className="mb-5">
            <CommonFilter
                aggregations={aggregations}
                search={search}
                onChange={handleChange}
                filters={FCFS_TYPE}
                title="First-Come, First-Served"
                columns={1}
                hasDivider={false}
                keepEnabled
            />
            <StyledModal
                size="md"
                isOpen={openShowAvailableModal}
                heading="Show First-come, First-served"
                onRequestClose={() => setOpenShowAvailableModal(false)}
            >
                <Text>Add Dates to Filter by First-come, First-served.</Text>
                <ModalActions>
                    <Button onClick={() => setOpenShowAvailableModal(false)}>OK</Button>
                </ModalActions>
            </StyledModal>
        </div>
    );
}

export default FirstComeFirstServedFilters;
