/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Icons, Button, FlexCol, FlexRow, Heading } from 'sarsaparilla';
import { get } from 'lodash';

import * as searchActions from '../actions/search';
import SearchFilterCampsiteUseTypes from '../components/SearchFilterCampsiteUseTypes';
import SearchFilterCampsiteTypes from '../components/SearchFilterCampsiteTypes';
import SearchFilterCampsiteAmenities from '../components/SearchFilterCampsiteAmenities';
import SearchFilterCampsiteEquipment from '../components/SearchFilterCampsiteEquipment';
import SearchFilterCampsiteElectrical from '../components/SearchFilterCampsiteElectrical';
import SearchFilterOccupants from '../components/SearchFilterOccupants';
import SearchFilterCampsiteVehicleLength from '../components/SearchFilterCampsiteVehicleLength';

class SearchFiltersCampsites extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func,
        campgroundId: PropTypes.string,
        search: PropTypes.object,
        searchAggregations: PropTypes.object,
        onApplyFilters: PropTypes.func,
        campsites: PropTypes.array,
    };

    static defaultProps = {};

    static displayName = 'SearchFiltersCampsites';

    constructor(props) {
        super(props);
        this.onApplyFilter = this.onApplyFilter.bind(this);
        this.onApplyFilters = this.onApplyFilters.bind(this);
        this.onFilterToggle = this.onFilterToggle.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onFilterRemove = this.onFilterRemove.bind(this);
        this.onResetFilters = this.onResetFilters.bind(this);
        this.onHideFilters = this.onHideFilters.bind(this);
    }

    componentDidMount() {
        searchActions.fetchCampsitesSearchFiltersAggregations(
            this.props.campgroundId,
            this.props.dispatch
        );
    }

    onResetFilters() {
        this.props.dispatch(searchActions.resetSearchFiltersCampsites());
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    onApplyFilters() {
        this.props.dispatch(searchActions.updateSearchCriteria('filtersVisible', false));
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    onApplyFilter() {
        setTimeout(() => {
            if (this.props.onApplyFilters) {
                this.props.onApplyFilters();
            }
        }, 200);
    }

    onFilterToggle(filterName, item) {
        this.props.dispatch(searchActions.toggleSearchFilter(filterName, item));
    }

    onFilterChange(filterName, item) {
        this.props.dispatch(searchActions.setSearchFilter(filterName, item));
    }

    onFilterRemove(filterName, item) {
        this.props.dispatch(searchActions.removeSearchFilter(filterName, item));
    }

    onHideFilters() {
        this.props.dispatch(searchActions.updateSearchCriteria('filtersVisible', false));
    }

    getAppliedFiltersCount = () => {
        let filtersCount = 0;
        if (this.props.search && this.props.search.uiFilters) {
            filtersCount += Object.keys(
                this.props.search.uiFilters.campsiteUseTypes
            ).length;
            filtersCount += Object.keys(this.props.search.uiFilters.campsiteTypes).length;
            filtersCount += Object.keys(
                this.props.search.uiFilters.campsiteAmenities
            ).length;
            filtersCount += Object.keys(
                this.props.search.uiFilters.campsiteEquipment
            ).length;
            filtersCount += Object.keys(
                this.props.search.uiFilters.campsiteElectricityHookup
            ).length;
            filtersCount += Object.keys(
                this.props.search.uiFilters.campsiteVehicleLength
            ).length;
            filtersCount += Object.keys(this.props.search.uiFilters.occupants).length;
        }
        return filtersCount;
    };

    renderSearchFilters = (campsiteFilterCount) => {
        return (
            <div className="search-filters-dropdown-body">
                <section className="search-filters-type-wrap">
                    <div className="search-filters-section-header">
                        <Heading headingLevel={2} appearance="h5">
                            Campsite Filters
                        </Heading>
                        <span>
                            {campsiteFilterCount} {campsiteFilterCount === 1 && 'Filter'}{' '}
                            {campsiteFilterCount !== 1 && 'Filters'} Selected
                        </span>
                    </div>

                    <div className="search-filters-occupants-length-wrap">
                        <FlexRow hasGutters={false}>
                            <FlexCol xs="variable" className="mr-3">
                                <SearchFilterOccupants
                                    uiFilters={this.props.search.uiFilters}
                                    aggregations={this.props.searchAggregations}
                                    onFilterChange={this.onFilterChange}
                                    onFilterRemove={this.onFilterRemove}
                                    onApplyFilters={this.onApplyFilter}
                                />
                            </FlexCol>

                            <FlexCol xs="variable">
                                <SearchFilterCampsiteVehicleLength
                                    uiFilters={this.props.search.uiFilters}
                                    aggregations={this.props.searchAggregations}
                                    onFilterChange={this.onFilterChange}
                                    onFilterRemove={this.onFilterRemove}
                                    onApplyFilters={this.onApplyFilter}
                                />
                            </FlexCol>
                        </FlexRow>
                    </div>

                    <SearchFilterCampsiteUseTypes
                        uiFilters={this.props.search.uiFilters}
                        aggregations={this.props.searchAggregations}
                        onFilterToggle={this.onFilterToggle}
                        onApplyFilters={this.onApplyFilter}
                    />

                    <SearchFilterCampsiteTypes
                        uiFilters={this.props.search.uiFilters}
                        aggregations={this.props.searchAggregations}
                        onFilterToggle={this.onFilterToggle}
                        onApplyFilters={this.onApplyFilter}
                    />

                    <SearchFilterCampsiteEquipment
                        uiFilters={this.props.search.uiFilters}
                        aggregations={this.props.searchAggregations}
                        onFilterToggle={this.onFilterToggle}
                        onApplyFilters={this.onApplyFilter}
                    />

                    <SearchFilterCampsiteAmenities
                        uiFilters={this.props.search.uiFilters}
                        aggregations={this.props.searchAggregations}
                        onFilterToggle={this.onFilterToggle}
                        onApplyFilters={this.onApplyFilter}
                    />

                    <SearchFilterCampsiteElectrical
                        uiFilters={this.props.search.uiFilters}
                        aggregations={this.props.searchAggregations}
                        onFilterToggle={this.onFilterToggle}
                        onApplyFilters={this.onApplyFilter}
                    />
                </section>
            </div>
        );
    };

    render() {
        const filtersCount = this.getAppliedFiltersCount();
        const searchFilters = this.renderSearchFilters(filtersCount);
        const totalItems = get(this.props.campsites, 'length', 0);
        // SHOW THE TOTAL NUMBER OF RESULTS ON THE APPLY BUTTON
        const totalItemLabel = totalItems === 1 ? 'Result' : 'Results';
        const totalItemText =
            totalItems > 0 ? `View ${totalItems} ${totalItemLabel}` : 'No Results';

        const clearBtn = (
            <Button
                appearance="link"
                iconAfterElement={<Icons.IconLoop />}
                className="reset"
                id="testhook-reset"
                onClick={this.onResetFilters}
            >
                Clear All Filters
            </Button>
        );

        return (
            <section className="search-filters-dropdown-wrap">
                {/* <SearchFiltersHeader
                    uiFilters={this.props.search.uiFilters}
                    aggregations={this.props.searchAggregations}
                    onFilterChange={this.onFilterChange}
                    onFilterRemove={this.onFilterRemove}
                    onFilterToggle={this.onFilterToggle}
                    onApplyFilters={this.onApplyFilter}
                    isCampsitesFilters />
                */}

                {searchFilters}

                <div className="search-filters-actions">
                    {filtersCount <= 0 ? '' : clearBtn}

                    <div className="search-close-cancel-wrap">
                        <Button
                            appearance="link"
                            id="testhook-hide"
                            onClick={this.onHideFilters}
                        >
                            Cancel
                        </Button>

                        <Button
                            className="close-btn"
                            iconAfterElement={<Icons.IconArrowUp />}
                            size="lg"
                            id="testhook-apply"
                            onClick={this.onApplyFilters}
                        >
                            {filtersCount <= 0 ? 'Hide Filters' : totalItemText}
                        </Button>
                    </div>
                </div>
            </section>
        );
    }
}

function select(state) {
    return {
        search: state.search,
        searchAggregations: state.searchAggregations,
        campsites: state.availableCampsites.results.campsites,
    };
}
export default connect(select)(SearchFiltersCampsites);
