/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import React, { useCallback, useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { uniqueId } from 'lodash';

import {
    FlexRow,
    FlexCol,
    Heading,
    TabsNew as Tabs,
    TabPanel,
    ContentContainerBodyShift,
    Icons,
    BrandingIcons,
    HeroImageSectionFront,
    NewInventoryIcons,
    TextSlider,
    HtmlParser,
    TextBlock,
} from 'sarsaparilla';
import INVENTORY_TYPES from './InventoryTypes';
import CaseStudies from './CaseStudies';

import campingHeroLgAvif from './images/learn-more-section/camping-hero-lg.avif';
import campingHeroLgJpg from './images/learn-more-section/camping-hero-lg.jpg';
import campingHeroLgWebp from './images/learn-more-section/camping-hero-lg.webp';
import campingHeroMdAvif from './images/learn-more-section/camping-hero-md.avif';
import campingHeroMdJpg from './images/learn-more-section/camping-hero-md.jpg';
import campingHeroMdWebp from './images/learn-more-section/camping-hero-md.webp';
import campingHeroSmAvif from './images/learn-more-section/camping-hero-sm.avif';
import campingHeroSmJpg from './images/learn-more-section/camping-hero-sm.jpg';
import campingHeroSmWebp from './images/learn-more-section/camping-hero-sm.webp';

import donationsHeroLgAvif from './images/learn-more-section/donations-hero-lg.avif';
import donationsHeroLgJpg from './images/learn-more-section/donations-hero-lg.jpg';
import donationsHeroLgWebp from './images/learn-more-section/donations-hero-lg.webp';
import donationsHeroMdAvif from './images/learn-more-section/donations-hero-md.avif';
import donationsHeroMdJpg from './images/learn-more-section/donations-hero-md.jpg';
import donationsHeroMdWebp from './images/learn-more-section/donations-hero-md.webp';
import donationsHeroSmAvif from './images/learn-more-section/donations-hero-sm.avif';
import donationsHeroSmJpg from './images/learn-more-section/donations-hero-sm.jpg';
import donationsHeroSmWebp from './images/learn-more-section/donations-hero-sm.webp';

import lotteryHeroLgAvif from './images/learn-more-section/lottery-hero-lg.avif';
import lotteryHeroLgJpg from './images/learn-more-section/lottery-hero-lg.jpg';
import lotteryHeroLgWebp from './images/learn-more-section/lottery-hero-lg.webp';
import lotteryHeroMdAvif from './images/learn-more-section/lottery-hero-md.avif';
import lotteryHeroMdJpg from './images/learn-more-section/lottery-hero-md.jpg';
import lotteryHeroMdWebp from './images/learn-more-section/lottery-hero-md.webp';
import lotteryHeroSmAvif from './images/learn-more-section/lottery-hero-sm.avif';
import lotteryHeroSmJpg from './images/learn-more-section/lottery-hero-sm.jpg';
import lotteryHeroSmWebp from './images/learn-more-section/lottery-hero-sm.webp';

import passesHeroLgAvif from './images/learn-more-section/passes-hero-lg.avif';
import passesHeroLgJpg from './images/learn-more-section/passes-hero-lg.jpg';
import passesHeroLgWebp from './images/learn-more-section/passes-hero-lg.webp';
import passesHeroMdAvif from './images/learn-more-section/passes-hero-md.avif';
import passesHeroMdJpg from './images/learn-more-section/passes-hero-md.jpg';
import passesHeroMdWebp from './images/learn-more-section/passes-hero-md.webp';
import passesHeroSmAvif from './images/learn-more-section/passes-hero-sm.avif';
import passesHeroSmJpg from './images/learn-more-section/passes-hero-sm.jpg';
import passesHeroSmWebp from './images/learn-more-section/passes-hero-sm.webp';

import permitsHeroLgAvif from './images/learn-more-section/permits-hero-lg.avif';
import permitsHeroLgJpg from './images/learn-more-section/permits-hero-lg.jpg';
import permitsHeroLgWebp from './images/learn-more-section/permits-hero-lg.webp';
import permitsHeroMdAvif from './images/learn-more-section/permits-hero-md.avif';
import permitsHeroMdJpg from './images/learn-more-section/permits-hero-md.jpg';
import permitsHeroMdWebp from './images/learn-more-section/permits-hero-md.webp';
import permitsHeroSmAvif from './images/learn-more-section/permits-hero-sm.avif';
import permitsHeroSmJpg from './images/learn-more-section/permits-hero-sm.jpg';
import permitsHeroSmWebp from './images/learn-more-section/permits-hero-sm.webp';

import scanPayHeroLgAvif from './images/learn-more-section/scan-pay-hero-lg.avif';
import scanPayHeroLgJpg from './images/learn-more-section/scan-pay-hero-lg.jpg';
import scanPayHeroLgWebp from './images/learn-more-section/scan-pay-hero-lg.webp';
import scanPayHeroMdAvif from './images/learn-more-section/scan-pay-hero-md.avif';
import scanPayHeroMdJpg from './images/learn-more-section/scan-pay-hero-md.jpg';
import scanPayHeroMdWebp from './images/learn-more-section/scan-pay-hero-md.webp';
import scanPayHeroSmAvif from './images/learn-more-section/scan-pay-hero-sm.avif';
import scanPayHeroSmJpg from './images/learn-more-section/scan-pay-hero-sm.jpg';
import scanPayHeroSmWebp from './images/learn-more-section/scan-pay-hero-sm.webp';

import ticketsHeroLgAvif from './images/learn-more-section/tickets-hero-lg.avif';
import ticketsHeroLgJpg from './images/learn-more-section/tickets-hero-lg.jpg';
import ticketsHeroLgWebp from './images/learn-more-section/tickets-hero-lg.webp';
import ticketsHeroMdAvif from './images/learn-more-section/tickets-hero-md.avif';
import ticketsHeroMdJpg from './images/learn-more-section/tickets-hero-md.jpg';
import ticketsHeroMdWebp from './images/learn-more-section/tickets-hero-md.webp';
import ticketsHeroSmAvif from './images/learn-more-section/tickets-hero-sm.avif';
import ticketsHeroSmJpg from './images/learn-more-section/tickets-hero-sm.jpg';
import ticketsHeroSmWebp from './images/learn-more-section/tickets-hero-sm.webp';

import timedEntryHeroLgAvif from './images/learn-more-section/timed-entry-hero-lg.avif';
import timedEntryHeroLgJpg from './images/learn-more-section/timed-entry-hero-lg.jpg';
import timedEntryHeroLgWebp from './images/learn-more-section/timed-entry-hero-lg.webp';
import timedEntryHeroMdAvif from './images/learn-more-section/timed-entry-hero-md.avif';
import timedEntryHeroMdJpg from './images/learn-more-section/timed-entry-hero-md.jpg';
import timedEntryHeroMdWebp from './images/learn-more-section/timed-entry-hero-md.webp';
import timedEntryHeroSmAvif from './images/learn-more-section/timed-entry-hero-sm.avif';
import timedEntryHeroSmJpg from './images/learn-more-section/timed-entry-hero-sm.jpg';
import timedEntryHeroSmWebp from './images/learn-more-section/timed-entry-hero-sm.webp';

import venuesHeroLgAvif from './images/learn-more-section/venues-hero-lg.avif';
import venuesHeroLgJpg from './images/learn-more-section/venues-hero-lg.jpg';
import venuesHeroLgWebp from './images/learn-more-section/venues-hero-lg.webp';
import venuesHeroMdAvif from './images/learn-more-section/venues-hero-md.avif';
import venuesHeroMdJpg from './images/learn-more-section/venues-hero-md.jpg';
import venuesHeroMdWebp from './images/learn-more-section/venues-hero-md.webp';
import venuesHeroSmAvif from './images/learn-more-section/venues-hero-sm.avif';
import venuesHeroSmJpg from './images/learn-more-section/venues-hero-sm.jpg';
import venuesHeroSmWebp from './images/learn-more-section/venues-hero-sm.webp';

const imageSrcConfig = {
    camping: {
        avif: {
            lg: campingHeroLgAvif,
            md: campingHeroMdAvif,
            sm: campingHeroSmAvif,
        },
        jpg: {
            lg: campingHeroLgJpg,
            md: campingHeroMdJpg,
            sm: campingHeroSmJpg,
        },
        webp: {
            lg: campingHeroLgWebp,
            md: campingHeroMdWebp,
            sm: campingHeroSmWebp,
        },
    },
    donations: {
        avif: {
            lg: donationsHeroLgAvif,
            md: donationsHeroMdAvif,
            sm: donationsHeroSmAvif,
        },
        jpg: {
            lg: donationsHeroLgJpg,
            md: donationsHeroMdJpg,
            sm: donationsHeroSmJpg,
        },
        webp: {
            lg: donationsHeroLgWebp,
            md: donationsHeroMdWebp,
            sm: donationsHeroSmWebp,
        },
    },
    lottery: {
        avif: {
            lg: lotteryHeroLgAvif,
            md: lotteryHeroMdAvif,
            sm: lotteryHeroSmAvif,
        },
        jpg: {
            lg: lotteryHeroLgJpg,
            md: lotteryHeroMdJpg,
            sm: lotteryHeroSmJpg,
        },
        webp: {
            lg: lotteryHeroLgWebp,
            md: lotteryHeroMdWebp,
            sm: lotteryHeroSmWebp,
        },
    },
    passes: {
        avif: {
            lg: passesHeroLgAvif,
            md: passesHeroMdAvif,
            sm: passesHeroSmAvif,
        },
        jpg: {
            lg: passesHeroLgJpg,
            md: passesHeroMdJpg,
            sm: passesHeroSmJpg,
        },
        webp: {
            lg: passesHeroLgWebp,
            md: passesHeroMdWebp,
            sm: passesHeroSmWebp,
        },
    },
    permits: {
        avif: {
            lg: permitsHeroLgAvif,
            md: permitsHeroMdAvif,
            sm: permitsHeroSmAvif,
        },
        jpg: {
            lg: permitsHeroLgJpg,
            md: permitsHeroMdJpg,
            sm: permitsHeroSmJpg,
        },
        webp: {
            lg: permitsHeroLgWebp,
            md: permitsHeroMdWebp,
            sm: permitsHeroSmWebp,
        },
    },
    'scan-pay': {
        avif: {
            lg: scanPayHeroLgAvif,
            md: scanPayHeroMdAvif,
            sm: scanPayHeroSmAvif,
        },
        jpg: {
            lg: scanPayHeroLgJpg,
            md: scanPayHeroMdJpg,
            sm: scanPayHeroSmJpg,
        },
        webp: {
            lg: scanPayHeroLgWebp,
            md: scanPayHeroMdWebp,
            sm: scanPayHeroSmWebp,
        },
    },
    tickets: {
        avif: {
            lg: ticketsHeroLgAvif,
            md: ticketsHeroMdAvif,
            sm: ticketsHeroSmAvif,
        },
        jpg: {
            lg: ticketsHeroLgJpg,
            md: ticketsHeroMdJpg,
            sm: ticketsHeroSmJpg,
        },
        webp: {
            lg: ticketsHeroLgWebp,
            md: ticketsHeroMdWebp,
            sm: ticketsHeroSmWebp,
        },
    },
    'timed-entry': {
        avif: {
            lg: timedEntryHeroLgAvif,
            md: timedEntryHeroMdAvif,
            sm: timedEntryHeroSmAvif,
        },
        jpg: {
            lg: timedEntryHeroLgJpg,
            md: timedEntryHeroMdJpg,
            sm: timedEntryHeroSmJpg,
        },
        webp: {
            lg: timedEntryHeroLgWebp,
            md: timedEntryHeroMdWebp,
            sm: timedEntryHeroSmWebp,
        },
    },
    venues: {
        avif: {
            lg: venuesHeroLgAvif,
            md: venuesHeroMdAvif,
            sm: venuesHeroSmAvif,
        },
        jpg: {
            lg: venuesHeroLgJpg,
            md: venuesHeroMdJpg,
            sm: venuesHeroSmJpg,
        },
        webp: {
            lg: venuesHeroLgWebp,
            md: venuesHeroMdWebp,
            sm: venuesHeroSmWebp,
        },
    },
};

export function NewInventoryLearnMorePage() {
    const { invType } = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const history = useHistory();

    useEffect(() => {
        switch (invType) {
            case 'camping':
                setActiveTab(0);
                break;
            case 'venues':
                setActiveTab(1);
                break;
            case 'scan':
                setActiveTab(2);
                break;
            case 'pass':
                setActiveTab(3);
                break;
            case 'tickets':
                setActiveTab(4);
                break;
            case 'timedentry':
                setActiveTab(5);
                break;
            case 'permits':
                setActiveTab(6);
                break;
            case 'lottery':
                setActiveTab(7);
                break;
            case 'donations':
                setActiveTab(8);
                break;
            default:
                break;
        }
    }, [invType]);

    const getTitleFromIndex = (idx) => {
        switch (idx) {
            case 0:
                return 'Add Your Camping & Day Use Facility';
            case 1:
                return 'Venues';
            case 2:
                return 'Add Your Scan & Pay Facility for a Cashless, First-Come/First-Served Payment Method';
            case 3:
                return 'Add Your Pass Facility to Cover Access or Activity-Related Fees';
            case 4:
                return 'Add Your Ticket Facility for Per-Person Tours, Educational Programs, or Special Events';
            case 5:
                return 'Add Your Timed Ticket Facility to Manage Visitation per Vehicle or Group';
            case 6:
                return 'Add Your Permit Facility to Manage Access to Specified Areas or Activities';
            case 7:
                return 'Add Your Lottery Facility for Equal Access to High Demand Opportunities';
            case 8:
                return 'Donation Services';
            default:
                return 'invtype';
        }
    };

    const getActiveImageFromIndex = (idx) => {
        switch (idx) {
            case 0:
                return 'camping';
            case 1:
                return 'venues';
            case 2:
                return 'scan-pay';
            case 3:
                return 'passes';
            case 4:
                return 'tickets';
            case 5:
                return 'timed-entry';
            case 6:
                return 'permits';
            case 7:
                return 'lottery';
            case 8:
                return 'donations';
            default:
                return 'invtype';
        }
    };

    const getAltTextFromIndex = (idx) => {
        switch (idx) {
            case 0:
                return 'Tent setup at a campsite';
            case 1:
                return 'People playing with a ball at sunset';
            case 2:
                return 'Person using the Recreation.gov Scan & Pay app at a campsite';
            case 3:
                return 'A boat navigating on a lake';
            case 4:
                return 'An archaeological site at Mesa Verde National Park';
            case 5:
                return 'Body of water with snowcapped mountains in the distance';
            case 6:
                return 'Group of kayakers enjoying time on the river';
            case 7:
                return 'Sunrise at Half Dome in Yosemite National Park';
            case 8:
                return 'River flowing through a forest';
            default:
                return 'invtype';
        }
    };

    const handleTabChange = useCallback(
        (index) => {
            switch (index) {
                case 0:
                    history.push('/learnmore/camping');
                    break;
                case 1:
                    history.push('/learnmore/venues');
                    break;
                case 2:
                    history.push('/learnmore/scan');
                    break;
                case 3:
                    history.push('/learnmore/pass');
                    break;
                case 4:
                    history.push('/learnmore/tickets');
                    break;
                case 5:
                    history.push('/learnmore/timedentry');
                    break;
                case 6:
                    history.push('/learnmore/permits');
                    break;
                case 7:
                    history.push('/learnmore/lottery');
                    break;
                case 8:
                    history.push('/learnmore/donations');
                    break;
                default:
                    return 'invtype';
            }
            setActiveTab(index);
            return '';
        },
        [setActiveTab, history]
    );

    const generateIcon = (spec, size) => {
        let icon = null;
        switch (spec.icon) {
            /// Camping Icons
            case 'camping-primary-icon':
                icon = <NewInventoryIcons.NewInventoryIconCamping />;
                break;
            case 'tent-icon-camping-page':
                icon = <Icons.IconTentOpen size={size} />;
                break;
            case 'calendar-check-icon-camping-page':
                icon = <Icons.IconCalendarAvailable size={size} />;
                break;
            case 'location-icon-camping-page':
                icon = <BrandingIcons.PinSolid size={size} />;
                break;

            /// Venue Icons
            case 'venues-primary-icon':
                icon = <NewInventoryIcons.NewInventoryIconVenues />;
                break;
            case 'clock-icon-venues-page':
                icon = <Icons.IconClock size={size} />;
                break;
            case 'cog-icon-venues-page':
                icon = <Icons.IconCog size={size} />;
                break;
            case 'cash-icon-venues-page':
                icon = <Icons.IconDollar size={size} />;
                break;

            /// Tickets Icons
            case 'ticket-primary-icon':
                icon = <NewInventoryIcons.NewInventoryIconTickets />;
                break;
            case 'ticket-icon-tickets-page':
                icon = <Icons.IconTicket size={size} />;
                break;
            case 'qrcode-icon-tickets-page':
                icon = <Icons.IconQrCode size={size} />;
                break;
            case 'check-icon-tickets-page':
                icon = <Icons.IconCheck size={size} />;
                break;
            case 'cash-icon-tickets-page':
                icon = <Icons.IconDollar size={size} />;
                break;

            /// Timed Entry Icons
            case 'timed-entry-primary-icon':
                icon = <NewInventoryIcons.NewInventoryIconTimedEntry />;
                break;
            case 'car-icon-timed-entry-page':
                icon = <Icons.IconCar size={size} />;
                break;
            case 'ticket-icon-timed-entry-page':
                icon = <Icons.IconQrCode size={size} />;
                break;
            case 'ticket2-icon-timed-entry-page':
                icon = <Icons.IconCheck size={size} />;
                break;
            case 'mobilecash-icon-timed-entry-page':
                icon = <Icons.IconPhoneIphonePay size={size} />;
                break;

            /// Scan & Pay Icons
            case 'scan-primary-icon':
                icon = <NewInventoryIcons.NewInventoryIconScanner />;
                break;
            case 'nowifi-icon-scan-pay-page':
                icon = <Icons.IconNoWifiSignal size={size} />;
                break;
            case 'cash-icon-scan-pay-page':
                icon = <Icons.IconDollar size={size} />;
                break;
            case 'r1s-icon-scan-pay-page':
                icon = <BrandingIcons.LogoSymbols size={size} />;
                break;

            /// Permit Icons
            case 'permit-primary-icon':
                icon = <NewInventoryIcons.NewInventoryIconPermits />;
                break;
            case 'permit-icon-permit-page':
                icon = <Icons.IconPermit size={size} />;
                break;
            case 'cog-icon-permit-page':
                icon = <Icons.IconCog size={size} />;
                break;
            case 'check-icon-permit-page':
                icon = <Icons.IconCheck size={size} />;
                break;
            case 'key-icon-permit-page':
                icon = <Icons.IconKey size={size} />;
                break;
            case 'lotteryIcon-icon-permit-page':
                icon = <Icons.IconLottery size={size} />;
                break;

            /// Passes Icons
            case 'pass-primary-icon':
                icon = <NewInventoryIcons.NewInventoryIconPasses />;
                break;
            case 'pass-icon-pass-page':
                icon = <Icons.IconCardVerified size={size} />;
                break;
            case 'card-icon-pass-page':
                icon = <Icons.IconCard size={size} />;
                break;
            case 'mobileqr-icon-pass-page':
                icon = <Icons.IconPhoneIphoneValidate size={size} />;
                break;
            case 'mobilecash-icon-pass-page':
                icon = <Icons.IconPhoneIphonePay size={size} />;
                break;

            // Extra Section
            case 'download-icon-pass-page':
                icon = <Icons.IconFileDownload size={size} />;
                break;
            case 'walking-icon-pass-page':
                icon = <BrandingIcons.Hike size={size} />;
                break;
            case 'tree-icon-pass-page':
                icon = <Icons.IconTree size={size} />;
                break;
            case 'clock-icon-pass-page':
                icon = <Icons.IconClock size={size} />;
                break;
            case 'tickets-icon-pass-page':
                icon = <Icons.IconTickets size={size} />;
                break;
            case 'moon-icon-pass-page':
                icon = <Icons.IconOvernight size={size} />;
                break;
            case 'pass2-icon-pass-page':
                icon = <Icons.IconPermit size={size} />;
                break;
            case 'discount-icon-pass-page':
                icon = <Icons.IconDiscount size={size} />;
                break;

            /// Lottery Icons
            case 'lottery-primary-icon':
                icon = <NewInventoryIcons.NewInventoryIconLottery />;
                break;

            /// Donations Icons
            case 'donations-primary-icon':
                icon = <NewInventoryIcons.NewInventoryIconDonations />;
                break;
            case 'donations-icon-donations-page':
                icon = <Icons.IconDonations size={size} />;
                break;
            case 'distribute-icon-donations-page':
                icon = <Icons.IconDistribute size={size} />;
                break;
            case 'check-icon-donations-page':
                icon = <Icons.IconCheck size={size} />;
                break;

            /// Results and Reviews Block Icons
            case 'result-icon':
                icon = <NewInventoryIcons.NewInventoryIconResults />;
                break;

            case 'review-icon':
                icon = <NewInventoryIcons.NewInventoryIconReview />;
                break;

            default:
                break;
        }
        return icon;
    };

    const generateDescription = (inv) => {
        if (inv.id !== 'camping') {
            const invDesc = [];

            for (const [i, desc] of inv.description.entries()) {
                invDesc.push(
                    <React.Fragment key={i}>
                        <HtmlParser html={desc} />
                    </React.Fragment>
                );
            }
            return <TextBlock width="lg">{invDesc}</TextBlock>;
        }

        const invBullets = [];
        for (const [i, bullet] of inv.bullets.entries()) {
            invBullets.push(
                <li key={i}>
                    <HtmlParser html={bullet} />
                </li>
            );
        }

        return <ul>{invBullets}</ul>;
    };

    const generateDescriptionCont = (inv) => {
        if (inv.descriptionCont) {
            const invDesc = [];

            for (const [i, desc] of inv.descriptionCont.entries()) {
                invDesc.push(
                    <React.Fragment key={i}>
                        <HtmlParser html={desc} />
                    </React.Fragment>
                );
            }
            return <TextBlock width="lg">{invDesc}</TextBlock>;
        }
        return null;
    };

    const generate2ndSection = (inv) => {
        if (inv.section2) {
            const bullets = [];
            for (const [i, bullet] of inv.section2.entries()) {
                bullets.push(
                    <div className="nav-ni-inventory-types-page-list-items" key={i}>
                        <div className="list-items-icon-wrap">
                            {generateIcon(bullet, 'lg')}
                        </div>
                        <TextBlock width="lg">{bullet.bullet}</TextBlock>
                    </div>
                );
            }
            return (
                <FlexRow>
                    <FlexCol>
                        <Heading headingLevel={2} appearance="h4" className="mb-3">
                            Key Benefits
                        </Heading>
                        {bullets}
                    </FlexCol>
                </FlexRow>
            );
        }
        return null;
    };

    const generateMiddleSection = (inv) => {
        if (inv.middlesection) {
            const entries = [];
            for (const [i, sect] of inv.middlesection.entries()) {
                entries.push(
                    <li key={i}>
                        {sect.bold?.length >= 1 ? (
                            <HtmlParser
                                html={`<strong>${sect.bold}</strong> ${sect.bullet}`}
                            />
                        ) : (
                            <HtmlParser html={sect.bullet} />
                        )}
                    </li>
                );
            }
            return <ul>{entries}</ul>;
        }
        return null;
    };

    const generateYoutubeSection = (inv) => {
        if (inv.youtube) {
            return (
                <FlexCol className="nav-ni-inventory-types-video-wrap" lg="variable">
                    <iframe
                        width="672"
                        height="378"
                        src={inv.youtube}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    />
                </FlexCol>
            );
        }
        return null;
    };

    const generateReviewMeasureSection = (inv) => {
        if (inv.reviewMeasureSection) {
            const blocks = [];
            for (const [i, block] of inv.reviewMeasureSection.entries()) {
                const isReviewItem = generateIcon(block).props.alt === 'Review';
                blocks.push(
                    <FlexCol md={6} key={i} className="mb-4 mb-lg-0">
                        <div className="nav-ni-inventory-types-page-feedback-block">
                            <Heading headingLevel={2} appearance="h4" className="mb-2">
                                {block.header}
                            </Heading>

                            {isReviewItem && (
                                <div className="nav-ni-inventory-types-page-feedback-stars">
                                    <Icons.IconStar />
                                    <Icons.IconStar />
                                    <Icons.IconStar />
                                    <Icons.IconStar />
                                    <Icons.IconStar />
                                </div>
                            )}

                            <HtmlParser html={block.bodyCopy} />
                            <p>{block.paragraph2}</p>

                            <div className="nav-ni-inventory-types-page-feedback-bg-icon">
                                {generateIcon(block, 'lg')}
                            </div>
                        </div>
                    </FlexCol>
                );
            }

            return (
                <FlexRow justifyContent="center" className="mt-8 mb-4 my-lg-8">
                    {blocks}
                </FlexRow>
            );
        }
        return null;
    };

    const generateTextSliderImage = (inv) => {
        if (inv.textSliderImage) {
            return (
                <img src={inv.textSliderImage[0].path} alt={inv.textSliderImage[0].alt} />
            );
        }
        return null;
    };

    const generateTextSliderSection = (inv) => {
        if (inv.textSliderItems) {
            return (
                <section className="nav-ni-inventory-types-page-quote-section-wrapper">
                    <ContentContainerBodyShift>
                        <FlexRow alignItems="center">
                            <FlexCol lg={6} className="my-3">
                                <TextSlider hasNavButtons={false}>
                                    {inv.textSliderItems.map((tsi) => (
                                        <React.Fragment key={uniqueId}>
                                            <q className="nav-ni-intro-section-quote">
                                                {tsi.quote}
                                            </q>
                                            <strong>{tsi.author}</strong>

                                            <span className="sarsa-text-slider-location nav-ni-intro-section-location">
                                                {tsi.location}
                                            </span>
                                        </React.Fragment>
                                    ))}
                                </TextSlider>
                            </FlexCol>

                            <FlexCol lg={6}>
                                <div className="nav-ni-inventory-types-page-text-slider-picture-wrap">
                                    {generateTextSliderImage(inv)}
                                </div>
                            </FlexCol>
                        </FlexRow>
                    </ContentContainerBodyShift>
                </section>
            );
        }
        return null;
    };

    const generateEndSection = (inv) => {
        if (inv.endSection) {
            const blocks = [];
            for (const [i, block] of inv.endSection.entries()) {
                blocks.push(
                    <FlexCol mdOffset={3} md={9} lgOffset={2} lg={10} key={i}>
                        <Heading headingLevel={2} appearance="h4" className="mb-2">
                            {block.header}
                        </Heading>
                        <TextBlock width="lg">
                            <HtmlParser html={block.body} />
                        </TextBlock>
                    </FlexCol>
                );
            }

            return <FlexRow className="my-4">{blocks}</FlexRow>;
        }
        return null;
    };

    const getInventoryTabPanels = () => {
        const invTypePanels = [];
        for (const [i, inv] of INVENTORY_TYPES.entries()) {
            invTypePanels.push(
                <TabPanel key={i} label={inv.label} gaTrackingId="997127979960">
                    <ContentContainerBodyShift>
                        <FlexRow className="nav-ni-inventory-types-page-top-info">
                            <FlexCol md={3} lg={2} className="icon-wrap mb-4 mb-md-0">
                                {generateIcon(inv, 'xxl')}
                            </FlexCol>
                            <FlexCol md={9} lg={10}>
                                {generateDescription(inv)}
                                <div className="mt-n2">
                                    {generateDescriptionCont(inv)}
                                </div>
                                {generateMiddleSection(inv)}
                                {generate2ndSection(inv)}
                                {generateYoutubeSection(inv)}
                            </FlexCol>
                        </FlexRow>

                        <FlexRow>
                            <FlexCol md={9} mdOffset={3} lg={10} lgOffset={2}>
                                <CaseStudies invType={inv} />
                            </FlexCol>
                        </FlexRow>
                    </ContentContainerBodyShift>

                    {generateTextSliderSection(inv)}

                    {(generateReviewMeasureSection(inv) || generateEndSection(inv)) && (
                        <ContentContainerBodyShift>
                            {generateReviewMeasureSection(inv)}

                            {generateEndSection(inv)}
                        </ContentContainerBodyShift>
                    )}
                </TabPanel>
            );
        }
        return invTypePanels;
    };

    return (
        <div className="nav-ni-page-inventory-breakout" role="main">
            <section
                id="inventory-types-expanded"
                className="nav-ni-inventory-types-page-wrap"
            >
                <HeroImageSectionFront
                    title={getTitleFromIndex(activeTab)}
                    imageSrcConfig={
                        imageSrcConfig[`${getActiveImageFromIndex(activeTab)}`]
                    }
                    backgroundImageAltText={getAltTextFromIndex(activeTab)}
                    backgroundImageColorOverlay="#345D96"
                    backgroundImageColorOverlayOpacity={0.6}
                    hasButton
                    buttonAppearance="pill"
                    buttonText="Back to List your Facility"
                    buttonLink="/new-implementations"
                    buttonIconBeforeText={<Icons.IconArrowBack />}
                    buttonSize="xxs"
                />

                <Tabs activeIndex={activeTab} onChange={handleTabChange}>
                    {getInventoryTabPanels()}
                </Tabs>
            </section>
        </div>
    );
}
