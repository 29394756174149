/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * A button that shows up when user alters map position
 */
export default class MapSearchAreaControl {
    constructor(onClickSearch) {
        this._onClickSearch = this._onClickSearch.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this._onClickSearchCallback = onClickSearch;
    }

    onAdd(map) {
        this._map = map;
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl-button-group';
        this._container.style.display = 'none';

        this._searchButton = document.createElement('button');
        this._searchButton.type = 'button';
        this._searchButton.id = 'MapSearchButton';
        this._searchButton.name = 'MapSearchButton';
        this._searchButton.setAttribute('aria-label', 'Search this Area');
        this._searchButton.className = 'map-search-area-button';
        this._searchButton.appendChild(document.createTextNode('Search this Area'));

        this._searchButton.addEventListener('click', this._onClickSearch);

        this._container.appendChild(this._searchButton);

        return this._container;
    }

    _onClickSearch() {
        if (this._onClickSearchCallback) {
            this._onClickSearchCallback();
        }
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

    show() {
        this._container.style.display = 'block';
    }

    hide() {
        this._container.style.display = 'none';
    }
}
