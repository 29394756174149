/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox, Heading } from 'sarsaparilla';
import { SEARCH_FILTER_INVENTORY_VALUES } from 'site-kit';

export default class SearchFilterInventoryType extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        uiFilters: PropTypes.object,
        aggregations: PropTypes.object,
        onApplyFilters: PropTypes.func,
        onFilterToggle: PropTypes.func,
    };

    static displayName = 'SearchFilterInventoryType';

    handleChange(event, item) {
        if (this.props.onFilterToggle) {
            const value = omit(item, ['icon']);
            this.props.onFilterToggle('inventoryTypes', value);
        }
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    processAggregations(searchAggregations) {
        const counts = {};
        // find aggregated counts for item attribute
        SEARCH_FILTER_INVENTORY_VALUES.forEach((item) => {
            const filter = item.filters[0];
            counts[filter.attribute] = {};
            if (
                searchAggregations.campsiteUseTypes &&
                searchAggregations.campsiteUseTypes[filter.attribute]
            ) {
                const aggs = searchAggregations.campsiteUseTypes[filter.attribute];
                for (let i = 0; i < aggs.length; i++) {
                    counts[filter.attribute][aggs[i].name] = aggs[i].count;
                }
            }
            if (
                searchAggregations.entityTypes &&
                searchAggregations.entityTypes[filter.attribute]
            ) {
                const aggs = searchAggregations.entityTypes[filter.attribute];
                for (let i = 0; i < aggs.length; i++) {
                    counts[filter.attribute][aggs[i].name] = aggs[i].count;
                }
            }
        });
        // aggregate comma separate filter counts into one
        SEARCH_FILTER_INVENTORY_VALUES.forEach((item) => {
            const filter = item.filters[0];
            const parts = filter.filterValue.split(',');
            let count = 0;
            parts.forEach((part) => {
                const cnt = counts[filter.attribute];
                if (cnt && cnt[part]) {
                    count += cnt[part];
                }
            });
            counts[filter.attribute][filter.filterValue] = count;
        });
        return counts;
    }

    renderCheckbox(item, counts) {
        const filter = item.filters[0];
        const attCounts = counts[filter.attribute];
        let count;
        if (attCounts) {
            count = attCounts[filter.filterValue];
        }
        const selections = this.props.uiFilters.inventoryTypes;
        const isChecked = selections.hasOwnProperty(item.key);
        let label;
        if (count || isChecked) {
            label = `${item.value} (${count})`;
        } else {
            label = item.value;
        }
        const disableIcon = count === 0 && !isChecked ? 'is-disabled' : '';

        return (
            <div
                htmlFor={item.key}
                key={item.key}
                className={`search-filter-check-item hasIcon ${disableIcon}`}
            >
                <Checkbox
                    onChange={(e) => this.handleChange(e, item)}
                    id={item.key}
                    label={label}
                    value={item.value}
                    className={count === 0 && !isChecked ? 'disabled' : ''}
                    isChecked={isChecked}
                />
                {item.icon}
            </div>
        );
    }

    render() {
        const className = `${this.props.className}`;
        const counts = this.processAggregations(this.props.aggregations);

        const checkboxListClassName = this.props.useNewCampingFilters
            ? 'search-filters-double-type-wrap'
            : 'search-filters-check-wrap';
        return (
            <div className={`${className}`}>
                <div className="search-filters-section-header">
                    <Heading
                        headingLevel={2}
                        appearance={!this.props.useNewCampingFilters ? 'h5' : 'h6'}
                    >
                        Booking Types
                    </Heading>
                </div>
                <div
                    className={checkboxListClassName}
                    role="group"
                    aria-label="Recreation site type filters"
                >
                    <legend className="rec-sr-only">
                        Select recreation site type to filter on
                    </legend>
                    {SEARCH_FILTER_INVENTORY_VALUES.map((item) =>
                        this.renderCheckbox(item, counts)
                    )}
                </div>
            </div>
        );
    }
}
