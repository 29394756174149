/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * appIconSingleton.js
 * Created by Stephanie Condrey on 11/28/18.
 */

import { IconSingleton } from '../iconSingleton';

class AppIconSingleton extends IconSingleton {
    constructor() {
        super();

        this.notificationName = 'icons-app.loaded';
        this.svgPath = `${process.env.CDN}/graphics/appIcons.svg`;
    }
}

const instance = new AppIconSingleton();
export default instance;
