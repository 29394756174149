/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { shuffle } from 'lodash';
import { useDispatch } from 'react-redux';

import { InventoryPromoLink, MapHelper, Carousel, SearchActions } from 'sarsaparilla';
import HeroCarouselPlaceholder from './HeroCarouselPlaceholder.jsx';
import { getAvailableDates, getAvailableDatesMoment } from '../../../utils/dateHelper';

export default function DayUseVenuesCarousel({
    itemWidth,
    data,
    dates,
    enableAvailableThisWeekend,
}) {
    const dispatch = useDispatch();
    const isMountedRef = useRef(false);
    const { isLoading, hasErrors, locations } = data;
    const gaEventCategory = 'Homepage';
    const gaEventLabel = 'Hero';
    const shuffledLocations = useMemo(() => {
        let items = [];

        if (!isMountedRef.current && !!locations.length) {
            items = shuffle(locations);
            isMountedRef.current = true;
        } else {
            items = locations;
        }

        return items;
    }, [locations]);

    if (isLoading) {
        return <HeroCarouselPlaceholder />;
    }

    if (hasErrors || (locations?.length === 0 && !isLoading)) {
        return null;
    }

    const getClickTagAction = (item) => {
        const baseAction = `Day Use / Venues Card - ${item.name}`;

        if (!item.preview_image_url) {
            return `${baseAction} - Photo Unavailable`;
        }

        return baseAction;
    };

    const sortedLocations = shuffledLocations?.sort((a, b) => a.distance - b.distance);

    const locationSlides = sortedLocations.map((item, index) => {
        const hasCity = item.city === undefined ? '' : `${item.city}`;
        const hasStateCode = item.state_code === undefined ? '' : item.state_code;
        const hasComma = hasCity && hasStateCode ? ',' : '';
        const availableDatesMoment = enableAvailableThisWeekend
            ? getAvailableDatesMoment(item)
            : '';
        const availableDates = enableAvailableThisWeekend ? getAvailableDates(item) : '';

        const onClickPromoLink = (d) => {
            const startDate = d?.[0] || dates?.startDate;
            const endDate = d?.[1] || dates?.endDate;

            if (startDate && endDate && enableAvailableThisWeekend) {
                dispatch(
                    SearchActions.updateSearchCriterias({
                        checkin_time_custom: startDate.format('MM/DD/YYYY'),
                        checkout_time_custom: endDate.format('MM/DD/YYYY'),
                    })
                );
            }
            SearchActions.trackRunSearch(
                {
                    category: gaEventCategory,
                    action: getClickTagAction(item),
                    label: gaEventLabel,
                },
                () => {}
            );
        };

        return (
            <InventoryPromoLink
                shouldTranslateHeading={false}
                shouldTranslateLocation={false}
                dates={availableDates}
                imagePath={item.preview_image_url}
                imageHeight="80px"
                onClick={() => onClickPromoLink(availableDatesMoment)}
                href={MapHelper.getTargetUri(item)}
                heading={item.name}
                location={`${hasCity}${hasComma} ${hasStateCode}`}
                mobileCoverage={null}
                key={index}
                clickTagCategory={gaEventCategory}
                clickTagAction={getClickTagAction(item)}
                clickTagLabel={gaEventLabel}
            />
        );
    });

    return (
        <Carousel
            className="nav-hero-search-carousel"
            slideTargetWidth={itemWidth}
            hasGutters
        >
            {locationSlides}
        </Carousel>
    );
}

DayUseVenuesCarousel.propTypes = {
    itemWidth: PropTypes.number,
    dates: PropTypes.object,
    data: PropTypes.shape({
        locations: PropTypes.array,
        isLoading: PropTypes.bool,
        hasErrors: PropTypes.bool,
    }),
    enableAvailableThisWeekend: PropTypes.bool,
};

DayUseVenuesCarousel.defaultProps = {
    itemWidth: 175,
    data: {
        locations: [],
        isLoading: true,
        hasErrors: false,
    },
    enableAvailableThisWeekend: false,
};
