/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { SingleColumnContainer, Heading, ExternalLink, TextBlock } from 'sarsaparilla';

const partnerSites = [
    {
        name: 'Bureau of Land Management',
        url: 'https://www.blm.gov/',
    },
    {
        name: 'Bureau of Reclamation',
        url: 'https://www.usbr.gov/',
    },
    {
        name: 'Federal Highway Administration',
        url: 'https://www.fhwa.dot.gov',
    },
    {
        name: 'National Archives & Records Administration',
        url: 'https://www.archives.gov',
    },
    {
        name: 'National Oceanic and Atmospheric Administration',
        url: 'https://www.noaa.gov',
    },
    {
        name: 'National Park Service',
        url: 'https://www.nps.gov',
    },
    {
        name: 'Smithsonian Institution',
        url: 'https://www.si.edu',
    },
    {
        name: 'Tennessee Valley Authority',
        url: 'https://www.tva.gov/',
    },
    {
        name: 'US Army Corps of Engineers',
        url: 'https://www.usace.army.mil',
    },
    {
        name: 'US Fish & Wildlife Service',
        url: 'https://www.fws.gov',
    },
    {
        name: 'USDA Forest Service',
        url: 'https://www.fs.fed.us',
    },
];

export function FOIAPage() {
    return (
        <SingleColumnContainer role="main">
            <section className="rec-tertiary">
                <Heading headingLevel={1} appearance="h1">
                    Freedom of Information Act
                </Heading>
                <TextBlock width="xl">
                    <p>
                        The Freedom of Information Act (FOIA) gives you the right to
                        access Federal records unless the information in those records is
                        protected by one or more of the nine exemptions (reasons an agency
                        may withhold records from a requester) and there is a sound legal
                        basis to withhold them. Our responsibility is to provide you with
                        copies of the documents you are entitled to receive under the law.
                    </p>

                    <p>
                        If you are seeking general information from Recreation.gov
                        participating agencies, you may wish to visit their home pages:
                    </p>

                    <ul>
                        {partnerSites.map((partnerSite, index) => (
                            <li key={index}>
                                <ExternalLink url={partnerSite.url} hasExternalIcon>
                                    {partnerSite.name}
                                </ExternalLink>
                            </li>
                        ))}
                    </ul>

                    <p>
                        The information you are seeking may also already be available at
                        &nbsp;
                        <ExternalLink url="https://www.foia.gov" hasExternalIcon>
                            FOIA.gov
                        </ExternalLink>
                        . If it is not, you will need to submit a written FOIA request to
                        the appropriate Federal organization. If you have questions on
                        what information is available without submitting a FOIA request or
                        where it may be located, contact the agency directly.
                    </p>
                </TextBlock>
            </section>
        </SingleColumnContainer>
    );
}
