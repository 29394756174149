/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { SecurityHelper } from 'sarsaparilla';

import * as serviceNowAPIVersions from '../constants/serviceNowAPIVersions';
import { ArticlesResponse, ArticleContentResponse } from '../types';

type PaginatedArticlesArgs = {
    pageIndex?: number;
    limit?: number;
};

// This is the redux toolkit slice that is added to the redux store creator
export const articlesApiSlice = createApi({
    reducerPath: 'articlesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.SN_API,
        prepareHeaders: (headers) => {
            headers.set('Authorization', SecurityHelper.getAuthHeader());
            return headers;
        },
    }),
    tagTypes: ['homepage', 'featured', 'articles', 'article'],
    endpoints: (builder) => {
        return {
            fetchHomepageArticles: builder.query<ArticlesResponse, void>({
                query: () => {
                    return `/${serviceNowAPIVersions.HOMEPAGE_FEATURED_VERSION}/content/homepage`;
                },
                providesTags: ['homepage'],
            }),
            fetchFeaturedArticles: builder.query<ArticlesResponse, void>({
                query: () => {
                    return `/${serviceNowAPIVersions.HOMEPAGE_FEATURED_VERSION}/content/featured`;
                },
                providesTags: ['featured'],
            }),
            fetchArticleById: builder.query<ArticleContentResponse, string>({
                query: (articleId) => {
                    return `/${serviceNowAPIVersions.ARTICLE_VERSION}/content/article/${articleId}`;
                },
                providesTags: ['article'],
            }),
            fetchAllArticles: builder.query<ArticlesResponse, PaginatedArticlesArgs>({
                query: ({ pageIndex, limit = 9 }) => {
                    const offset = (pageIndex ?? 0) * limit;
                    return `/${serviceNowAPIVersions.ARTICLES_VERSION}/content/all?offset=${offset}&limit=${limit}`;
                },
                providesTags: ['articles'],
                serializeQueryArgs: ({ queryArgs }) => {
                    const newArgs = { ...queryArgs };
                    delete newArgs.pageIndex;
                    return newArgs;
                },
                merge: (currentCache, incoming, { arg }) => {
                    const { pageIndex } = arg;
                    // add new pages onto existing cache instead of replacing
                    if (!pageIndex || !currentCache.result) {
                        return incoming;
                    }

                    return {
                        result: {
                            ...currentCache.result,
                            count_row:
                                currentCache.result.count_row + incoming.result.count_row,
                            records: [
                                ...currentCache.result.records,
                                ...incoming.result.records,
                            ],
                        },
                    };
                },
                forceRefetch: ({ currentArg, previousArg }) => {
                    return currentArg?.pageIndex !== previousArg?.pageIndex;
                },
            }),
        };
    },
});

export const {
    useFetchAllArticlesQuery,
    useFetchHomepageArticlesQuery,
    useFetchFeaturedArticlesQuery,
    useFetchArticleByIdQuery,
    usePrefetch,
} = articlesApiSlice;
