/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * PartnersSection.jsx
 * Created by Destin Frasier on 03/31/22
 */

import { ContentContainer } from 'sarsaparilla';
import * as PartnerIcons from '../Icons/Partner/PartnerIcons';

const partnerBlocks = [
    {
        icon: <PartnerIcons.BLM />,
        name: 'Bureau of Land Management',
    },
    {
        icon: <PartnerIcons.DOI />,
        name: 'Bureau of Reclamation',
    },
    {
        icon: <PartnerIcons.BEP />,
        name: 'Bureau of Engraving and Printing',
    },
    {
        icon: <PartnerIcons.ForestService />,
        name: 'US Forest Service',
    },
    {
        icon: <PartnerIcons.NPS />,
        name: 'National Park Service',
    },
    {
        icon: <PartnerIcons.PT />,
        name: 'Presidio Trust',
    },
    {
        icon: <PartnerIcons.FWS />,
        name: 'US Fish and Wildlife Service',
    },
    {
        icon: <PartnerIcons.USACE />,
        name: 'US Army Corps of Engineers',
    },
    {
        icon: <PartnerIcons.NationalArchives />,
        name: 'National Archives & Records Administration',
    },
];

export default function PartnersSection() {
    return (
        <section className="nav-ni-partners-section-wrap">
            <ContentContainer className="my-0">
                <div className="nav-ni-partners-icon-wrap">
                    {partnerBlocks.map((partnerBlock) => (
                        <div
                            className="nav-ni-partners-icon-block"
                            key={partnerBlock.name}
                        >
                            {partnerBlock.icon}
                        </div>
                    ))}
                </div>
            </ContentContainer>
        </section>
    );
}

// cSpell:ignore USACE
