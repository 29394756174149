/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { InventoryTag } from 'sarsaparilla';
import { HOMEPAGE_MAP_INVENTORY } from 'site-kit';

export const mapID = 'homepageMap';

export const defaultSearchLocation = {
    latitude: process.env.DEFAULT_SEARCH_LOCATION?.lat || 38.0,
    longitude: process.env.DEFAULT_SEARCH_LOCATION?.lng || -97.0,
};

export const defaultZoomLevel = process.env.DEFAULT_MAP_ZOOM_LEVEL || 4;
export const searchResultZoomLevel = 9;
export const maximumMapSearchResults = 400;

export const denverLatLong = {
    latitude: 39.742043,
    longitude: -104.991531,
};

export const inventoryTags = {
    recarea: <InventoryTag.InventoryRecArea isSpanItem isInverse size="sm" />,
    permit: <InventoryTag.InventoryPermit isSpanItem isInverse size="sm" />,
    vehiclepermit: <InventoryTag.InventoryPermit isSpanItem isInverse size="sm" />,
    ticketfacility: <InventoryTag.InventoryTicket isSpanItem isInverse size="sm" />,
    tour: <InventoryTag.InventoryTicket isSpanItem isInverse size="sm" />,
    timedentry: <InventoryTag.InventoryTicket isSpanItem isInverse size="sm" />,
    timedentry_tour: <InventoryTag.InventoryTicket isSpanItem isInverse size="sm" />,
    activitypass: <InventoryTag.InventoryActivityPass isSpanItem isInverse size="sm" />,
    treepermit: <InventoryTag.InventoryTreePermits isSpanItem isInverse size="sm" />,
    venuereservations: (
        <InventoryTag.InventoryVenueReservations isSpanItem isInverse size="sm" />
    ),
};

export const inventory = HOMEPAGE_MAP_INVENTORY;

const getEntityTypes = () => {
    const entityTypes = {};
    Object.keys(inventory).forEach((inv) => {
        const item = inventory[inv];
        if (item.entity_type !== 'campground') {
            item.entity_type.split(',').forEach((entType) => {
                entityTypes[entType] = item.key;
            });
        } else {
            item.campsite_type_of_use.split(',').forEach((type) => {
                entityTypes[type.toLowerCase()] = item.key;
            });
        }
    });

    return entityTypes;
};

export const entityTypes = getEntityTypes();

export const calculateBoundsRadius = (lat1, lon1, lat2, lon2) => {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;

    let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
        dist = 1;
    }

    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    return dist / 2;
};
