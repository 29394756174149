/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Button } from 'sarsaparilla';

export function GetInvolved() {
    const gaCategory = 'Footer';
    const gaLabel = 'Join Us List';

    return (
        <ul className="nav-footer-list">
            <li>
                <Button
                    appearance="link"
                    href="/get-involved"
                    clickTagCategory={gaCategory}
                    clickTagAction="Get Involved link"
                    clickTagLabel={gaLabel}
                >
                    Volunteer
                </Button>
            </li>
            <li>
                <Button
                    appearance="link"
                    href="/use-our-data"
                    clickTagCategory={gaCategory}
                    clickTagAction="Use Our Data link"
                    clickTagLabel={gaLabel}
                >
                    Data Access
                </Button>
            </li>
            <li>
                <Button
                    appearance="link"
                    href="/new-implementations"
                    clickTagCategory={gaCategory}
                    clickTagAction="Add Your Facility link"
                    clickTagLabel={gaLabel}
                >
                    Add Your Facility
                </Button>
            </li>
        </ul>
    );
}
