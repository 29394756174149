/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * ArticleFunFacts.jsx
 * Created by Destin Frasier on 03/27/18
 */

import React from 'react';
import PropTypes from 'prop-types';
import { DisplayHtmlContent, Heading } from 'sarsaparilla';
import { uniqueId } from 'lodash';

import { FUN_FACT_ITEM } from '../../../constants/propTypes';

const defaultProps = {
    facts: [],
};

function ArticleFunFacts({ facts }) {
    return (
        <>
            <Heading headingLevel={3} appearance="h3">
                Fun Facts
            </Heading>
            <ul className="nav-article-fun-facts-wrap">
                {facts.map((fact) => (
                    <li key={`${uniqueId(fact.fun_facts_description)}`}>
                        <mark>
                            <DisplayHtmlContent html={fact.fun_facts_description} />
                        </mark>
                    </li>
                ))}
            </ul>
        </>
    );
}

ArticleFunFacts.propTypes = {
    facts: PropTypes.arrayOf(FUN_FACT_ITEM),
};
ArticleFunFacts.defaultProps = defaultProps;

export default ArticleFunFacts;
