/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import type { GetMenuAccountItemsType } from '../../../types/getMenuAccountItemsType';

export const getMenuAccountItems: GetMenuAccountItemsType = (cartCount = 0) => {
    return [
        {
            parentClasses: 'rec-select-option',
            clickTagCategory: 'Global Navigation',
            clickTagAction: 'Account',
            text: 'Account',
            title: 'Account',
            ariaLabel: 'Account',
            href: '/account/profile',
            badge: 0,
            shouldHide: false,
        },
        {
            parentClasses: 'rec-select-option rec-menu-cart',
            clickTagCategory: 'Global Navigation',
            clickTagAction: 'Cart',
            text: 'Cart',
            title: 'Cart',
            ariaLabel: 'Cart',
            href: '/cart',
            badge: cartCount,
            shouldHide: cartCount === 0,
        },
        {
            parentClasses: 'rec-select-option',
            clickTagCategory: 'Global Navigation',
            clickTagAction: 'Reservations',
            text: 'Reservations',
            title: 'Reservations',
            ariaLabel: 'Reservations',
            href: '/account/reservations/upcoming',
            badge: 0,
            shouldHide: false,
        },
        {
            parentClasses: 'rec-select-option',
            clickTagCategory: 'Global Navigation',
            clickTagAction: 'Favorites',
            text: 'Favorites',
            title: 'Favorites',
            ariaLabel: 'Favorites',
            href: '/account/favorites',
            badge: 0,
            shouldHide: false,
        },
        {
            parentClasses: 'rec-select-option',
            clickTagCategory: 'Global Navigation',
            clickTagAction: 'Passes',
            text: 'Passes',
            title: 'Passes',
            ariaLabel: 'Passes',
            href: '/account/passes',
            badge: 0,
            shouldHide: false,
        },
        {
            parentClasses: 'rec-select-option',
            clickTagCategory: 'Global Navigation',
            clickTagAction: 'Availability Alerts',
            text: 'Availability Alerts',
            title: 'Availability Alerts',
            ariaLabel: 'Availability Alerts',
            href: '/account/alerts',
            badge: 0,
            shouldHide: false,
        },
    ];
};
