/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import './NeedToKnowList.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { TextBlock, Stack, PlaceholderText } from 'sarsaparilla';

import NeedToKnowListItem from './NeedToKnowListItem';

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export interface NeedToKnowListItemType {
    title?: string;
    headingLevel?: HeadingLevel;
    priority?: 'high' | 'low';
    description: string;
}

interface NeedToKnowListProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
    listItems: NeedToKnowListItemType[];
    className?: string;
    headingLevel?: HeadingLevel;
    isLoading?: boolean;
    space?: 'sm' | 'md' | 'lg' | 'xl';
}

export default function NeedToKnowList({
    listItems = [],
    className,
    headingLevel = 3,
    isLoading,
    space = 'lg',
    ...rest
}: NeedToKnowListProps) {
    if (isLoading) {
        return (
            <Stack space="lg">
                <PlaceholderText lines={2} />
                <PlaceholderText lines={2} />
            </Stack>
        );
    }

    if (isEmpty(listItems)) {
        return null;
    }

    return (
        <div
            data-component="NeedToKnowList"
            className={cx('shared-need-to-know-list', className)}
            {...rest}
        >
            <TextBlock>
                <Stack hasListRole space={space}>
                    {listItems.map((item, index) => (
                        <NeedToKnowListItem
                            key={index}
                            title={item.title}
                            priority={item.priority}
                            description={item.description}
                            headingLevel={item.headingLevel || headingLevel}
                        />
                    ))}
                </Stack>
            </TextBlock>
        </div>
    );
}

NeedToKnowList.propTypes = {
    listItems: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            priority: PropTypes.string,
            description: PropTypes.node,
        })
    ),
    className: PropTypes.string,
    headingLevel: PropTypes.number,
    isLoading: PropTypes.bool,
    space: PropTypes.string,
};
