/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * ArticleBestSpot.jsx
 * Created by Destin Frasier on 03/28/18.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { DisplayHtmlContent, Heading } from 'sarsaparilla';
import { generateContentImage } from '../../../utils/articlesHelper';

class ArticleBestSpot extends React.PureComponent {
    static propTypes = {
        imageUrl: PropTypes.string,
        alt: PropTypes.string,
        location: PropTypes.string,
        inventoryLink: PropTypes.string,
        description: PropTypes.string,
    };

    render() {
        const { description, inventoryLink, imageUrl, alt } = this.props;
        if (description === '') {
            return null;
        }

        return (
            <div className="nav-article-best-spot-wrap">
                <Heading headingLevel={3} appearance="h3">
                    Best Spot to Bring Home a Story
                </Heading>
                <figure>
                    {generateContentImage({
                        url: inventoryLink,
                        img: imageUrl,
                        altText: alt,
                    })}
                    <figcaption>{this.props.location}</figcaption>
                </figure>
                <DisplayHtmlContent html={this.props.description} />
            </div>
        );
    }
}

export default ArticleBestSpot;
