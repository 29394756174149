/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * Newsletter.jsx
 * Created by michael bray on 2019-04-10.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FlexCol, FlexRow, Heading, ContentBlock, Box } from 'sarsaparilla';

import { NewsletterSubscribeForm } from 'site-kit';

export default class Newsletter extends React.PureComponent {
    static propTypes = {
        isBackgroundLight: PropTypes.bool,
    };

    static defaultProps = {
        isBackgroundLight: false,
    };

    render() {
        const backgroundClass = this.props.isBackgroundLight ? 'background-light' : '';
        return (
            <Box className={`nav-newsletter ${backgroundClass}`}>
                <ContentBlock>
                    <section>
                        <FlexRow className="rec-section-outer-wrap">
                            <FlexCol sm={12} md={8} xl={8} xxl={6}>
                                <Heading
                                    headingLevel={3}
                                    appearance="h3"
                                    className="nav-newsletter-title"
                                >
                                    Let&#39;s Stay in Touch
                                </Heading>
                                <p>
                                    Get travel planning ideas, helpful tips, and stories
                                    from our visitors delivered right to your inbox.
                                </p>

                                <NewsletterSubscribeForm
                                    isBackgroundLight={this.props.isBackgroundLight}
                                />
                            </FlexCol>
                        </FlexRow>
                    </section>
                </ContentBlock>
            </Box>
        );
    }
}
