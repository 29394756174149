/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Checkbox, Heading } from '@fp/sarsaparilla';
import PropTypes from 'prop-types';
import { SEARCH_FILTER_START_TIME as options } from '../constants';

const propTypes = {
    uiFilters: PropTypes.object,
    onFilterToggle: PropTypes.func,
    onApplyFilters: PropTypes.func,
    useNewCampingFilters: PropTypes.bool,
};

export default function SearchFilterStartTime({
    onFilterToggle,
    onApplyFilters,
    uiFilters,
    useNewCampingFilters,
}) {
    function handleChange(item) {
        if (onFilterToggle) {
            onFilterToggle('tourTimes', item);
        }
        if (onApplyFilters) {
            onApplyFilters();
        }
    }

    function renderCheckbox(item) {
        const selections = uiFilters.tourTimes;
        const isChecked = selections.hasOwnProperty(item.key);

        return (
            <div className="search-filter-check-item" key={item.key}>
                <Checkbox
                    label={item.value}
                    id={item.key}
                    value={item.value}
                    isChecked={isChecked}
                    onChange={() => handleChange(item)}
                />
            </div>
        );
    }
    const checkboxListClassName = useNewCampingFilters
        ? 'search-filters-double-type-wrap'
        : 'search-filters-check-wrap';

    const wrapperClassName = useNewCampingFilters ? 'mt-0 pt-0' : '';
    return (
        <div>
            <Heading headingLevel={3} appearance="h3" className={wrapperClassName}>
                Start Time
            </Heading>
            <div
                className={checkboxListClassName}
                role="group"
                aria-label="Tour type filters"
            >
                {options.map((item) => renderCheckbox(item))}
            </div>
        </div>
    );
}

SearchFilterStartTime.propTypes = propTypes;
