/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

export default class MapCustomControl {
    constructor(props) {
        this.props = props;
        this.isControlHidden = false;
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
    }

    onAdd(map) {
        this._map = map;
        this._container = document.createElement('div');
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

    show() {
        this._container.style.display = 'block';
        this.isControlHidden = false;
    }

    hide() {
        this._container.style.display = 'none';
        this.isControlHidden = true;
    }
}
