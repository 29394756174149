/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { FauxImage, FauxUnavailableImage, Button, Icon } from 'sarsaparilla';
import { createComponent, handleClosePopUp } from './common';

const featureMapPopup = (props) => {
    if (!props) return null;
    const asset = typeof props === 'string' ? JSON.parse(props) : props;

    const popupContent = (
        <div className="map-popup">
            <Button
                className="close-pop-up-btn"
                appearance="subtle"
                size="sm"
                shouldFitContainer={false}
                onClick={handleClosePopUp}
                iconBeforeElement={<Icon iconName="close" />}
                screenReaderTextBefore="Close the modal"
            />
            <div className="map-popup-faux-image-wrap">
                {asset.imageUrl ? (
                    <FauxImage
                        src={asset.imageUrl}
                        altText={asset.imageTitle}
                        aspectRatio="16:9"
                    />
                ) : (
                    <FauxUnavailableImage aspectRatio="16:9" />
                )}
            </div>

            <div className="map-popup-message">
                <div className="map-popup-title">{asset.title}</div>

                <ul className="map-popup-location-count">
                    {asset.location && <li key={asset.location}>{asset.location}</li>}
                    {asset.attributes &&
                        asset.attributes.map((attribute) => (
                            <li key={attribute.key}>{attribute.value}</li>
                        ))}
                </ul>

                {asset.targetUri && (
                    <div className="map-popup-button">
                        <a
                            href={asset.targetUri}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {/* WHAT IS GOING ON HERE????? Buttons do not go inside links */}
                            <button type="button" className="rec-button-link">
                                View Details
                            </button>
                        </a>
                    </div>
                )}
            </div>
        </div>
    );

    return createComponent(popupContent);
};

export default featureMapPopup;
