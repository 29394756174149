/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'sarsaparilla';

export default class SearchSpellingSuggestions extends React.Component {
    static propTypes = {
        searchResults: PropTypes.object,
        onSpellingSearch: PropTypes.func,
    };

    static displayName = 'SearchSpellingSuggestions';

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const spelledCorrectly = !(
            this.props.searchResults.spelled_correctly &&
            this.props.searchResults.spelled_correctly === 'false'
        );
        const collation = this.props.searchResults.spelling_collation;
        const autocorrected = this.props.searchResults.spelling_autocorrected;

        return (
            <div>
                {autocorrected &&
                    !spelledCorrectly &&
                    !this.props.searchResults.exact && (
                        <div className="search-spelling-suggestions">
                            <p>
                                Showing results for <strong>{collation}</strong>
                            </p>
                            <p>
                                Search instead for
                                <Button
                                    appearance="link"
                                    onClick={this.props.onSpellingSearch.bind(
                                        this,
                                        this.props.searchResults.query,
                                        true
                                    )}
                                >
                                    <strong>{this.props.searchResults.query}</strong>
                                </Button>
                            </p>
                        </div>
                    )}

                {!autocorrected && !spelledCorrectly && (
                    <div className="search-spelling-suggestions">
                        <p>
                            Did you mean:
                            <Button
                                appearance="link"
                                onClick={this.props.onSpellingSearch.bind(
                                    this,
                                    collation,
                                    false
                                )}
                            >
                                <strong>{collation}</strong>
                            </Button>
                        </p>
                    </div>
                )}
            </div>
        );
    }
}
