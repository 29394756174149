/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Heading } from 'sarsaparilla';

const dragonActions = [
    {
        action: 'Turn the microphone on or off',
        command: 'Plus key (+) on the numeric keypad',
    },
    {
        action: 'Open the Correction menu',
        command: 'Minus key (-) on the numeric keypad',
    },
    {
        action: 'Force words to be recognized as commands',
        command: 'Press and hold down the CTRL key',
    },
    {
        action: 'Force words to be recognized as dictation',
        command: 'Press and hold down the SHIFT key',
    },
    {
        action: 'Open the Naturally Speaking menu',
        command: 'Asterisk key (*) on the numeric keypad',
    },
];

const tecName = 'Dragon';

export default function DragonActionsSection() {
    return (
        <section>
            <Heading
                headingLevel={3}
                appearance="h4"
                className="accessibility-table-heading mb-2"
            >
                {tecName} Actions and Commands
            </Heading>
            <table
                className="table-no-border mb-4"
                aria-label={`${tecName} Actions and Commands Table`}
            >
                <thead>
                    <tr>
                        <th scope="col">Action</th>
                        <th scope="col">Commands</th>
                    </tr>
                </thead>
                <tbody>
                    {dragonActions.map((dragonAction) => (
                        <tr key={dragonAction.action}>
                            <td width="70%">{dragonAction.action}</td>
                            <td width="30%">{dragonAction.command}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    );
}
