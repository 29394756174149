/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * SiteMap.jsx
 * Created by Destin Frasier on 09/13/19
 */

import React from 'react';
import { HelmetWrapper, SingleColumnContainer, Heading } from 'sarsaparilla';

import Account from '../../components/tertiary/siteMap/Account';
import Camping from '../../components/tertiary/siteMap/Camping';
import Passes from '../../components/tertiary/siteMap/Passes';
import Permits from '../../components/tertiary/siteMap/Permits';
import General from '../../components/tertiary/siteMap/General';
import Lottery from '../../components/tertiary/siteMap/Lottery';
import Search from '../../components/tertiary/siteMap/Search';
import Tickets from '../../components/tertiary/siteMap/Tickets';
import TripBuilder from '../../components/tertiary/siteMap/TripBuilder';

const pageTitle = `${process.env.SITE_NAME} Site Map`;

export default class SiteMap extends React.Component {
    render() {
        return (
            <SingleColumnContainer role="main">
                <section className="nav-site-map-page">
                    <HelmetWrapper title={pageTitle} />
                    <Heading headingLevel={1} appearance="h1">
                        {pageTitle}
                    </Heading>
                    <General />
                    <Account />
                    <Camping />
                    <Lottery />
                    <Passes />
                    <Permits />
                    <Search />
                    <Tickets />
                    <TripBuilder />
                </section>
            </SingleColumnContainer>
        );
    }
}
