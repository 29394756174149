/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

// CAMPSITE RESERVATION TYPE
export const NON_SITE_SPECIFIC = 'Non Site-Specific';
export const PER_PERSON = 'Per Person';

// CAMPSITE_RESERVABLE_TYPE
export const CAMPSITE_TYPE_OVERFLOW = 'Overflow';

export const ACCESSIBLE_CAMPSITE_TOOLTIP =
    'This site has features that provide better accessibility than others. Such features may include clear ground space, a firm surface and other features. We are in the process of examining features that improve accessibility.';

export const LONG_TERM_STAY_TOOLTIP =
    'This site is set aside for visitors staying for a month or longer. A portion of the balance is due ahead of arrival, and paid monthly thereafter.';

/** Site type config */
export const SITE_TYPE_CONFIG_PATH = 'site_type_config';
export const NO_PASSES_RULE_PATH = `${SITE_TYPE_CONFIG_PATH}.no_passes`;
