/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * TripIdeas.jsx
 * Created by Destin Frasier on 03/27/18
 */

import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { RELATED_TRIP_ITEM } from '../../../../constants/propTypes';
import { parseRelatedTripLink } from '../../../../utils/articlesHelper';

const propTypes = {
    trips: PropTypes.arrayOf(RELATED_TRIP_ITEM),
};

const defaultProps = {
    trips: [],
};

function TripIdeas({ trips }) {
    return (
        <div className="nav-article-left-rail-item">
            <div className="nav-article-left-rail-item-title">Explore Related Trips</div>
            <ul className="nav-article-img-link">
                {trips.map((trip) => (
                    <li
                        key={`${uniqueId(trip.explore_related_trips_alt)}`}
                        className="nav-article-trip-idea-item"
                    >
                        {trip.explore_related_trips_image !== null && (
                            <a
                                href={parseRelatedTripLink(trip.explore_trips_link)}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className="nav-article-trip-idea-image-wrap">
                                    <img
                                        src={`${trip.explore_related_trips_image}`}
                                        alt={`${trip.explore_related_trips_alt}`}
                                        aria-label={`${trip.explore_related_trips_alt}`}
                                    />
                                </div>
                            </a>
                        )}
                        <a
                            href={parseRelatedTripLink(trip.explore_trips_link)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {trip.explore_trips_link_title}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

TripIdeas.propTypes = propTypes;
TripIdeas.defaultProps = defaultProps;

export default TripIdeas;
