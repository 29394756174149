/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import type { LogoProps } from '../../../../types/Logo';

export function Logo({ isLight, ...rest }: LogoProps) {
    const textColor = isLight ? '#fff' : '#4e4e4f';
    const iconColor = isLight ? '#77A145' : '#64873a';

    return (
        <svg
            data-component="Icon"
            className="sarsa-icon rec-icon-logo"
            viewBox="0 0 355.1765 45.3194"
            height="35"
            width="240"
            {...rest}
        >
            <g>
                <path
                    fill={textColor}
                    d="M310.0018 36.7862a1.1011 1.1011 0 1 1-1.1016 1.1016 1.1045 1.1045 0 0 1 1.1016-1.1016zm16.1035-10.6807V38.961c0 3.9365-2.4775 6.3584-6.1387 6.3584a7.7786 7.7786 0 0 1-5.7529-2.56l.9629-1.0186a6.132 6.132 0 0 0 4.79 2.1748c3.2207 0 4.707-2.0918 4.707-4.9v-2.4491a5.405 5.405 0 0 1-4.542 2.4219 6.2441 6.2441 0 0 1-6.2207-6.6064 6.2447 6.2447 0 0 1 6.2207-6.6064 5.3852 5.3852 0 0 1 4.68 2.6426v-2.3127zm-1.4316 3.9922a5.1678 5.1678 0 0 0-4.542-2.918c-2.8076 0-4.7344 2.2842-4.7344 5.2021s1.9268 5.2031 4.7344 5.2031a5.1678 5.1678 0 0 0 4.542-2.918zm17.1786 2.2842a6.39 6.39 0 1 1-12.7725 0 6.39 6.39 0 1 1 12.7725 0zm-1.4863 0c0-2.835-1.7617-5.2021-4.9-5.2021s-4.8994 2.3672-4.8994 5.2021 1.7617 5.2031 4.8994 5.2031 4.9-2.3672 4.9-5.2031zm3.7998-6.2764l4.7617 10.1025 4.7627-10.1025h1.4863l-6.2763 13.1035-6.2754-13.1035zM0 2.2012h9.1436c7.792 0 12.2832 4.49 12.2832 10.7129 0 8.17-7.1426 10.28-9.7939 10.5508l10.8212 16.6113h-3.4082L8.4405 23.627h-5.627v16.4492H0zm9.3067 18.7207c5.6807 0 9.1982-3.03 9.1982-8.0078s-3.5713-8.0078-9.1982-8.0078H2.8135v16.0156zm22.5596-1.5694h16.882V21.95h-16.882v15.5285h18.5048v2.5977H29.0528V2.2012h20.9931v2.5967H31.8663zM86.5664 32.501l1.9482 1.9482A18.2882 18.2882 0 0 1 74.5 40.7256c-10.93 0-18.7207-8.6572-18.7207-19.5869S63.5704 1.5518 74.5 1.5518a18.0068 18.0068 0 0 1 13.4737 5.789l-2.002 1.84A15.229 15.229 0 0 0 74.5 4.3108c-9.4141 0-15.7988 7.5215-15.7988 16.8281S65.086 37.966 74.5 37.966a15.6363 15.6363 0 0 0 12.0664-5.465zm8.0049-30.2998h9.1436c7.792 0 12.2832 4.49 12.2832 10.7129 0 8.17-7.1426 10.28-9.7939 10.5508l10.8213 16.6113h-3.4082L103.0118 23.627h-5.627v16.4492h-2.8135zm9.307 18.7207c5.6807 0 9.1982-3.03 9.1982-8.0078s-3.5713-8.0078-9.1982-8.0078h-6.4935v16.0156zm22.5592-1.5694h16.8818V21.95h-16.8818v15.5285h18.5049v2.5977h-21.3183V2.2012h20.9932v2.5967h-18.18zm66.9248-14.5546h-11.417V2.2012h25.6475v2.5967h-11.417v35.2783h-2.8135z"
                />
                <path
                    fill={iconColor}
                    d="M152.746 40.077h-3l19.029-38.28 18.888 38.28h-2.991l-15.917-32.26-16.009 32.26m70.699-20.252l1.592-1.23-5.542-7.17V5.068l3.95 5.111 1.592-1.231L218.12 0l-6.917 8.948 1.592 1.231 3.886-5.028v6.358l-5.478 7.086 1.592 1.23 3.886-5.027v6.356l-5.478 7.087 1.592 1.231 3.886-5.028v15.632h2.814V24.36l3.95 5.112 1.592-1.231-5.542-7.17v-6.357l3.95 5.111"
                />
                <path
                    fill={textColor}
                    d="M277.4932 2.2012l23.2666 33.0049V2.2012h2.8135v37.875h-2.8135L277.4932 6.9619v33.1143h-2.8135V2.2012z"
                />
                <path
                    fill={iconColor}
                    d="M249.6524 16.9594a4.2876 4.2876 0 0 0-.8349-.0821 4.2611 4.2611 0 0 0-4.1488 5.2321l-4.4192 7.5987 7.5989-4.4192a4.2614 4.2614 0 0 0 5.15-4.9835l4.39-7.736zm.3923 5.4051a1.7335 1.7335 0 1 1 0-2.4516 1.7335 1.7335 0 0 1 0 2.4516z"
                />
                <path
                    fill={textColor}
                    d="M246.3183 4.5142V1.7308c-8.6708 1.1242-15.0434 8.0292-16.0614 16.9079h2.9336c.9267-7.2966 5.8791-13.0423 13.1278-14.1245zm.0008 36.0341v-2.7861c-7.25-1.0818-12.2019-6.827-13.1287-14.1236h-2.9346c1.0125 8.8828 7.3541 15.7902 16.0633 16.9097zm5-2.7862v2.786c8.7092-1.12 15.0505-8.0272 16.0627-16.9094h-2.9345c-.9268 7.2962-5.879 13.0413-13.1282 14.1234zm-.0008-36.0313v2.7834c7.25 1.0823 12.2019 6.8279 13.1287 14.1245h2.9336C266.3628 9.76 259.9903 2.855 251.3183 1.7308z"
                />
            </g>
        </svg>
    );
}
