/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

const ROOT = '/licensing';

export const stateRoute = (stateName = ':stateName') => `${ROOT}/${stateName}`;

// Big Game
export const smallGamePageRoute = (stateName) => `${stateRoute(stateName)}/small-game`;
export const bigGamePageRoute = (stateName) => `${stateRoute(stateName)}/big-game`;
export const huntCodesPageRoute = (stateName) =>
    `${stateRoute(stateName)}/big-game/hunt-codes`;
export const gmuPageRoute = (stateName, assetId) =>
    `${stateRoute(stateName)}/gmu/${assetId}`;

const childRoutes = [
    {
        path: bigGamePageRoute(),
        exact: true,
        componentName: 'SearchPage',
        title: 'Big Game Home',
    },
    {
        path: smallGamePageRoute(),
        exact: true,
        componentName: 'SearchPageSmallGame',
        title: 'Small Game Home',
    },
    {
        path: huntCodesPageRoute(),
        exact: true,
        componentName: 'HuntCodesPage',
        title: 'Hunt Codes Home',
    },
    {
        path: gmuPageRoute(':stateName', ':assetId'),
        componentName: 'GMUPage',
        title: 'GMU Detail',
    },
];

export default childRoutes;
