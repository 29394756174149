/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

// Account-Related Action Types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGIN_BAD_MAGIC = 'LOGIN_BAD_MAGIC';

// Map Actions
export const UPDATE_MAP_SUGGESTIONS = 'UPDATE_MAP_SUGGESTIONS';
export const CLEAR_MAP_SUGGESTIONS = 'CLEAR_MAP_SUGGESTIONS';
export const UPDATE_MAP_SEARCH_TERM = 'UPDATE_MAP_SEARCH_TERM';
export const UPDATE_MAP_SEARCH_RESULT_LAT_LONG = 'UPDATE_MAP_SEARCH_RESULT_LAT_LONG';
export const UPDATE_MAP_SEARCH_RESULT = 'UPDATE_MAP_SEARCH_RESULT';
export const MAP_INVENTORY_FETCHED = 'MAP_INVENTORY_FETCHED';
export const FETCHING_MAP_INVENTORY_FAILED = 'FETCHING_MAP_INVENTORY_FAILED';
export const INVENTORY_SELECTED_CARD = 'INVENTORY_SELECTED_CARD';
export const INVENTORY_DESELECTED_CARD = 'INVENTORY_DESELECTED_CARD';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTERS = 'REMOVE_FILTERS';
export const ADD_FILTERS = 'ADD_FILTERS';
export const UPDATE_FILTERED_MAP_INVENTORY = 'UPDATE_FILTERED_MAP_INVENTORY';

// Articles Actions
export const ARTICLES_FETCH_HAS_ERRORED = 'ARTICLES_FETCH_HAS_ERRORED';
export const ARTICLES_FETCH_IS_LOADING = 'ARTICLES_FETCH_IS_LOADING';
export const ARTICLES_FETCH_SUCCESS = 'ARTICLES_FETCH_SUCCESS';

// Article Actions
export const ARTICLE_FETCH_HAS_ERRORED = 'ARTICLE_FETCH_HAS_ERRORED';
export const ARTICLE_FETCH_IS_LOADING = 'ARTICLE_FETCH_IS_LOADING';
export const ARTICLE_FETCH_SUCCESS = 'ARTICLE_FETCH_SUCCESS';

// Featured Article Actions
export const FEATURED_HAS_ERRORED = 'FEATURED_HAS_ERRORED';
export const FEATURED_IS_LOADING = 'FEATURED_IS_LOADING';
export const FEATURED_FETCH_SUCCESS = 'FEATURED_FETCH_SUCCESS';

// Homepage Featured Article Actions
export const HOMEPAGE_FEATURED_HAS_ERRORED = 'HOMEPAGE_FEATURED_HAS_ERRORED';
export const HOMEPAGE_FEATURED_IS_LOADING = 'HOMEPAGE_FEATURED_IS_LOADING';
export const HOMEPAGE_FEATURED_FETCH_SUCCESS = 'HOMEPAGE_FEATURED_FETCH_SUCCESS';

// Modal Actions
export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL';
export const HIDE_LOGIN_MODAL = 'HIDE_LOGIN_MODAL';

// Contact Page actions
export const FEEDBACK_SUCCESS = 'FEEDBACK_SUCCESS';
export const FEEDBACK_FAILED = 'FEEDBACK_FAILED';

// New Inventory Page actions
export const NEW_INVENTORY_SUCCESS = 'NEW_INVENTORY_SUCCESS';
export const NEW_INVENTORY_FAILED = 'NEW_INVENTORY_FAILED';
export const NEW_IMP_SUCCESS = 'NEW_IMP_SUCCESS';
export const NEW_IMP_FAILED = 'NEW_IMP_FAILED';
