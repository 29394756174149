/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Switch } from 'react-router-dom';
import {
    NavbarContainer,
    SkipNavContent,
    ErrorBoundary,
    Spinner,
    useFlags,
} from 'sarsaparilla';

import { AppBase, ErrorPage } from 'shared-ui';
import { Footer, Logo, getMenuAccountItems, getMenuSections } from 'site-kit';

import makeSwitchRoutes from './makeSwitchRoutes';

if (process.env.REACT_AXE_ENV === 'local') {
    // eslint-disable-next-line
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 5000);
}

export default function App() {
    const { enableGiftCards, useNewCampingFilters } = useFlags();
    const routes = makeSwitchRoutes({ enableGiftCards });
    const menuSections = getMenuSections(useNewCampingFilters);

    return (
        <AppBase>
            <NavbarContainer
                getMenuAccountItems={getMenuAccountItems}
                menuSections={menuSections}
                logoElement={<Logo />}
                darkModeLogoElement={<Logo isLight />}
            />
            <ErrorBoundary fallbackElement={<ErrorPage />}>
                <SkipNavContent className="rec-page">
                    <Suspense fallback={<Spinner />}>
                        <Switch>{routes}</Switch>
                    </Suspense>
                </SkipNavContent>
            </ErrorBoundary>
            <Footer />
        </AppBase>
    );
}
