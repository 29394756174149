/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from 'sarsaparilla';
import { get } from 'lodash';

export default class SearchFiltersHeader extends React.Component {
    static propTypes = {
        uiFilters: PropTypes.object,
        onFilterChange: PropTypes.func,
        //dispatch: PropTypes.func,
        //onFilterRemove: PropTypes.func,
        //onFilterToggle: PropTypes.func,
        onApplyFilters: PropTypes.func,
        isCampsitesFilters: PropTypes.bool,
    };

    static defaultProps = {
        isCampsitesFilters: false,
    };

    static displayName = 'SearchFiltersHeader';

    constructor(props) {
        super(props);
        this.handleHideUnavailableChange = this.handleHideUnavailableChange.bind(this);
        this.handleHideNotReservableChange =
            this.handleHideNotReservableChange.bind(this);
    }

    handleHideUnavailableChange(event) {
        const filterOption = {
            key: 'includeUnavailable',
            value: !event.currentTarget.checked,
            filters: [
                {
                    attribute: 'reservable',
                    filterValue: '1',
                },
            ],
        };
        if (this.props.onFilterChange) {
            this.props.onFilterChange('includeUnavailable', filterOption);
        }
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    handleHideNotReservableChange(event) {
        const filterOption = {
            key: 'includeNotReservable',
            value: !event.currentTarget.checked,
            filters: [
                {
                    attribute: 'reservable',
                    filterValue: '1',
                },
            ],
        };
        if (this.props.onFilterChange) {
            this.props.onFilterChange('includeNotReservable', filterOption);
        }
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    render() {
        const includeUnavailable = get(
            this.props.uiFilters,
            'includeUnavailable.includeUnavailable.value',
            true
        );
        const includeNotReservable = get(
            this.props.uiFilters,
            'includeNotReservable.includeNotReservable.value',
            true
        );
        return (
            <div className="search-filters-dropdown-header">
                <div className="rec-form-inline-item-wrap">
                    {!this.props.isCampsitesFilters && (
                        <div className="search-filters-dropdown-header-items-wrap-left">
                            <div className="rec-form-inline-item">
                                <Checkbox
                                    className="mt-xl-3"
                                    label="Hide Unavailable"
                                    onChange={(e) => this.handleHideUnavailableChange(e)}
                                    id="search-filter-hide-unavailable"
                                    value="hide_unavailable"
                                    isChecked={!includeUnavailable}
                                />
                            </div>

                            <div className="rec-form-inline-item">
                                <Checkbox
                                    className="mt-xl-3"
                                    label="Hide First-come, First-served"
                                    onChange={(e) =>
                                        this.handleHideNotReservableChange(e)
                                    }
                                    id="search-filter-hide-notreservable"
                                    value="hide_notreservable"
                                    isChecked={!includeNotReservable}
                                />
                            </div>
                        </div>
                    )}

                    {/* need to remove these
                    <div className="search-filters-dropdown-header-items-wrap-right">
                        <SearchFilterOccupants
                            className="rec-form-inline-item occupants"
                            uiFilters={this.props.uiFilters}
                            aggregations={this.props.aggregations}
                            onFilterChange={this.props.onFilterChange}
                            onFilterRemove={this.props.onFilterRemove}
                            onApplyFilters={this.props.onApplyFilters} />

                        <SearchFilterCampsiteVehicleLength
                            className="rec-form-inline-item"
                            uiFilters={this.props.uiFilters}
                            aggregations={this.props.aggregations}
                            onFilterChange={this.props.onFilterChange}
                            onFilterRemove={this.props.onFilterRemove}
                            onApplyFilters={this.props.onApplyFilters} />
                    </div>
                */}
                </div>
            </div>
        );
    }
}
