/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { FlexRow, FlexCol, ImageWithFallback } from 'sarsaparilla';
import { MobileAppButtons } from './MobileAppButtons';

import marketingPhoneImageWebp from '../images/marketing-phone-image.webp';
import marketingPhoneImagePng from '../images/marketing-phone-image.png';

export function MobileAppMarketing() {
    return (
        <section className="nav-mobile-app-marketing">
            <div className="nav-mobile-app-marketing-content">
                <FlexRow justifyContent="center">
                    <FlexCol xl={8} lg={10} md={12}>
                        <FlexRow>
                            <FlexCol lg={7} lgOrder={2}>
                                <div className="nav-mobile-app-marketing-title">
                                    Recreation.gov Mobile App
                                </div>
                                <p>
                                    Get outdoors, experience America, and bring home a
                                    story with Recreation.gov!
                                </p>
                                <div className="nav-mobile-app-marketing-actions">
                                    <MobileAppButtons />
                                </div>
                            </FlexCol>
                            <FlexCol lg={5} lgOrder={1}>
                                <div className="nav-mobile-app-marketing-media">
                                    <div className="nav-mobile-app-marketing-media-item">
                                        <ImageWithFallback
                                            src={marketingPhoneImageWebp}
                                            fallbackSrc={marketingPhoneImagePng}
                                            alt="mobile app splash screen on black iPhone"
                                        />
                                    </div>
                                </div>
                            </FlexCol>
                        </FlexRow>
                    </FlexCol>
                </FlexRow>
            </div>
            <div className="nav-mobile-app-marketing-background" />
        </section>
    );
}
