/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import propTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Cookies from 'js-cookie';

import { DisplayHtmlContent, Announcement, Button, useBreakpoint } from 'sarsaparilla';

interface LDAnnouncementProps {
    setAnnouncementClose: () => void;
}

export function LDAnnouncement({ setAnnouncementClose }: LDAnnouncementProps) {
    const [shouldHideOnClose, setHideOnClose] = React.useState(false);
    const { announcementsSiteWide } = useFlags();
    const { isSmAndDown } = useBreakpoint();

    // Bail if no data from LaunchDarkly
    if (!announcementsSiteWide) {
        return null;
    }

    const [
        id,
        title,
        body,
        linkText,
        linkHref,
        imageUrl,
        imageCredit,
        theme,
        hidingEnabled,
        homepageOnly,
    ] = announcementsSiteWide;

    const isOff =
        !Array.isArray(announcementsSiteWide) || announcementsSiteWide.length === 0;
    const isHomepageOnly = homepageOnly === 'homepageOnly';

    const closedAnnouncement = () => {
        // Set a cookie to hide this announcement in the future
        if (shouldHideOnClose) {
            Cookies.set(id, 'hide', { expires: 730, secure: true });
        }
        setAnnouncementClose();
    };

    const handleCheckboxChange = () => {
        setHideOnClose(!shouldHideOnClose);
    };

    // Don't show if the flag is set to off
    if (isOff) {
        return null;
    }

    // Don't show if homepageOnly and it's not the homepage
    if (isHomepageOnly && window?.location?.pathname !== '/') {
        return null;
    }

    // Don't show if current page is the same as the button href
    if (window?.location?.pathname === linkHref) {
        return null;
    }

    const isLightTheme = theme === 'lightTheme';
    const textColor = isLightTheme ? 'dark' : 'light';
    const backgroundColor = isLightTheme ? 'light' : 'dark';
    const isHidingEnabled = hidingEnabled === 'hidingEnabled';

    let decodedBannerBody = '';
    try {
        decodedBannerBody = decodeURIComponent(body);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        decodedBannerBody = '';
    }

    return (
        <Announcement
            image={{ backgroundImage: `url(${process.env['CDN']}${imageUrl})` }}
            title={title}
            sticky
            textColor={textColor}
            background={backgroundColor}
            name={id}
            closedAnnouncement={closedAnnouncement}
        >
            {/* The banner_body is URI-encoded HTML */}
            <DisplayHtmlContent html={decodedBannerBody} />

            {/* Show this div if the button link and the button title are specified, or if the hidingEnabled flag is enabled */}
            {((linkText && linkHref) || isHidingEnabled) && (
                <div className="rec-announcement-link-btn-input-wrap">
                    {/* Show a button below the body that links to a page if both the button link and the button title are specified */}
                    {linkText && linkHref && (
                        <Button
                            shouldFitContainer={isSmAndDown}
                            href={linkHref}
                            className="rec-announcement-link-btn"
                        >
                            {linkText}
                        </Button>
                    )}

                    {/* Show a checkbox that allows the user to permanently hide the banner if this flag is enabled */}
                    {isHidingEnabled && (
                        <div className="rec-form-check-wrap">
                            <label htmlFor={id} className="rec-label-checkbox">
                                <input
                                    className="rec-input-hide"
                                    type="checkbox"
                                    id={id}
                                    name={id}
                                    onChange={handleCheckboxChange}
                                />
                                <span className="rec-input-checkbox" />
                                Don&#39;t show me this again
                            </label>
                        </div>
                    )}
                </div>
            )}

            {/* Show a photo credit if it exists */}
            {imageCredit && (
                <div className="rec-announcement-photo-credit">{imageCredit}</div>
            )}
        </Announcement>
    );
}

LDAnnouncement.propTypes = {
    setAnnouncementClose: propTypes.func,
};

// const options = [
//     "id",
//     "Banner title",
//     "Banner body copy",
//     "Link text [or empty string]",
//     "Link href [or empty string]",
//     "Background image url",
//     "Background image credit [or empty string]",
//     "lightTheme [or empty string]",
//     "hidingEnabled [or empty string]",
//     "homepageOnly [or empty string]"
// ]

// [
//     "easter-egg-2020-off",
//     "The White House Easter Egg Roll Lottery has Closed",
//     "%3Cp%3EThe%20last%20day%20to%20enter%20the%20ticket%20lottery%20was%20Monday%2C%20February%2024%2C%202020.%20Thank%20you%20to%20all%20our%20applicants%2C%20and%20be%20on%20the%20lookout%20for%20the%20lottery%20results%20announcement%20on%20Wednesday%2C%20March%204%2C%202020.%3C%2Fp%3E",
//     "",
//     "",
//     "/img/announcements/2020WHEER-Closed.jpg",
//     "Official White House Photo by Tia Dufour",
//     "lightTheme",
//     "hidingEnabled",
//     "homepageOnly"
// ]

// cSpell:ignore WHEER, Dufour
