/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import {
    Checkbox,
    Heading,
    Accordion,
    AccordionContent,
    AccordionHeading,
    AccordionItem,
} from 'sarsaparilla';
import { isNil, isEmpty } from 'lodash';
import { SEARCH_FILTER_AGENCIES_VALUES } from '../constants/index';

export default class SearchFilterAgencies extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        uiFilters: PropTypes.object,
        onFilterToggle: PropTypes.func,
        aggregations: PropTypes.object,
        onApplyFilters: PropTypes.func,
        expandable: PropTypes.bool,
    };

    static displayName = 'SearchFilterAgencies';

    handleChange(event, item) {
        if (this.props.onFilterToggle) {
            this.props.onFilterToggle('agencies', item);
        }
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    processAggregations(searchAggregations) {
        const counts = {};
        // find aggregated counts for item attribute
        SEARCH_FILTER_AGENCIES_VALUES.forEach((item) => {
            const filter = item.filters[0];
            counts[filter.attribute] = {};
            if (
                searchAggregations.agencies &&
                searchAggregations.agencies[filter.attribute]
            ) {
                const aggs = searchAggregations.agencies[filter.attribute];
                for (let i = 0; i < aggs.length; i += 1) {
                    counts[filter.attribute][aggs[i].name] = aggs[i].count;
                }
            }
        });
        // aggregate comma separate filter counts into one
        SEARCH_FILTER_AGENCIES_VALUES.forEach((item) => {
            const filter = item.filters[0];
            const parts = filter.filterValue.split(',');
            let count = 0;
            parts.forEach((part) => {
                const cnt = counts[filter.attribute];
                if (cnt && cnt[part]) {
                    count += cnt[part];
                }
            });
            counts[filter.attribute][filter.filterValue] = count;
        });
        return counts;
    }

    renderCheckbox(item, counts) {
        const filter = item.filters[0];
        const attCounts = counts[filter.attribute];
        let count;
        if (attCounts) {
            count = attCounts[filter.filterValue];
        }

        const selections = this.props.uiFilters?.agencies || {};
        const isChecked =
            !isNil(selections) && !isEmpty(selections)
                ? selections.hasOwnProperty(item.key)
                : false;
        let label;
        if (count || isChecked) {
            label = `${item.displayName} ${
                item.hasCode ? `- ${item.key}` : ''
            } (${count})`;
        } else {
            label = `${item.displayName} ${item.hasCode ? ` - ${item.key}` : ''}`;
        }

        return (
            <div className="search-filter-check-item" htmlFor={item.key} key={item.key}>
                <Checkbox
                    onChange={(e) => this.handleChange(e, item)}
                    id={item.key}
                    label={label}
                    value={item.value}
                    className={count === 0 && !isChecked ? 'disabled' : ''}
                    isChecked={isChecked}
                />
                {item.icon}
            </div>
        );
    }

    render() {
        const className = `${this.props.className}`;
        const counts = this.processAggregations(this.props.aggregations);

        if (this.props.expandable) {
            return (
                <div className="search-filters-accordion__container pb-0 my-0">
                    <Accordion
                        hasIndentedBody={false}
                        className="search-filters-accordion__item"
                    >
                        <AccordionItem>
                            <AccordionHeading className="search-filters-accordion__heading">
                                Federal Agencies Offering Reservations
                            </AccordionHeading>
                            <AccordionContent>
                                <div
                                    className="search-filters-double-type-wrap"
                                    role="group"
                                    aria-label="Agencies filters"
                                >
                                    <legend className="rec-sr-only">
                                        Select agencies to filter on
                                    </legend>
                                    {SEARCH_FILTER_AGENCIES_VALUES.map((item) =>
                                        this.renderCheckbox(item, counts)
                                    )}
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </div>
            );
        }

        return (
            <div className={`${className}`}>
                <div className="search-filters-section-header">
                    <Heading headingLevel={2} appearance="h5">
                        Federal Agencies Offering Reservations
                    </Heading>
                </div>
                <div
                    className="search-filters-double-type-wrap"
                    role="group"
                    aria-label="Agencies filters"
                >
                    <legend className="rec-sr-only">Select agencies to filter on</legend>
                    {SEARCH_FILTER_AGENCIES_VALUES.map((item) =>
                        this.renderCheckbox(item, counts)
                    )}
                </div>
            </div>
        );
    }
}
