/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isNil from 'lodash/isNil';

import { useRect, useWindowSize, ErrorBoundary } from 'sarsaparilla';

/** TODO:
 * Maybe we can disable the useRect observer if the device width is too small
 * to ever show the side-by-side layout */

export default function CardMapLayout({
    children,
    className,
    mapComponent,
    offsetTop = 0,
    hideMap,
    isListTab,
}) {
    const { width: windowWidth } = useWindowSize();
    const isMobile = windowWidth < 768;

    const cardContainerRef = useRef();
    const mapContainerRef = useRef();
    const ref = useRef();
    const rect = useRect(ref, !isMobile);
    const [stuckStatus, setStuckStatus] = useState('none');
    const [parentWidth, setParentWidth] = useState(0);

    const width = rect?.width;
    const top = rect?.top;
    const bottom = rect?.bottom;
    const mapHeight = mapContainerRef.current?.offsetHeight;
    const cardContainerHeight = cardContainerRef.current?.offsetHeight;
    const isMapShorterThanCards = cardContainerHeight > mapHeight;
    const isModalMap = !!document.getElementsByClassName('map-modal-background')?.length;

    useEffect(() => {
        if (!isNil(top) && isMapShorterThanCards && !isModalMap) {
            if (bottom < window.innerHeight) {
                setStuckStatus('bottom');
            } else if (top <= 80 + offsetTop) {
                setStuckStatus('top');
            } else {
                setStuckStatus('none');
            }
        } else {
            setStuckStatus('none');
        }
    }, [top, bottom, isMapShorterThanCards, offsetTop]);

    useEffect(() => {
        if (!isNil(width)) {
            setParentWidth(width);
        }
    }, [width]);

    return (
        <div data-shared-cml className={cx('shared-card-map-layout', className)}>
            <div>
                <div data-shared-cml-cards className="shared-card-map-layout-cards">
                    <div ref={cardContainerRef}>{children}</div>
                </div>
                <div data-shared-cml-map ref={ref} className={hideMap ? 'hide-map' : ''}>
                    <div
                        ref={mapContainerRef}
                        style={{ width: `${parentWidth}px` }}
                        className={cx('shared-sticky-map-inner', {
                            'stuck-top': stuckStatus === 'top',
                            'stuck-bottom': stuckStatus === 'bottom',
                            'list-tab-view': isListTab,
                        })}
                        role="region"
                        aria-label="Map"
                    >
                        {!isMobile && parentWidth > 0 && (
                            <ErrorBoundary
                                hasDefaultWrapperClass={false}
                                fallbackElement={
                                    <div>
                                        We apologize but maps for this location are not
                                        available at this time.
                                    </div>
                                }
                            >
                                {mapComponent}
                            </ErrorBoundary>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

CardMapLayout.propTypes = {
    children: PropTypes.node,
    mapComponent: PropTypes.node,
    className: PropTypes.string,
    offsetTop: PropTypes.number,
    hideMap: PropTypes.bool,
};
