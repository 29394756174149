/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useRef, useEffect } from 'react';
import {
    useWindowSize,
    StyledModal,
    InlinePopup,
    Icons,
    Button,
    Alert,
    ButtonGroup,
    pluralize,
} from 'sarsaparilla';
import moment from 'moment';
import cx from 'classnames';
import FlexDatesTabs from './FlexDatesTabs';
import FlexDatesInput from './FlexDatesInput';

export interface FiltersProps {
    startDate?: string | moment.Moment | null;
    endDate?: string | moment.Moment | null;
    startRange?: string;
    endRange?: string;
    duration?: string;
    months?: Array<string> | null;
}

export type FlexDatesMainProps = {
    filters: FiltersProps;
    onFiltersChange: (v: FiltersProps) => void;
    isSearch: boolean;
    showClear?: boolean;
    limit?: number;
    monthsLimit?: number;
};

function FlexDatesWrapper({
    filters,
    limit,
    onFiltersChange,
    isSearch,
    showClear,
}: FlexDatesMainProps) {
    // Layout
    const [openPortal, setOpenPortal] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [tab, setTab] = useState<string>('0');

    /*  As a heads up, right now the HeroContainer.jsx has it's own isMobile variable and doesn't even use this component when it's below 768px.
     *  It's been that way for about 1.5 years, but since I don't know the context
     *  I'm leaving the logic here in case that changes although I'm tempted to delete it.
     */
    const { width } = useWindowSize();
    const isMobile = width ? width < 768 : false;

    const triggerRef = useRef<HTMLInputElement>(null);
    const portalRef = useRef<HTMLInputElement>(null);

    // Carousel Tab
    const [checked, setChecked] = useState<Array<string>>([]);
    const [duration, setDuration] = useState<string>('1');

    // Calendar Tab
    const [dates, setDates] = useState<{
        startDate?: moment.Moment | null;
        endDate?: moment.Moment | null;
    }>({ startDate: null, endDate: null });
    const [startRange, setStartRange] = useState<string>('');
    const [endRange, setEndRange] = useState<string>('');

    const monthsOptions = [...new Array(24).keys()].map((item) => ({
        month: moment().add(item, 'M').format('MMMM'),
        monthShort: moment().add(item, 'M').format('MMM'),
        year: moment().add(item, 'M').format('YYYY'),
        value: moment().add(item, 'M').format('MM-YYYY'),
    }));

    const isClearButtonActive = () => {
        return (
            document.getElementById('flex-dates-input__clear') === document.activeElement
        );
    };

    const isCloseButtonActive = () => {
        return (
            document.getElementById('flex-dates-close-button') === document.activeElement
        );
    };

    const handleKeyup = (event: KeyboardEvent): void => {
        if (openPortal) {
            const key = event.key || event.which;

            if (key === 'Escape' || key === 'Esc' || key === 27) {
                event.preventDefault();
                setOpenPortal(false);
                const isButtonRefWorking = !!triggerRef?.current?.focus;

                if (isButtonRefWorking) {
                    triggerRef?.current?.focus();
                }
            }

            if (key === 'Tab' || key === 9) {
                const _flexDatesWrapper =
                    document?.getElementById('flex-dates-container');
                if (
                    tab === '0' &&
                    dates.startDate &&
                    dates.endDate &&
                    _flexDatesWrapper
                ) {
                    if (!_flexDatesWrapper.contains(document.activeElement)) {
                        document.getElementById('calendar-button-handler')?.focus();
                    }
                    return;
                }
                if (isClearButtonActive()) {
                    return;
                }

                const isWrapperRefWorking = !!portalRef?.current?.contains;
                if (
                    isWrapperRefWorking &&
                    !portalRef.current?.contains(document.activeElement)
                ) {
                    triggerRef?.current?.focus();
                    setOpenPortal(false);
                }
            }
        }
    };

    useEffect(() => {
        document.addEventListener('keyup', handleKeyup);
        return () => {
            document.removeEventListener('keyup', handleKeyup);
        };
        // eslint-disable-next-line
    }, [openPortal, dates]);

    useEffect(() => {
        if (openPortal && filters?.duration) {
            setTab('1');
        }
        if (!openPortal) {
            setError('');
        }
        // eslint-disable-next-line
    }, [openPortal]);

    const handleBlur = () => {
        if (document.activeElement === triggerRef.current || isClearButtonActive()) {
            return;
        }

        let payload: FiltersProps = {};
        if (tab === '1') {
            if (checked.length !== 0) {
                payload = { months: checked, duration };
            }
        } else if (tab === '0') {
            if (dates?.startDate || dates?.endDate) {
                payload = { startDate: dates?.startDate, endDate: dates?.endDate };
            }
            if (startRange) {
                payload.startRange = startRange;
            }
            if (endRange) {
                payload.endRange = endRange;
            }
        }

        if (Object.keys(payload).length > 0) {
            onFiltersChange({
                ...payload,
            });
        }
        if (portalRef.current?.contains(document.activeElement) && !isCloseButtonActive())
            return;

        setOpenPortal(false);
        triggerRef?.current?.focus();
        setTab('0');
    };

    const handleApplyFilters = (): void => {
        let payload = {};
        if (tab === '1') {
            if (checked.length === 0) {
                setError(
                    'You need to select at least one month to search for availability.'
                );
                return;
            }
            payload = { months: checked, duration };
        }
        if (tab === '0') {
            if (!dates?.startDate || !dates?.endDate) {
                setError(
                    'You need to select a Check In and a Check Out date to search for availability.'
                );
                return;
            }
            if (dates.startDate.isAfter(dates.endDate)) {
                setError('Check In date cannot be after the Check Out date.');
                return;
            }
            if (dates?.startDate && dates.startDate.isBefore(moment().startOf('d'))) {
                setError('Start Date cannot be before today.');
                return;
            }
            payload = {
                startRange,
                endRange,
                startDate: dates?.startDate,
                endDate: dates?.endDate,
            };
        }
        onFiltersChange({
            ...payload,
        });
        setOpenPortal(false);
    };

    const handleClear = (): void => {
        setDates({ startDate: null, endDate: null });
        setDuration('1');
        setStartRange('');
        setEndRange('');
        setChecked([]);
    };

    const handleReset = () => {
        handleClear();
        onFiltersChange({});
    };

    const isSearchPage = window.location.pathname.includes('search');

    const generateGaProps = () => {
        const clickTagCategory = isSearchPage ? 'Search' : 'Homepage';
        if (tab === '0') {
            let startTagAction = '';
            let endTagAction = '';
            let clickTagLabel = 'Selected Dates';
            if (dates?.startDate) {
                startTagAction = `Check-in: ${moment(dates?.startDate).format('LL')}`;
            }
            if (dates?.endDate) {
                endTagAction = `Check-out: ${moment(dates?.endDate).format('LL')}`;
            }
            if (startRange) {
                startTagAction = `${startTagAction} plus or minus ${startRange} ${pluralize(
                    Number(startRange),
                    'Day',
                    'Days'
                )}`;
                clickTagLabel = 'Selected Dates with plus or minus days';
            }
            if (endRange) {
                endTagAction = `${endTagAction} plus or minus ${endRange} ${pluralize(
                    Number(endRange),
                    'Day',
                    'Days'
                )}`;
                clickTagLabel = 'Selected Dates with plus or minus days';
            }
            return {
                clickTagCategory,
                clickTagAction: `${startTagAction}, ${endTagAction}`,
                clickTagLabel,
            };
        }
        const clickTagAction = `${duration} ${pluralize(
            Number(duration),
            'Day',
            'Days'
        )} Within ${checked.length} Selected ${pluralize(
            Number(checked.length),
            'Month',
            'Months'
        )}`;
        return {
            clickTagCategory,
            clickTagAction,
            clickTagLabel: 'Flexible Booking',
        };
    };
    const gaProps = generateGaProps();

    const shouldCloseOnInteractOutside = (el: Element) => {
        if (portalRef.current?.contains(el)) return false;
        if (triggerRef.current?.contains(el)) return true;

        (document.activeElement as HTMLElement)?.blur(); // reset the activeElement so the handleBlur function works as expected.
        return true;
    };

    return (
        <div
            className={cx('flex-dates-container', {
                'flex-dates-container-search': isSearch,
            })}
            id="flex-dates-container"
        >
            <FlexDatesInput
                filters={filters}
                openPortal={openPortal}
                setOpenPortal={setOpenPortal}
                monthsOptions={monthsOptions}
                onClear={handleReset}
                // @ts-expect-error
                ref={triggerRef}
                showClear={showClear}
                startRange={startRange}
                endRange={endRange}
            />
            <InlinePopup
                isOpen={openPortal && !isMobile}
                close={handleBlur}
                shouldCloseOnInteractOutside={shouldCloseOnInteractOutside}
                hideClose
            >
                <FlexDatesTabs
                    ref={portalRef}
                    dates={dates}
                    setDates={setDates}
                    setDuration={setDuration}
                    duration={duration}
                    checked={checked}
                    setChecked={setChecked}
                    handleApplyFilters={handleApplyFilters}
                    filters={filters}
                    tab={tab}
                    onClear={handleReset}
                    setTab={setTab}
                    limit={limit}
                    isMobile={isMobile}
                    monthsOptions={monthsOptions}
                    error={error}
                    setError={setError}
                    onClose={handleBlur}
                    startRange={startRange}
                    setStartRange={setStartRange}
                    setEndRange={setEndRange}
                    endRange={endRange}
                    generateGaProps={generateGaProps}
                />
            </InlinePopup>
            {openPortal && isMobile && (
                <StyledModal
                    size="md"
                    isOpen={openPortal}
                    shouldShowCloseButton={false}
                    heading=""
                    onRequestClose={() => {}}
                    overlayClassName="flex-dates-mobile-modal"
                    // @ts-expect-error
                    ref={portalRef}
                >
                    <div className="flex-dates-mobile">
                        <Button
                            aria-label="Close"
                            className="flex-dates-mobile-close"
                            appearance="link"
                            size="sm"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                if (e?.preventDefault) {
                                    e.preventDefault();
                                }
                                handleBlur();
                            }}
                            onFocus={(e: React.FocusEvent<HTMLButtonElement>) => {
                                if (e?.preventDefault) {
                                    e.preventDefault();
                                }
                            }}
                        >
                            <Icons.IconCloseCircleOutline />
                        </Button>
                        {error && isMobile && (
                            <div className="pb-2">
                                <Alert type="error">{error}</Alert>
                            </div>
                        )}

                        <FlexDatesTabs
                            ref={null}
                            dates={dates}
                            setDates={setDates}
                            setDuration={setDuration}
                            duration={duration}
                            checked={checked}
                            setChecked={setChecked}
                            handleApplyFilters={handleApplyFilters}
                            filters={filters}
                            tab={tab}
                            setTab={setTab}
                            isMobile={isMobile}
                            monthsOptions={monthsOptions}
                            error={error}
                            setError={setError}
                            onClear={handleReset}
                            onClose={handleBlur}
                            startRange={startRange}
                            setStartRange={setStartRange}
                            setEndRange={setEndRange}
                            endRange={endRange}
                            generateGaProps={generateGaProps}
                        />
                        <div className="flex-dates-mobile__actions">
                            <ButtonGroup isFullWidthOnMobile={false} isStretchedToFit>
                                <Button appearance="link" onClick={handleReset}>
                                    Clear Selection
                                </Button>
                                <Button onClick={handleApplyFilters} {...gaProps}>
                                    Apply Dates
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </StyledModal>
            )}
        </div>
    );
}

// Temporary LD wrapper to keep previous component during the development process
function FlexDatesWithFlag({
    filters,
    onFiltersChange,
    isSearch,
    monthsLimit,
    limit,
    showClear,
}: FlexDatesMainProps) {
    return (
        <FlexDatesWrapper
            limit={monthsLimit}
            monthsLimit={limit}
            showClear={showClear}
            filters={filters}
            onFiltersChange={onFiltersChange}
            isSearch={isSearch}
        />
    );
}

export default FlexDatesWithFlag;
