/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * General.jsx
 * Created by Destin Frasier on 09/16/19
 */

import React from 'react';
import { FlexRow, FlexCol, Heading } from 'sarsaparilla';

import SiteMapItem from './SiteMapItem';

export default function General() {
    return (
        <FlexRow>
            <FlexCol>
                <Heading headingLevel={2} appearance="h5">
                    General
                </Heading>
                <ul className="nav-site-map-wrap">
                    <SiteMapItem name="About Us" url="/about-us" />
                    <SiteMapItem name="Accessibility" url="/accessibility" />
                    <SiteMapItem name="Articles" url="/articles" />
                    <SiteMapItem
                        name="Contact Us"
                        url={`${process.env.SN_KB_EXT_URL}?id=external_contact_us`}
                        openNewTab
                    />
                    <SiteMapItem name="Disclaimers" url="/disclaimers" />

                    {process.env.FEEDBACK_ENABLED ? (
                        <SiteMapItem name="Feedback" url="/feedback" />
                    ) : null}

                    <SiteMapItem name="FOIA" url="/foia" />
                    <SiteMapItem name="Get Involved" url="/get-involved" />
                    <SiteMapItem name="Media Center" url="/media-center" />
                    <SiteMapItem name="Mobile App" url="/mobile-app" />
                    <SiteMapItem name="Add Your Facility" url="/new-inventory" />
                    <SiteMapItem name="Partners" url="/partners" />
                    <SiteMapItem name="Privacy Policy" url="/privacy-policy" />
                    <SiteMapItem
                        name="Rules & Reservation Policies"
                        url="/rules-reservation-policies"
                    />
                </ul>
            </FlexCol>
        </FlexRow>
    );
}
