/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import campingImageSrc from './images/learn-more-section/learn-more-camping.webp';
import donationsImageSrc from './images/learn-more-section/learn-more-donations.webp';
import passesImageSrc from './images/learn-more-section/learn-more-passes.webp';
import permitsImageSrc from './images/learn-more-section/learn-more-permits.webp';
import ticketsImageSrc from './images/learn-more-section/learn-more-tickets.webp';
import timedEntryImageSrc from './images/learn-more-section/learn-more-timed-entry.webp';

const CAMPING = {
    id: 'camping',
    label: 'Camping & Day Use',
    icon: 'camping-primary-icon',
    snowCategory: 'Camping',
    bullets: [
        `<strong>Camping</strong> allows overnight reservations for specific campsites, group sites, cabins, yurts, and lookouts.`,
        `<strong>Day Use</strong> allows daily reservations for specific sites such as picnic areas, pavilions, etc.`,
        `<strong>Itinerary Camping</strong> allows overnight reservations where visitors can move among different sites within the area.`,
    ],
    section2: [
        {
            icon: 'tent-icon-camping-page',
            bullet: `Facility managers can update campsite availability and amenities in real-time.`,
        },
        {
            icon: 'location-icon-camping-page',
            bullet: `Capture campsite photos and map locations from your phone, with or without cell data, using the R1S Ranger App (Apple Only)`,
        },
        {
            icon: 'calendar-check-icon-camping-page',
            bullet: `Facility managers can enable special business rules to preserve first-come, first-served sites for more spontaneous visitors. This allows for a more 'last-minute' camping experience while giving visitors more certainty.`,
        },
    ],
    textSliderItems: [
        {
            quote: 'Recreation.gov reduced confusion and frustration for the public and staff, allowing for a more enjoyable experience at the park. The benefits were more than staff imagined he explained…We have about half of our campsites available for advanced reservation and we are looking into adding the remaining campsites to Recreation.gov.',
            author: 'Ryan Koepke, Park Guide',
            location: 'Dunewood Campground',
        },
    ],
    textSliderImage: [
        {
            path: campingImageSrc,
            alt: 'A pop up van camper parked at a campsite',
        },
    ],
    reviewMeasureSection: [
        {
            header: 'Recreation.gov Facility Benefits:',
            bodyCopy:
                '<ul><li>Reduce cash handling with secure centralized financial collection, distribution, reporting and automated refund processing.</li><li>Update content, reservations, inventory, and business rules from one central location.</li><li>Provide access to a variety of real-time reports that help managers track visitation and identify trends.</li></ul>',
            icon: 'result-icon',
        },
        {
            header: 'User Review: French Creek Campground',
            bodyCopy:
                "<p>Thank you recreation.gov for making this campsite reservable!!  We never would have found it, or driven 1600 miles to visit, if we couldn't have made advance reservations!!!</p>",
            icon: 'review-icon',
        },
    ],
};

const VENUES = {
    id: 'venues',
    label: 'Venues',
    snowCategory: 'Venues',
    icon: 'venues-primary-icon',
    description: [
        `<p><strong>Venues</strong> allow advanced reservations for a specific time slot during a single day, for a selected event or activity within a physical location at participating facilities – providing a simple, integrated booking process that can be customized.</p>`,
    ],
    descriptionCont: [
        `<p>Examples of this service may include the ability for visitors to reserve a time slot to use a ballfield for a period of time, or book a time slot at a picnic shelter for a small family gathering for an afternoon.</p>`,
    ],
    section2: [
        {
            icon: 'clock-icon-venues-page',
            bullet: `Time slots are managed from a dedicated facility webpage on Recreation.gov, and visitors may select time slot(s) per day to use the reservable area or space. Managers can also schedule and manage recurring reservations.`,
        },
        {
            icon: 'cog-icon-venues-page',
            bullet: `Management tools for customizing event types and scheduling for each individual venue.`,
        },
        {
            icon: 'cash-icon-venues-page',
            bullet: `Choose from a variety of fee options including application fees, deposits, and fees applied per group, per person, or per vehicle. `,
        },
    ],
};

const SCAN = {
    id: 'scan',
    label: 'Scan & Pay',
    snowCategory: 'Scan',
    icon: 'scan-primary-icon',
    description: [
        `<p><strong>Scan & Pay</strong> allows visitors to pay for a first-come, first-served campsite or activity pass via a QR code on their mobile device. The visitor simply claims an open campsite or chooses their activity and scans the associated QR code via the Recreation.gov mobile app. Scan & Pay reduces cash handling and risk to the agency. Facilities can continue using the fee tube/cash payments if preferred.</p>`,
    ],
    section2: [
        {
            icon: 'nowifi-icon-scan-pay-page',
            bullet: `No Signal, No Problem - Even in remote areas with no mobile service, Scan & Pay allows visitors to pay offline and then process transactions once back online. A temporary confirmation ID is provided for verification.`,
        },
        {
            icon: 'cash-icon-scan-pay-page',
            bullet: `Ideal for unstaffed, partially staffed, or unconnected locations. Eliminates cash handling from traditional fee tubes.`,
        },
        {
            icon: 'r1s-icon-scan-pay-page',
            bullet: `Visitors are encouraged to install or update the Recreation.gov mobile app prior to their trip.`,
        },
    ],
    youtube: 'https://www.youtube.com/embed/ykVxcwk9CUg?cc_load_policy=1',
};

const PASS = {
    id: 'pass',
    label: 'Passes',
    snowCategory: 'Pass',
    icon: 'pass-primary-icon',
    description: [
        `<p>Whether your guests visit once or multiple times throughout the year, digital passes provide visitors with a streamlined online reservation and mobile validation process. You can purchase both site and activity passes through Recreation.gov.</p>`,
    ],
    middlesection: [
        {
            bullet: '<strong>Site Passes:</strong> These digital passes cover entrance, standard amenity (day use) or recreation fees at a single location. This is the best option when visitors plan to visit one site multiple times in a given time period.',
        },
        {
            bullet: `<strong>Activity Passes:</strong> These passes may be purchased in advance or on-site and cover fees related to a specific activity at one location like boating, fishing, mountain biking, shooting ranges, hiking and more.`,
        },
        {
            bullet: `<strong>America the Beautiful - The National Parks & Federal Recreational Lands Annual Pass (referred to as the Interagency Annual Pass):</strong> This pass is valid for one full year through the month of purchase and is the best option when visiting multiple sites that charge entrance or standard amenity fees.`,
        },
        {
            bullet: `<strong>Additional pass options</strong> are available through the USGS Store and include Senior, Access, Military, 4th Grade, and other passes.`,
        },
    ],
    section2: [
        {
            icon: 'pass-icon-pass-page',
            bullet: `Single-day, multi-day, weekly, seasonal, or annual pass types can be implemented.`,
        },
        {
            icon: 'card-icon-pass-page',
            bullet: `There is no limit to the number of passes that can be sold.`,
        },
        {
            icon: 'mobileqr-icon-pass-page',
            bullet: `Mobile Validation: Passes can be added to the visitor's mobile wallet and scanned via their mobile device.`,
        },
        {
            icon: 'mobilecash-icon-pass-page',
            bullet: `Offline Options: Visitors can pay by scanning a QR code upon arrival (first-come, first-served only).`,
        },
        {
            icon: 'moon-icon-pass-page',
            bullet: `May provide day use or overnight access.`,
        },
        {
            icon: 'pass2-icon-pass-page',
            bullet: `Digital passes improve compliance and reduce fraud.`,
        },
        {
            icon: 'discount-icon-pass-page',
            bullet: `Interagency Passes may cover the entrance fee (NPS) or provide a discounted ticket fee.`,
        },
    ],
    textSliderItems: [
        {
            quote: 'The Recreation.gov team was amazing, they were available to answer any question I had. They provided all the resources I would need. The one thing that worked well was how knowledgeable the Recreation Team was about the set-up process, and at troubleshooting any problems. The little hiccups we had were solved with in 30 minutes of making a call.  The team made everything easier from filling out the forms to testing the system.',
            author: 'Joseph Reyes,',
            location: 'Thomas Edison National Historical Park',
        },
    ],
    textSliderImage: [
        {
            path: passesImageSrc,
            alt: 'Man fishing on the bank of a river',
        },
    ],
    reviewMeasureSection: [
        {
            header: 'Key Data',
            bodyCopy:
                '<p>The switch to Recreation.gov translated to a SAVINGS OF <strong>$24,418</strong> ANNUALLY without a staff person having to perform this work.</p>',
            icon: 'result-icon',
        },
    ],
};

const TICKETS = {
    id: 'tickets',
    label: 'Tickets',
    snowCategory: 'Tickets',
    icon: 'ticket-primary-icon',
    description: [
        `<p>Tickets provide individuals or groups access to an organized tour, program, or event, which can be available for commercial users and/or educational groups.</p>`,
    ],

    section2: [
        {
            icon: 'ticket-icon-tickets-page',
            bullet: `Quota-based: There is a maximum number of tickets available per event/tour for each time-based instance.`,
        },
        {
            icon: 'qrcode-icon-tickets-page',
            bullet: `Digital tickets with scanning / QR code option available.`,
        },
        {
            icon: 'check-icon-tickets-page',
            bullet: `Digital tickets improve compliance and reduce fraud.`,
        },
        {
            icon: 'cash-icon-tickets-page',
            bullet: `Interagency Passes may cover the entrance fee (NPS) or provide a discounted ticket fee.`,
        },
    ],
    textSliderItems: [
        {
            quote: 'Transitioning the Keys Ranch tour to Recreation.gov has been one of the best decisions we have made. Better service to the public, savings in staff hours, and better communication of important need-to-know information.',
            author: 'Dave Larson,',
            location: 'Keys Ranch Tours at Joshua Tree National Park',
        },
    ],
    textSliderImage: [
        {
            path: ticketsImageSrc,
            alt: 'Group of people on a cave tour',
        },
    ],
    reviewMeasureSection: [
        {
            header: 'Ticketing Services',
            bodyCopy:
                '<p>Visitors can reserve tickets or times in advance to special events or experiences you have to offer. Some locations are in such high demand that a lottery is used for a chance to secure tickets.</p>',
            icon: 'result-icon',
        },
        {
            header: 'User Review: Haleakala National Park Summit Tours',
            bodyCopy:
                '<p>Reservation process was smooth, as was check-in.  Helpful information was provided on the website. The frigid temperatures are not exaggerated. The experience is spectacular.</p>',
            icon: 'review-icon',
        },
    ],
};

const TIMED_ENTRY = {
    id: 'timedentry',
    label: 'Timed Tickets',
    snowCategory: 'Timed Entry',
    icon: 'timed-entry-primary-icon',
    description: [
        `<p>Timed Reservation Tickets allow you to manage the number of vehicles accessing an area per day and/or time slot.</p>`,
        `<p><strong>NPS:</strong> Entry ticket allows visitors to reserve entry times and pay the entrance fee.</p>`,
        `<p><strong>BLM/USFS:</strong> Standard amenity tickets can manage the number of visitors and/or vehicles in an area and include payment for the fee.</p>`,
    ],
    section2: [
        {
            icon: 'car-icon-timed-entry-page',
            bullet: `Manage the number of vehicles allowed to enter in real-time to regulate visitation volumes, deliver a safe and positive visitor experience, and help protect natural and cultural resources.`,
        },
        {
            icon: 'ticket-icon-timed-entry-page',
            bullet: `Adjust the number of tickets available for specified dates and/or times to regulate visitation volume.`,
        },
        {
            icon: 'ticket2-icon-timed-entry-page',
            bullet: `Control how many tickets are released within a booking window (i.e., release 70% of tickets 1, 2, or 3 months in advance and 30% 48-hours in advance).`,
        },
        {
            icon: 'mobilecash-icon-timed-entry-page',
            bullet: `Reduce cash handling and in-person transactions with advanced reservations.`,
        },
    ],
    textSliderItems: [
        {
            quote: 'This [Timed Ticket] system has put us in a better position to meet our mission to protect, enhance, and improve Red Rock Canyon not only for our visitors today but for the generations to come.',
            author: 'Josh Travers,',
            location: 'Red Rock Canyon Assistant Field Manager',
        },
    ],
    textSliderImage: [
        {
            path: timedEntryImageSrc,
            alt: 'Desert landscape',
        },
    ],
    reviewMeasureSection: [
        {
            header: 'Key Data',
            bodyCopy:
                "<p>Red Rock Canyon's (RRC) implementation of Timed Tickets decreased entrance wait times by <strong>90%</strong> (from almost an hour to under 5 minutes).</p><p>In the first 5 months of RRC's Timed Ticket implementation, an average of <strong>84%</strong> of all timed entry tickets were sold online, thus reducing crowds and staffing needs while increasing revenue as visitors entered throughout the day.</p>",
            icon: 'result-icon',
        },
        {
            header: 'User Review: Red Canyon Scenic Drive',
            bodyCopy:
                '<p>With the 8-9:00 reservation there was no line at the entrance. We saw a park ranger a couple times throughout our visit keeping an eye on everything. The loop makes for great bicycling and jogging.</p>',
            icon: 'review-icon',
        },
    ],
};

const PERMITS = {
    id: 'permits',
    label: 'Permits',
    snowCategory: 'Permits',
    icon: 'permit-primary-icon',
    description: [
        `<p>Recreation.gov offers one integrated platform with multiple permit service types, each with innovative tools and a level of adaptability.</p>`,
        `<p>The types of permits that are sold through Recreation.gov are: Water-Based Entry Permits, Land-Based Entry Permits, Itinerary Permits, Day Use Permits, Vehicle Permits, and Hunting Permits.</p>`,
    ],
    middlesection: [
        {
            bold: 'Water-Based Entry Permits: ',
            bullet: `Includes permits required to access segment(s) of a river for day use or overnight trips where quota is only utilized on the reservation entry date.`,
        },
        {
            bold: `Land-Based Entry Permits: `,
            bullet: `Includes permits required to enter a specified wilderness area or zone for overnight trips where quota is only utilized on the reservation entry date.`,
        },
        {
            bold: `Itinerary Permits: `,
            bullet: `Includes permits required for overnight reservations (backcountry camping, water-based itinerary trip, etc.) with specific site selections (individual sites or zones) that utilize quota for each day/night of the reservation.`,
        },
        {
            bold: 'Day Use Permits: ',
            bullet: `Includes permits required for day use activities (day parking, day hiking, mountain biking, climbing/canyoneering, etc.) where reservations can only be made for a single day with quota to be utilized on the reservation date.`,
        },
        {
            bold: 'Vehicle Permits: ',
            bullet: `Includes permits required to access an area, zone, or parking lot/parking spaces for day-use, multi day-use, or annual access.`,
        },
        {
            bold: 'Hunting Permits: ',
            bullet: `Includes permits required for quota-based hunting activities for either open hunts that allow access to several areas for multiple dates or closed hunts that provide access to specific units for single dates.`,
        },
    ],
    section2: [
        {
            icon: 'permit-icon-permit-page',
            bullet: `Designed to facilitate the visitor's ability to secure a reservation to locations and activities that require a permit, the Recreation.gov reservation system provides a robust internal management system for managers seeking an intuitive digital permitting platform.`,
        },
        {
            icon: 'cog-icon-permit-page',
            bullet: `Manage daily operations with ease through one centralized and secure location.`,
        },
        {
            icon: 'check-icon-permit-page',
            bullet: `Responsibly steward quality recreation resources while simultaneously engendering trust and ease-of-mind with the public through a top-notch digital experience.`,
        },
        {
            icon: 'key-icon-permit-page',
            bullet: `Manage access to resources, experiences, activities, and events with quota limits.`,
        },
        {
            icon: 'lotteryIcon-icon-permit-page',
            bullet: `Distribute permits through a lottery for a fair and randomized system to ensure equal access to the recreation opportunity or special event.`,
        },
    ],
    textSliderItems: [
        {
            quote: 'The permit system on Recreation.gov is working just how we would have imagined. It has freed up a lot of our time on the phone and in the office to be able to focus on other projects and pressing matters… Customers are able to conveniently secure and pay for their reservation online, and even make modifications if needed. It has been wonderful!!',
            author: 'Todd Murdock,',
            location: 'FM at Westwater Canyon River, Bureau of Land Management, Utah',
        },
    ],
    textSliderImage: [
        {
            path: permitsImageSrc,
            alt: 'Person climbing a rock wall',
        },
    ],
    reviewMeasureSection: [
        {
            header: 'User Review:',
            bodyCopy:
                '<p>The system is easy to access. The required information is easy to enter. The required videos, even though I have seen them before, always adds some excitement after booking the reservations. Keep up the great work!</p>',
            icon: 'review-icon',
        },
    ],
};

const LOTTERY = {
    id: 'lottery',
    label: 'Lotteries',
    icon: 'lottery-primary-icon',
    snowCategory: 'Lotteries',
    description: [
        `<p>There are many incredibly popular sites, events and activities available through Recreation.gov, some of which require a lottery process to manage visitation when demand is greater than what can be accommodated.</p>`,
    ],
    middlesection: [
        {
            bold: '',
            bullet: `The Recreation.gov lottery process is based on a fair and randomized distribution system to ensure equal access to the recreation opportunity or special event. Many of these opportunities are in such high demand that access is limited due to space restrictions, to prevent damage to the natural landscape, and to preserve the visitor experience.`,
        },
        {
            bold: '',
            bullet: `A lottery application period is opened at a designated time for each recreation opportunity (Example: river permit, wilderness permit, special event, high-demand campground, etc.), and requires applicants to submit an online or call center application during this time frame. Successful and unsuccessful lottery applicants will be notified following the application period. Often, there are additional steps that the successful lottery winners must follow to secure and pay for the reservation.`,
        },
        {
            bold: '',
            bullet: `For each lottery application there is a service fee - whether the visitor is successful or not in securing a reservation through the lottery.`,
        },
    ],
    endSection: [
        {
            header: 'How random is the lottery process?',
            body: `<p>Each lottery is randomized by shuffling all the applications using the Fisher-Yates Shuffle, which produces an unbiased and random ordering of results. We also use a Cryptographically Secure Pseudo Random Number Generator (CSPRNG) to prevent an inadvertent bias in the lottery process. These random number generators are vetted to produce random numbers that cannot be predicted based upon past outputs, and they don't allow anyone to predict future or past numbers generated.</p>`,
        },
    ],
};

const DONATIONS = {
    id: 'donations',
    label: 'Donation Services',
    snowCategory: 'Donations',
    icon: 'donations-primary-icon',
    description: [
        `<p>Federal agencies are able to accept nominal donations by visitors to maintain areas for which people care. If donations are enabled for your agency, visitors to Recreation.gov will be offered the opportunity to make donations in support of recreation-related projects if they choose.</p>`,
    ],
    section2: [
        {
            icon: 'donations-icon-donations-page',
            bullet: `Visitors who willingly want to support their public lands have the option to donate money to a specific agency that hosts recreation opportunities.`,
        },
        {
            icon: 'distribute-icon-donations-page',
            bullet: `Agencies determine where donations will be distributed, either to the agency or to a field location associated with the transaction.`,
        },
        {
            icon: 'check-icon-donations-page',
            bullet: `The donations feature is available to all agencies and can be activated if or when they choose to participate. Contact your Agency Program Manager for more information.`,
        },
    ],
    textSliderItems: [
        {
            quote: 'Since the inception of the donation program, USACE has received donations at more than 160 lakes and rivers projects. These donations will help support the protection, improvement, restoration, and interpretation of natural resources, environmental features, recreation areas and facilities, and cultural resources.',
            author: 'Heather Burke,',
            location:
                'National Partnerships and Volunteer Program Manager, USACE Headquarters',
        },
    ],
    textSliderImage: [
        {
            path: donationsImageSrc,
            alt: 'Park staff assisting a young girl with her life jacket.',
        },
    ],
};

const INVENTORY_TYPES = [
    CAMPING,
    VENUES,
    SCAN,
    PASS,
    TICKETS,
    TIMED_ENTRY,
    PERMITS,
    LOTTERY,
    DONATIONS,
];

export default INVENTORY_TYPES;
