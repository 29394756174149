/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * Lottery.jsx
 * Created by Destin Frasier on 09/16/19
 */

import React from 'react';
import { FlexRow, FlexCol, Heading } from 'sarsaparilla';

import SiteMapItem from './SiteMapItem';

export default class Lottery extends React.PureComponent {
    render() {
        return (
            <FlexRow>
                <FlexCol>
                    <Heading headingLevel={2} appearance="h5">
                        Lottery
                    </Heading>
                    <ul className="nav-site-map-wrap">
                        <SiteMapItem name="Available" url="/lottery/available" />
                        <SiteMapItem name="How They Work" url="/lottery/how-they-work" />
                    </ul>
                </FlexCol>
            </FlexRow>
        );
    }
}
