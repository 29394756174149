/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import {
    Stack,
    Heading,
    StarRating,
    InlineBulletList,
    ImageUnavailable,
    useId,
} from 'sarsaparilla';

export default function RecommendationCard({
    id,
    className,
    heading,
    location,
    imageUrl,
    linkUrl,
    headingLevel = 3,
    rating,
    reviewCount,
    bonusItemsTitle,
    bonusItems = [],
    ...rest
}) {
    const autoId = useId(id);
    const BonusItemsTitleTag = `h${headingLevel + 1}`;

    return (
        <div
            data-component="RecommendationCard"
            className={cx('shared-recommendation-card', className)}
            {...rest}
        >
            <a id={autoId} href={linkUrl} aria-label={heading}>
                &nbsp;
            </a>

            <div data-shared-rec-card-top>
                <Stack space="sm">
                    <div data-shared-rec-card-image>
                        {!imageUrl && <ImageUnavailable isStretchy />}
                        {!!imageUrl && (
                            <div
                                role="img"
                                aria-label={heading}
                                className="image"
                                style={{
                                    backgroundImage: `url("${imageUrl}")`,
                                }}
                            />
                        )}
                    </div>
                    <div>
                        <Heading headingLevel={headingLevel} appearance="h6">
                            {heading}
                        </Heading>
                        {location && <div data-shared-rec-card-location>{location}</div>}
                    </div>
                    {!isEmpty(bonusItems) && (
                        <div data-shared-rec-card-bonus>
                            {bonusItemsTitle && (
                                <BonusItemsTitleTag className="bonus-heading">
                                    {bonusItemsTitle}
                                    <span>{': '}</span>
                                </BonusItemsTitleTag>
                            )}
                            <InlineBulletList>
                                {bonusItems.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </InlineBulletList>
                        </div>
                    )}
                </Stack>
            </div>
            {!isNil(rating) && (
                <div className="mt-one-and-half" data-shared-rec-card-bottom>
                    <StarRating rating={rating} reviewCount={reviewCount} />
                </div>
            )}
        </div>
    );
}

RecommendationCard.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    location: PropTypes.string,
    imageUrl: PropTypes.string,
    linkUrl: PropTypes.string,
    headingLevel: PropTypes.number,
    rating: PropTypes.number,
    reviewCount: PropTypes.number,
    heading: PropTypes.string,
    bonusItemsTitle: PropTypes.string,
    bonusItems: PropTypes.array,
};
