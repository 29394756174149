/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icons, Button, useFlags } from 'sarsaparilla';

const propTypes = {
    category: PropTypes.string,
    primaryContent: PropTypes.node,
    secondaryContent: PropTypes.node,
    fullContent: PropTypes.node,
    isExpandedDefault: PropTypes.bool,
};

function SearchFilterToggle({
    category = null,
    primaryContent = null,
    secondaryContent = null,
    fullContent = null,
    isExpandedDefault = false,
}) {
    const { useSearchToggles } = useFlags();
    const [isExpanded, setIsExpanded] = useState(isExpandedDefault);

    if (!useSearchToggles) {
        return (
            <>
                {primaryContent}
                {secondaryContent}
            </>
        );
    }

    return (
        <>
            {!isExpanded && primaryContent}
            {isExpanded && fullContent}
            <div className="search-filter-toggle-item">
                {!isExpanded && (
                    <Button
                        appearance="link"
                        iconBeforeElement={<Icons.IconChevronDown />}
                        onClick={() => setIsExpanded(true)}
                        clickTagCategory="Search"
                        clickTagAction={`Show More ${category}`}
                        clickTagLabel="Active Filters Section"
                    >
                        Show More {category}
                    </Button>
                )}
                {isExpanded && (
                    <Button
                        appearance="link"
                        iconBeforeElement={<Icons.IconChevronUp />}
                        onClick={() => setIsExpanded(false)}
                        clickTagCategory="Search"
                        clickTagAction={`Show Less ${category}`}
                        clickTagLabel="Active Filters Section"
                    >
                        Show Less {category}
                    </Button>
                )}
            </div>
        </>
    );
}

SearchFilterToggle.propTypes = propTypes;

export default SearchFilterToggle;
