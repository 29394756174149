/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { flatten, get, isEmpty, uniq } from 'lodash';
import { FiltersTypeProps, UiFiltersProps } from '../types';
import {
    INVENTORY_TYPES_CAMPING,
    INVENTORY_TYPES_DAY_USE,
    INVENTORY_TYPES_TICKETS,
    INVENTORY_TYPES_VENUES,
} from '../constants/filters/inventoryTypes';
import {
    CAMPSITE_CAMPING_TYPE,
    CAMPSITE_DAYUSE_TYPE,
    FLAT_CAMPSITE_FILTERS,
    FLAT_CAMPING_FILTERS,
    OCCUPANTS_TYPE,
    VEHICLE_LENGTH_TYPE,
    FG_FILTERS_GLOSARY,
} from '../constants/filters/camping';

export const getCount = (keys: Array<string>, uiFilters: { [key: string]: Object }) => {
    let count = 0;
    keys.forEach((item) => {
        count += Object.keys(uiFilters[item]).length;
    });
    return count;
};

export const getGroupCount = (
    keys: Array<string>,
    search: { [key: string]: { [key: string]: FiltersTypeProps } }
) => {
    let count = 0;
    const fgKeys = get(search, 'fg', []);
    // @ts-ignore
    fgKeys.forEach((item: string) => {
        if (keys.find((v) => item.includes(v))) {
            count += 1;
        }
    });
    return count;
};

const parseQueryFgParams = (
    uiFilters: UiFiltersProps,
    query?: { [key: string]: string | string[] }
) => {
    const parsedFilters: string[] = [];
    const campingUiFilters = {
        ...uiFilters?.campsiteEquipment,
        ...uiFilters?.campsiteTypes,
        ...uiFilters?.campsiteUseTypes,
        ...uiFilters?.campsiteTypes,
        ...uiFilters?.campsiteAmenities,
        ...uiFilters?.campsiteElectricityHookup,
        ...uiFilters?.campsiteVehicleLength,
    };
    if (!isEmpty(campingUiFilters)) {
        Object.keys(campingUiFilters).forEach((item) => {
            const current = FLAT_CAMPING_FILTERS.find((v) => v.legacyKey === item);
            if (current) {
                parsedFilters.push(current.key);
            }
        });
    }
    if (query && !isEmpty(query)) {
        flatten(Object.values(query)).forEach((item) => {
            const current = FLAT_CAMPING_FILTERS.find(
                (v) =>
                    (item && v.value.toLowerCase() === item?.toLowerCase()) ||
                    v.key.toLowerCase() === item?.toLowerCase()
            );
            if (current) {
                parsedFilters.push(current.key);
            }
        });
        if (query[OCCUPANTS_TYPE.key]) {
            parsedFilters.push(`${OCCUPANTS_TYPE.key}:${query[OCCUPANTS_TYPE.key]}`);
        }
        if (query[VEHICLE_LENGTH_TYPE.key]) {
            parsedFilters.push(
                `${VEHICLE_LENGTH_TYPE.key}:${query[VEHICLE_LENGTH_TYPE.key]}`
            );
        }
    }
    return parsedFilters;
};

export const parseCampingFilters = (
    search: {
        uiFilters: UiFiltersProps;
        fg: Array<string>;
    },
    query?: { [key: string]: string | string[] }
) => {
    const fgParams = get(search, 'fg', []);
    const uiFilters = search?.uiFilters;
    let parsedFilters: Array<string> = [];
    const tentParams = ['campsite-type-tent-only', 'campsite-type-standard'];
    const rvParams = ['campsite-equipment-rv', 'campsite-equipment-trailer'];
    const lodgingParams = [
        'campsite-type-cabin',
        'campsite-type-lookout',
        'campsite-type-yurts',
    ];

    if (
        tentParams.filter((item) => uiFilters?.campsiteTypes[item]).length ===
        tentParams.length
    ) {
        parsedFilters = [INVENTORY_TYPES_CAMPING, 'tent'];
    }
    if (
        rvParams.filter((item) => uiFilters?.campsiteEquipment[item]).length ===
        rvParams.length
    ) {
        parsedFilters = [INVENTORY_TYPES_CAMPING, 'rmt'];
    }
    if (
        lodgingParams.filter((item) => uiFilters?.campsiteTypes[item]).length ===
        lodgingParams.length
    ) {
        parsedFilters = [INVENTORY_TYPES_CAMPING, 'lodging'];
    }
    if (!isEmpty(uiFilters?.inventoryTypes)) {
        Object.keys(uiFilters?.inventoryTypes).forEach((item) => {
            if (item === 'tours') {
                parsedFilters.push(INVENTORY_TYPES_TICKETS);
            } else if (item === 'venuereservations') {
                parsedFilters.push(INVENTORY_TYPES_VENUES);
            } else {
                parsedFilters.push(item);
            }
        });
    }

    const parsedQueryFg = parseQueryFgParams(uiFilters, query);
    parsedFilters = [...parsedFilters, ...fgParams, ...parsedQueryFg].map((item) => {
        if (item === CAMPSITE_DAYUSE_TYPE) {
            return INVENTORY_TYPES_DAY_USE;
        }
        if (item === CAMPSITE_CAMPING_TYPE) {
            return INVENTORY_TYPES_CAMPING;
        }
        return item;
    });
    return uniq(parsedFilters);
};

export const parseCampsiteFilters = (fg: Array<string>) => {
    const params: Array<string> = [];
    const keys = FLAT_CAMPSITE_FILTERS.map((item) => item.key);
    if (fg?.length > 0) {
        fg.forEach((item: string) => {
            if (
                keys.find(
                    (v) =>
                        item.includes(v) &&
                        item !== INVENTORY_TYPES_CAMPING &&
                        item !== INVENTORY_TYPES_DAY_USE
                )
            ) {
                params.push(item);
            } else if (item === INVENTORY_TYPES_CAMPING) {
                params.push(CAMPSITE_CAMPING_TYPE);
            } else if (item === INVENTORY_TYPES_DAY_USE) {
                params.push(CAMPSITE_DAYUSE_TYPE);
            }
        });
    }
    return params;
};

export const toggleFilterGroups = (
    item: FiltersTypeProps,
    search: { fg: Array<string> }
) => {
    let searchFilterGroups: Array<string> = [];
    if (search?.fg) {
        const fgParams = get(search, 'fg', []);
        searchFilterGroups = [...fgParams];
    }

    const removeFilter = searchFilterGroups.find((key) => key === item.key);
    if (removeFilter) {
        searchFilterGroups = searchFilterGroups.filter((key) => key !== item.key);
    } else {
        searchFilterGroups = [...searchFilterGroups, item.key];
    }
    return searchFilterGroups;
};

export const getFgActiveFiltersForGa = (items: string[]) => {
    let params: Object = {};
    if (items.length > 0) {
        items.forEach((value) => {
            Object.entries(FG_FILTERS_GLOSARY).forEach((item) => {
                const [key, values] = item;
                const current = values.find((v) => value.includes(v.key));
                if (current) {
                    const acc: string = get(params, key, '');
                    params = {
                        ...params,
                        [key]: isEmpty(acc) ? value : `${acc},${value}`,
                    };
                }
            });
        });
    }

    let activeFilters = '';

    if (!isEmpty(params)) {
        Object.entries(params).forEach((item) => {
            const [key, value] = item;
            activeFilters += `|${key}:${value}`;
        });
    }

    return activeFilters;
};
