/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { ContentContainer, Heading, FlexRow, FlexCol, Icons } from 'sarsaparilla';

import image from './images/field-personnel-section/field-personnel-bg.webp';
import imageSm from './images/field-personnel-section/field-personnel-bg-sm.webp';
import imageMd from './images/field-personnel-section/field-personnel-bg-md.webp';

const hubDetails = [
    {
        icon: <Icons.IconFolderFile size="md" />,
        title: 'Reports',
        listItems: [
            'Provide operational & financial reports which can be scheduled in advanced or generated on-demand.',
        ],
    },
    {
        icon: <Icons.IconWarning size="md" />,
        title: 'Alerts and Communications',
        listItems: [
            'Provide communication tools and alerts so staff can notify visitors and other staff in real time about important updates.',
        ],
    },
    {
        icon: <Icons.IconDigitalPayment size="md" />,
        title: 'Digital Payment Solutions',
        listItems: [
            'Streamline payment solutions and reduce cash handling with secure financial collection, distribution, and reporting.',
        ],
    },
    {
        icon: <Icons.IconCogHand size="md" />,
        title: 'Facility Management',
        listItems: [
            'Update content, reservations, inventory, and business rules from one central location.',
        ],
    },
];

export default function FieldPersonnelSection() {
    return (
        <section className="nav-ni-field-personnel-section-wrap">
            <ContentContainer className="my-0 nav-ni-field-personnel-section-container">
                <FlexRow className="nav-ni-field-personnel-section-content-wrap">
                    <FlexCol md={6} className="my-5 my-md-10">
                        <Heading
                            headingLevel={2}
                            appearance="h4"
                            className="nav-ni-field-personnel-section-heading mb-1"
                        >
                            Field Personnel Have the Controls
                        </Heading>

                        <Heading
                            headingLevel={3}
                            appearance="h5"
                            className="nav-ni-field-personnel-section-sub-heading"
                        >
                            The Hub
                        </Heading>

                        {hubDetails.map((hubDetail, index) => (
                            <div
                                className="nav-ni-field-personnel-section-content-list-item"
                                key={index}
                            >
                                <div className="nav-ni-field-personnel-section-icon-wrap">
                                    {hubDetail.icon}
                                </div>
                                <div>
                                    <Heading headingLevel={4} appearance="h6">
                                        {hubDetail.title}
                                    </Heading>

                                    <ul>
                                        {hubDetail.listItems.map((listItem, i) => (
                                            <li key={i}>{listItem}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </FlexCol>

                    <FlexCol
                        md={6}
                        className="nav-ni-field-personnel-section-content-picture-wrap"
                    >
                        <picture>
                            <source
                                media="(max-width: 576px)"
                                srcSet={imageSm}
                                type="image/webp"
                            />
                            <source
                                media="(max-width: 768px)"
                                srcSet={imageMd}
                                type="image/webp"
                            />
                            <img
                                src={image}
                                alt="Computer displaying the Recreation.gov Hub"
                                height="auto"
                                width="100%"
                            />
                        </picture>
                    </FlexCol>
                </FlexRow>
            </ContentContainer>
        </section>
    );
}
