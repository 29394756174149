/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

export function getTargetUri(
    item: any,
    cmsDomain = process.env.SITE_ROOT,
    kbDomain = process.env.SN_KB_EXT_URL
) {
    let targetUri;
    if (item.entity_type === 'campground' && item.reservable) {
        targetUri = `/camping/campgrounds/${item.entity_id}`;
    } else if (item.campsite_id) {
        targetUri = `/camping/campsites/${item.campsite_id}`;
    } else if (item.entity_type === 'permit') {
        targetUri = `/permits/${item.entity_id}`;
    } else if (item.entity_type === 'vehiclepermit') {
        targetUri = `/vehiclepermits/${item.entity_id}`;
    } else if (item.entity_type === 'tour') {
        const parentId = item.parent_entity_id ? item.parent_entity_id : item.parent_id;
        targetUri = `/ticket/${parentId}/ticket/${item.entity_id}`;
    } else if (item.entity_type === 'ticketfacility') {
        targetUri = `/ticket/facility/${item.entity_id}`;
    } else if (item.entity_type === 'timedentry_tour') {
        const parentId = item.parent_entity_id ? item.parent_entity_id : item.parent_id;
        targetUri = `/timed-entry/${parentId}/ticket/${item.entity_id}`;
    } else if (item.entity_type === 'timedentry') {
        targetUri = `/timed-entry/${item.entity_id}`;
    } else if (item.entity_type === 'recarea') {
        targetUri =
            process.env.GATEWAY_VERSION === 'v2'
                ? `/gateways/v2/${item.entity_id}`
                : `/gateways/${item.entity_id}`;
    } else if (item.entity_type === 'pass') {
        targetUri = `/sitepass/${
            item.park_pass_facility_id ? item.park_pass_facility_id : item.entity_id
        }`;
    } else if (item.entity_type === 'activitypass') {
        targetUri = `/activitypass/${item.entity_id}`;
    } else if (item.entity_type === 'treepermit') {
        targetUri = `/tree-permits/${item.entity_id}`;
    } else if (item.entity_type === 'venuereservations') {
        targetUri = `/venues/${item.entity_id}`;
    } else if (item.entity_type === 'venuereservations_venue') {
        const parentId = item.parent_entity_id ? item.parent_entity_id : item.parent_id;
        targetUri = `/venues/${parentId}/details/${item.entity_id}`;
    } else if (item.entity_type === 'program') {
        targetUri = `/programs/${item.entity_id}`;
    } else if (item.entity_type === 'kb') {
        // ServiceNow knowledge base
        targetUri = `${kbDomain}?id=kb_article&sys_id=${item.entity_id}`;
    } else if (item.entity_type === 'cms') {
        // navigate to CMS article - the Drupal way
        const name = item.name.toLowerCase().split(' ').join('-');
        const type = item.content_type.toLowerCase().split('_').join('-');
        targetUri = `${cmsDomain}/articles/${type}/${name}/${item.entity_id}`;
    } else if (
        item.entity_type === 'page' ||
        item.entity_type === 'giftcard' ||
        item.entity_type === 'merchandise'
    ) {
        // page
        targetUri = item.url;
    } else {
        targetUri =
            process.env.POI_VERSION === 'v2'
                ? `/poi/${item.entity_id}`
                : `/camping/poi/${item.entity_id}`;
    }

    return targetUri;
}
