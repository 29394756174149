/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'lodash';
import { NumberField } from 'sarsaparilla';

export default class SearchFilterOccupants extends React.Component {
    static propTypes = {
        uiFilters: PropTypes.object,
        onFilterChange: PropTypes.func,
        onFilterRemove: PropTypes.func,
        isRequired: PropTypes.bool,
        onApplyFilters: PropTypes.func,
        defaultGroupSize: PropTypes.bool,
    };

    static defaultProps = {
        isRequired: false,
        defaultGroupSize: false,
    };

    static displayName = 'SearchFilterOccupants';

    componentDidMount() {
        if (this.props.defaultGroupSize) {
            const value = get(
                this.props,
                'uiFilters.occupants.filters[0].filterValue',
                ''
            );
            const parts = value.split('>=');
            if (parts.length <= 1) {
                this.handleChange(1);
            }
        }
    }

    handleChange = (value) => {
        // create/update a range filter value
        const filterOption = {
            key: 'occupants',
            filters: [
                {
                    attribute: 'att_max_num_of_people',
                    filterValue: `>=${value}`,
                },
                {
                    attribute: 'att_min_num_of_people',
                    filterValue: `<=${value}`,
                },
            ],
        };
        if (value !== '' && value !== '0' && value !== 0) {
            if (this.props.onFilterChange) {
                this.props.onFilterChange('occupants', filterOption);
            }
        } else if (this.props.onFilterRemove) {
            this.props.onFilterRemove('occupants', filterOption);
        }

        this.runSearch();
    };

    runSearch = () => {
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    };

    render() {
        const selections = this.props.uiFilters.occupants;
        let value = 0;
        if (
            selections.occupants &&
            selections.occupants.filters &&
            selections.occupants.filters.length > 0
        ) {
            const filter = selections.occupants.filters[0];
            const val = filter.filterValue;
            const parts = val.split('>=');
            if (parts.length > 1) {
                value = parseInt(parts[1], 10);
            }
        }

        return (
            <div className="rec-search-filters-number-field-wrap">
                <NumberField
                    visualLabel="Group Size"
                    units="guests"
                    min={0}
                    max={99}
                    id="occupants"
                    value={value}
                    isRequired={this.props.isRequired}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                    onBlur={this.onBlur}
                />
            </div>
        );
    }
}
