/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom-v5-compat';
import isNil from 'lodash/isNil';

import { Box, Stack, Heading, ImageUnavailable, useId } from 'sarsaparilla';

export default function MobileSearchResultCard({
    id: userId,
    linkUrl,
    imageUrl,
    heading,
    headingLevel = 2,
    children,
    className,
    isLinkToSameService = true,
    ...rest
}) {
    const id = useId(userId);

    return (
        <div data-component="MobileSearchResultCard" className={className} {...rest}>
            {isLinkToSameService ? (
                <Link id={id} to={linkUrl} aria-label={heading}>
                    &nbsp;
                </Link>
            ) : (
                <a id={id} href={linkUrl} aria-label={heading}>
                    &nbsp;
                </a>
            )}

            <div className="with-sidebar sarsa-shadow-3">
                <div className="sidebar">
                    {isNil(imageUrl) && <ImageUnavailable isStretchy />}
                    {!isNil(imageUrl) && (
                        <div
                            role="img"
                            aria-label={heading}
                            className="image"
                            style={{
                                backgroundImage: `url("${imageUrl}")`,
                            }}
                        />
                    )}
                </div>
                <div className="not-sidebar">
                    <div className="details">
                        <Box padding="sm">
                            <Stack space="sm">
                                <Heading headingLevel={headingLevel} appearance="h6">
                                    {heading}
                                </Heading>
                                <div>{children}</div>
                            </Stack>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    );
}

MobileSearchResultCard.propTypes = {
    id: PropTypes.string,
    linkUrl: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    headingLevel: PropTypes.number,
    className: PropTypes.string,
    actionButtonText: PropTypes.string,
    children: PropTypes.node,

    /** If true, renders react router Links to preserve redux state */
    isLinkToSameService: PropTypes.bool,
};
