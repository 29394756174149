/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * ArticleSource.jsx
 * Created by Destin Frasier on 03/31/20.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { DisplayHtmlContent } from 'sarsaparilla';

export default class ArticleSource extends React.PureComponent {
    static propTypes = {
        articleLocationSource: PropTypes.string,
    };

    static defaultProps = {
        articleLocationSource: '',
    };

    render() {
        if (this.props.articleLocationSource) {
            return (
                <div className="nav-article-location-source">
                    <DisplayHtmlContent html={this.props.articleLocationSource} />
                </div>
            );
        }

        return null;
    }
}
