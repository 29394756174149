/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import { StyledModal, useWindowSize, ErrorBoundary } from 'sarsaparilla';

function MapViewWithCards({ cards = [], mapComponent, isOpen, onClose, onMapClick }) {
    const { height } = useWindowSize();

    // Set the required references to get the list scroll position and programatically scroll it
    const cardsView = useRef(null);
    const cardWidth = useRef(0);

    const cardElementRef = useCallback(
        (node) => {
            if (isOpen && node) {
                cardWidth.current = node.getBoundingClientRect().width;
            }
        },
        [isOpen]
    );

    if (!isOpen) {
        return null;
    }

    return (
        <StyledModal
            size="md"
            isOpen={isOpen}
            onRequestClose={onClose}
            className="shared-map-view-with-cards"
            overlayClassName="shared-map-view-with-cards-overlay"
        >
            <div className="shared-map-view-with-cards-map">
                <ErrorBoundary
                    hasDefaultWrapperClass={false}
                    fallbackElement={
                        <div>
                            We apologize but maps for this location are not available at
                            this time.
                        </div>
                    }
                >
                    {mapComponent}
                </ErrorBoundary>
            </div>

            <div className="shared-ios-hack-thing" style={{ height }}>
                <div className="shared-map-view-with-cards-cards">
                    <ul
                        className="shared-map-view-with-cards-ul"
                        ref={cardsView}
                        onScroll={debounce(() => {
                            if (cardsView.current) {
                                const horizontalScroll = cardsView.current.scrollLeft;
                                const selectedItem = Math.floor(
                                    horizontalScroll / cardWidth.current
                                );
                                const id = cards[selectedItem]?.key;
                                if (id) onMapClick(id);
                            }
                        }, 500)}
                    >
                        <div aria-hidden="true" className="shared-map-view-spacer" />
                        {cards.map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    ref={index === 0 ? cardElementRef : null}
                                    className="shared-map-view-with-cards-li"
                                >
                                    <div className="shared-map-view-li-inner">
                                        <div className="shared-map-view-card-wrap">
                                            {item}
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                        <div aria-hidden="true" className="shared-map-view-spacer" />
                    </ul>
                </div>
            </div>
        </StyledModal>
    );
}

MapViewWithCards.propTypes = {
    cards: PropTypes.arrayOf(PropTypes.object),
    mapComponent: PropTypes.node,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onMapClick: PropTypes.func,
};

export default MapViewWithCards;
