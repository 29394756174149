/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * MobileSignUp.jsx
 * Created by Hassan Riaz on 01/21/20
 */

import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isNil } from 'lodash';

import {
    recaptchav3Lib,
    CreateAccountForm,
    CreateAccountConfirmation,
    LoginActions,
    HelmetWrapper,
} from 'sarsaparilla';

const alreadyExistsErr = 'An account already exists for this email address';
const challengeRequired = 'additional challenge required';

export class MobileSignUpContainer extends React.Component {
    static propTypes = {
        accountServiceEnabled: PropTypes.bool,
        verification: PropTypes.string,
        auth: PropTypes.object,
    };

    state = {
        firstName: '',
        lastName: '',
        email: '',
        cellPhone: '',
        errorMessage: '',
        verification: '',
        additionalChallengeRequired: false,
        completed: false,
        isLoading: false,
    };

    componentDidUpdate(prevProps) {
        // You may call setState() immediately in componentDidUpdate() but note that it must be wrapped in a condition
        if (this.props.verification !== prevProps.verification) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                verification: this.props.verification,
            });
        }
    }

    confirmAccount = (account) => {
        this.setState(
            {
                email: account.email,
                firstName: account.firstName,
                lastName: account.lastName,
                cellPhone: account.cellPhone,
                optIn: account.optIn,
            },
            () => {
                this.sendAccountConfirmationEmail();
            }
        );
    };

    sendAccountConfirmationEmail() {
        // To avoid bot detection as much as possible, we are using the constants EAST and WEST
        // to denote which version of CAPTCHA should be used. We immediately begin with V3,
        // and fall back to V2 if V3 fails.
        const regionEast = 'EAST'; // Disguised Value for V3
        const regionWest = 'WEST'; // Disguised Value for V2
        const action = 'initializeRegistration'; // Action being performed

        const url = `${process.env.API}/accounts/registration`;

        if (this.state.additionalChallengeRequired) {
            // If V3 failed, we will attempt V2
            const recaptcha = {
                section: action, // Action
                code: this.state.verification, // V2 Captcha Token
                region: regionWest, // Captcha Version (V2)
            };

            this.setState({ isLoading: true });

            axios
                .post(url, {
                    email: this.state.email,
                    first_name: this.state.firstName,
                    last_name: this.state.lastName,
                    cell_phone: this.state.cellPhone,
                    opt_in: this.state.optIn,
                    system: recaptcha,
                })
                .then(() => {
                    this.setState({
                        errorMessage: '',
                        completed: true,
                    });
                })
                .catch((error) => {
                    if (error.response.data.error === challengeRequired) {
                        this.setState({
                            additionalChallengeRequired: true,
                        });
                    } else {
                        let message = 'An error has occurred, please try again later.';
                        if (error?.response?.data?.error === 'invalid cellphone') {
                            message =
                                'The phone number you provided does not appear to be valid.';
                        }
                        if (error.response.data.error === alreadyExistsErr) {
                            message =
                                'You look familiar! This email already has an account associated with it. Please log in with this email.';
                        }
                        this.setState({
                            errorMessage: message,
                        });
                    }
                })
                .finally(() => {
                    this.setState({ isLoading: false });
                });
        } else {
            // Initial Attempt, use V3
            recaptchav3Lib.getRecaptchaV3(
                process.env.RECAPTCHAV3_SITE_KEY,
                action,
                (res) => {
                    this.setState({ isLoading: true });

                    const recaptcha = {
                        section: action, // Action
                        code: !isNil(res.error) ? '' : res.token, // V3 Captcha Token
                        region: regionEast, // Captcha Version (V3)
                    };

                    axios
                        .post(url, {
                            email: this.state.email,
                            first_name: this.state.firstName,
                            last_name: this.state.lastName,
                            cell_phone: this.state.cellPhone,
                            opt_in: this.state.optIn,
                            system: recaptcha,
                        })
                        .then(() => {
                            this.setState({
                                errorMessage: '',
                                completed: true,
                            });
                        })
                        .catch((error) => {
                            if (error.response.data.error === challengeRequired) {
                                this.setState({
                                    additionalChallengeRequired: true,
                                });
                            } else {
                                let message =
                                    'An error has occurred, please try again later.';
                                if (
                                    error?.response?.data?.error === 'invalid cellphone'
                                ) {
                                    message =
                                        'The phone number you provided does not appear to be valid.';
                                }
                                if (error.response.data.error === alreadyExistsErr) {
                                    message =
                                        'You look familiar! This email already has an account associated with it. Please log in with this email.';
                                }
                                this.setState({
                                    errorMessage: message,
                                });
                            }
                        })
                        .finally(() => {
                            this.setState({ isLoading: false });
                        });
                }
            );
        }
    }

    render() {
        let disableAccountFeatures = false;
        if (this.props.auth.accountServiceStatusUpdated) {
            // We're using this boolean to set disabled={true/false} on form elements,
            // so let's coerce it so we can use it in that manner
            disableAccountFeatures = !this.props.auth.accountServiceEnabled;
        }

        let errorText = null;

        if (this.state.errorMessage) {
            errorText = this.state.errorMessage;
        }

        let content = null;

        if (this.state.completed) {
            content = (
                <CreateAccountConfirmation
                    email={this.state.email}
                    sendAccountConfirmationEmail={this.sendAccountConfirmationEmail}
                />
            );
        } else {
            content = (
                <CreateAccountForm
                    disableAccountFeatures={disableAccountFeatures}
                    disableSignUpButton={
                        this.state.additionalChallengeRequired &&
                        this.state.verification === ''
                    }
                    errorText={errorText}
                    confirmAccount={this.confirmAccount}
                    additionalChallengeRequired={this.state.additionalChallengeRequired}
                    isLoading={this.state.isLoading}
                />
            );
        }

        return (
            <div role="main">
                <HelmetWrapper title="Sign up" />
                <div className="rec-mobile-sign-up">
                    <div className="rec-modal-content-wrap mt-6 mx-3 mx-sm-0">
                        {content}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        verification: state.recaptcha.verification,
        auth: state.auth,
    }),
    (dispatch) => bindActionCreators(LoginActions, dispatch)
)(MobileSignUpContainer);

// cSpell:ignore RECAPTCHAV
