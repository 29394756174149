/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * WhatsNew.jsx
 * Created by Destin Frasier on 06/26/20
 */

import React, { useState, useEffect } from 'react';
import {
    ContentContainer,
    HeroImageSectionFront,
    FlexRow,
    FlexCol,
    SearchActions,
    FeatureFlag,
    Spacer,
    Breadcrumbs,
    Pagination,
} from 'sarsaparilla';
import { WHATS_NEW_EXPLAINER_TEXT } from 'site-kit';

import Filter from '../../components/tertiary/whatsNew/Filter';
import Sort from '../../components/tertiary/whatsNew/Sort';
import Items from '../../components/tertiary/whatsNew/Items';
import TreePermitContainer from '../homepage/TreePermitContainer';

const ALL_FILTERS = ['camping', 'dayuse', 'tickettours', 'permits']; //, 'pass'];
const ITEMS_PER_PAGE = 36;

export default function WhatsNew() {
    const whatsNewRef = React.useRef(null);
    const [items, setItems] = useState([]);
    const [aggregations, setAggregations] = useState({});
    const [filters, setFilters] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, setSort] = useState('new');
    const [hasErrors, setHasErrors] = useState(false);

    const totalPages = React.useMemo(() => {
        return Math.ceil(total / ITEMS_PER_PAGE);
    }, [total]);

    useEffect(() => {
        const fetchItems = () => {
            if (items.length === 0) {
                setLoading(true);
            }
            // fetch search results with applied filters
            SearchActions.fetchNearbyInventoryLocations(
                null,
                null,
                filters.length === 0 ? ALL_FILTERS : filters,
                36,
                false,
                sort,
                [`go_live_date_dt>NOW-3MONTHS`],
                (currentPage - 1) * 36,
                []
            )
                .then((result) => {
                    if (Array.isArray(result?.data?.results)) {
                        setItems(result?.data?.results);
                    } else {
                        setItems([]);
                    }
                    setTotal(result?.data?.total);
                    setLoading(false);
                })
                .catch(() => {
                    setHasErrors(true);
                    setLoading(false);
                });
            // fetch aggregations for filters (without applying filters)
            SearchActions.fetchNearbyInventoryLocations(
                null,
                null,
                ALL_FILTERS,
                0,
                false,
                sort,
                [`go_live_date_dt>NOW-3MONTHS`],
                0,
                ['entity_type', 'campsite_type_of_use']
            )
                .then((result) => {
                    if (result?.data?.aggregations) {
                        setAggregations(result?.data?.aggregations);
                    } else {
                        setAggregations({});
                    }
                })
                .catch(() => {
                    setHasErrors(true);
                });
        };

        fetchItems();
    }, [sort, currentPage, filters, items.length]);

    if (hasErrors) {
        return null;
    }

    // Change page
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);

        if (whatsNewRef.current) {
            whatsNewRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    const sortOnChange = (event) => {
        setCurrentPage(1);
        setSort(event?.target?.value);
    };

    const onApplyFilters = (newFilters) => {
        setCurrentPage(1);
        setFilters(newFilters);
    };

    return (
        <main className="nav-whats-new-page">
            <HeroImageSectionFront
                title={`What's New on ${process.env.SITE_NAME}`}
                text={WHATS_NEW_EXPLAINER_TEXT}
                backgroundImagePath="/img/whats-new/hero/whats-new-hero"
                backgroundImageAltText="View of a mountain range within Zion National Park"
                backgroundImageColorOverlay="#1C1D1F"
                backgroundImageColorOverlayOpacity={0.4}
                className="mb-3"
            />

            <ContentContainer className="mb-5">
                <FlexRow id="whats-new-top" className="mb-3" ref={whatsNewRef}>
                    <FlexCol md="variable">
                        <Breadcrumbs
                            path={[{ title: 'Home', url: '/' }, { title: "What's New" }]}
                        />
                    </FlexCol>
                </FlexRow>

                <section>
                    <FlexRow id="whats-new-top" className="mb-3">
                        <FlexCol md="variable">
                            <Filter
                                aggregations={aggregations}
                                selectedFilters={filters}
                                onApply={onApplyFilters}
                            />
                        </FlexCol>
                        <FlexCol>
                            <Sort onChange={sortOnChange} />
                        </FlexCol>
                    </FlexRow>

                    <Items items={items} isLoading={loading} tag={'new'} />

                    {!loading && totalPages > 0 ? (
                        <Pagination
                            pages={totalPages}
                            activePage={currentPage - 1}
                            onChange={(pageIndex) => paginate(pageIndex + 1)}
                        />
                    ) : null}
                </section>

                {/* Added a flag to hide / show the Tree Permits promo banner  */}
                <FeatureFlag flag="treePermitsHomepagePromo">
                    <Spacer size="lg" />
                    <TreePermitContainer />
                </FeatureFlag>
            </ContentContainer>
        </main>
    );
}

// cSpell:ignore dayuse, tickettours
