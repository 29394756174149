/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { size } from 'lodash';
import { FlexRow, FlexCol, TextBlock, Spinner } from 'sarsaparilla';
import { SearchPagination } from 'ui-search';
import { HomepageCard } from './HomepageCard';
import * as mapActions from '../../../actions/mapActions';

export function HomepageSearchResult({ mapInventory, inventoryIsLoaded }) {
    const PAGINATION_SIZE = 5;
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);

    const onPageChange = (newPage) => {
        setPage(newPage);
    };

    const paginatedInventory = mapInventory.slice(
        PAGINATION_SIZE * (page - 1),
        Math.min(size(mapInventory), PAGINATION_SIZE * page)
    );

    const onCardOverEvent = (feature) => {
        dispatch(mapActions.selectItem(feature));
    };

    return (
        <>
            {!inventoryIsLoaded && (
                <div
                    className="nav-search-loading-status"
                    role="alert"
                    aria-live="assertive"
                >
                    <Spinner screenReaderText="" size="md" color="brand" />
                    <p>Loading inventory data</p>
                </div>
            )}

            {!mapInventory.length && inventoryIsLoaded && (
                <TextBlock className="nav-search-text" width="sm" alignX="left">
                    No results were found in this area.
                </TextBlock>
            )}

            {mapInventory && !!mapInventory.length && (
                <FlexRow hasGutters={false} className="nav-map-search-result-wrapper">
                    <FlexCol>
                        <FlexRow
                            hasGutters={false}
                            className="nav-map-search-result-container"
                        >
                            {paginatedInventory.map((item) => {
                                return (
                                    <FlexCol
                                        xs={12}
                                        className="nav-map-search-result-card-wrapper"
                                        key={`card_${item.id}`}
                                    >
                                        <HomepageCard
                                            item={item}
                                            onCardOver={onCardOverEvent}
                                        />
                                    </FlexCol>
                                );
                            })}
                        </FlexRow>

                        <FlexRow className="nav-map-search-pagination">
                            <SearchPagination
                                start={(page - 1) * PAGINATION_SIZE}
                                pageSize={PAGINATION_SIZE}
                                total={size(mapInventory)}
                                onPage={onPageChange}
                            />
                        </FlexRow>
                    </FlexCol>
                </FlexRow>
            )}
        </>
    );
}

HomepageSearchResult.propTypes = {
    mapInventory: PropTypes.array,
    inventoryIsLoaded: PropTypes.bool,
};

HomepageSearchResult.defaultProps = {
    mapInventory: [],
};
