/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox, Heading } from 'sarsaparilla';
import {
    CAMPSITE_EQUIPMENT_FILTERS,
    PRIMARY_CAMPSITE_EQUIPMENT_FILTERS_IDS,
} from '../constants';
import SearchFilterToggle from './SearchFilterToggle';
import { groupFilters } from '../utils/utils';

export default class SearchFilterCampsiteEquipment extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        headingLevel: PropTypes.number,
        uiFilters: PropTypes.object,
        aggregations: PropTypes.object,
        onFilterToggle: PropTypes.func,
        onApplyFilters: PropTypes.func,
        filterEmptyFilters: PropTypes.bool,
    };

    static displayName = 'SearchFilterCampsiteEquipment';

    handleChange(event, item) {
        if (this.props.onFilterToggle) {
            this.props.onFilterToggle('campsiteEquipment', item);
        }
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    processAggregations(searchAggregations) {
        const counts = {};
        // find aggregated counts for item attribute
        CAMPSITE_EQUIPMENT_FILTERS.forEach((item) => {
            const filter = item.filters[0];
            const attribute = filter.attribute.replaceAll(`~`, '');
            counts[attribute] = {};
            if (
                searchAggregations.campsiteEquipment &&
                searchAggregations.campsiteEquipment[attribute]
            ) {
                const aggs = searchAggregations.campsiteEquipment[attribute];
                for (let i = 0; i < aggs.length; i++) {
                    counts[attribute][aggs[i].name] = aggs[i].count;
                }
            }
        });

        // aggregate comma separate filter counts into one
        CAMPSITE_EQUIPMENT_FILTERS.forEach((item) => {
            const filter = item.filters[0];
            const parts = filter.filterValue.split(',');
            const attribute = filter.attribute.replaceAll(`~`, '');
            let count = 0;
            parts.forEach((part) => {
                const cnt = counts[attribute];
                if (cnt && cnt[part]) {
                    count += cnt[part];
                }
            });
            counts[attribute][filter.filterValue] = count;
        });
        return counts;
    }

    renderCheckbox(item, counts) {
        const { filterEmptyFilters } = this.props;
        const filter = item.filters[0];
        const attribute = filter.attribute.replaceAll(`~`, '');
        const attCounts = counts[attribute];
        const selections = this.props.uiFilters.campsiteEquipment;
        const isChecked = selections.hasOwnProperty(item.key);
        let count = 0;
        if (attCounts) {
            count = attCounts[filter.filterValue];
        }
        let label;
        if (count || isChecked) {
            label = `${item.value} (${count})`;
        } else {
            label = item.value;
        }

        if (filterEmptyFilters && count === 0 && !isChecked) {
            return null;
        }

        return (
            <div className="search-filter-check-item" htmlFor={item.key} key={item.key}>
                <Checkbox
                    onChange={(e) => this.handleChange(e, item)}
                    id={item.key}
                    label={label}
                    value={item.value}
                    className={count === 0 && !isChecked ? 'disabled' : ''}
                    isChecked={isChecked}
                />
            </div>
        );
    }

    render() {
        const { headingLevel = 3 } = this.props;
        const className = `${this.props.className}`;
        const counts = this.processAggregations(this.props.aggregations);
        const [primaryItems, secondaryItems] = groupFilters(
            CAMPSITE_EQUIPMENT_FILTERS,
            PRIMARY_CAMPSITE_EQUIPMENT_FILTERS_IDS
        );
        const primaryContent =
            primaryItems?.map((item) => this.renderCheckbox(item, counts)) || null;
        const secondaryContent =
            secondaryItems?.map((item) => this.renderCheckbox(item, counts)) || null;
        const fullContent =
            CAMPSITE_EQUIPMENT_FILTERS?.sort((a, b) =>
                a?.value.localeCompare(b?.value)
            )?.map((item) => this.renderCheckbox(item, counts)) || null;

        return (
            <div className={`${className}`}>
                <Heading headingLevel={headingLevel} appearance="h6">
                    Allowable Equipment
                </Heading>
                <div
                    className="search-filters-check-wrap"
                    role="group"
                    aria-label="Allowable equipment filters"
                >
                    <legend className="rec-sr-only">
                        Select allowable equipment to filter on
                    </legend>
                    <SearchFilterToggle
                        primaryContent={primaryContent}
                        secondaryContent={secondaryContent}
                        fullContent={fullContent}
                        isExpandedDefault={
                            Object.keys(this.props.uiFilters.campsiteEquipment).length > 0
                        }
                        category="Allowable Equipment"
                    />
                </div>
            </div>
        );
    }
}
