/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Heading, ContentBlock, Stack, Box, Spacer, Button } from 'sarsaparilla';
import { getGatewayUrl } from 'shared-ui/utils/getGatewayUrl';

export default function DiscoverContainer() {
    const byStates = [
        { slug: 'Alabama', stateName: 'Alabama' },
        { slug: 'Alaska', stateName: 'Alaska' },
        { slug: 'Arizona', stateName: 'Arizona' },
        { slug: 'Arkansas', stateName: 'Arkansas' },
        { slug: 'California', stateName: 'California' },
        { slug: 'Colorado', stateName: 'Colorado' },
        { slug: 'Connecticut', stateName: 'Connecticut' },
        { slug: 'Delaware', stateName: 'Delaware' },
        { slug: 'Florida', stateName: 'Florida' },
        { slug: 'Georgia', stateName: 'Georgia' },
        { slug: 'Hawaii', stateName: 'Hawaii' },
        { slug: 'Idaho', stateName: 'Idaho' },
        { slug: 'Illinois', stateName: 'Illinois' },
        { slug: 'Indiana', stateName: 'Indiana' },
        { slug: 'Iowa', stateName: 'Iowa' },
        { slug: 'Kansas', stateName: 'Kansas' },
        { slug: 'Kentucky', stateName: 'Kentucky' },
        { slug: 'Louisiana', stateName: 'Louisiana' },
        { slug: 'Maine', stateName: 'Maine' },
        { slug: 'Maryland', stateName: 'Maryland' },
        { slug: 'Massachusetts', stateName: 'Massachusetts' },
        { slug: 'Michigan', stateName: 'Michigan' },
        { slug: 'Minnesota', stateName: 'Minnesota' },
        { slug: 'Mississippi', stateName: 'Mississippi' },
        { slug: 'Missouri', stateName: 'Missouri' },
        { slug: 'Montana', stateName: 'Montana' },
        { slug: 'Nebraska', stateName: 'Nebraska' },
        { slug: 'Nevada', stateName: 'Nevada' },
        { slug: 'New-Hampshire', stateName: 'New Hampshire' },
        { slug: 'New-Jersey', stateName: 'New Jersey' },
        { slug: 'New-Mexico', stateName: 'New Mexico' },
        { slug: 'New-York', stateName: 'New York' },
        { slug: 'North-Carolina', stateName: 'North Carolina' },
        { slug: 'North-Dakota', stateName: 'North Dakota' },
        { slug: 'Ohio', stateName: 'Ohio' },
        { slug: 'Oklahoma', stateName: 'Oklahoma' },
        { slug: 'Oregon', stateName: 'Oregon' },
        { slug: 'Pennsylvania', stateName: 'Pennsylvania' },
        { slug: 'Rhode-Island', stateName: 'Rhode Island' },
        { slug: 'South-Carolina', stateName: 'South Carolina' },
        { slug: 'South-Dakota', stateName: 'South Dakota' },
        { slug: 'Tennessee', stateName: 'Tennessee' },
        { slug: 'Texas', stateName: 'Texas' },
        { slug: 'Utah', stateName: 'Utah' },
        { slug: 'Vermont', stateName: 'Vermont' },
        { slug: 'Virginia', stateName: 'Virginia' },
        { slug: 'Washington', stateName: 'Washington' },
        { slug: 'West-Virginia', stateName: 'West Virginia' },
        { slug: 'Wisconsin', stateName: 'Wisconsin' },
        { slug: 'Wyoming', stateName: 'Wyoming' },
    ];

    const byLocations = [
        { id: '2554', locationName: 'Acadia National Park' },
        { id: '444', locationName: 'Alabama River Lakes' },
        { id: '440', locationName: 'Allatoona Lake' },
        { id: '1088', locationName: 'Allegheny National Forest' },
        { id: '122', locationName: 'Apache-Sitgreaves National Forest' },
        {
            id: '1051',
            locationName:
                'Arapaho & Roosevelt National Forests Pawnee National Grassland',
        },
        { id: '2573', locationName: 'Arches National Park' },
        { id: '526', locationName: 'Arkansas River - Murray Lock and Dam' },
        { id: '1030', locationName: 'Ashley National Forest' },
        { id: '2576', locationName: 'Assateague Island National Seashore' },
        { id: '352', locationName: 'Barren River Lake' },
        { id: '530', locationName: 'Beaver Lake' },
        { id: '493', locationName: 'Belton Lake' },
        { id: '2584', locationName: 'Big Bend National Park' },
        { id: '1058', locationName: 'Bighorn National Forest' },
        { id: '1014', locationName: 'Bitterroot National Forest' },
        { id: '1057', locationName: 'Black Hills National Forest' },
        { id: '2593', locationName: 'Blue Ridge Parkway' },
        { id: '1023', locationName: 'Boise National Forest' },
        { id: '2599', locationName: 'Bryce Canyon National Park' },
        { id: '495', locationName: 'Canyon Lake' },
        { id: '2609', locationName: 'Cape Hatteras National Seashore' },
        { id: '2617', locationName: 'Capitol Reef National Park' },
        { id: '1024', locationName: 'Caribou-Targhee National Forest' },
        { id: '1686', locationName: 'Carlyle Lake' },
        { id: '1048', locationName: 'Cherokee National Forest' },
        { id: '2337', locationName: 'Chugach National Forest' },
        { id: '532', locationName: 'Clearwater Lake' },
        { id: '1062', locationName: 'Cleveland National Forest' },
        { id: '1092', locationName: 'Coconino National Forest Recreation' },
        { id: '1102', locationName: 'Columbia River Gorge National Scenic Area' },
        { id: '158', locationName: 'Coralville Lake' },
        { id: '385', locationName: 'Cordell Hull Lake' },
        { id: '1017', locationName: 'Custer Gallatin National Forest' },
        { id: '1015', locationName: 'Custer National Forest' },
        { id: '386', locationName: 'Dale Hollow Lake' },
        { id: '1042', locationName: 'Daniel Boone National Forest' },
        { id: '149', locationName: 'Degray Lake' },
        { id: '2658', locationName: 'Denali National Park & Preserve' },
        { id: '14492', locationName: 'Deschutes National Forest' },
        { id: '2664', locationName: 'Dinosaur National Monument' },
        { id: '1031', locationName: 'Dixie National Forest' },
        { id: '1063', locationName: 'Eldorado National Forest' },
        { id: '14487', locationName: 'El Yunque National Forest' },
        { id: '554', locationName: 'Eufaula Lake' },
        { id: '1016', locationName: 'Flathead National Forest' },
        { id: '516', locationName: 'Georgetown Lake' },
        { id: '1050', locationName: 'George Washington & Jefferson National Forest' },
        { id: '1117', locationName: 'Gifford Pinchot National Forest' },
        { id: '2725', locationName: 'Glacier National Park' },
        { id: '2733', locationName: 'Grand Canyon National Park' },
        {
            id: '1052',
            locationName: 'Grand Mesa, Uncompahgre and Gunnison National Forest',
        },
        { id: '2738', locationName: 'Great Sand Dunes National Park & Preserve' },
        { id: '2739', locationName: 'Great Smoky Mountains National Park' },
        { id: '537', locationName: 'Greers Ferry Lake' },
        { id: '2743', locationName: 'Gulf Islands National Seashore' },
        { id: '2751', locationName: 'Haleakalā National Park' },
        { id: '267', locationName: 'Harry S. Truman Lake' },
        { id: '454', locationName: 'Hartwell Lake' },
        { id: '1081', locationName: 'Hiawatha National Forest' },
        { id: '1029', locationName: 'Humboldt-Toiyabe National Forest Headquarters' },
        { id: '1082', locationName: 'Huron-Manistee National Forests' },
        { id: '1011', locationName: 'Idaho Panhandle National Forests' },
        { id: '1064', locationName: 'Inyo National Forest' },
        { id: '458', locationName: 'John H. Kerr Dam and Reservoir' },
        { id: '2782', locationName: 'Joshua Tree National Park' },
        { id: '387', locationName: 'J Percy Priest Lake' },
        { id: '453', locationName: 'J. Strom Thurmond Lake' },
        { id: '1094', locationName: 'Kaibab National Forest' },
        { id: '391', locationName: 'Lake Cumberland' },
        { id: '497', locationName: "Lake O' The Pines" },
        { id: '160', locationName: 'Lake Red Rock' },
        { id: '1691', locationName: 'Lake Shelbyville' },
        { id: '442', locationName: 'Lake Sidney Lanier' },
        { id: '2025', locationName: 'Lake Tahoe Basin Management Unit' },
        { id: '578', locationName: 'Lake Texoma' },
        { id: '2803', locationName: 'Lassen Volcanic National Park' },
        { id: '1067', locationName: 'Los Padres National Forest' },
        { id: '16834', locationName: 'Lower Deschutes Wild and Scenic River' },
        { id: '2818', locationName: 'Mammoth Cave National Park' },
        { id: '1033', locationName: 'Manti-La Sal National Forest' },
        { id: '1685', locationName: 'Mark Twain Lake' },
        { id: '269', locationName: 'Melvern Lake' },
        { id: '159', locationName: 'Mississippi River - Pools 11-22' },
        { id: '2835', locationName: 'Mount Rainier National Park' },
        { id: '2837', locationName: 'Mount Rushmore National Memorial' },
        { id: '1118', locationName: 'Mt. Baker-Snoqualmie National Forest' },
        { id: '1106', locationName: 'Mt. Hood National Forest' },
        { id: '1039', locationName: 'National Forests in Florida' },
        { id: '1045', locationName: 'National Forests in North Carolina' },
        { id: '2870', locationName: 'National Mall and Memorial Parks' },
        { id: '25', locationName: 'New Melones Lake' },
        { id: '371', locationName: 'Nolin River Lake' },
        { id: '540', locationName: 'Norfork Lake' },
        { id: '2845', locationName: 'North Cascades National Park' },
        { id: '16822', locationName: 'Okanogan-Wenatchee National Forest' },
        { id: '2881', locationName: 'Olympic National Park' },
        { id: '147', locationName: 'Ouachita Lake' },
        {
            id: '16221',
            locationName: 'Paria Canyon-Vermilion Cliffs Wilderness (The Wave)',
        },
        { id: '14525', locationName: 'Pearl Harbor National Memorial' },
        { id: '2895', locationName: 'Pictured Rocks National Lakeshore' },
        { id: '2893', locationName: 'Pinnacles National Park' },
        { id: '1070', locationName: 'Plumas National Forest' },
        { id: '2864', locationName: 'Point Reyes National Seashore' },
        { id: '272', locationName: 'Pomme De Terre Lake' },
        { id: '1095', locationName: 'Prescott National Forest' },
        { id: '1053', locationName: 'PSICC' },
        { id: '187', locationName: 'Raystown Lake' },
        { id: '1690', locationName: 'Rend Lake' },
        { id: '2907', locationName: 'Rocky Mountain National Park' },
        { id: '1108', locationName: 'Rogue River-Siskiyou National Forest' },
        { id: '373', locationName: 'Rough River Lake' },
        { id: '1026', locationName: 'Salmon-Challis National Forest' },
        { id: '505', locationName: 'Sam Rayburn Reservoir' },
        { id: '1071', locationName: 'San Bernardino National Forest' },
        { id: '1054', locationName: 'San Juan National Forest' },
        { id: '16245', locationName: 'San Juan River' },
        { id: '1027', locationName: 'Sawtooth National Forest' },
        { id: '161', locationName: 'Saylorville Lake' },
        { id: '2931', locationName: 'Sequoia & Kings Canyon National Parks' },
        { id: '1072', locationName: 'Sequoia National Forest' },
        { id: '1073', locationName: 'Shasta-Trinity National Forest' },
        { id: '2933', locationName: 'Shenandoah National Park' },
        { id: '1074', locationName: 'Sierra National Forest' },
        { id: '1110', locationName: 'Siuslaw National Forest' },
        { id: '2937', locationName: 'Sleeping Bear Dunes National Lakeshore' },
        { id: '1076', locationName: 'Stanislaus National Forest' },
        { id: '276', locationName: 'Stockton Lake' },
        { id: '1085', locationName: 'Superior National Forest' },
        { id: '2264', locationName: 'Table Rock Lake' },
        { id: '1077', locationName: 'Tahoe National Forest' },
        { id: '571', locationName: 'Tenkiller Ferry Lake' },
        { id: '441', locationName: 'Tennessee-Tombigbee Waterway' },
        { id: '1096', locationName: 'Tonto National Forest' },
        { id: '1035', locationName: 'Uinta-Wasatch-Cache National Forest' },
        { id: '1112', locationName: 'Umpqua National Forest' },
        { id: '2970', locationName: 'Voyageurs National Park' },
        { id: '510', locationName: 'Waco Lake' },
        { id: '1113', locationName: 'Wallowa Whitman National Forest' },
        { id: '449', locationName: 'Walter F. George Lake' },
        { id: '1692', locationName: 'Wappapello Lake' },
        { id: '450', locationName: 'West Point Lake' },
        { id: '1087', locationName: 'White Mountain National Forest' },
        { id: '1055', locationName: 'White River National Forest' },
        { id: '511', locationName: 'Whitney Lake' },
        { id: '1114', locationName: 'Willamette National Forest' },
        { id: '461', locationName: 'W. Kerr Scott Dam & Reservoir' },
        { id: '509', locationName: 'Wright Patman Lake' },
        { id: '2991', locationName: 'Yosemite National Park' },
        { id: '2994', locationName: 'Zion National Park' },
    ];

    // Sort by First Letter
    const listOfLocations = byLocations.reduce((r, location) => {
        const firstLetter = location.locationName[0];
        if (!r[firstLetter]) {
            // eslint-disable-next-line no-param-reassign
            r[firstLetter] = [];
        }
        r[firstLetter].push(location);
        return r;
    }, {});

    // Sort by State Name
    const listOfStates = byStates.reduce((r, state) => {
        const firstLetter = state.stateName[0];
        if (!r[firstLetter]) {
            // eslint-disable-next-line no-param-reassign
            r[firstLetter] = [];
        }
        r[firstLetter].push(state);
        return r;
    }, {});

    const getStateUrl = (slug) => {
        if (slug === 'Alaska') {
            return `/search?q=${slug}&radius=200&lat_sw=51.1555979&lng_sw=-179.9&lat_ne=71.420291&lng_ne=-129.9902344`;
        }
        return `/search?q=${slug}`;
    };

    return (
        <ContentBlock>
            <section>
                <Button appearance="link" href="#state" className="skip-link-list mb-1">
                    Skip to Explore by State
                </Button>
                <Stack space={'xl'}>
                    <Box>
                        <Heading headingLevel={2} appearance="h4" hasUnderline="left">
                            Explore Most Popular Locations
                        </Heading>
                    </Box>

                    <Box className="nav-discover-container">
                        {Object.entries(listOfLocations).map(
                            ([letterGroup, value], i) => (
                                <div
                                    className={`nav-discover-item mb-half ${
                                        value.length === 2 ? 'no-break' : ''
                                    }`}
                                    key={i}
                                >
                                    <div
                                        id={`${letterGroup}-location`}
                                        className="nav-discover-section-head"
                                    >
                                        <span>{letterGroup}</span>
                                    </div>
                                    <ul aria-labelledby={`${letterGroup}-location`}>
                                        {value.map((byLocation, j) => (
                                            <li key={j}>
                                                <a
                                                    href={getGatewayUrl(byLocation.id)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="truncate ga-popular-locations-link"
                                                >
                                                    {byLocation.locationName}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )
                        )}
                    </Box>
                </Stack>
            </section>

            <Spacer size="xxl" />

            <section id="state">
                <Button appearance="link" href="#footer" className="skip-link-list mb-1">
                    Skip to Footer
                </Button>
                <Stack space={'xl'}>
                    <Box>
                        <Heading headingLevel={2} appearance="h4" hasUnderline="left">
                            Explore by State
                        </Heading>
                    </Box>
                    <Box className="nav-discover-container">
                        {Object.entries(listOfStates).map(([letterGroup, value], i) => (
                            <div
                                className={`nav-discover-item mb-half ${
                                    value.length === 2 ? 'no-break' : ''
                                }`}
                                key={i}
                            >
                                <div
                                    id={`${letterGroup}-state`}
                                    className="nav-discover-section-head"
                                >
                                    <span>{letterGroup}</span>
                                </div>
                                <ul aria-labelledby={`${letterGroup}-state`}>
                                    {value.map((byState, j) => {
                                        const url = getStateUrl(byState.slug);
                                        return (
                                            <li key={j}>
                                                <a
                                                    href={url}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="truncate ga-by-state-link"
                                                >
                                                    {byState.stateName}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        ))}
                    </Box>
                </Stack>
            </section>
        </ContentBlock>
    );
}
