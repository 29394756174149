/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

interface CounterState {
    pageIndex: number;
}

const initialState: CounterState = {
    pageIndex: 0,
};

const articlesSlice = createSlice({
    name: 'articles',
    initialState,
    reducers: {
        incrementPageIndex: (state) => {
            state.pageIndex += 1;
        },
    },
});

export const { incrementPageIndex } = articlesSlice.actions;
export default articlesSlice.reducer;
