/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function FacilityFilterBarItem({
    label,
    placeholder,
    value,
    onClearClick,
    clearButtonLabel,
    barPlaceholder,
    barValue,
    barBadgeCount,
    children,
    className,
    isResultButtonDisabled,
    resultsText,
    filterBarOnly,
    preserveComponent,
    // eslint-disable-next-line no-unused-vars
    panelWidth = 'lg',
    ...rest
}) {
    return (
        <div
            data-component="FacilityFilterBarItem"
            className={cx('shared-facility-filter-bar-item', className)}
            {...rest}
        >
            {children}
        </div>
    );
}

FacilityFilterBarItem.propTypes = {
    /** Modal: Tab button label, e.g. "Check In / Check Out"  */
    label: PropTypes.node,

    /** Modal: Tab button: Text under the label when there is no value. e.g. "Add dates ..." */
    placeholder: PropTypes.node,

    /** Modal & Filter bar: display of user selected value. e.g. "Jul 3 - 10" */
    value: PropTypes.node,

    /** Modal: The text used for the 2 clear buttons (1 aria-label and 1 display label). e.g. "Clear dates"  */
    clearButtonLabel: PropTypes.string,

    /** Modal: Callback fired when use clicks a clear button */
    onClearClick: PropTypes.func,

    /** Filter bar: If provided, this overrides the `placeholder` only for the filter bar */
    barPlaceholder: PropTypes.node,

    /** Filter bar: If provided, this overrides `value` only for the filter bar */
    barValue: PropTypes.node,

    /** Filter bar: If provided, adds a badge after the `value` or `barValue` in the filter bar */
    barBadgeCount: PropTypes.number,

    /** Modal: The panel contents displayed when the tab is active */
    children: PropTypes.node,

    /** Modal Bar: Text to display on the main button inside the bar */
    resultsText: PropTypes.string,

    /** Modal Bar: To disable the results button or not */
    isResultButtonDisabled: PropTypes.bool,

    /** Constrains the panel width  */
    panelWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),

    /** Filter Bar: Prop to render the component only in the filter bar and not in the modal  */
    filterBarOnly: PropTypes.bool,

    /** Filter Bar: If preserve component is set to true it will keep the component as is, otherwise the filter bar will turn it into a button  */
    preserveComponent: PropTypes.bool,

    className: PropTypes.string,
};
