/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { debounce, get, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { NumberField } from 'sarsaparilla';
import { updateSearchCriteria } from 'ui-search/src/actions/search';
import { NumberFieldFilterPlaceholder } from './NumberFieldFilterPlaceholder';

type NumberFieldFilterProps = {
    label: string;
    units: string;
    id?: string;
    max: number;
    filter: string;
    onApplyFilters?: () => void;
    unitAfter?: string;
    filterEmptyFilters?: boolean;
    aggregations?: {
        [key: string]: Number;
    };
    delay?: number;
    onCriteriaUpdate?: (v: string, k: string[]) => void;
    search: any;
};

export default function NumberFieldFilter({
    label,
    units,
    id,
    max,
    onApplyFilters,
    filter,
    unitAfter,
    filterEmptyFilters,
    aggregations,
    delay = 500,
    onCriteriaUpdate,
    search,
}: NumberFieldFilterProps) {
    const dispatch = useDispatch();

    const onChange = (value: number) => {
        const index = search?.fg?.findIndex((item: string) => item.includes(filter));
        let list = [...search?.fg];
        if (index !== -1) {
            list.splice(index, 1);
        }
        const param = `${filter}:${value}`;
        list.push(param);

        if (value === 0) {
            list = list.filter((item) => !item.includes(filter));
        }
        if (onCriteriaUpdate) {
            onCriteriaUpdate('fg', list);
        } else {
            // @ts-ignore
            dispatch(updateSearchCriteria('fg', list));
        }
        if (onApplyFilters) {
            onApplyFilters();
        }
    };

    const onChangeDebounce = React.useCallback(
        debounce((value: number) => {
            onChange(value);
        }, delay),
        [search?.fg]
    );

    const getValue = () => {
        let v = 0;
        const fg = get(search, 'fg', []);
        const current = fg.find((item: string) => item.includes(filter));
        if (current) {
            const data = current.split(':');
            v = Number(data[1]);
        }
        return v;
    };

    const hasAggregations = !isEmpty(aggregations);

    if (filterEmptyFilters && !hasAggregations) {
        return <NumberFieldFilterPlaceholder />;
    }
    const value = getValue();
    return (
        <div className="rec-search-filters-number-field-wrap">
            <NumberField
                visualLabel={label}
                units={units}
                min={0}
                max={max}
                id={id}
                value={value}
                onChange={onChangeDebounce}
                shouldTriggerOnChange
                unitAfter={unitAfter}
            />
        </div>
    );
}
