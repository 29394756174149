/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SEARCH_FILTER_INVENTORY_VALUES } from 'site-kit';
import { updateSearchCriteria } from '../../../actions/search';
import { CampingTypeFiltersProps, FiltersTypeProps } from '../../../types';
import { toggleFilterGroups } from '../../../utils/filters';
import CommonFilter from '../common/CommonFilter';
import { INVENTORY_TYPES_FILTERS } from '../../../constants/filters/inventoryTypes';

function InventoryTypeFilters({
    aggregations,
    onApplyFilters,
    storePath = 'search',
    onCriteriaUpdate,
}: CampingTypeFiltersProps) {
    const dispatch = useDispatch();
    const search = useSelector((state: Record<string, any>) => state[storePath]);

    const onChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        item: FiltersTypeProps
    ) => {
        event.preventDefault();
        const params = toggleFilterGroups(item, search);
        if (onCriteriaUpdate) {
            onCriteriaUpdate('fg', params);
        } else {
            // @ts-ignore
            dispatch(updateSearchCriteria('fg', params));
        }
        if (onApplyFilters) {
            onApplyFilters();
        }
    };

    let filters: Array<FiltersTypeProps> = [];
    SEARCH_FILTER_INVENTORY_VALUES.forEach((item) => {
        const current = INVENTORY_TYPES_FILTERS.find(
            (value) => value.legacyKey === item.key
        );
        if (current) {
            filters = [
                ...filters,
                {
                    value: current.value,
                    key: current.key,
                    legacyKey: current.legacyKey || '',
                    icon: item.icon,
                },
            ];
        }
    });

    const inventoryAggregations = aggregations ? aggregations.inventoryTypes : {};
    return (
        <CommonFilter
            aggregations={inventoryAggregations}
            search={search}
            filters={filters}
            title="Booking Types"
            onChange={onChange}
            hasDivider={false}
            headingLevel={2}
        />
    );
}

export default InventoryTypeFilters;
