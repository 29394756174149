/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import PropTypes from 'prop-types';
import {
    useTable,
    usePagination,
    useSortBy,
    useFlexLayout,
    useResizeColumns,
    useGlobalFilter,
    useAsyncDebounce,
} from 'react-table';
import './ReactTableWithLayout.scss';
import cx from 'classnames';
import { Select, SelectOption, Pagination, Spinner } from 'sarsaparilla';
import SortableReactTableColumnHeader from './SortableReactTableColumnHeader';
import { RowCountText } from './RowCountText';
import { getRowCount } from './ReactTableWithLayout.utils';

function RowsNotFound({ noDataComponent: NoDataComponent, noDataText }) {
    if (NoDataComponent)
        return <div className="rt-noData-container">{NoDataComponent}</div>;

    return (
        <div className="rt-noData-container">
            <div className="rt-noData">{noDataText || 'No rows found.'}</div>
        </div>
    );
}

const DEFAULT_PAGE_SIZE = 10;

function ReactTableWithLayout({
    // used as table caption
    'aria-label': ariaLabel,
    appearance = 'dark',
    id,
    className = '',
    tableClassName = '',
    data,
    columns,
    isLoading = false,
    hasBorders = true,
    hasPagination,
    autoResetPage = true,
    autoResetSortBy,
    loadingText,
    noDataComponent,
    noDataText,
    loadingComponent,
    showPageSize = true,
    getTdProps = () => ({}),
    getTrProps = () => ({}),
    trClassName = '',
    hiddenColumns = [],
    defaultPageSize = DEFAULT_PAGE_SIZE,
    defaultSorted = [],
    showRowCount,
    showRowCountAbove,
    isStriped = true,
    useManualPagination = false,
    useManualSort = false,
    useManualGlobalFilter = false,
    pageCount: controlledPageCount,
    rowCount: rowCountProp,
    pageIndex: pageIndexProp = 0,
    onPageChange,
    onPageSizeChange,
    onTableChange,
    onSortChange,
    hasExternalFilter,
    globalFiler,
    gaTrackingId,
}) {
    // We shouldn't need to memoize the data here because it should always be memoized by the parent component
    // But people do silly things, so we're compensating for that here. Perhaps we should remove this in the future.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedData = React.useMemo(() => data, [JSON.stringify(data)]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        rows,
        gotoPage,
        pageOptions,
        setPageSize,
        prepareRow,
        setGlobalFilter,
        state: { pageIndex, pageSize, sortBy },
    } = useTable(
        {
            columns,
            data: memoizedData,
            manualPagination: useManualPagination,
            manualSortBy: useManualSort,
            manualGlobalFilter: useManualGlobalFilter,
            pageCount: useManualPagination ? controlledPageCount : -1,
            autoResetPage,
            autoResetSortBy,
            initialState: {
                hiddenColumns,
                pageSize: defaultPageSize > 0 ? defaultPageSize : DEFAULT_PAGE_SIZE,
                sortBy: defaultSorted,
                pageIndex: pageIndexProp,
            },
        },
        useFlexLayout,
        useResizeColumns,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { rowCount, isRowCountEstimated } = getRowCount({
        pageSize,
        dataLength: data?.length ?? 0,
        manualPageCount: controlledPageCount,
        manualRowCount: rowCountProp,
    });

    const onGlobalFilterChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    React.useEffect(() => {
        if (onTableChange && hasPagination && page) {
            onTableChange(page);
        }
    }, [page, hasPagination, onTableChange]);

    React.useEffect(() => {
        if (hasExternalFilter) {
            onGlobalFilterChange(globalFiler);
        }
    }, [globalFiler, hasExternalFilter, onGlobalFilterChange]);

    // If manually sorting, call onSortChange when the sort changes
    React.useEffect(() => {
        if (useManualSort && onSortChange) {
            onSortChange(sortBy);
        }
    }, [sortBy, onSortChange, useManualSort]);

    // If manually paginating, call onPageChange when the page changes
    React.useEffect(() => {
        if (useManualPagination && onPageChange) {
            onPageChange(pageIndex);
        }
    }, [onPageChange, pageIndex, useManualPagination]);

    function handlePageSizeChange(event) {
        const newPageSize = Number(event.target.value);
        setPageSize(newPageSize);
        if (useManualPagination && onPageSizeChange) {
            onPageSizeChange(newPageSize);
        }
    }

    const cellPropsInterception = (cellProps, cell) => {
        const obj = cellProps;

        if (obj.style && !obj.style.maxWidth && cell.maxWidth) {
            obj.style = { ...obj.style, maxWidth: `${cell.maxWidth}px` };
        }

        if (obj.style && !obj.style.maxWidth && cell.column && cell.column.maxWidth) {
            obj.style = { ...obj.style, maxWidth: `${cell.column.maxWidth}px` };
            if (cell.column?.hasOwnProperty('getCellBackgroundColor')) {
                obj.style = {
                    ...obj.style,
                    backgroundColor: cell.column.getCellBackgroundColor(cell),
                };
            }
        }

        return obj;
    };

    const columnTypeSorter = (column) => {
        if (column.sortable) {
            const cellProps = cellPropsInterception(
                column.getHeaderProps(column.getSortByToggleProps()),
                column
            );

            return (
                <SortableReactTableColumnHeader
                    className={column.className}
                    isSorted={column.isSorted}
                    isAsc={!column.isSortedDesc}
                    {...cellProps}
                >
                    {column.render('Header')}
                    {column.resizable && (
                        <div {...column.getResizerProps()} className="rt-resizer" />
                    )}
                </SortableReactTableColumnHeader>
            );
        }

        const cellProps = cellPropsInterception(column.getHeaderProps(), column);
        return (
            <div {...cellProps} className={cx('rt-th', column.className)}>
                {column.render('Header')}
                {column.resizable && (
                    <div {...column.getResizerProps()} className="rt-resizer" />
                )}
            </div>
        );
    };

    const hasNoRows =
        !isLoading &&
        ((hasPagination && page.length === 0) || (!hasPagination && rows.length === 0));

    return (
        <div
            id={id}
            className={cx('shared-ui-react-table-with-layout', className, {
                '-striped': isStriped && appearance !== 'light',
            })}
        >
            {pageOptions.length > 0 && showRowCountAbove ? (
                <div className="mb-1">
                    <RowCountText
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        rowCount={rowCount}
                        isEstimate={isRowCountEstimated}
                        dataLength={data?.length ?? 0}
                    />
                </div>
            ) : null}
            <div className="rt-table-wrapper">
                <div
                    {...getTableProps()}
                    className={cx(tableClassName, 'rt-table', {
                        'rt-empty-table': hasNoRows,
                        'table-no-border': !hasBorders,
                        'rec-table-light': appearance === 'light',
                    })}
                    aria-label={ariaLabel}
                >
                    <div className="rt-thead">
                        {headerGroups.map((headerGroup) => (
                            <div {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                                {headerGroup.headers.map((column) => {
                                    return columnTypeSorter(column);
                                })}
                            </div>
                        ))}
                    </div>
                    <div className="rt-tbody" {...getTableBodyProps()}>
                        {hasPagination
                            ? page.map((row) => {
                                  prepareRow(row);
                                  const { style, ...otherRowProps } = row.getRowProps();
                                  const {
                                      className: userClassName,
                                      style: userStyle,
                                      ...otherUserProps
                                  } = getTrProps(row);

                                  return (
                                      <div
                                          style={{ ...style, ...userStyle }}
                                          className={cx(
                                              'rt-tr',
                                              trClassName,
                                              userClassName
                                          )}
                                          {...otherUserProps}
                                          {...otherRowProps}
                                      >
                                          {row.cells.map((cell) => {
                                              const cellProps = cellPropsInterception(
                                                  cell.getCellProps(),
                                                  cell
                                              );
                                              return (
                                                  <div
                                                      {...getTdProps(cell)}
                                                      {...cellProps}
                                                      className={cx(
                                                          'rt-td',
                                                          cell.column.className
                                                      )}
                                                  >
                                                      {cell.render('Cell')}
                                                  </div>
                                              );
                                          })}
                                      </div>
                                  );
                              })
                            : rows.map((row) => {
                                  prepareRow(row);
                                  const { style, ...otherRowProps } = row.getRowProps();
                                  const {
                                      className: userClassName,
                                      style: userStyle,
                                      ...otherUserProps
                                  } = getTrProps(row);

                                  return (
                                      <div
                                          style={{ ...style, ...userStyle }}
                                          className={cx(
                                              'rt-tr',
                                              trClassName,
                                              userClassName
                                          )}
                                          {...otherUserProps}
                                          {...otherRowProps}
                                      >
                                          {row.cells.map((cell) => {
                                              const cellProps = cellPropsInterception(
                                                  cell.getCellProps(),
                                                  cell
                                              );

                                              const {
                                                  className: getTdPropsClassName,
                                                  ...otherTdProps
                                              } = getTdProps(cell);

                                              return (
                                                  <div
                                                      {...otherTdProps}
                                                      {...cellProps}
                                                      className={cx(
                                                          'rt-td',
                                                          cell.column.className,
                                                          getTdPropsClassName
                                                      )}
                                                  >
                                                      {cell.render('Cell')}
                                                  </div>
                                              );
                                          })}
                                      </div>
                                  );
                              })}

                        {hasNoRows && (
                            <RowsNotFound
                                noDataText={noDataText}
                                noDataComponent={noDataComponent}
                            />
                        )}
                    </div>

                    {isLoading && !!loadingComponent ? (
                        <div className="-loading"> {loadingComponent} </div>
                    ) : null}

                    {loadingText && isLoading ? loadingText : null}

                    {isLoading && !loadingComponent && !loadingText ? (
                        <div className="-loading">
                            <Spinner />
                        </div>
                    ) : null}
                </div>
            </div>

            {!isLoading && hasPagination ? (
                <div className="table-pagination">
                    {pageOptions.length > 0 && showRowCount ? (
                        <RowCountText
                            pageIndex={pageIndex}
                            pageSize={pageSize}
                            rowCount={rowCount}
                            isEstimate={isRowCountEstimated}
                            dataLength={data?.length ?? 0}
                        />
                    ) : null}
                    {showPageSize ? (
                        <Select
                            id="rows-per=page"
                            label="Rows per page"
                            isInline
                            value={`${pageSize}`}
                            onChange={handlePageSizeChange}
                        >
                            {['5', '10', '20', '25', '50', '100'].map((rowsSize) => (
                                <SelectOption key={rowsSize} value={rowsSize}>
                                    {`${rowsSize} rows`}
                                </SelectOption>
                            ))}
                        </Select>
                    ) : null}

                    {pageOptions.length > 0 ? (
                        <Pagination
                            pages={pageOptions.length}
                            activePage={pageIndex}
                            onChange={gotoPage}
                            gaTrackingId={gaTrackingId}
                        />
                    ) : null}
                </div>
            ) : null}
        </div>
    );
}

ReactTableWithLayout.propTypes = {
    'aria-label': PropTypes.string,
    appearance: PropTypes.oneOf(['dark', 'light']),
    id: PropTypes.string,
    className: PropTypes.string,
    tableClassName: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
    getTdProps: PropTypes.func,
    getTrProps: PropTypes.func,
    isLoading: PropTypes.bool,
    isStriped: PropTypes.bool,
    hasBorders: PropTypes.bool,
    hasExternalFilter: PropTypes.bool,
    globalFiler: PropTypes.string,
    hasPagination: PropTypes.bool,
    autoResetPage: PropTypes.bool,
    gaTrackingId: PropTypes.string,

    /** Determines whether to auto reset sort when data changes */
    autoResetSortBy: PropTypes.bool,
    showPageSize: PropTypes.bool,
    trClassName: PropTypes.string,
    loadingText: PropTypes.string,
    noDataText: PropTypes.string,
    loadingComponent: PropTypes.element,
    noDataComponent: PropTypes.element,
    showRowCount: PropTypes.bool,
    showRowCountAbove: PropTypes.bool,
    hiddenColumns: PropTypes.arrayOf(PropTypes.string),
    defaultPageSize: PropTypes.number,
    defaultSorted: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                desc: PropTypes.bool,
            })
        ),
    ]),
    useManualPagination: PropTypes.bool,
    useManualSort: PropTypes.bool,
    useManualGlobalFilter: PropTypes.bool,
    pageIndex: PropTypes.number,
    pageCount: PropTypes.number,
    rowCount: PropTypes.number,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    onSortChange: PropTypes.func,
};

RowsNotFound.propTypes = {
    noDataComponent: PropTypes.element,
    noDataText: PropTypes.string,
};

export default ReactTableWithLayout;

// cSpell:ignore Resizer
