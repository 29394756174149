/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { Icon, ErrorBoundary } from 'sarsaparilla';
import MapCustomControl from './MapCustomControl';
import {
    CAMPING_PIN,
    circleIconsColors,
    DAY_USE_PIN,
    FACILITY_TYPES,
    PASSES_PIN,
    PERMITS_PIN,
    POI_PIN,
    REC_AREA_PIN,
    REC_CAMPING_PIN,
    REC_DAY_USE_PIN,
    REC_PASSES_PIN,
    REC_PERMITS_PIN,
    REC_POI_PIN,
    REC_REC_AREA_PIN,
    REC_TOURS_PIN,
    REC_VENUE_OTHER_PIN,
    SITE_STATUS,
    TOURS_PIN,
    VENUE_OTHER_PIN,
} from '../constants';

function LegendContent({ listContent, listContainer, btn }) {
    React.useEffect(() => {
        const container = listContainer;
        const button = btn;

        container.style.display = 'none';
        container.querySelector('div button').addEventListener('click', () => {
            container.style.display = 'none';
            button.style.display = 'block';
        });
    }, [btn, listContainer]);

    return (
        <ErrorBoundary>
            <div className="map-legend-control-list-button-container">
                <button type="button" data-ga-tracking-id="376589499389">
                    <span>
                        <strong>Legend</strong>
                    </span>
                    <span>
                        <Icon iconName="chevron-down" />
                    </span>
                </button>
            </div>
            <div className="map-legend-control-list-icon">{listContent}</div>
        </ErrorBoundary>
    );
}

LegendContent.propTypes = {
    listContent: PropTypes.node.isRequired,
    listContainer: PropTypes.object.isRequired,
    btn: PropTypes.object.isRequired,
};

export default class MapLegendControl extends MapCustomControl {
    constructor({ listContent, hideStatusLegend }) {
        super({ listContent, hideStatusLegend });
        this._listContent = listContent;
        this._isClustered = false;
        this._hideStatusLegend = hideStatusLegend;
        this.updateContent = this.updateContent.bind(this);
        this._pinColors = circleIconsColors.reduce((acc, current) => {
            acc[current[0]] = current[1];
            return acc;
        }, {});
        this._facilities = [
            { mapIcon: CAMPING_PIN, legendIcon: REC_CAMPING_PIN, label: 'Campground' },
            { mapIcon: DAY_USE_PIN, legendIcon: REC_DAY_USE_PIN, label: 'Day Use' },
            { mapIcon: PERMITS_PIN, legendIcon: REC_PERMITS_PIN, label: 'Permits' },
            { mapIcon: POI_PIN, legendIcon: REC_POI_PIN, label: 'Point of Interest' },
            { mapIcon: PASSES_PIN, legendIcon: REC_PASSES_PIN, label: 'Pass' },
            {
                mapIcon: REC_AREA_PIN,
                legendIcon: REC_REC_AREA_PIN,
                label: 'Recreation Area',
            },
            { mapIcon: TOURS_PIN, legendIcon: REC_TOURS_PIN, label: 'Tour' },
            {
                mapIcon: VENUE_OTHER_PIN,
                legendIcon: REC_VENUE_OTHER_PIN,
                label: 'Venues',
            },
        ];
    }

    onAdd(map) {
        super.onAdd(map);
        this._container.className =
            'map-legend-control mapboxgl-ctrl mapboxgl-ctrl-text-group';
        this._listContainer = document.createElement('div');
        this._listContainer.className = 'map-legend-control-list';
        this._listContainer.style.display = 'none';

        this._btn = document.createElement('button');
        this._btn.innerHTML = 'Legend';
        this._btn.setAttribute('aria-label', 'View the map legend');
        this._btn.setAttribute('data-ga-tracking-id', '126738784214');
        this._btn.addEventListener('click', this._onClick);
        this._container.appendChild(this._btn);
        this._container.appendChild(this._listContainer);

        this.root = createRoot(this._listContainer);

        this.updateContent(this._listContent, () => {
            // note this is a callback, ReactDOM.render() is asynchronous and not guaranteed to execute in order
            this._listContainer.style.display = 'none';
            this._listContainer
                .querySelector('div button')
                .addEventListener('click', () => {
                    this._listContainer.style.display = 'none';
                    this._btn.style.display = 'block';
                });
        });

        return this._container;
    }

    _onClick = () => {
        this._btn.style.display = 'none';
        this._listContainer.style.display = 'block';
    };

    updateContent = (listContent) => {
        if (this.root) {
            this.root.render(
                <LegendContent
                    listContent={listContent}
                    listContainer={this._listContainer}
                    btn={this._btn}
                />
            );
        }
    };

    getStatusContent = () => {
        const statuses = [
            {
                iconName: <span className="full-circle-availability circle-available" />,
                displayNames: 'Available for selected dates',
                key: 'available',
            },
            {
                iconName: <span className="half-circle-availability" />,
                displayNames: 'Partially available or no dates',
                key: 'partially-available',
            },
            {
                iconName: (
                    <span className="full-circle-availability circle-unavailable" />
                ),
                displayNames: 'Unavailable',
                key: 'unavailable',
            },
        ];

        return (
            <ul className="legend-section">
                <li key="status-segment" className="legend-section-title">
                    {SITE_STATUS}
                </li>

                {statuses.map((status) => {
                    return (
                        <li key={status.key} className="availability-status-li">
                            <span className="availability-status-icon">
                                {status.iconName}
                            </span>
                            <span>{status.displayNames}</span>
                        </li>
                    );
                })}
            </ul>
        );
    };

    getFacilityContent = (isClustered) => {
        return (
            <ul className="legend-section">
                <li key="facility-segment" className="legend-section-title">
                    {FACILITY_TYPES}
                </li>
                {this._facilities.map(({ mapIcon, legendIcon, label }) => (
                    <li key={mapIcon}>
                        {isClustered ? (
                            <span
                                className="circle-pin"
                                style={{
                                    backgroundColor: `${this._pinColors[mapIcon]}`,
                                }}
                            />
                        ) : (
                            <img
                                alt=""
                                src={`${process.env.CDN}/img/search/${legendIcon}`}
                            />
                        )}
                        <span>{label}</span>
                    </li>
                ))}
            </ul>
        );
    };

    getGlobalContent = (isClustered) => {
        return (
            <>
                {!this._hideStatusLegend && this.getStatusContent()}
                {this.getFacilityContent(isClustered)}
            </>
        );
    };

    updateGlobalContent = ({ maxZoom, currentZoom }) => {
        const shouldCluster = currentZoom < maxZoom;
        if (shouldCluster !== this.isClustered) {
            this.isClustered = shouldCluster;
            this.updateContent(this.getGlobalContent(shouldCluster));
        }
    };
}
