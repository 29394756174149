/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * Camping.jsx
 * Created by Destin Frasier on 09/17/19
 */

import React from 'react';
import { FlexRow, FlexCol, Heading } from 'sarsaparilla';
import { getGatewayUrl } from 'shared-ui/utils/getGatewayUrl';

import SiteMapItem from './SiteMapItem';

export default class Camping extends React.PureComponent {
    render() {
        return (
            <FlexRow>
                <FlexCol>
                    <Heading headingLevel={2} appearance="h5">
                        Camping
                    </Heading>
                    <ul className="nav-site-map-wrap">
                        <SiteMapItem
                            name="Grand Canyon National Park"
                            url={getGatewayUrl('2733')}
                        />
                        <SiteMapItem
                            name="Mather Campground"
                            url="/camping/campgrounds/232490"
                        />
                        <SiteMapItem
                            name="Camping"
                            url="/search?inventory_type=camping"
                        />
                        <SiteMapItem
                            name="Day Use & Venues"
                            url="/search?inventory_type=dayuse&inventory_type=venuereservations"
                        />
                    </ul>
                </FlexCol>
            </FlexRow>
        );
    }
}
