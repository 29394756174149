/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Icons, Button, Badge, Checkbox, withLDConsumer } from 'sarsaparilla';
import { get, isEmpty, isNil } from 'lodash';
import moment from 'moment';
import FlexDates from 'ui-navigation/src/components/homepage/FlexDates/FlexDatesMain';

import * as searchActions from '../actions/search';
import * as types from '../constants/index';
import SearchInputContainer from './SearchInputContainer';
import { SearchCriteriaDates } from '../components/SearchCriteriaDates';
import { CAL_DATE_FORMAT } from '../constants';

class SearchCriteriaInputs extends React.Component {
    static propTypes = {
        account: PropTypes.object,
        search: PropTypes.object,
        dispatch: PropTypes.func,
        displayAvailability: PropTypes.object,
        page: PropTypes.string,
        isMainSearchCriteria: PropTypes.bool,
        isCampsitesSearchCriteria: PropTypes.bool,
        onApplyFilters: PropTypes.func,
        isCampingSearch: PropTypes.bool,
        hasDatesInfoModal: PropTypes.func,
        onShowAvailableModalChange: PropTypes.func,
        checkin_time: PropTypes.any,
        onApplyPreferences: PropTypes.func,
        loadingPreferences: PropTypes.bool,
        useSingleDateCalendar: PropTypes.any,
    };

    static defaultProps = {
        isMainSearchCriteria: true,
        isCampsitesSearchCriteria: false,
        displayAvailability: { isValid: false, dates: [] },
    };

    constructor(props) {
        super(props);
        this.onToggleFilters = this.onToggleFilters.bind(this);
        this.onFilterToggle = this.onFilterToggle.bind(this);
        this.handleShowAvailableChange = this.handleShowAvailableChange.bind(this);
    }

    componentDidMount() {
        if (!this.props.search.checkin_time) {
            this.props.dispatch(searchActions.updateSelectedDate('', true));
        }
        if (!this.props.search.checkout_time) {
            this.props.dispatch(searchActions.updateSelectedDate('', false));
        }
    }

    componentDidUpdate(prevProps) {
        const { search } = this.props;
        if (
            prevProps.search.checkin_time !== search.checkin_time &&
            isEmpty(search.checkin_time) &&
            isEmpty(search.avail_months) &&
            (!search.include_unavailable ||
                !search.include_notreservable ||
                !search.include_partially_available)
        ) {
            this.updateShowAvailableFilters(false);
        }
    }

    onFilterToggle(filterName, item) {
        this.props.dispatch(searchActions.toggleSearchFilter(filterName, item));
    }

    onToggleFilters() {
        // close filters and refresh results
        this.props.dispatch(
            searchActions.updateSearchCriteria(
                'filtersVisible',
                !this.props.search.filtersVisible
            )
        );
        this.props.dispatch(
            searchActions.updateSearchCriteria('inventoryTypeFiltersVisible', false)
        );

        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    handleResetFilters() {
        this.props.dispatch(searchActions.resetSearchFilters());

        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    showFiltersOnThisPage() {
        switch (this.props.page) {
            case types.CAMPSITE_PAGE:
            case types.CAMPGROUND_PAGE:
            case types.CAMPGROUND_AVAILABILITY_PAGE:
                return false;
            default:
                return true;
        }
    }

    handleShowAvailableChange(event) {
        if (this.props.hasDatesInfoModal(event)) {
            this.props.onShowAvailableModalChange(true);
            return;
        }

        this.updateShowAvailableFilters(event.currentTarget.checked);
    }

    updateShowAvailableFilters(value) {
        const uiFilters = {
            ...this.props.search?.uiFilters,
        };
        this.props.dispatch(
            searchActions.updateSearchCriterias({
                include_unavailable: !value,
                include_notreservable: !value,
                include_partially_available: !value,
                start: null,
                uiFilters,
            })
        );
        this.props.dispatch(searchActions.navigateToSearchResults());
    }

    onDatesChange = ({ startDate, endDate }) => {
        const useNewCampingFilters = this.props.flags?.useNewCampingFilters;
        const showAvailable =
            !get(this.props.search, 'include_unavailable', true) &&
            !get(this.props.search, 'include_notreservable', true) &&
            !get(this.props.search, 'include_partially_available', true) &&
            !(
                isEmpty(this.props.search.checkin_time) &&
                isEmpty(this.props.search.avail_months)
            );

        this.setState({
            startDate,
            endDate,
        });
        if (!showAvailable && useNewCampingFilters) {
            this.updateShowAvailableFilters(true);
        }
    };

    parseFlexDatesParams = (months, duration) => {
        let monthsArray = [];
        if (months) {
            months.map((item) => {
                const av = moment(item, 'MM-YYYY')
                    .startOf('months')
                    .format('YYYY-MM-DD[T]HH:mm:ss[Z]');
                monthsArray = [...monthsArray, av];
                return item;
            });
        }
        return { avail_months: monthsArray, avail_nights: duration };
    };

    onFlexFiltersChange = (flexDatesFilter) => {
        const startDate = flexDatesFilter?.startDate || null;
        const endDate = flexDatesFilter?.endDate || null;
        let startDateStr = startDate
            ? moment(flexDatesFilter.startDate, types.CAL_DATE_FORMAT)
                  .startOf('day')
                  .format(types.CAL_DATE_FORMAT)
            : null;
        let endDateStr = endDate
            ? moment(flexDatesFilter.endDate, types.CAL_DATE_FORMAT)
                  .startOf('day')
                  .format(types.CAL_DATE_FORMAT)
            : null;
        const { avail_months, avail_nights } = this.parseFlexDatesParams(
            flexDatesFilter?.months,
            flexDatesFilter?.duration
        );
        let sort = this.props.search.sort;

        this.props.dispatch(searchActions.updateSelectedDate(startDateStr, true));
        this.props.dispatch(searchActions.updateSelectedDate(endDateStr, false));

        if (startDateStr) {
            if (!sort || sort === '' || sort === 'score') {
                // default to available sort if dates are set
                sort = 'available';
            }
        } else if (sort === 'available') {
            // clear available sort if no dates are set
            sort = null;
        }
        if (flexDatesFilter?.months && flexDatesFilter?.duration) {
            sort = 'available';
        }

        let avMonths = avail_months;
        let avNights = avail_nights;
        if (isEmpty(flexDatesFilter)) {
            startDateStr = '';
            endDateStr = '';
            avMonths = [];
            avNights = '';
        }

        let showAvailable = {};
        if (startDate && endDate && this.props.flags?.useNewCampingFilters) {
            showAvailable = {
                include_unavailable: false,
                include_notreservable: false,
                include_partially_available: false,
            };
        }

        this.props.dispatch(
            searchActions.updateSearchCriterias({
                start: null,
                cursor_mark: null,
                sort,
                checkin_time: startDateStr,
                checkout_time: endDateStr,
                flex_start_range: flexDatesFilter?.startRange || '',
                flex_end_range: flexDatesFilter?.endRange || '',
                avail_nights: avNights || '',
                avail_months: avMonths || [],
                ...showAvailable,
            })
        );

        this.props.onApplyFilters();
    };

    getFlexDates = (props) => {
        const flexDates = {};

        if (props.search.checkin_time) {
            flexDates.startDate = moment(props.search.checkin_time, CAL_DATE_FORMAT);
        }
        if (props.search.checkout_time) {
            flexDates.endDate = moment(props.search.checkout_time, CAL_DATE_FORMAT);
        }
        if (props.search.avail_nights) {
            flexDates.duration = props.search.avail_nights;
        }
        if (props.search.flex_start_range) {
            flexDates.startRange = props.search.flex_start_range;
        }
        if (props.search.flex_end_range) {
            flexDates.endRange = props.search.flex_end_range;
        }

        if (!isEmpty(props.search.avail_months)) {
            flexDates.months = props.search.avail_months?.map((item) =>
                moment(item).utc().format('MM-YYYY')
            );
        }
        return flexDates;
    };

    renderMainSearchCriteria() {
        const { flags } = this.props;
        let filterCount = 0;
        if (this.props.search && this.props.search.uiFilters) {
            filterCount += Object.keys(this.props.search.uiFilters.occupants).length;

            filterCount += Object.keys(this.props.search.uiFilters.activities).length;
            filterCount += Object.keys(this.props.search.uiFilters.agencies).length;

            if (flags?.useNewCampingFilters) {
                const fg = get(this.props.search, 'fg', []);
                filterCount += fg.length;
            }
            if (!flags?.useNewCampingFilters) {
                let fltValue = get(this.props.search, 'include_unavailable', true);
                if (fltValue === false) {
                    filterCount += 1;
                }
                fltValue = get(this.props.search, 'include_notreservable', true);
                if (fltValue === false) {
                    filterCount += 1;
                }
                fltValue = get(this.props.search, 'include_partially_available', true);
                if (fltValue === false) {
                    filterCount += 1;
                }
                filterCount += Object.keys(
                    this.props.search.uiFilters.campsiteUseTypes
                ).length;
                filterCount += Object.keys(
                    this.props.search.uiFilters.campsiteTypes
                ).length;
                filterCount += Object.keys(
                    this.props.search.uiFilters.campsiteAmenities
                ).length;
                filterCount += Object.keys(
                    this.props.search.uiFilters.campsiteEquipment
                ).length;
                filterCount += Object.keys(
                    this.props.search.uiFilters.campsiteElectricityHookup
                ).length;
            }
            filterCount += Object.keys(
                this.props.search.uiFilters.campsiteVehicleLength
            ).length;

            filterCount += Object.keys(this.props.search.uiFilters.tourTypes).length;
            filterCount += Object.keys(this.props.search.uiFilters.tourAccessible).length;
            filterCount += Object.keys(this.props.search.uiFilters.tourDifficulty).length;
            filterCount += Object.keys(this.props.search.uiFilters.tourDuration).length;
            filterCount += Object.keys(this.props.search.uiFilters.tourPrice).length;
            filterCount += Object.keys(this.props.search.uiFilters.tourTime).length;

            if (this.props.search.uiFilters?.tourTimes) {
                filterCount += Object.keys(this.props.search.uiFilters.tourTimes).length;
            }

            filterCount += Object.keys(this.props.search.uiFilters.permitTypes).length;
            filterCount += Object.keys(this.props.search.uiFilters.inventoryTypes).length;
        }

        const showAvailable =
            !get(this.props.search, 'include_unavailable', true) &&
            !get(this.props.search, 'include_notreservable', true) &&
            !get(this.props.search, 'include_partially_available', true) &&
            !(
                isEmpty(this.props.search.checkin_time) &&
                isEmpty(this.props.search.avail_months)
            );

        const flexDatesFilter = this.getFlexDates(this.props);

        const applyPreferences =
            this.props.account &&
            !isNil(this.props.search?.tripPreferences) &&
            this.props.search?.tripPreferences;

        // Search results header filters
        return (
            <div className="header-filters" role="menubar">
                <SearchInputContainer />
                {this.props.isCampingSearch && (
                    <div className="search-flex-wrapper">
                        <FlexDates
                            monthsLimit={4}
                            onFiltersChange={this.onFlexFiltersChange}
                            filters={flexDatesFilter}
                            isSearch
                            showClear
                        />
                    </div>
                )}
                {!this.props.isCampingSearch && (
                    <div className="date-range-picker-wrap">
                        <SearchCriteriaDates
                            onApplyFilters={this.props.onApplyFilters}
                            onDatesChange={this.onDatesChange}
                        />
                    </div>
                )}

                {flags?.enableMoreFiltersButtonRedesign && (
                    <div className="show-filters-dropdown-wrap more-filters-btn__wrapper pl-1 pr-2">
                        <div
                            className="rec-select-holder show-filters-dropdown"
                            role="menuitem"
                        >
                            <Button
                                className="more-filters-btn"
                                appearance="tertiary"
                                iconBeforeElement={<Icons.IconFilterList />}
                                aria-label={
                                    this.props.search.filtersVisible
                                        ? 'Hide filters'
                                        : 'Show filters'
                                }
                                onClick={this.onToggleFilters}
                                ref={(holder) => {
                                    this.holder = holder;
                                }}
                                gaTrackingId="554715340412"
                            >
                                {this.props.search.filtersVisible
                                    ? 'Hide Filters'
                                    : 'More Filters'}
                                {filterCount > 0 && (
                                    <Badge appearance="primary">{filterCount}</Badge>
                                )}
                            </Button>
                        </div>
                    </div>
                )}

                <div className="search-header-check-item-wrap">
                    <Checkbox
                        className="mb-1"
                        label="Only show available"
                        onChange={(e) => this.handleShowAvailableChange(e)}
                        value="show_unavailable"
                        isChecked={showAvailable}
                        clickTagCategory="Search"
                        clickTagAction={
                            showAvailable
                                ? 'Only Show Available Checkbox Unchecked'
                                : 'Only Show Available Checkbox Checked'
                        }
                        clickTagLabel="Top Search Bar"
                        name="only_show_available"
                    />
                </div>

                <div className="search-header-check-item-wrap">
                    <Checkbox
                        className="mb-1"
                        label="Apply Trip Preferences"
                        onChange={this.props.onApplyPreferences}
                        isDisabled={this.props.loadingPreferences}
                        value="trip_preferences"
                        id="toggle-preferences"
                        isChecked={applyPreferences || false}
                        clickTagCategory="Search"
                        clickTagAction={
                            applyPreferences
                                ? 'Apply Trip Preferences Unchecked'
                                : 'Apply Trip Preferences Checked'
                        }
                        clickTagLabel="Top Search Bar"
                    />
                </div>

                {!flags?.enableMoreFiltersButtonRedesign && (
                    <div className="show-filters-dropdown-wrap">
                        <div
                            className="rec-select-holder show-filters-dropdown"
                            role="menuitem"
                        >
                            <Button
                                className="more-filters-btn"
                                appearance="link"
                                iconBeforeElement={<Icons.IconFilterList />}
                                aria-label={
                                    this.props.search.filtersVisible
                                        ? 'Hide filters'
                                        : 'Show filters'
                                }
                                onClick={this.onToggleFilters}
                                ref={(holder) => {
                                    this.holder = holder;
                                }}
                                gaTrackingId="554715340412"
                            >
                                {this.props.search.filtersVisible
                                    ? 'Hide Filters'
                                    : 'More Filters'}
                                {filterCount > 0 && (
                                    <Badge appearance="primary">{filterCount}</Badge>
                                )}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    renderCampsitesSearchCriteria() {
        let filterCount = 0;
        if (this.props.search && this.props.search.uiFilters) {
            filterCount += Object.keys(
                this.props.search.uiFilters.campsiteUseTypes
            ).length;
            filterCount += Object.keys(this.props.search.uiFilters.campsiteTypes).length;
            filterCount += Object.keys(
                this.props.search.uiFilters.campsiteAmenities
            ).length;
            filterCount += Object.keys(
                this.props.search.uiFilters.campsiteEquipment
            ).length;
            filterCount += Object.keys(
                this.props.search.uiFilters.campsiteElectricityHookup
            ).length;
            filterCount += Object.keys(
                this.props.search.uiFilters.campsiteVehicleLength
            ).length;
            filterCount += Object.keys(this.props.search.uiFilters.occupants).length;
        }

        // Campgrounds header filters
        return (
            <div className="header-filters">
                <SearchCriteriaDates
                    displayAvailability={this.props.displayAvailability}
                    className="camping-view-date-picker"
                    onApplyFilters={this.props.onApplyFilters}
                    useSingleDateCalendar={this.props.useSingleDateCalendar}
                    role="menuitem"
                />

                <div className="show-filters-dropdown-wrap camping-view">
                    <div
                        className="rec-select-holder show-filters-dropdown"
                        role="menuitem"
                    >
                        <Button
                            className="more-filters-btn"
                            appearance="link"
                            iconBeforeElement={<Icons.IconFilterList />}
                            aria-label={
                                this.props.search.filtersVisible
                                    ? 'Hide filters'
                                    : 'Show filters'
                            }
                            onClick={this.onToggleFilters}
                            ref={(holder) => {
                                this.holder = holder;
                            }}
                        >
                            {this.props.search.filtersVisible
                                ? 'Hide Filters'
                                : 'More Filters'}
                            {filterCount > 0 && (
                                <Badge appearance="primary">{filterCount}</Badge>
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const showFilters = this.showFiltersOnThisPage();
        if (showFilters) {
            if (this.props.isCampsitesSearchCriteria) {
                return this.renderCampsitesSearchCriteria();
            }
            if (this.props.isMainSearchCriteria) {
                return this.renderMainSearchCriteria();
            }
            return null;
        }
        return null;
    }
}

function select(state) {
    return {
        account: get(state, 'auth.account', null),
        search: state.search,
        searchAggregations: state.searchAggregations,
    };
}

export default connect(select)(withLDConsumer()(SearchCriteriaInputs));
