/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { LoggingHelper } from 'sarsaparilla';

export function milesToKms(miles) {
    let kms = null;
    if (typeof miles === 'number') {
        kms = (miles * 1.60934).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }
    return kms;
}

export function kmsToMiles(kms) {
    let miles = null;
    if (typeof kms === 'number') {
        miles = (kms * 0.621371).toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
    }
    return miles;
}

export function isEmpty(str) {
    return str === null || str.length === 0;
}

// This is needed to compensate for fetch's lack of url parameter management without string interpolation
export function urlWithParams(urlString, params = {}) {
    let searchParams = '';
    Object.keys(params).forEach((key) => {
        let sep = '';
        if (searchParams !== '') {
            sep = '&';
        }
        if (params[key] || params[key] === 0 || params[key] === false) {
            const val = params[key];
            if (!isEmpty(val) || val === 0 || val === false) {
                if (Array.isArray(val)) {
                    val.forEach((v) => {
                        if (searchParams !== '') {
                            sep = '&';
                        }
                        searchParams = `${searchParams + sep + key}=${encodeURIComponent(
                            v
                        )}`;
                    });
                } else {
                    searchParams = `${searchParams + sep + key}=${encodeURIComponent(
                        val
                    )}`;
                }
            }
        }
    });
    let ret = urlString;
    if (!isEmpty(searchParams)) {
        ret = `${ret}?${searchParams}`;
    }
    return ret;
}

let lastId = 0;

export function newId(prefix = 'id') {
    lastId += 1;
    return `${prefix}${lastId}`;
}

// parse fetch response to json
export function json(response) {
    return response.json();
}

// fetch doesn't handle errors well
export function status(response) {
    if (response.status >= 200 && response.status < 300) {
        // console.log(response.status, response)
        return Promise.resolve(response);
    }
    LoggingHelper.logError(response.statusText);
    // console.log(response)
    // return Promise.reject(new Error(response.statusText))
    return response
        .json()
        .catch(() => {
            const error = new Error(response.statusText);
            error.response = response;
            // throw error
            return Promise.resolve(response);
        })
        .then((data) => {
            // console.log(json)
            const error = new Error(data || response.statusText);
            error.response = response;
            // throw error
            return Promise.resolve(data);
        });
}

export function groupFilters(items = [], ids = []) {
    const hasItems = !!items?.length;
    const hasIds = !!ids?.length;

    if (!hasItems && !hasIds) {
        return [];
    }

    const primaryGroup = [];
    const secondaryGroup = [];

    items.forEach((item) => {
        if (ids?.includes(item?.key)) {
            primaryGroup.push(item);
        } else {
            secondaryGroup.push(item);
        }
    });

    return [primaryGroup, secondaryGroup];
}
