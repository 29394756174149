/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { FlexCol, FlexRow, SearchBarContainer, Button } from 'sarsaparilla';

export default function MobileSearch({
    isLoading,
    submitSearch,
    runSearch,
    pictureSource,
}) {
    return (
        <section className="nav-hero-wrap-new">
            <div className="nav-hero-content-wrap">
                <FlexRow hasGutters={false}>
                    <FlexCol>
                        <div className="nav-hero-search-section-wrap-new mobile">
                            <div className="nav-search-input-wrapper">
                                <div className="form-item-wrap">
                                    <SearchBarContainer
                                        size="lg"
                                        placeholder="Search for campsites, tours, and more..."
                                        onRunSearch={runSearch}
                                        defaultEventCategory="Homepage"
                                        id="hero-mobile-search-input"
                                    />
                                </div>

                                <Button
                                    className="nav-search-button"
                                    isLoading={isLoading}
                                    onClick={submitSearch}
                                    id="gtm-explore-all-hero-mobile-search"
                                >
                                    SEARCH
                                </Button>
                            </div>
                        </div>
                    </FlexCol>
                </FlexRow>
            </div>

            <picture>
                {/* Show the hero image as an .avif */}
                {pictureSource('avif')}

                {/* If the browser can't handle an .avif, try a .webP */}
                {pictureSource('webp')}

                {/* If the browser can't handle an .avif or .webP, go with .jpg */}
                {pictureSource('jpg')}
            </picture>
        </section>
    );
}

MobileSearch.propTypes = {
    isLoading: PropTypes.bool,
    submitSearch: PropTypes.func,
    runSearch: PropTypes.func,
    pictureSource: PropTypes.func,
};

MobileSearch.defaultProps = {
    isLoading: false,
};
