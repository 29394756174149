/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-use-before-define */

import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { sarsaparillaReducers } from 'sarsaparilla';
import { searchResultsReducers } from 'ui-search';

import contactReducer from '../reducers/contactReducer';
import mapReducer from '../reducers/mapReducer';
import { articlesApiSlice } from './articles-api-slice';
import articlesSlice from './articles-slice';

const rootReducer = combineReducers({
    ...sarsaparillaReducers,
    [articlesApiSlice.reducerPath]: articlesApiSlice.reducer,
    articles: articlesSlice,
    navigation: combineReducers({
        contact: contactReducer,
        map: mapReducer,
        searchResults: searchResultsReducers,
    }),
});

export function setupStore() {
    return configureStore({
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ serializableCheck: false }) // temporary because of LD hack
                .concat(articlesApiSlice.middleware), // This middleware is required for RTK Query and is not added by default
        reducer: rootReducer,
    });
}

export const store = setupStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
