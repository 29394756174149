/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    StringHelper,
    SearchActions,
    Autosuggest,
    LocationSuggestion,
    Icon,
    type LocationSuggestionOptionType,
} from 'sarsaparilla';
import debounce from 'lodash/debounce';
import { Section, Item } from 'react-stately';
import { AppDispatch, RootState } from 'ui-search/dev/store';
import * as actions from '../actions/search';
import type { SearchInputContainerProps, Suggestion } from '../types';
import { getTargetUri } from '../utils/pathUtils';

const TEXT_SEARCH_NEAR_ME = 'Search for places or activities near me';
const TEXT_CLEAR_SEARCH_HIST = 'Clear Search History';

const containsEmail = (email: string) => {
    return email?.match(
        // eslint-disable-next-line no-useless-escape
        /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/
    );
};

// SearchInputContainer is search input box and autosuggestions combo
export default function SearchInputContainer(props: SearchInputContainerProps) {
    const {
        placeholder = 'Where to?',
        updateSearchCriterias = SearchActions.updateSearchCriterias,
        fetchSuggestions = SearchActions.fetchSuggestions,
        disableSearchOnSelect = false,
        queryCriteriaPropertyName = 'what',
        onFocus: onFocusProp,
        onRunSearch,
        onBlur: onBlurProp,
    } = props;

    const dispatch = useDispatch<AppDispatch>();
    const search = useSelector((state: RootState) => state.search);
    const searchSuggestions = useSelector(
        (state: RootState) => state.searchSuggestions
    ) || {
        suggestions: [],
        inventory_suggestions: [],
        content_suggestions: [],
    };

    const [searchText, setSearchText] = React.useState(
        search[queryCriteriaPropertyName] || ''
    );
    const [searchHistory, setSearchHistory] = React.useState<Suggestion[]>([]);
    const [selectedKey, setSelectedKey] = React.useState<
        string | number | null | undefined
    >(null);

    const selectedSuggestion = React.useRef<Suggestion | null>(null);

    const debouncedFetchSuggestions = debounce((value) => {
        dispatch(fetchSuggestions(value));
    }, 300);

    const onSuggestionsFetchRequested = React.useCallback(
        ({ value }: { value: string }) => {
            if (value && value.length > 0) {
                debouncedFetchSuggestions(value);
            }
        },
        [debouncedFetchSuggestions]
    );

    // execute search request to fetch search results
    const runSearch = async (
        suggestion: Suggestion | null,
        searchTextCriteria = searchText
    ) => {
        const eventCategory = 'Search';
        let eventAction = 'Submit Search';
        let eventLabel = searchTextCriteria;
        if (suggestion) {
            eventAction = 'Select Search Suggestion';
            if (suggestion.last_used) {
                eventAction = 'Select Search History Suggestion';
            }
            eventLabel = suggestion.name || suggestion.text;
        }

        try {
            await SearchActions.addSearchHistoryItem({
                searchHistory,
                rawSearchText: searchTextCriteria,
                suggestion,
            });
        } catch (e) {
            // ignore errors
            // eslint-disable-next-line no-console
            console.error('error saving history', e);
        }

        if (containsEmail(eventLabel)) {
            eventLabel = '[Redacted Email]';
        }
        const event = {
            category: eventCategory,
            action: eventAction,
            label: eventLabel,
        };
        if (
            suggestion &&
            (suggestion.reservable ||
                suggestion.entity_type === 'recarea' ||
                suggestion.entity_type === 'kb' ||
                suggestion.entity_type === 'cms' ||
                suggestion.entity_type === 'page' ||
                suggestion.entity_type === 'pass')
        ) {
            const url = getTargetUri(suggestion);
            SearchActions.trackRunSearch(event, () => {
                window.location.assign(url);
            });
        } else if (onRunSearch) {
            SearchActions.trackRunSearch(event, () => {
                onRunSearch(searchTextCriteria, suggestion);
            });
        } else {
            let lat;
            let lng;
            let radius;
            let location;
            let lat_sw;
            let lng_sw;
            let lat_ne;
            let lng_ne;
            if (
                suggestion &&
                !suggestion.entity_type &&
                suggestion.lat &&
                suggestion.lng
            ) {
                // default search to suggestion location, if selected
                lat = suggestion.lat;
                lng = suggestion.lng;
                radius = 200;
                location = suggestion.text;

                // apply only to large states
                if (
                    suggestion.lat_sw &&
                    suggestion.lat_ne &&
                    suggestion.lng_sw &&
                    suggestion.lng_ne
                ) {
                    lat = null;
                    lng = null;
                    lat_sw = suggestion.lat_sw;
                    lng_sw = suggestion.lng_sw;
                    lat_ne = suggestion.lat_ne;
                    lng_ne = suggestion.lng_ne;
                }
            }

            const criteria = {
                what: searchTextCriteria,
                headerTextQuery: null,
                filtersVisible: false,
                entity_id: suggestion != null ? suggestion.entity_id : null,
                entity_type: suggestion != null ? suggestion.entity_type : null,
                lat,
                lng,
                radius,
                location,
                map_center_lat: null,
                map_center_lng: null,
                start: null,
                cursor_mark: null,
                placename: null,
                user_placename: null,
                lat_sw,
                lng_sw,
                lat_ne,
                lng_ne,
                parent_asset_id: null,
                org_id: null,
            };
            dispatch(updateSearchCriterias(criteria));
            SearchActions.trackRunSearch(event, () => {
                const uri = actions.buildSearchNavUri('/search', { search });
                window.history.replaceState({}, '', uri);
                dispatch(actions.fetchSearchResults());
            });
        }
    };

    React.useEffect(() => {
        if (!selectedSuggestion.current) {
            const nextWhat = search[queryCriteriaPropertyName];
            if (
                !nextWhat ||
                (nextWhat &&
                    nextWhat !== '' &&
                    (!searchText || searchText === '' || nextWhat !== searchText))
            ) {
                setSearchText(nextWhat || '');
                onSuggestionsFetchRequested({
                    value: search[queryCriteriaPropertyName] || '',
                });
            }
        }
    }, [onSuggestionsFetchRequested, queryCriteriaPropertyName, search, searchText]);

    const loadHistory = async () => {
        try {
            const loadedHistory = await SearchActions.loadSearchHistoryItems();
            setSearchHistory(loadedHistory);
        } catch (e) {
            // ignore errors
            // eslint-disable-next-line no-console
            console.error('error loading history', e);
        }
    };

    // on suggestion value change (on keypress etc)
    const onChange = ({ newValue }: { newValue: string }) => {
        if (newValue === TEXT_SEARCH_NEAR_ME || newValue === TEXT_CLEAR_SEARCH_HIST) {
            return;
        }

        if (selectedKey) {
            setSelectedKey(null);
            selectedSuggestion.current = null;
        }

        setSearchText(newValue);
        const updateParams: { [key: string]: any } = {};
        updateParams[queryCriteriaPropertyName] = newValue;
        dispatch(updateSearchCriterias(updateParams));
    };

    // get search suggestion string to display in the drop down
    const getSuggestionValue = (suggestion: Suggestion) => {
        let suggestionText = suggestion.text;
        if (suggestion.is_inventory) {
            // if suggestion is associated with an inventory name, then capitalize the name to clean it up
            suggestionText = StringHelper.toTitleCase(suggestionText);
        }

        return suggestionText;
    };

    // when auto suggestion was selected somehow using navs
    const onSuggestionSelected = async ({ suggestion }: { suggestion: Suggestion }) => {
        if (suggestion.text === TEXT_SEARCH_NEAR_ME) {
            selectedSuggestion.current = null;
            setSearchHistory([]);
            setSearchText('');
            runSearch(null, ''); // pass empty string to run search near me to override current searchText state.
            return;
        }

        if (suggestion.text === TEXT_CLEAR_SEARCH_HIST) {
            selectedSuggestion.current = null;
            try {
                await SearchActions.deleteSearchHistoryItems();
            } catch (e) {
                // ignore errors
                // eslint-disable-next-line no-console
                console.error('error deleting history', e);
            }
            setSearchHistory([]);
            setSearchText('');
            return;
        }

        const suggestionText = getSuggestionValue(suggestion);
        selectedSuggestion.current = suggestion;
        setSearchText(suggestionText);
        dispatch(updateSearchCriterias({ selectedSuggestion: suggestion }));
        if (!disableSearchOnSelect) {
            runSearch(suggestion, suggestionText);
        }
    };

    // on focus search input
    const onFocus = (event: React.FocusEvent) => {
        loadHistory();
        if (onFocusProp) {
            onFocusProp(event);
        }
    };

    // on removal of focus from search input
    const onBlur = (event: React.FocusEvent) => {
        if (onBlurProp) {
            onBlurProp(event);
        }
    };

    // construct suggestion sections from fetched data
    const getSuggestions = () => {
        const sections = [];

        if (!searchText || searchText === '') {
            const sHist = [...searchHistory];
            if (sHist && sHist.length > 0) {
                sections.push({
                    text: TEXT_SEARCH_NEAR_ME,
                    id: 'searchNearMe',
                });
                sHist.push({
                    text: TEXT_CLEAR_SEARCH_HIST,
                    id: 'clearSearchHistory',
                    customRender: (
                        <div className="search-suggestion-content search-suggestion-link clear-search-history">
                            <Icon iconName="loop" size="sm" />
                            <span>{TEXT_CLEAR_SEARCH_HIST}</span>
                        </div>
                    ),
                });
                sections.push({
                    suggestions: sHist,
                    title: 'Recent Searches',
                });
            }
            return sections;
        }

        if (searchSuggestions.inventory_suggestions?.length > 0) {
            sections.push({
                title: 'Recreation Areas, Facilities, Tours, Trails',
                suggestions: searchSuggestions.inventory_suggestions,
            });
        }

        if (searchSuggestions.suggestions?.length > 0) {
            sections.push({
                title: 'Locations, City, State, Zip Code',
                suggestions: searchSuggestions.suggestions,
            });
        }

        if (searchSuggestions.content_suggestions?.length > 0) {
            sections.push({
                title: 'Help Topics, Frequently Asked Questions, Articles',
                suggestions: searchSuggestions.content_suggestions,
            });
        }

        return sections;
    };

    const getItemId = (item: Suggestion) => {
        const { id, lat = '', lng = '', name = '', text = '' } = item;

        if (id) return id;

        return `${lat}${lng}${name}${text}`;
    };

    const getSuggestionByKey = (key: React.Key) => {
        const suggestions = getSuggestions();
        for (const option of suggestions) {
            if (option.suggestions) {
                const optionSuggestion = option.suggestions.find(
                    (suggestion: Suggestion) => getItemId(suggestion) === key
                );

                if (optionSuggestion) return optionSuggestion;
            } else if (getItemId(option as Suggestion) === key) {
                return option;
            }
        }

        return null;
    };

    const handleInputChange = (value: string) => {
        onChange({ newValue: value });
        onSuggestionsFetchRequested({ value });
    };

    const handleOnSelect = (key: React.Key) => {
        const suggestion = getSuggestionByKey(key);

        if (!suggestion) return;

        onSuggestionSelected({ suggestion });
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        const { key } = event;

        if (key === 'Enter' && !selectedSuggestion.current) {
            runSearch(null);
        }
    };

    const handleSelectionChange = (key: React.Key | null) => {
        if (!key) return;

        const suggestion = getSuggestionByKey(key);

        if (!suggestion) return;

        // console.log('🚀 ~ handleSelectionChange ~ suggestion:', suggestion);

        const suggestionText = getSuggestionValue(suggestion);

        if (
            suggestionText !== TEXT_SEARCH_NEAR_ME &&
            suggestionText !== TEXT_CLEAR_SEARCH_HIST
        ) {
            selectedSuggestion.current = suggestion;
            setSelectedKey(getItemId(suggestion));
            setSearchText(suggestionText);
        }
    };

    const inputValue = searchText || '';
    const suggestions = getSuggestions();

    return (
        <div className={`search-input-wrapper`}>
            <div className="form-item-wrap" role="search">
                <Autosuggest
                    allowsCustomValue
                    allowsEmptyCollection
                    isLabelVisible={false}
                    menuTrigger="focus"
                    label={`Search ${process.env.SITE_NAME}`}
                    placeholder={placeholder}
                    inputValue={inputValue}
                    items={suggestions}
                    selectedKey={selectedKey}
                    onInputChange={handleInputChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSelect={handleOnSelect}
                    onKeyDown={handleKeyDown}
                    onClear={() => {
                        onSuggestionSelected({
                            suggestion: { text: TEXT_SEARCH_NEAR_ME },
                        });
                    }}
                    onSelectionChange={handleSelectionChange}
                >
                    {(option) => {
                        return option.title ? (
                            <Section
                                key={option.title}
                                title={option.title}
                                items={
                                    option.suggestions as LocationSuggestionOptionType[]
                                }
                            >
                                {(item: LocationSuggestionOptionType) => {
                                    return (
                                        <Item
                                            key={getItemId(item)}
                                            textValue={getSuggestionValue(item)}
                                        >
                                            <LocationSuggestion
                                                suggestion={item}
                                                searchText={inputValue}
                                            />
                                        </Item>
                                    );
                                }}
                            </Section>
                        ) : (
                            <Item
                                key={getItemId(option as Suggestion)}
                                textValue={getSuggestionValue(option as Suggestion)}
                            >
                                <LocationSuggestion
                                    suggestion={option as LocationSuggestionOptionType}
                                    searchText={inputValue}
                                />
                            </Item>
                        );
                    }}
                </Autosuggest>
            </div>
        </div>
    );
}

SearchInputContainer.propTypes = {
    placeholder: PropTypes.string,
    updateSearchCriterias: PropTypes.func,
    fetchSuggestions: PropTypes.func,
    disableSearchOnSelect: PropTypes.bool,
    queryCriteriaPropertyName: PropTypes.string,
    onFocus: PropTypes.func,
    onRunSearch: PropTypes.func,
    onBlur: PropTypes.func,
};

//cspell:ignore Criterias (it's supposed to be criteria), recarea, navs
