/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from 'sarsaparilla';
import { SEARCH_FILTER_PERMIT_TYPES as allOptions } from '../constants';

export default class SearchFilterPermitType extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        uiFilters: PropTypes.object,
        aggregations: PropTypes.object,
        onFilterToggle: PropTypes.func,
        onApplyFilters: PropTypes.func,
        showCounts: PropTypes.bool,
        viewType: PropTypes.string,
        useNewCampingFilters: PropTypes.bool,
    };

    static defaultProps = {
        enableAll: false,
        showCounts: true,
        viewType: 'checkbutton',
    };

    static displayName = 'SearchFilterPermitType';

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange(event, i) {
        const item = i;
        item.filters = [
            {
                attribute: 'permit_activities',
                filterValue: item.value,
            },
        ];
        if (this.props.onFilterToggle) {
            this.props.onFilterToggle('permitTypes', item);
        }
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    processAggregations(searchAggregations) {
        const counts = {};
        if (
            searchAggregations.permitTypes &&
            searchAggregations.permitTypes.permit_activities
        ) {
            const aggs = searchAggregations.permitTypes.permit_activities;
            for (let i = 0; i < aggs.length; i += 1) {
                counts[aggs[i].name.toUpperCase()] = aggs[i].count;
            }
        }
        return counts;
    }

    renderSelection(item, counts) {
        const value = item.value.toUpperCase();
        const count = counts[value] || 0;
        const selections = this.props.uiFilters.permitTypes;
        const isChecked = selections.hasOwnProperty(item.key);
        let label;
        if (count || isChecked) {
            label = `${item.value} (${count})`;
        } else {
            label = item.value;
        }
        if (!this.props.showCounts) {
            label = item.value;
        }
        if (this.props.viewType === 'checkbox') {
            return (
                <div className="rec-form-check-wrap" key={item.key}>
                    <label
                        htmlFor={`activity_${item.key}`}
                        className="rec-label-checkbox"
                    >
                        <input
                            onChange={(e) => this.handleChange(e, item)}
                            type="checkbox"
                            id={`activity_${item.key}`}
                            className="rec-input-hide"
                            title={item.value}
                            disabled={count === 0 && !isChecked}
                            checked={isChecked}
                        />
                        <span className="rec-input-checkbox" />
                        {label}
                    </label>
                </div>
            );
        }

        return (
            <div className="search-filter-check-item" htmlFor={item.key} key={item.key}>
                <Checkbox
                    onChange={(e) => this.handleChange(e, item)}
                    id={item.key}
                    label={label}
                    title={item.value}
                    value={item.value}
                    isDisabled={count === 0 && !isChecked}
                    isChecked={isChecked}
                />
            </div>
        );
    }

    render() {
        const className = `${this.props.className}`;
        const counts = this.processAggregations(this.props.aggregations);
        //  const wrapperClass = this.props.viewType === 'checkbox' ? 'search-filter-multicolumn' : 'rec-check-buttons-wrap';
        const checkboxListClassName = this.props.useNewCampingFilters
            ? 'search-filters-double-type-wrap'
            : 'search-filters-check-wrap';
        return (
            <div className={className}>
                <div
                    className={checkboxListClassName}
                    role="group"
                    aria-label="Permit type filters"
                >
                    <legend className="rec-sr-only">
                        Select permit types to filter on
                    </legend>
                    {allOptions.map((item) => this.renderSelection(item, counts))}
                </div>
            </div>
        );
    }
}
