/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * FeaturedArticle.jsx
 * Created by Destin Frasier on 11/22/17
 * Updated 09/14/20
 */

import { Heading, FauxImage, Button, useBreakpoint } from 'sarsaparilla';
import { parseArticleImage, getContentTypeLabel } from '../../../utils/articlesHelper';
import type { ArticleRecordWithUrl } from '../../../types';

interface FeaturedArticleProps {
    article: ArticleRecordWithUrl;
}

export default function FeaturedArticle({ article }: FeaturedArticleProps) {
    const { isSmAndDown } = useBreakpoint();
    const label = getContentTypeLabel(article.content_type);

    return isSmAndDown ? (
        <Button
            isUnstyled
            className="nav-articles-featured-mobile-block-wrap"
            href={article.url}
            key={article.id}
            clickTagCategory="Homepage"
            clickTagAction="Featured Article Link (Mobile View)"
            clickTagLabel="Inspiration & Information Section"
        >
            <div className="nav-articles-featured-mobile-image-wrap">
                <div className="nav-articles-featured-mobile-section-name">{label}</div>
                <FauxImage
                    src={parseArticleImage(article)}
                    altText={article.hero_image_alt_text}
                    aria-hidden="true"
                    style={{ minHeight: '100%' }}
                />
            </div>
            <div className="nav-articles-featured-mobile-title-wrap">
                {article.article_title}
            </div>
        </Button>
    ) : (
        <Button
            isUnstyled
            to={article.url}
            key={article.id}
            clickTagCategory="Homepage"
            clickTagAction="Featured Article Link"
            clickTagLabel="Inspiration & Information Section"
        >
            <div className="nav-articles-featured-image-wrap">
                <div className="nav-articles-featured-section-title-wrap">
                    <div className="nav-articles-featured-section-name">{label}</div>
                    <Heading
                        headingLevel={2}
                        appearance="h4"
                        className="nav-articles-featured-headline"
                    >
                        {article.article_title}
                    </Heading>
                </div>

                <FauxImage
                    src={parseArticleImage(article)}
                    altText={article.hero_image_alt_text}
                    aria-hidden="true"
                    style={{ minHeight: '436px' }}
                />
            </div>
        </Button>
    );
}
