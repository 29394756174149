/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

/*
 * QuoteSection.jsx
 * Created by Destin Frasier on 03/30/22
 */

import * as React from 'react';
import { ContentContainer, FlexRow, FlexCol, TextSlider } from 'sarsaparilla';

const textSliderItems = [
    {
        quote: 'Recreation.gov reduced confusion and frustration for the public and staff, allowing for a more enjoyable experience at the park. The benefits were more than staff imagined…We have about half of our campsites available for advanced reservation and we are looking into adding the remaining campsites to Recreation.gov.',
        author: 'Ryan Koepke',
        location: 'Park Guide, Dunewood Campground, Indiana Dunes National Park',
    },
    {
        quote: 'Transitioning the Keys Ranch tour to Recreation.gov has been one of the best decisions we have made. Better service to the public, savings in staff hours, and better communication of important need-to-know information.',
        author: 'Dave Larson',
        location: 'Keys Ranch Tours at Joshua Tree National Park',
    },
    {
        quote: 'The permit system on Recreation.gov is working just how we would have imagined. It has freed up a lot of our time on the phone and in the office to be able to focus on other projects and pressing matters… Customers are able to conveniently secure and pay for their reservation online, and even make modifications if needed. It has been wonderful!!',
        author: 'Todd Murdock',
        location: 'FM at Westwater Canyon River, Bureau of Land Management, Utah',
    },
];

export default function QuoteSection() {
    return (
        <section className="nav-ni-intro-section-wrap">
            <ContentContainer className="my-0">
                <FlexRow>
                    <FlexCol lg={8} lgOffset={2}>
                        <TextSlider
                            isQuote
                            hasNavButtons
                            shouldAutoRotate
                            className=""
                            slideInstructionsIdSuffix=""
                            cycleDuration={8000}
                        >
                            {textSliderItems.map((textSliderItem, index) => (
                                <React.Fragment key={index}>
                                    <q className="nav-ni-intro-section-quote">
                                        {textSliderItem.quote}
                                    </q>
                                    <strong>{textSliderItem.author}</strong>

                                    <span className="sarsa-text-slider-location nav-ni-intro-section-location">
                                        {textSliderItem.location}
                                    </span>
                                </React.Fragment>
                            ))}
                        </TextSlider>
                    </FlexCol>
                </FlexRow>
            </ContentContainer>
        </section>
    );
}
