/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Icon, Button, Text, InlineBulletList, StarRating } from 'sarsaparilla';
import { getSpecies } from 'ui-licensing/src/utils/asset';
import { gmuPageRoute } from 'ui-licensing/dev/routesArray';
import { createComponent, handleClosePopUp } from './common';

export default function licensingMapPopup(feature) {
    let location;
    if (!feature) return location;
    try {
        location = typeof feature === 'object' ? feature : JSON.parse(feature);
    } catch (e) {
        return location;
    }

    const image = location?.img || {
        url: `${process.env.CDN}/img/global/unavailable/image-unavailable.jpg`,
    };
    const stateName = 'colorado';
    const title = `GMU ${location?.asset_number_label}: ${location?.name}`;
    const nearItems = [location?.city, location?.state].filter((item) => !!item);
    const hasNearItems = !!nearItems?.length;
    const nearText = hasNearItems ? `Near ${nearItems.join(', ')}` : null;
    const specieItems = getSpecies(location);
    const species = specieItems?.sort((a, b) => a?.localeCompare(b));
    const hasSpecies = !!species?.length;
    const rating = location?.rating?.rating;
    const reviewCount = location?.rating?.reviewCount;

    const popup = (
        <div className="map-popup licensing-map-popup">
            <Button
                className="close-pop-up-btn"
                appearance="subtle"
                size="sm"
                shouldFitContainer={false}
                onClick={handleClosePopUp}
                iconBeforeElement={<Icon iconName="close" />}
                screenReaderTextBefore="Close the modal"
                gaTrackingId="681762123001"
            />
            {image.url && (
                <div className="map-popup-image">
                    <img src={image.url} alt={title} />
                </div>
            )}
            <div className="map-popup-message">
                <span className="h4 map-popup-title">{title}</span>
                {nearText && <Text className="map-popup-location">{nearText}</Text>}
                {hasSpecies && (
                    <InlineBulletList className="map-popup-species">
                        {species?.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </InlineBulletList>
                )}
                <div className="map-popup-controls">
                    <StarRating
                        className="map-popup-rating"
                        rating={rating}
                        reviewCount={reviewCount}
                    />
                    <Button
                        className="map-popup-cta"
                        href={gmuPageRoute(stateName, location?.asset_id)}
                        target="_blank"
                        rel="noopener noreferrer"
                        size="xxs"
                        title="View Details"
                        aria-label="View Details"
                    >
                        View Details
                    </Button>
                </div>
            </div>
        </div>
    );

    return createComponent(popup);
}
