/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import { NumberField } from 'sarsaparilla';
import { get } from 'lodash';

export default class SearchFilterCampsiteVehicleLength extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        uiFilters: PropTypes.object,
        onFilterChange: PropTypes.func,
        onFilterRemove: PropTypes.func,
        onApplyFilters: PropTypes.func,
        isRequired: PropTypes.bool,
        unitAfter: PropTypes.string,
    };

    static displayName = 'SearchFilterCampsiteVehicleLength';

    handleChange = (value) => {
        // create/update a range filter value
        const filterOption = {
            key: 'campsiteVehicleLength',
            value,
            filters: [
                {
                    attribute: 'campsite_equipment_max_length',
                    filterValue: `>=${value}`,
                },
            ],
        };
        if (value !== '' && value !== '0' && value !== 0) {
            if (this.props.onFilterChange) {
                this.props.onFilterChange('campsiteVehicleLength', filterOption);
            }
        } else if (this.props.onFilterRemove) {
            this.props.onFilterRemove('campsiteVehicleLength', filterOption);
        }

        this.runSearch();
    };

    runSearch() {
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    render() {
        const selections = this.props.uiFilters.campsiteVehicleLength;
        const label = this.props.unitAfter ? 'Vehicle Length' : 'Vehicle Length (ft)';
        return (
            <div className="rec-search-filters-number-field-wrap">
                <NumberField
                    visualLabel={label}
                    min={0}
                    isRequired={this.props.isRequired}
                    max={99}
                    id="vehicle-length"
                    value={get(selections, 'campsiteVehicleLength.value', 0)}
                    onChange={this.handleChange}
                    shouldTriggerOnChange
                    units="feet"
                    unitAfter={this.props.unitAfter}
                />
            </div>
        );
    }
}
