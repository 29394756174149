/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * ArticleLandingHero.jsx
 * Created by Destin Frasier on 03/26/18.
 */

import { Button, Heading, HtmlParser } from 'sarsaparilla';

import type { ArticlesResponse } from '../../../types';
import { generateArticleLink } from '../../../utils/articlesHelper';

interface ArticleLandingHeroProps {
    data?: ArticlesResponse;
}

export function ArticleLandingHero({ data }: ArticleLandingHeroProps) {
    if (!data || !data.result.records) {
        return null;
    }

    const article = data.result.records[0];
    const url = generateArticleLink(
        article.content_type,
        article.article_title,
        article.id
    );

    return (
        <div
            className="article-landing-hero-full-width-wrap"
            style={{ backgroundImage: `url(${article.hero_image})` }}
        >
            <div className="article-landing-hero-body-outer-wrap">
                <div className="article-landing-hero-body">
                    <div className="article-landing-hero-body-main">
                        <Heading
                            appearance="h6"
                            headingLevel={2}
                            className="article-landing-hero-section-name"
                        >
                            Featured Article
                        </Heading>
                        <a href={url}>
                            <Heading
                                appearance="h2"
                                headingLevel={3}
                                className="article-landing-hero-section-title"
                            >
                                <HtmlParser html={article.article_title} />
                            </Heading>
                        </a>
                        <Button to={url} className="article-landing-hero-btn">
                            Learn More
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ArticleLandingHero;
