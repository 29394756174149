/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * partnerIconSingleton.js
 * Created by Destin Frasier 10/23/17
 */

import { IconSingleton } from '@fp/sarsaparilla';

class PartnerIconSingleton extends IconSingleton {
    constructor() {
        super();

        this.notificationName = 'icons-partner.loaded';
        this.svgPath = `${process.env.CDN}/graphics/partnerIcons.svg`;
    }
}

const instance = new PartnerIconSingleton();
export default instance;
