/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import {
    Checkbox,
    Button,
    Icons,
    Accordion,
    AccordionHeading,
    AccordionItem,
    AccordionContent,
} from 'sarsaparilla';
import { SEARCH_FILTER_ACTIVITIES as allOptions } from '../constants';

export const shortOptions = [
    {
        key: 'activity-auto-touring',
        value: 'Auto Touring',
    },
    {
        key: 'activity-boating',
        value: 'Boating',
    },
    {
        key: 'activity-camping',
        value: 'Camping',
    },
    {
        key: 'activity-climbing',
        value: 'Climbing',
    },
    {
        key: 'activity-diving',
        value: 'Diving',
    },
    {
        key: 'activity-fish-hatchery',
        value: 'Fish Hatchery',
    },
    {
        key: 'activity-fishing',
        value: 'Fishing',
    },
    {
        key: 'activity-hiking',
        value: 'Hiking',
    },
    {
        key: 'activity-historic-and-cultural-site',
        value: 'Historic & Cultural Site',
    },
    {
        key: 'activity-horseback-riding',
        value: 'Horseback Riding',
    },
    {
        key: 'activity-hunting',
        value: 'Hunting',
    },
    {
        key: 'activity-off-highway-vehicle',
        value: 'Off Highway Vehicle',
    },
    {
        key: 'activity-paddling',
        value: 'Paddling',
    },
    {
        key: 'activity-picnicking',
        value: 'Picnicking',
    },
    {
        key: 'activity-snorkeling',
        value: 'Snorkeling',
    },
    {
        key: 'activity-snowpark',
        value: 'Snowpark',
    },
    {
        key: 'activity-swimming',
        value: 'Swimming',
    },
    {
        key: 'activity-water-sports',
        value: 'Water Sports',
    },
    {
        key: 'activity-wildlife-viewing',
        value: 'Wildlife Viewing',
    },
    {
        key: 'activity-winter-sports',
        value: 'Winter Sports',
    },
];

export default class SearchFilterActivities extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        uiFilters: PropTypes.object,
        aggregations: PropTypes.object,
        onFilterToggle: PropTypes.func,
        onApplyFilters: PropTypes.func,
        enableAll: PropTypes.bool,
        showCounts: PropTypes.bool,
        viewType: PropTypes.string,
        useShortList: PropTypes.bool,
        useNewCampingFilters: PropTypes.bool,
        expandable: PropTypes.bool,
    };

    static defaultProps = {
        enableAll: false,
        showCounts: true,
        viewType: 'checkbutton',
        useShortList: false,
    };

    static displayName = 'SearchFilterActivities';

    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
        };
    }

    handleChange(event, i) {
        const item = i;
        item.filters = [
            {
                attribute: 'asset_activities',
                filterValue: item.value.toUpperCase(),
            },
        ];
        if (this.props.onFilterToggle) {
            this.props.onFilterToggle('activities', item);
        }
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    processAggregations(searchAggregations) {
        const counts = {};
        if (
            searchAggregations.activities &&
            searchAggregations.activities.asset_activities
        ) {
            const aggs = searchAggregations.activities.asset_activities;
            for (let i = 0; i < aggs.length; i++) {
                counts[aggs[i].name] = aggs[i].count;
            }
        }
        return counts;
    }

    filterBtn = () => {
        const counts = this.processAggregations(this.props.aggregations);

        let options = allOptions;
        if (this.props.useShortList) {
            options = shortOptions;
        }

        let toggleFilters = shortOptions.map((item) =>
            this.renderSelection(item, counts)
        );

        if (this.state.showFilters) {
            toggleFilters = options.map((item) => this.renderSelection(item, counts));
        }
        return toggleFilters;
    };

    toggleFiltersButton = () => {
        let btntext = 'Show More Activities';
        let btnIcon = <Icons.IconChevronDown />;
        let clickTagAction = 'Show More Activities';

        if (this.state.showFilters) {
            btntext = 'Show Less Activities';
            btnIcon = <Icons.IconChevronUp />;
            clickTagAction = 'Show Less Activities';
        }

        return (
            <Button
                appearance="link"
                iconBeforeElement={btnIcon}
                onClick={this.toggleFiltersHandler}
                clickTagCategory="Search"
                clickTagAction={clickTagAction}
                clickTagLabel="Active Filters Section"
            >
                {btntext}
            </Button>
        );
    };

    toggleFiltersHandler = () => {
        const doesShow = this.state.showFilters;
        this.setState({ showFilters: !doesShow });
    };

    renderSelection(item, counts) {
        const value = item.value.toUpperCase();
        const count = counts[value] || 0;
        let disabled;
        const selections = this.props.uiFilters.activities;
        const isChecked = selections.hasOwnProperty(item.key);
        let label;
        if (count || isChecked) {
            disabled = count === 0 && !isChecked ? true : undefined;
            label = `${item.value} (${count})`;
        } else {
            disabled = true;
            label = item.value;
        }
        if (this.props.enableAll) {
            disabled = null;
        }
        if (!this.props.showCounts) {
            label = item.value;
        }
        if (this.props.viewType === 'checkbox') {
            return (
                <div
                    className="search-filter-check-item"
                    htmlFor={`activity_${item.key}`}
                    key={item.key}
                >
                    <Checkbox
                        onChange={(e) => this.handleChange(e, item)}
                        id={`activity_${item.key}`}
                        label={label}
                        value={item.value}
                        isChecked={isChecked}
                        className={disabled ? 'disabled' : ''}
                    />
                </div>
            );
        }

        return (
            <div className="search-filter-check-item" htmlFor={item.key} key={item.key}>
                <Checkbox
                    onChange={(e) => this.handleChange(e, item)}
                    id={item.key}
                    label={label}
                    value={item.value}
                    isDisabled={disabled}
                    isChecked={isChecked}
                />
            </div>
        );
    }

    render() {
        const className = `${this.props.className}`;

        if (this.props.expandable) {
            return (
                <div className="search-filters-accordion__container pb-0 my-0">
                    <Accordion
                        hasIndentedBody={false}
                        className="search-filters-accordion__item"
                    >
                        <AccordionItem>
                            <AccordionHeading className="search-filters-accordion__heading">
                                Activities Nearby
                            </AccordionHeading>
                            <AccordionContent>
                                <div
                                    className="search-filters-double-type-wrap"
                                    role="group"
                                    aria-label="Activity filters"
                                >
                                    <legend className="rec-sr-only">
                                        Select activities to filter on
                                    </legend>
                                    {this.filterBtn()}
                                </div>
                                {this.toggleFiltersButton()}
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </div>
            );
        }

        const wrapperClassName = this.props.useNewCampingFilters
            ? 'search-filters-double-type-wrap'
            : `search-filters-check-wrap mb-2 ${className}`;
        return (
            <>
                <div
                    className={wrapperClassName}
                    role="group"
                    aria-label="Activity filters"
                >
                    <legend className="rec-sr-only">
                        Select activities to filter on
                    </legend>
                    {this.filterBtn()}
                </div>
                {this.toggleFiltersButton()}
            </>
        );
    }
}
