/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { FlexCol, FlexRow, PlaceholderText } from 'sarsaparilla';

export function NumberFieldFilterPlaceholder() {
    return (
        <FlexRow>
            <FlexCol xs={6}>
                <PlaceholderText lines={1} fontSize={30} lineHeight={36} />
            </FlexCol>
            <FlexCol xs={3}>
                <PlaceholderText lines={1} fontSize={30} lineHeight={36} />
            </FlexCol>
        </FlexRow>
    );
}
