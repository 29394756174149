/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { Heading, TextBlock } from 'sarsaparilla';

interface ChangesSectionProps {
    changeAnchor: string;
}

export default function ChangesSection({ changeAnchor = '' }: ChangesSectionProps) {
    return (
        <section className="mb-8" id={changeAnchor}>
            <Heading
                headingLevel={2}
                appearance="h1"
                className="rules-reservation-policies-section-heading"
            >
                Changes to Policies and Procedures
            </Heading>

            <TextBlock width="xl">
                <p>
                    Recreation.gov reserves the right to modify reservation policies.
                    These policies were updated in <strong>May 2024</strong>.
                </p>
            </TextBlock>
        </section>
    );
}
