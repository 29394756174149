/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * ArticleHero.jsx
 * Created by Destin Frasier on 03/27/18.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { DisplayHtmlContent, Heading } from 'sarsaparilla';
import { ARTICLE } from '../../../constants/propTypes';

const propTypes = {
    article: ARTICLE,
    articleTitle: PropTypes.string,
};

function ArticleHero({ article, articleTitle }) {
    return (
        <section
            title={articleTitle}
            className="nav-article-hero-full-width-wrap"
            style={{ backgroundImage: `url(${article.hero_image})` }}
        >
            <div className="nav-article-hero-body-outer-wrap">
                <div className="nav-article-hero-body">
                    <div className="nav-article-hero-body-main">
                        <Heading headingLevel={1} appearance="h1">
                            <DisplayHtmlContent html={article.article_title} />
                        </Heading>
                        <cite>
                            {article.article_byline ? (
                                <DisplayHtmlContent
                                    html={`By ${article.article_byline}`}
                                />
                            ) : (
                                ''
                            )}
                        </cite>
                    </div>
                </div>
            </div>
        </section>
    );
}

ArticleHero.propTypes = propTypes;

export default ArticleHero;
