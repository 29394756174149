/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import { Heading, Checkbox, DividerNew, Tooltip, Button, Icons } from 'sarsaparilla';
import { get, isEmpty, isEqual, sortBy } from 'lodash';
import cx from 'classnames';
import { FiltersTypeProps } from '../../../types';
import { CommonFilterPlaceholder } from './CommonFilterPlaceholder';

export type CommonFilterProps = {
    aggregations?: { [key: string]: Number };
    search: any;
    title: string;
    filters: Array<FiltersTypeProps>;
    onChange?: (v: React.ChangeEvent<HTMLInputElement>, k: any) => void;
    filterEmptyFilters?: boolean;
    columns?: Number;
    hasDivider?: boolean;
    entityType?: string;
    keepEnabled?: boolean;
    dividerClass?: string;
    clickTagCategory?: string;
    headingLevel?: 3 | 2 | 1 | 4 | 5 | 6 | undefined;
};

function CommonFilter({
    aggregations = {},
    search,
    title,
    filters,
    onChange,
    filterEmptyFilters,
    columns,
    hasDivider = true,
    keepEnabled = false,
    dividerClass,
    clickTagCategory = 'Global Search Filters',
    headingLevel = 3,
}: CommonFilterProps) {
    const [selectedFilters, setSelectedFilters] = React.useState<Array<string>>([]);
    const fg = get(search, 'fg', []);

    React.useEffect(() => {
        if (!isEqual(sortBy(selectedFilters), sortBy(fg))) {
            setSelectedFilters(fg);
        }
    }, [fg]);

    const onFilterSelect = (
        event: React.ChangeEvent<HTMLInputElement>,
        item: FiltersTypeProps
    ) => {
        if (onChange) {
            onChange(event, item);
        }
    };

    if (filters?.length === 0 || !filters) {
        return null;
    }

    let className = 'search-filters-double-type-wrap';
    if (columns === 1) {
        className = 'search-filters-single-type-wrap';
    }

    const hasAggregations = filters?.find((item) => aggregations[item.key]);
    let showDivider = hasDivider || false;
    const hasCheckedFilters = filters.find((item) => fg.indexOf(item.key) !== -1);

    if (filterEmptyFilters && isEmpty(aggregations)) {
        return <CommonFilterPlaceholder lines={filters.length} hasDivider={hasDivider} />;
    }

    if (filterEmptyFilters && !hasAggregations && !hasCheckedFilters) {
        showDivider = false;
        return null;
    }

    return (
        <div className="search-common-filters__container">
            <Heading className="mb-2 mt-0" headingLevel={headingLevel} appearance="h6">
                {title}
            </Heading>
            <div className={className} role="group" aria-label={`${title} filters`}>
                <legend className="rec-sr-only">Select {title} to filter on</legend>
                {filters?.map((item) => {
                    const count = (item.key && aggregations[item.key]) || 0;
                    const checkboxClassname = cx('search-filter-check-item', {
                        hasIcon: item.icon,
                        disableIcon: item.icon && count === 0,
                    });
                    const isChecked = selectedFilters.indexOf(item.key) !== -1;
                    let label;
                    if (!item.accessibility && (count || (isChecked && count > 0))) {
                        label = `${item.value} (${count})`;
                    } else {
                        label = item.value;
                    }
                    if (filterEmptyFilters && count === 0 && !isChecked) {
                        return null;
                    }
                    return (
                        <div className={checkboxClassname} key={item.key}>
                            <Checkbox
                                onChange={(e) => onFilterSelect(e, item)}
                                id={item.key}
                                label={label}
                                value={item.key}
                                isDisabled={count === 0 && !isChecked && !keepEnabled}
                                isChecked={isChecked}
                                clickTagCategory={clickTagCategory}
                                clickTagAction={`${title}: ${item.key}`}
                                clickTagLabel={`${item.value} Filter`}
                            />
                            {item.icon && item.icon}
                            {item.accessibility && (
                                <span className="search-accessible-camping">
                                    <Tooltip content="Accessible Site: This site has features that provide better accessibility than others.  Such features may include clear ground space, a firm surface and other features.  We are in the process of examining features that improve accessibility and welcome your feedback.">
                                        <Button
                                            appearance="subtle"
                                            size="sm"
                                            iconBeforeElement={<Icons.IconInfo />}
                                            screenReaderTextBefore="Show accessible campsite filter help text"
                                        />
                                    </Tooltip>
                                </span>
                            )}
                        </div>
                    );
                })}
                {showDivider && (
                    <div
                        className={cx('search-common-filter__divider', {
                            'pt-2 pb-2': !dividerClass,
                            dividerClass,
                        })}
                    >
                        <DividerNew />
                    </div>
                )}
            </div>
        </div>
    );
}

export default CommonFilter;
