/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import type { ArticleRecord, ArticlesResponse } from '../../types';
import { generateArticleLink } from '../../utils/articlesHelper';

interface ArticleRecordWithUrl extends ArticleRecord {
    url: string;
}

export function makeArticles(data?: ArticlesResponse) {
    // If no data, then return an empty array
    if (!data?.result?.records || data.result.records.length === 0) return null;

    const allArticles: ArticleRecordWithUrl[] = [];

    // Step 1: Add urls to all articles
    data.result.records.forEach((item) => {
        allArticles.push({
            ...item,
            url: generateArticleLink(item.content_type, item.article_title, item.id),
        });
    });

    // Step 2: Get the featured article
    // Previously the BE sent a field called field_feature_article and if it was "Yes" that
    // was the featured article. That appears to be gone, so we use the first item in the list.
    const featuredArticle = allArticles[0];
    const otherArticles = allArticles.slice(1);

    return { featuredArticle, otherArticles };
}
