/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import moment from 'moment-timezone';

export const getAvailableDatesMoment = (item) => {
    const availableDates = item?.available_dates?.[0];
    const startDateMoment = moment(availableDates?.start_date || null).utc();
    const endDateMoment = moment(availableDates?.end_date || null).utc();

    if (!startDateMoment.isValid() || !endDateMoment.isValid()) {
        return '';
    }

    return [startDateMoment, endDateMoment];
};

export const getAvailableDates = (item) => {
    const availableDatesMoment = getAvailableDatesMoment(item);

    if (!availableDatesMoment) {
        return '';
    }

    const [startDateMoment, endDateMoment] = availableDatesMoment;
    const startDateMonth = startDateMoment.format('MMM');
    const startDateDay = startDateMoment.format('D');
    const endDateMonth = endDateMoment.format('MMM');
    const endDateDay = endDateMoment.format('D');

    if (startDateMonth === endDateMonth && startDateDay === endDateDay) {
        return `${startDateMonth} ${startDateDay}`;
    }

    if (startDateMonth === endDateMonth && startDateDay !== endDateDay) {
        return `${startDateMonth} ${startDateDay} - ${endDateDay}`;
    }

    return `${startDateMonth} ${startDateDay} - ${endDateMonth} ${endDateDay}`;
};
