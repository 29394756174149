/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { HelmetWrapper, SingleColumnContainer, Heading } from 'sarsaparilla';

export default class DonationTACUSACE extends React.Component {
    render() {
        return (
            <SingleColumnContainer role="main">
                <HelmetWrapper title="USACE Terms & Conditions for Donations" />

                <section className="rules-reservation-policies-wrap mb-8">
                    <Heading headingLevel={1} appearance="h1">
                        USACE Terms &amp; Conditions for Donations
                    </Heading>

                    <p>
                        By making a donation to the U.S. Army Corps of Engineers you are
                        agreeing to the following terms and conditions:
                        <ol>
                            <li>
                                Three percent of your donation will be used to cover a
                                transaction fee for {process.env.SITE_NAME}’s online
                                services.
                            </li>
                            <li>Donations and associated fees are not refundable.</li>
                            <li>
                                Your donation will only be used to benefit the programs
                                and projects that support the USACE recreation area or
                                facility you are donating to.
                            </li>
                            <li>
                                Please do not provide a donation if one or more of these
                                situations apply to you:
                                <ul>
                                    <li>
                                        You are party to a contract, grant, or cooperative
                                        agreement with USACE or are seeking to do business
                                        with USACE.
                                    </li>
                                    <li>
                                        You have a financial interest that may be
                                        substantially affected by performance or
                                        non-performance of USACE.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                For more information regarding donations to USACE and the
                                USACE Contributions, Fundraising, and Recognition Policy,
                                please visit{' '}
                                <a href="https://corpslakes.erdc.dren.mil/employees/policy.cfm?Id=contributions&Code=All&View=Yes">
                                    https://corpslakes.erdc.dren.mil/employees/policy.cfm?Id=contributions&Code=All&View=Yes
                                </a>
                                .
                            </li>
                        </ol>
                    </p>
                </section>
            </SingleColumnContainer>
        );
    }
}

// cSpell:ignore USACE, TACUSACE
