/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';

import {
    Button,
    ButtonGroup,
    StyledModal,
    ModalActions,
    Stack,
    Text,
} from 'sarsaparilla';

export default function ConfirmDeleteModal({
    isOpen,
    onClose,
    onRemoveClick,
    parentSelector,
}) {
    if (!isOpen) {
        return null;
    }

    return (
        <StyledModal
            size="sm"
            shouldShowCloseButton={false}
            shouldActionsStickInMobile
            isOpen={isOpen}
            heading="Remove from cart?"
            parentSelector={parentSelector || undefined}
            onRequestClose={onClose}
        >
            <Stack>
                <Text>Are you sure you want to remove this item from your cart?</Text>
            </Stack>
            <ModalActions>
                <ButtonGroup isStretchedToFit>
                    <Button appearance="tertiary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={onRemoveClick}>Remove from Cart</Button>
                </ButtonGroup>
            </ModalActions>
        </StyledModal>
    );
}

ConfirmDeleteModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onRemoveClick: PropTypes.func,
    /** Used by ui-widget for the Modal to change the parent element that the modal will be attached to */
    parentSelector: PropTypes.func,
};
