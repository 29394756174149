/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { CalendarDate } from '@internationalized/date';
import * as React from 'react';
import type { DateRange, DateValue } from 'react-aria';
import { useSelector, useDispatch } from 'react-redux';
import { type Moment } from 'moment';

import {
    DateRangePicker,
    calendarDateToMoment,
    localToday,
    DateRangePickerOnChange,
} from 'sarsaparilla';

import { updateSelectedDate, updateSearchCriterias } from '../actions/search';

const usePrevious = (value: any) => {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

// This is a partial
type SearchFromRedux = {
    checkin_time: string; // weird format 12/19/2023
    checkout_time: string;
    sort: string;
} | null;

interface SearchCriteriaDatesProps {
    onApplyFilters?: () => void;
    onDatesChange: ({
        startDate,
        endDate,
    }: {
        startDate: Moment | null;
        endDate: Moment | null;
    }) => void;
}
// The search services saves dates in the redux store as 'MM/DD/YYYY' strings. This does
// literally no one any good, so we have to have this complicated thing to unwind it
// TODO: convert all uses of this to standard ISO date string
function weirdFormatStringToCalendarDate(weirdString: unknown) {
    const weirdStringPattern = /^(\d{1,2})\/\d{1,2}\/\d\d\d\d/;
    if (
        weirdString &&
        typeof weirdString === 'string' &&
        weirdStringPattern.test(weirdString)
    ) {
        const [month, day, year] = weirdString.split('/');

        return new CalendarDate(Number(year), Number(month), Number(day));
    }

    return null;
}

function calendarDateToWeirdFormatString(value?: DateValue) {
    if (value) {
        const { month, day, year } = value;

        return `${month}/${day}/${year}`;
    }

    return '';
}

export function SearchCriteriaDates({
    onApplyFilters,
    onDatesChange = () => {},
}: SearchCriteriaDatesProps) {
    const dispatch = useDispatch();
    const search = useSelector((state: any) => {
        return state?.search || null;
    }) as SearchFromRedux;

    const start = weirdFormatStringToCalendarDate(search?.checkin_time);
    const end = weirdFormatStringToCalendarDate(search?.checkout_time);
    const reduxDateRangeValue = start && end ? { start, end } : null;

    // Creating some local state to store the start and end values ... this creates an intermediary
    // between the "real" state in the redux store and what the date picker displays.
    // This allows the date picker to behave correctly and be in an invalid state without
    // adding invalid values to the redux store, which would cause failures
    // in other parts of the app, such as fetching availability for the search results
    const [dateRangeValue, setDateRangeValue] = React.useState<DateRange | null>(
        reduxDateRangeValue
    );

    const prevStartDate = usePrevious(start);
    const prevEndDate = usePrevious(end);

    React.useEffect(() => {
        if (!search?.checkin_time) {
            // @ts-ignore ... store doesn't have types
            dispatch(updateSelectedDate('', true));
        }
        if (!search?.checkout_time) {
            // @ts-ignore ... store doesn't have types
            dispatch(updateSelectedDate('', false));
        }
        if (!search?.checkin_time && !search?.checkout_time) {
            setDateRangeValue(null);
        }
        if (
            search?.checkin_time &&
            search?.checkout_time &&
            !prevEndDate &&
            !prevStartDate
        ) {
            setDateRangeValue(reduxDateRangeValue);
        }
    }, [dispatch, search?.checkin_time, search?.checkout_time]);

    const handleDateRangeChange: DateRangePickerOnChange = (value, validation) => {
        setDateRangeValue(value);

        // Bail is value is not valid to cover cases where the user is still typing the year
        if (validation && validation.isInvalid) return;

        const startString = calendarDateToWeirdFormatString(value?.start);
        const endString = calendarDateToWeirdFormatString(value?.end);

        // @ts-ignore ... store doesn't have types
        dispatch(updateSelectedDate(startString, true));
        // @ts-ignore ... store doesn't have types
        dispatch(updateSelectedDate(endString, false));

        if (onApplyFilters) {
            onApplyFilters();
        }

        if (onDatesChange) {
            onDatesChange({
                startDate: calendarDateToMoment(value?.start || null),
                endDate: calendarDateToMoment(value?.end || null),
            });
        }

        let sort = search?.sort;

        if (startString) {
            if (!sort || sort === '' || sort === 'score') {
                // default to available sort if dates are set
                sort = 'available';
            }
        } else if (sort === 'available') {
            // clear available sort if no dates are set
            sort = '';
        }

        dispatch(
            // @ts-ignore ... store doesn't have types
            updateSearchCriterias({
                start: null,
                cursor_mark: null,
                sort,
            })
        );
    };

    return (
        <DateRangePicker
            label="Check In and Check Out Dates"
            isLabelVisible={false}
            value={dateRangeValue}
            onChange={handleDateRangeChange}
            minValue={localToday}
            hasFloatingError={true}
        />
    );
}

// cSpell:ignore checkin_time, checkout_time, Criterias
