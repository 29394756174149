/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import cx from 'classnames';
import { FlexRow, FlexCol, useWindowSize, HtmlParser } from 'sarsaparilla';

export default function InventoryTypeFees() {
    const { width = 0 } = useWindowSize();

    const serviceFeesTable = [
        {
            colA: 'Fees By Inventory Type',
            colB: 'Recreation Use Fee',
            colC: 'Reservation Fee at “Add-On” Fee Agencies and Locations',
            colD: 'Change/Modification Fee',
            colE: 'Cancellation Fee*',
            colF: 'Refunds Allowed',
        },
        {
            colA: '<strong>Camping</strong>',
            colB: 'Fees vary by location and/or activity',
            colC: '<div class="mb-2"><strong>Fees vary by reservation method:</strong></div> $8 if made online or through the app; $9 if made through the contact center; $3 if made in-person <div>(If applicable)</div>',
            colD: '$10 Change fee',
            colE: '$10',
            colF: 'Yes',
        },
        {
            colA: '<strong>Scan & Pay Campsites</strong>',
            colB: 'Fees vary by location and/or activity',
            colC: 'Sliding scale based on cost of Use Fee',
            colD: 'No modifications allowed',
            colE: 'No cancellations allowed',
            colF: 'No',
        },
        {
            colA: '<strong>Tickets / Timed Entry</strong>',
            colB: 'Fees vary by location and/or activity',
            colC: '$1 if made on-line or through the contact center',
            colD: 'Change/Modification fees vary by location and/or activity',
            colE: 'All non-refundable reservation fees will be withheld from any refund amount provided',
            colF: 'Yes* <br />No refunds for fee-free tickets',
        },
        {
            colA: '<strong>Permits</strong>',
            colB: 'Fees vary by location and/or activity',
            colC: 'Permit Reservation fees vary by location and/or activity',
            colD: 'Change/Modification fees vary by location and/or activity',
            colE: 'Cancellation fees vary by location and/or activity',
            colF: 'Refunds vary by location and/or activity',
        },
        {
            colA: '<strong>Site/Activity Passes (Advanced and Scan & Pay)</strong>',
            colB: 'Fees vary by location and/or activity',
            colC: 'Reservation fees vary by Agency',
            colD: 'No modifications allowed',
            colE: 'No cancellations allowed',
            colF: 'No',
        },
        {
            colA: '<strong>Venues</strong>',
            colB: 'Fees vary by location and/or activity',
            colC: 'For applicable locations, a $0-$10 Reservation incurs a $1 Service Fee <br /> A reservation of $10.01 or more has a Service Fee of $8 if made online or through the app, $9 if made through the contact center, or $3 if made in-person (if applicable)',
            colD: 'Modifications can be made for reservations when there is no change to the reservation cost',
            colE: 'Cancellations made more than 14 days before the scheduled arrival date will be refunded the use fee and the deposit (if applicable) less a $10.00 cancellation fee <br /> No refunds will be given for cancellations made 14 days or less before the scheduled arrival date',
            colF: 'Yes <br /> *Refund amount is determined by when the cancellation is made',
        },
    ];

    const colA = serviceFeesTable.map((fee) => fee.colA);
    const colB = serviceFeesTable.map((fee) => fee.colB);
    const colC = serviceFeesTable.map((fee) => fee.colC);
    const colD = serviceFeesTable.map((fee) => fee.colD);
    const colE = serviceFeesTable.map((fee) => fee.colE);
    const colF = serviceFeesTable.map((fee) => fee.colF);

    const inventoryListItems = (
        colTitle: string,
        colContent: string,
        setClass?: string
    ) => {
        return (
            <FlexRow className={cx('table-item-list-view', setClass)}>
                <FlexCol sm={4}>
                    <p>
                        <strong>
                            <HtmlParser html={colTitle} />:
                        </strong>
                    </p>
                </FlexCol>
                <FlexCol sm={8}>
                    <p>
                        <HtmlParser html={colContent} />
                    </p>
                </FlexCol>
            </FlexRow>
        );
    };

    return (
        <>
            {/* show the data as a table */}
            {width >= 992 && (
                <FlexRow>
                    <FlexCol sm={12}>
                        <table className="rec-table-light inventory-type mb-half">
                            <thead>
                                <tr>
                                    <th>
                                        <HtmlParser html={colA[0]} />
                                    </th>
                                    <th>
                                        <HtmlParser html={colB[0]} />
                                    </th>
                                    <th>
                                        <HtmlParser html={colC[0]} />
                                    </th>
                                    <th>
                                        <HtmlParser html={colD[0]} />
                                    </th>
                                    <th>
                                        <HtmlParser html={colE[0]} />
                                    </th>
                                    <th>
                                        <HtmlParser html={colF[0]} />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <HtmlParser html={colA[1]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colB[1]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colC[1]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colD[1]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colE[1]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colF[1]} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <HtmlParser html={colA[2]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colB[2]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colC[2]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colD[2]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colE[2]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colF[2]} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <HtmlParser html={colA[3]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colB[3]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colC[3]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colD[3]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colE[3]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colF[3]} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <HtmlParser html={colA[4]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colB[4]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colC[4]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colD[4]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colE[4]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colF[4]} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <HtmlParser html={colA[5]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colB[5]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colC[5]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colD[5]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colE[5]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colF[5]} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <HtmlParser html={colA[6]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colB[6]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colC[6]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colD[6]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colE[6]} />
                                    </td>
                                    <td>
                                        <HtmlParser html={colF[6]} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </FlexCol>
                </FlexRow>
            )}

            {/* show the data as a list */}
            {width <= 993 && (
                <>
                    <div className="fees-info-wrap mb-2">
                        {inventoryListItems(colA[0], colA[1])}
                        {inventoryListItems(colB[0], colB[1])}
                        {inventoryListItems(colC[0], colC[1])}
                        {inventoryListItems(colD[0], colD[1])}
                        {inventoryListItems(colE[0], colE[1])}
                    </div>

                    <div className="fees-info-wrap mb-2">
                        {inventoryListItems(colA[0], colA[2])}
                        {inventoryListItems(colB[0], colB[2])}
                        {inventoryListItems(colC[0], colC[2])}
                        {inventoryListItems(colD[0], colD[2])}
                        {inventoryListItems(colE[0], colE[2])}
                    </div>

                    <div className="fees-info-wrap mb-2">
                        {inventoryListItems(colA[0], colA[3])}
                        {inventoryListItems(colB[0], colB[3])}
                        {inventoryListItems(colC[0], colC[3])}
                        {inventoryListItems(colD[0], colD[3])}
                        {inventoryListItems(colE[0], colE[3])}
                    </div>

                    <div className="fees-info-wrap mb-2">
                        {inventoryListItems(colA[0], colA[4])}
                        {inventoryListItems(colB[0], colB[4])}
                        {inventoryListItems(colC[0], colC[4])}
                        {inventoryListItems(colD[0], colD[4])}
                        {inventoryListItems(colE[0], colE[4])}
                    </div>

                    <div className="fees-info-wrap mb-1">
                        {inventoryListItems(colA[0], colA[5])}
                        {inventoryListItems(colB[0], colB[5])}
                        {inventoryListItems(colC[0], colC[5])}
                        {inventoryListItems(colD[0], colD[5])}
                        {inventoryListItems(colE[0], colE[5])}
                    </div>

                    <div className="fees-info-wrap mb-1">
                        {inventoryListItems(colA[0], colA[6])}
                        {inventoryListItems(colB[0], colB[6])}
                        {inventoryListItems(colC[0], colC[6])}
                        {inventoryListItems(colD[0], colD[6])}
                        {inventoryListItems(colE[0], colE[6])}
                    </div>
                </>
            )}
            <p className="table-sub-text">
                *The fees listed under the Cancellation Fees column do not consider any
                late cancellations, which could incur additional fees not listed in the
                above chart.
            </p>
        </>
    );
}
