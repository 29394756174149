/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Heading, TextBlock } from 'sarsaparilla';

interface VenuesSectionProps {
    venuesAnchor?: string;
}

export default function VenuesSection({ venuesAnchor = '' }: VenuesSectionProps) {
    return (
        <section className="mb-8" id={venuesAnchor}>
            <Heading headingLevel={2} appearance="h2" className="mb-1">
                Venues
            </Heading>
            <TextBlock width="xl">
                <p>
                    <strong>Application Fee:</strong> Some locations require a review of
                    the reservation application by the facility manager. In these cases,
                    the application fee is paid for during your reservation payment
                    process and is non-refundable regardless of whether the facility
                    approves or denies the application. If the facility approves your
                    application, then a reservation will be awarded and you will be
                    notified by the facility. If the facility denies your application, the
                    application fee is non-refundable.
                </p>
                <p>
                    <strong>Modifications:</strong> Modifications can be made for
                    reservations when there is no change to the reservation cost. These
                    modifications may include the date, venue/location, group size, and/or
                    the number of vehicles. All modifications are subject to availability
                    on a facility-by-facility basis. Modifications can be made via the
                    contact center or by the facility.
                </p>
                <p>
                    <strong>Cancellations:</strong> Cancellations made more than 14 days
                    before the scheduled arrival date will be refunded the use fee and the
                    deposit (if applicable to the location) minus a $10.00 cancellation
                    fee. No refunds will be given for cancellations made 14 days or less
                    before the scheduled arrival date. Cancellations can be made via the
                    contact center or by the facility.
                </p>
                <p>
                    <strong>Security Deposit:</strong> Due at the time of booking, the
                    security deposit is used to cover damages and theft and may be
                    refunded if the facility is left in an acceptable condition
                    (determined by the managing agency). Refunds are returned to the
                    original method of payment.
                </p>
            </TextBlock>
        </section>
    );
}
