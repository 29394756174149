/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * Passes.jsx
 * Created by Destin Frasier on 09/16/19
 */

import React from 'react';
import { FlexRow, FlexCol, Heading } from 'sarsaparilla';

import SiteMapItem from './SiteMapItem';

export default class Passes extends React.Component {
    render() {
        return (
            <FlexRow>
                <FlexCol>
                    <Heading headingLevel={2} appearance="h5">
                        Passes
                    </Heading>
                    <ul className="nav-site-map-wrap">
                        <SiteMapItem name="Pass" url="/pass" />
                        <SiteMapItem name="America the Beautiful Pass" url="/pass/atb" />
                        <SiteMapItem
                            name="Interagency Annual Pass"
                            url="/pass/order-details/pass/7005cd6e-8386-458e-b4b1-8b382bf70889"
                            requiresLogin
                        />
                    </ul>
                </FlexCol>
            </FlexRow>
        );
    }
}
