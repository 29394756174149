/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import { HelmetWrapper, SingleColumnContainer, Heading } from 'sarsaparilla';

export function DisclaimersPage() {
    const SERVICE_NOW_EXTERNAL_URL = process.env['SN_KB_EXT_URL'];

    return (
        <SingleColumnContainer role="main">
            <HelmetWrapper title="Disclaimers" />
            <section>
                <Heading headingLevel={1} appearance="h1">
                    Disclaimers
                </Heading>

                <Heading headingLevel={2} appearance="h2">
                    Disclaimer and Liability Notice
                </Heading>
                <p>
                    This website and the information it contains are provided as a public
                    service by Booz Allen Hamilton under contract to the U.S. Department
                    of Agriculture, Forest Service, and Recreation One Stop participating
                    agencies. This system is monitored to ensure proper operation, to
                    verify the functioning of applicable security features, and for
                    comparable purposes. Anyone using this system expressly consents to
                    such monitoring. Unauthorized attempts to modify any information
                    stored on this system, to defeat or circumvent security features, or
                    to utilize this system for other than its intended purposes are
                    prohibited and may result in criminal prosecution.
                </p>

                <Heading headingLevel={2} appearance="h2">
                    Restriction of Liability
                </Heading>
                <p>
                    Recreation.gov makes no claims, promises, or guarantees about the
                    accuracy, completeness, or adequacy of the contents of this website
                    and expressly disclaims liability for errors and omissions in the
                    contents of this website. No warranty of any kind, implied, expressed
                    or statutory, including but not limited to the warranties of
                    non-infringement of third party rights, title, merchantability,
                    fitness for a particular purpose and freedom from computer virus, is
                    given with respect to the contents of this website or its hyperlinks
                    to other Internet resources. Reference in this website to any specific
                    commercial products, processes, or services, or the use of any trade,
                    firm, or corporation name is for the information and convenience of
                    the public, and does not constitute endorsement, recommendation, or
                    favoring by the USDA, Forest Service or other participating agencies.
                </p>

                <Heading headingLevel={2} appearance="h2">
                    Ownership
                </Heading>
                <p>
                    Information presented on this website is considered public information
                    and may be distributed or copied. Recreation.gov shall have the
                    unlimited right to use for any purpose, free of any charge, all
                    information submitted to Recreation.gov via this site except those
                    submissions made under separate legal contract. Recreation.gov shall
                    be free to use, for any purpose, any ideas, concepts, or techniques
                    contained in information provided to Recreation.gov through this site.
                </p>

                <Heading headingLevel={2} appearance="h2">
                    Opt-In Communications
                </Heading>
                <p className="mb-2">
                    When you establish an account and make reservations on Recreation.gov,
                    you also have the opportunity to opt-in for additional communications.
                    These communications include critical updates about conditions that
                    may impact your upcoming travel, like wildfires, floods, or other
                    natural disasters as well as updates and travel tips to help you
                    discover and get the most of your outdoor experience. Communications
                    options include email and/or text (SMS) message updates from
                    Recreation.gov. These opt-in messages are not mandatory. Communication
                    preferences can be selected with account creation and updated at any
                    time through your Recreation.gov account. The information below
                    reflects carrier-specific text (SMS) messaging disclaimers:
                </p>
                <ol type="1" className="mb-6">
                    <li>
                        When you opt-in to the service, Recreation.gov will send you an
                        SMS message to confirm your signup.
                    </li>
                    <li>
                        You can cancel the SMS service at any time. Just text
                        &quot;STOP&quot; to the short code provided. After you send the
                        SMS message &quot;STOP&quot; to us, we will send you an SMS
                        message to confirm that you have been unsubscribed. After this,
                        you will no longer receive SMS messages from us. If you want to
                        join again, just sign up as you did the first time and we will
                        start sending SMS messages to you again.
                    </li>
                    <li>
                        If at any time you forget what keywords are supported, just text
                        &quot;HELP&quot; to the short code provided. After you send the
                        SMS message &quot;HELP&quot; to us, we will respond with
                        instructions on how to use our service as well as how to
                        unsubscribe.
                    </li>
                    <li>
                        We are able to deliver messages to the following mobile phone
                        carriers: Major carriers: AT&T, Verizon Wireless, Sprint,
                        T-Mobile, MetroPCS, U.S. Cellular, Alltel, Boost Mobile, Nextel,
                        and Virgin Mobile. Minor carriers: Alaska Communications Systems
                        (ACS), Appalachian Wireless (EKN), Bluegrass Cellular, Cellular
                        One of East Central IL (ECIT), Cellular One of Northeast
                        Pennsylvania, Cincinnati Bell Wireless, Cricket, Coral Wireless
                        (Mobi PCS), COX, Cross, Element Mobile (Flat Wireless), Epic Touch
                        (Elkhart Telephone), GCI, Golden State, Hawkeye (Chat Mobility),
                        Hawkeye (NW Missouri), Illinois Valley Cellular, Inland Cellular,
                        iWireless (Iowa Wireless), Keystone Wireless (Immix Wireless/PC
                        Man), Mosaic (Consolidated or CTC Telecom), Nex-Tech Wireless,
                        NTelos, Panhandle Communications, Pioneer, Plateau (Texas RSA 3
                        Ltd), Revol, RINA, Simmetry (TMP Corporation), Thumb Cellular,
                        Union Wireless, United Wireless, Viaero Wireless, and West Central
                        (WCC or 5 Star Wireless).{' '}
                        <strong>
                            ***Carriers are not liable for delayed or undelivered
                            messages***
                        </strong>
                    </li>
                    <li>
                        As always, message and data rates may apply for any messages sent
                        to you from us and to us from you. Message Frequency may vary We
                        are not responsible for the format/rendering of any information as
                        displayed on your mobile device, for any misdelivery or untimely
                        delivery of any mobile messaging, or for your deletion of or
                        failure to store any mobile messaging from us. If you have any
                        questions about your text plan or data plan, it is best to contact
                        your wireless provider. For all questions about the services
                        provided by this short code, you can contact us by visiting our{' '}
                        <a href={SERVICE_NOW_EXTERNAL_URL}>Help Center</a>.
                    </li>
                    <li>
                        If you have any questions regarding privacy, please read our{' '}
                        <a href="/privacy-policy">Privacy Policy</a>.
                    </li>
                </ol>

                <p>
                    If you have any questions or concerns about the privacy policy, the
                    practices of the Site, or your dealings with the Site, you can contact
                    us by using the &quot;Contact Us&quot; link at the bottom of this
                    page.
                </p>

                <address
                    className="rec-address"
                    aria-label="Recreation One Stop mailing address"
                >
                    <p>You can also send inquiries by mail to:</p>
                    <ul>
                        <li>Recreation One Stop</li>
                        <li>201 14th St. SW.</li>
                        <li>Washington, DC 20227</li>
                        <li>C/O. RHVR 5th floor</li>
                    </ul>
                </address>
            </section>
        </SingleColumnContainer>
    );
}

// cSpell:ignore RHVR, misdelivery, Viaero, Alltel, ECIT, Mobi, NTelos, Revol, RINA, Simmetry, signup
