/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Heading, TextBlock } from 'sarsaparilla';

interface ChristmasTreeSectionProps {
    christmasTreeAnchor: string;
}

export default function ChristmasTreeSection({
    christmasTreeAnchor = '',
}: ChristmasTreeSectionProps) {
    return (
        <section className="mb-8" id={christmasTreeAnchor}>
            <Heading headingLevel={2} appearance="h2" className="mb-1">
                Christmas Tree Permits
            </Heading>
            <TextBlock width="xl">
                <p>
                    Christmas tree permits sold by the U.S. Forest Service are
                    non-transferrable, non-refundable, not replaceable if lost or stolen,
                    and are void if altered or reproduced.
                </p>
            </TextBlock>
        </section>
    );
}
