/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';

export const getTitleSlug = (title) => {
    if (!title) {
        return '';
    }

    return title.trim().toLowerCase().replace(/ /gi, '-');
};

export const getContentType = (contentType) => {
    if (!contentType) {
        return '';
    }

    return contentType.trim().toLowerCase().replace(/_/g, '-');
};

export const getContentTypeLabel = (contentType) => {
    if (!contentType) {
        return '';
    }

    return contentType.trim().toUpperCase().replace(/_/g, ' ');
};

export const generateArticleLink = (type, title, id) =>
    `/articles/${getContentType(type)}/${getTitleSlug(title)}/${id}`;

export const parseRelatedTripLink = (relatedLinkTrip) => {
    if (!relatedLinkTrip) {
        return '';
    }

    return relatedLinkTrip.trim().replace(/\/content/g, '/articles');
};

export const youtubeFormatter = (youtubeURL) => {
    if (youtubeURL.indexOf('watch') > 0) {
        return youtubeURL.replace(/\/watch\?v=/g, '/embed/');
    }

    return youtubeURL;
};

export const parseArticleImage = (article) => {
    if (article.content_type === 'location_spotlight') {
        return article.field_location_spotlight_promotional_image;
    }
    if (article.content_type === 'journal') {
        return article.field_journal_promotional_image;
    }
    if (article.content_type === 'list') {
        return article.field_list_promotional_image;
    }

    return null;
};

export const generateContentImage = ({ url, img, altText }) => {
    const image = <img src={img} alt={altText} />;

    if (url) {
        return (
            <a href={url} target="_blank" rel="noopener noreferrer">
                {image}
            </a>
        );
    }

    return image;
};
