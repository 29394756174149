/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import {
    RecaptchaCheckbox,
    StyledModal,
    ModalActions,
    Button,
    ButtonGroup,
} from 'sarsaparilla';

const propTypes = {
    isOpen: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    action: PropTypes.string,
    onRecaptchaChange: PropTypes.func,
    isSolved: PropTypes.bool,
};

const defaultProps = {
    isOpen: false,
};

export class EnterpriseRecaptchaModal extends React.PureComponent {
    static propTypes = propTypes;

    static defaultProps = defaultProps;

    render() {
        const { action, closeModal, isOpen, onRecaptchaChange, isSolved } = this.props;
        return (
            <div>
                <StyledModal
                    isOpen={isOpen}
                    shouldCloseOnOverlayClick={false}
                    shouldShowCloseButton={false}
                    contentLabel="Recaptcha"
                    heading="We need to verify that you’re a human"
                    ariaHideApp={false}
                    size="md"
                >
                    <div className="rec-modal-content-wrap">
                        <div className="challenge-wrap">
                            <RecaptchaCheckbox
                                action={action}
                                size="normal"
                                onChange={onRecaptchaChange}
                                sitekey={process.env.RECAPTCHA_ENTERPRISE_KEY}
                            />
                        </div>
                    </div>

                    <ModalActions>
                        <ButtonGroup>
                            <Button
                                appearance="tertiary"
                                aria-label="Continue Booking"
                                onClick={closeModal}
                                disabled={!isSolved}
                            >
                                Continue Booking
                            </Button>
                        </ButtonGroup>
                    </ModalActions>
                </StyledModal>
            </div>
        );
    }
}
