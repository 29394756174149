/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as React from 'react';
import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';

import { LocalStorage } from 'sarsaparilla';

/**
 * <DialogflowChatBot /> component dynamically injects Dialogflow-integrated chat bot widget into R1S html pages,
 * configured via feature flagged implementation. This component should be injected at most once per page.
 *
 * https://cloud.google.com/dialogflow/docs/integrations/dialogflow-messenger
 *
 * When enabled, component dynamically injects java script sourced from Google's website
 * ```<script src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"></script>```
 * which once loaded would enable html web component defined with

 * <df-messenger
 *    intent="WELCOME"
 *    chat-title="UC-MESSAGING-DEV"
 *    agent-id="340392ec-e0d5-4503-a02a-75909c334742"
 *    language-code="en"
 * ></df-messenger>
 *
 */

const agentID = process.env['DIALOGFLOW_AGENT_ID'];
let dfMessengerScriptLoaded = false;
let sessionId: string | null = null;
let userId: string | null = null;

const loadDialogflowMessenger = (callback: () => void) => {
    if (dfMessengerScriptLoaded) {
        // don't double load script
        if (callback) callback();
        return;
    }
    const script = document.createElement('script');
    script.src =
        'https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1';
    script.id = 'dialogflow-messenger';
    document.body.appendChild(script);
    script.onload = () => {
        dfMessengerScriptLoaded = true;
        if (callback) callback();
    };
};

export function DialogflowChatBot() {
    const [dfMessengerLoaded, setDfMessengerLoaded] = React.useState(false);
    const didCallLoadRef = React.useRef(false);
    React.useEffect(() => {
        if (!didCallLoadRef.current) {
            didCallLoadRef.current = true;

            loadDialogflowMessenger(() => {
                sessionId = LocalStorage.getItem('df-session-id');
                if (!sessionId) {
                    sessionId = uuid();
                    LocalStorage.setItem('df-session-id', sessionId);
                }
                userId = window.location.pathname;
                const cookieName = '_ga';
                const gid = Cookies.get(cookieName);
                if (gid) {
                    userId += ',';
                    userId += gid;
                }
                setDfMessengerLoaded(true);

                // does not work without setInterval.
                const interval = setInterval(() => {
                    const dfMessenger = document.querySelector('df-messenger');
                    clearInterval(interval);
                    if (dfMessenger) {
                        window.addEventListener('df-messenger-loaded', async () => {
                            try {
                                const response = await fetch(
                                    `${process.env['API']}/compassinventory/sessions/${sessionId}`
                                );
                                const responseJson = (await response.json()) as any[];
                                if (response && responseJson) {
                                    responseJson.forEach((e) => {
                                        if (
                                            e?.request !== 'WELCOME' &&
                                            e?.request !== 'welcome2'
                                        ) {
                                            // @ts-expect-error
                                            dfMessenger.renderCustomText(e.request);
                                            // @ts-expect-error
                                            dfMessenger.renderCustomText(e.response);
                                        }
                                    });
                                }
                            } catch (e) {
                                // eslint-disable-next-line no-console
                                console.log(e);
                            }
                        });
                    }
                }, 1000);
            });
        }
    }, []);

    return (
        <div className="df-messenger-rec-wrapper">
            {dfMessengerLoaded && (
                // @ts-expect-error
                <df-messenger
                    alt="Chat icon"
                    intent="WELCOME"
                    chat-icon="/shared/graphics/chat_icon.png"
                    chat-title="Recreation.gov"
                    agent-id={agentID}
                    session-id={sessionId}
                    user-id={userId}
                    wait-open={'true'}
                    language-code="en"
                />
            )}
        </div>
    );
}

// cSpell:ignore Dialogflow, compassinventory
