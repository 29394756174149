/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * PartnerIcons.jsx
 * Created by Destin Frasier on 10/23/17.
 */
import { BaseIcon, BaseIconsProps } from 'sarsaparilla';
import PartnerIconSingleton from './partnerIconSingleton';

type IconProps = Omit<BaseIconsProps, 'iconName' | 'iconClass' | 'alt'>;

const iconPrefix = `rec-icon`;

class PartnerIcon extends BaseIcon {
    constructor(props: BaseIconsProps) {
        super(props);

        this.iconSingleton = PartnerIconSingleton;
    }
}
export function NationalArchives(props: IconProps) {
    return (
        <PartnerIcon
            iconName={`${iconPrefix}-national-archives`}
            iconClass={`${iconPrefix}-national-archives`}
            alt="National Archives Logo"
            {...props}
        />
    );
}

export function DOI(props: IconProps) {
    return (
        <PartnerIcon
            iconName={`${iconPrefix}-doi`}
            iconClass={`${iconPrefix}-doi`}
            alt="Bureau of Reclamation Logo"
            {...props}
        />
    );
}

export function TVA(props: IconProps) {
    return (
        <PartnerIcon
            iconName={`${iconPrefix}-tva`}
            iconClass={`${iconPrefix}-tva`}
            alt="Tennessee Valley Authority Logo"
            {...props}
        />
    );
}

export function TRE(props: IconProps) {
    return (
        <PartnerIcon
            iconName={`${iconPrefix}-tre`}
            iconClass={`${iconPrefix}-tre`}
            alt="Department of The Treasury Logo"
            {...props}
        />
    );
}

export function NPS(props: IconProps) {
    return (
        <PartnerIcon
            iconName={`${iconPrefix}-nps`}
            iconClass={`${iconPrefix}-nps`}
            alt="National Park Service Logo"
            {...props}
        />
    );
}

export function USACE(props: IconProps) {
    return (
        <PartnerIcon
            iconName={`${iconPrefix}-usace`}
            iconClass={`${iconPrefix}-usace`}
            alt="U.S. Army Corps of Engineers Logo"
            {...props}
        />
    );
}

export function ForestService(props: IconProps) {
    return (
        <PartnerIcon
            iconName={`${iconPrefix}-forest-service`}
            iconClass={`${iconPrefix}-forest-service`}
            alt="Forest Service Logo"
            {...props}
        />
    );
}

export function NOAA(props: IconProps) {
    return (
        <PartnerIcon
            iconName={`${iconPrefix}-noaa`}
            iconClass={`${iconPrefix}-noaa`}
            alt="National Oceanic and Atmospheric Administration Logo"
            {...props}
        />
    );
}

export function BLM(props: IconProps) {
    return (
        <PartnerIcon
            iconName={`${iconPrefix}-blm`}
            iconClass={`${iconPrefix}-blm`}
            alt="Bureau of Land Management Logo"
            {...props}
        />
    );
}

export function Smithsonian(props: IconProps) {
    return (
        <PartnerIcon
            iconName={`${iconPrefix}-smithsonian`}
            iconClass={`${iconPrefix}-smithsonian`}
            alt="Smithsonian Logo"
            {...props}
        />
    );
}

export function FWS(props: IconProps) {
    return (
        <PartnerIcon
            iconName={`${iconPrefix}-fws`}
            iconClass={`${iconPrefix}-fws`}
            alt="Fish & Wildlife Logo"
            {...props}
        />
    );
}

export function DOT(props: IconProps) {
    return (
        <PartnerIcon
            iconName={`${iconPrefix}-dot`}
            iconClass={`${iconPrefix}-dot`}
            alt="Department of Transportation Logo"
            {...props}
        />
    );
}

export function BEP(props: IconProps) {
    return (
        <PartnerIcon
            iconName={`${iconPrefix}-bep`}
            iconClass={`${iconPrefix}-bep`}
            alt="Bureau of Engraving and Printing Logo"
            {...props}
        />
    );
}

export function PT(props: IconProps) {
    return (
        <PartnerIcon
            iconName={`${iconPrefix}-pt`}
            iconClass={`${iconPrefix}-pt`}
            alt="Presidio Trust"
            {...props}
        />
    );
}

export function PTWithText(props: IconProps) {
    return (
        <PartnerIcon
            iconName={`${iconPrefix}-pt-with-text`}
            iconClass={`${iconPrefix}-pt-with-text`}
            alt="Presidio Trust"
            {...props}
        />
    );
}

export function NDW(props: IconProps) {
    return (
        <PartnerIcon
            iconName={`${iconPrefix}-ndw`}
            iconClass={`${iconPrefix}-ndw`}
            alt="Naval District Washington"
            {...props}
        />
    );
}

// cSpell:ignore NOAA, USACE
