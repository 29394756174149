/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox, Heading } from 'sarsaparilla';
import { SEARCH_FILTER_TOUR_DIFFICULTY as options } from '../constants';

export default class SearchFilterTourDifficulty extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        uiFilters: PropTypes.object,
        aggregations: PropTypes.object,
        onFilterToggle: PropTypes.func,
        onApplyFilters: PropTypes.func,
        useNewCampingFilters: PropTypes.bool,
    };

    static displayName = 'SearchFilterTourDifficulty';

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange(event, item) {
        if (this.props.onFilterToggle) {
            this.props.onFilterToggle('tourDifficulty', item);
        }
        if (this.props.onApplyFilters) {
            this.props.onApplyFilters();
        }
    }

    processAggregations(searchAggregations) {
        const counts = {};
        // find aggregated counts for item attribute
        options.forEach((item) => {
            const filter = item.filters[0];
            counts[filter.attribute] = {};
            if (
                searchAggregations.tourDifficulty &&
                searchAggregations.tourDifficulty[filter.attribute]
            ) {
                const aggs = searchAggregations.tourDifficulty[filter.attribute];
                for (let i = 0; i < aggs.length; i += 1) {
                    counts[filter.attribute][aggs[i].name] = aggs[i].count;
                }
            }
        });
        // aggregate comma separate filter counts into one
        options.forEach((item) => {
            const filter = item.filters[0];
            const parts = filter.filterValue.split(',');
            let count = 0;
            parts.forEach((part) => {
                const cnt = counts[filter.attribute];
                if (cnt && cnt[part]) {
                    count += cnt[part];
                }
            });
            counts[filter.attribute][filter.filterValue] = count;
        });
        return counts;
    }

    renderCheckbox(item, counts) {
        const filter = item.filters[0];
        const attCounts = counts[filter.attribute];
        const selections = this.props.uiFilters.tourDifficulty;
        const isChecked = selections.hasOwnProperty(item.key);
        let count = 0;
        if (attCounts) {
            count = attCounts[filter.filterValue];
        }
        let label;
        if (count || isChecked) {
            label = `${item.value} (${count})`;
        } else {
            label = item.value;
        }
        return (
            <div className="search-filter-check-item" htmlFor={item.key} key={item.key}>
                <Checkbox
                    onChange={(e) => this.handleChange(e, item)}
                    id={item.key}
                    label={label}
                    value={item.value}
                    className={count === 0 && !isChecked ? 'disabled' : ''}
                    isChecked={isChecked}
                />
            </div>
        );
    }

    render() {
        const className = `${this.props.className}`;
        const counts = this.processAggregations(this.props.aggregations);
        const checkboxListClassName = this.props.useNewCampingFilters
            ? 'search-filters-double-type-wrap'
            : 'search-filters-check-wrap';
        return (
            <div className={`${className}`}>
                <Heading headingLevel={3} appearance="h3">
                    Level of Effort
                </Heading>
                <div
                    className={checkboxListClassName}
                    role="group"
                    aria-label="Tour difficulty filters"
                >
                    <legend className="rec-sr-only">
                        Select tour difficulty to filter on
                    </legend>
                    {options.map((item) => this.renderCheckbox(item, counts))}
                </div>
            </div>
        );
    }
}
