/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import './AnchorPageWithSidebarLayout.scss';

import * as React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useLocation } from 'react-router-dom-v5-compat';

import { announce, Button, useStickySidebar, useWindowSize } from 'sarsaparilla';

// This is the minimum ratio of on-screen vs. off-screen for a section to be deemed "active".
// It needs to be near zero otherwise tall sections will never become active.
const INTERSECTION_THRESHOLD = 0.1;

/**
 * Link click handler that smooths scrolls to the link,
 * sets the focus, and updates the browser url
 */
function anchorLinkHandler(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    id: string,
    offsetTop: number
) {
    const distanceToTop = (el: Element) => Math.floor(el.getBoundingClientRect().top);
    const targetId = id || event?.currentTarget?.getAttribute('href');

    if (!targetId) return;

    const targetAnchor = document.querySelector(targetId) as HTMLButtonElement | null;
    if (!targetAnchor) return;

    let timer = 0;

    /**
     * Check when scroll event ends
     */
    const onScrollEnd = () => {
        clearTimeout(timer);
        timer = window.setTimeout(() => {
            targetAnchor.focus(); // Set the focus to first section element
            window.removeEventListener('scroll', onScrollEnd); // Remove listener after the element gets the focus
        }, 300);
    };

    announce(`Scrolled to ${targetAnchor?.innerText || id} section`); // no matter what, try to announce it as page has moved
    const originalTop = distanceToTop(targetAnchor);
    window.scrollBy({ top: originalTop - 100 - offsetTop, left: 0, behavior: 'smooth' });
    window.addEventListener('scroll', onScrollEnd); // Add scroll event listener
}

interface AnchorPageWithSidebarLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    offsetTop?: number;
    preventScrollToAnchor?: boolean;
    singlePanelMode?: boolean;
    onScrollToAnchor?: () => void;
}

/**
 * Exported React component
 */
export function AnchorPageWithSidebarLayout({
    children,
    offsetTop = 0,
    preventScrollToAnchor = false,
    singlePanelMode = false,
    onScrollToAnchor = () => {},
    ...rest
}: AnchorPageWithSidebarLayoutProps) {
    const notSidebarRef = React.useRef<HTMLDivElement>(null);
    // caching the scroll position so we can determine if the scroll direction is up or down

    const [activeLink, setActiveLink] = React.useState('');
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [headingElements, setHeadingElements] = React.useState<Element[]>([]);
    const [sectionIds, setSectionIds] = React.useState<string[]>([]);
    const [intersects, setIntersects] = React.useState<Record<string, number>>({});
    const initialScrollRef = React.useRef(false);

    const { hash } = useLocation();
    const { width = 0 } = useWindowSize();
    const { containerRef, stuckStatus, containerWidth } = useStickySidebar(
        80 + offsetTop,
        width > 990
    );

    // ---------------------------------------
    // Get all the headings and setup IntersectionObservers
    React.useEffect(() => {
        let isMounted = true;
        function intersectionCallback(entries: IntersectionObserverEntry[]) {
            const entry = entries[0];

            if (isMounted) {
                setIntersects((prev) => ({
                    ...prev,
                    [entry.target.id]: entry.intersectionRatio,
                }));
            }
        }

        const observers: IntersectionObserver[] = [];

        if (notSidebarRef?.current?.querySelectorAll) {
            const headingEls = Array.from(
                notSidebarRef.current.querySelectorAll(
                    '[data-shared-anchor-page-section-heading]'
                )
            );
            const headingIds = headingEls.map((item) => item.id);
            setHeadingElements(headingEls);
            setSectionIds(headingIds.map((item) => `${item}-section`));

            if (headingIds.length > 0) {
                headingIds.forEach((id) => {
                    const options = {
                        rootMargin: '-90px 0px 0px 0px',
                        threshold: INTERSECTION_THRESHOLD,
                    };
                    const observer = new IntersectionObserver(
                        intersectionCallback,
                        options
                    );
                    const el = document.getElementById(`${id}-section`);

                    if (el) {
                        observer.observe(el);
                        observers.push(observer);
                    }
                });
            }
        }

        return () => {
            isMounted = false;
            observers.forEach((observer) => {
                observer.disconnect();
            });
        };
    }, [children]);

    // ---------------------------------------
    // If there is a hash in the url, scroll the page to that id
    React.useEffect(() => {
        // Skip if there's no hash or the feature is off
        if (!hash || preventScrollToAnchor) {
            return;
        }

        const hashId = hash.split('#')[1];
        const sectionId = `${hashId}-section`;
        const intersect = intersects?.[sectionId];
        const hasMatchingIntersect = typeof intersect === 'number';

        // Skip if it's already been set or there's no intersect data
        if (initialScrollRef.current || !hasMatchingIntersect) {
            return;
        }

        // Flip initialScroll because from here on we only want to do this once
        initialScrollRef.current = true;

        if (intersect < INTERSECTION_THRESHOLD) {
            // Wait a tiny bit for the page to settle more
            window.setTimeout(() => {
                anchorLinkHandler(null, hash, offsetTop);
                onScrollToAnchor();
            }, 300);
        }
    }, [hash, preventScrollToAnchor, onScrollToAnchor, offsetTop, intersects]);

    // ---------------------------------------
    // When insects changes, find the active link
    React.useEffect(() => {
        if (sectionIds.length > 0) {
            // sectionsIds match the page order, we look through them until we find the
            // first item with a ratio higher than the threshold, which means there enough
            // on the page to be considered the "active" section
            for (let i = 0; i < sectionIds.length; i += 1) {
                const id = sectionIds[i];
                if (intersects[id] >= INTERSECTION_THRESHOLD) {
                    const headingId = id.split('-section')[0];
                    setActiveLink(headingId);
                    break;
                }
            }
        }
    }, [intersects, sectionIds]);

    return (
        <div data-component="AnchorPageWithSidebarLayout" {...rest}>
            <div className="intermediary-wrapper">
                <div className="sidebar">
                    <div className="sticky-container" ref={containerRef}>
                        {headingElements.length > 0 && (
                            <ul
                                style={{ width: `${containerWidth}px` }}
                                aria-label="Page contents"
                                className={cx('shared-toc-list', {
                                    'stuck-top': stuckStatus === 'top',
                                    'stuck-bottom': stuckStatus === 'bottom',
                                })}
                            >
                                {headingElements.map((item, index) => {
                                    return (
                                        <li key={item.id}>
                                            <Button
                                                appearance="link"
                                                className={cx('p-1', {
                                                    active: item.id === activeLink,
                                                })}
                                                onClick={(
                                                    event: React.MouseEvent<
                                                        HTMLButtonElement,
                                                        MouseEvent
                                                    >
                                                ) => {
                                                    setActiveIndex(index);
                                                    anchorLinkHandler(
                                                        event,
                                                        `#${item.id}`,
                                                        offsetTop
                                                    );
                                                }}
                                            >
                                                {item.innerHTML}
                                            </Button>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                </div>
                <div ref={notSidebarRef} className="not-sidebar">
                    {singlePanelMode &&
                        React.Children.toArray(children).map((child, index) => {
                            return (
                                <div
                                    className={`${activeIndex === index ? 'active' : 'hidden'}`}
                                >
                                    {child}
                                </div>
                            );
                        })}
                    {!singlePanelMode && children}
                </div>
            </div>
        </div>
    );
}

AnchorPageWithSidebarLayout.propTypes = {
    children: PropTypes.node,
    offsetTop: PropTypes.number,
    preventScrollToAnchor: PropTypes.bool,
    onScrollToAnchor: PropTypes.func,
};
