/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { ExternalLink, useWindowSize, useFlags } from 'sarsaparilla';
import * as PartnerIcons from '../Icons/Partner/PartnerIcons';

const partners = [
    {
        icon: <PartnerIcons.BLM />,
        linkUrl: 'https://www.blm.gov/',
        title: 'Bureau of Land Management',
    },
    {
        icon: <PartnerIcons.DOT />,
        linkUrl: 'https://www.fhwa.dot.gov/',
        title: 'Federal Highway Administration',
    },
    {
        icon: <PartnerIcons.NOAA />,
        linkUrl: 'https://www.noaa.gov/',
        title: 'National Oceanic Atmosphere Administration',
    },
    {
        icon: <PartnerIcons.Smithsonian />,
        linkUrl: 'https://www.si.edu/',
        title: 'Smithsonian Institution',
    },
    {
        icon: <PartnerIcons.ForestService />,
        linkUrl: 'https://www.fs.usda.gov/',
        title: 'USDA Forest Service',
    },
    {
        icon: <PartnerIcons.USACE />,
        linkUrl: 'https://www.usace.army.mil/Missions/Civil-Works/Recreation/',
        title: 'US Army Corps of Engineers',
    },
    {
        icon: <PartnerIcons.FWS />,
        linkUrl: 'https://www.fws.gov',
        title: 'US Fish and Wildlife Service',
    },
    {
        icon: <PartnerIcons.NPS />,
        linkUrl: 'https://www.nps.gov/index.htm',
        title: 'National Park Service',
    },
    {
        icon: <PartnerIcons.TRE />,
        linkUrl: 'https://www.moneyfactory.gov',
        title: 'Bureau of Engraving and Printing',
    },
    {
        icon: <PartnerIcons.PT />,
        linkUrl: 'https://presidio.gov/about/presidio-trust',
        title: 'Presidio Trust',
    },
    {
        icon: <PartnerIcons.TVA />,
        linkUrl: 'https://www.tva.gov',
        title: 'Tennessee Valley Authority',
    },
    {
        icon: <PartnerIcons.DOI />,
        linkUrl: 'https://www.usbr.gov',
        title: 'Bureau of Reclamation',
    },
    {
        icon: <PartnerIcons.NationalArchives />,
        linkUrl: 'https://www.archives.gov',
        title: 'National Archives',
    },
    {
        icon: <PartnerIcons.NDW />,
        linkUrl: 'https://www.navyregionmwrndw.com',
        title: 'Naval District Washington',
    },
];

interface OurPartnersProps {
    isFooterInline?: boolean;
}

export function OurPartners({ isFooterInline }: OurPartnersProps) {
    const { width = 0 } = useWindowSize();
    const { ndwContent } = useFlags();

    // Remove the Naval District Washington partner block
    const filterOut = partners.filter(
        (partner) => partner.title !== 'Naval District Washington'
    );

    // Use the LD flag to show the Naval District Washington partner block on a specific date
    const newPartners = ndwContent ? filterOut : partners;

    return (
        <ul className="nav-footer-list">
            {newPartners.map((partner) => (
                <li key={partner.title}>
                    {/* isFooterInline displays the name of the partner without the icon */}
                    {isFooterInline || width <= 768 ? (
                        <ExternalLink url={partner.linkUrl}>{partner.title}</ExternalLink>
                    ) : (
                        <ExternalLink url={partner.linkUrl}>{partner.icon}</ExternalLink>
                    )}
                </li>
            ))}
        </ul>
    );
}

// cSpell:ignore NOAA, USACE
