/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/**
 * partnerColorIconSingleton.js
 * Created by Destin Frasier 02/21/18
 */

import { IconSingleton } from '@fp/sarsaparilla';

class PartnerColorIconSingleton extends IconSingleton {
    constructor() {
        super();

        this.notificationName = 'icons-partner.loaded';
        this.svgPath = `${process.env.CDN}/graphics/partnerColorIcons.svg`;
    }
}

const instance = new PartnerColorIconSingleton();
export default instance;
