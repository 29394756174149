/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-restricted-syntax */

import './PrivacyPolicyPage.scss';

import {
    HelmetWrapper,
    SingleColumnContainer,
    Heading,
    TextBlock,
    ExternalLink,
} from 'sarsaparilla';

export function PrivacyPolicyPage() {
    return (
        <SingleColumnContainer role="main" className="r1s-privacy-policy-page">
            <HelmetWrapper title="Privacy Policy" />
            <section>
                <Heading
                    headingLevel={1}
                    appearance="h1"
                    className="head-with-revised-text"
                >
                    Privacy Policy
                    <span className="revised-text">
                        This privacy policy was last revised on January 30th, 2024.
                    </span>
                </Heading>
                <TextBlock width="xl">
                    <p>
                        We, at Recreation.gov, have created this privacy policy to
                        demonstrate our firm commitment to protecting your personal
                        information and informing you about how we handle it. This privacy
                        policy only applies to transactions made, and data gathered, on
                        the Recreation.gov web site. Please review this privacy policy
                        periodically to check for revisions as we may update it from time
                        to time. Each time you visit the Site or provide us with
                        information, by doing so you are accepting the practices described
                        in this privacy policy at that time.
                    </p>
                </TextBlock>

                <Heading headingLevel={2} appearance="h2">
                    Personal and Other Information We May Collect
                </Heading>
                <Heading headingLevel={3} appearance="h3">
                    &#34;Personal Information&#34;
                </Heading>
                <TextBlock width="xl">
                    <p className="rec-connected-list-below">
                        Personal Information is data and information that can identify you
                        as an individual, either alone or when combined with other
                        information for example:
                    </p>
                    <ul>
                        <li>Contact Information (name and home address);</li>
                        <li>Phone number if you opt in to receive text messages</li>
                        <li>Email</li>
                        <li>Credit or Debit Card Number</li>
                        <li>Geolocation</li>
                        <li>Birthdate</li>
                        <li>License plate number</li>
                    </ul>

                    <p>
                        It is important to keep in mind, however, that we do not share or
                        release personal information, credit card or financial information
                        for use by other organizations, except as required by law.
                    </p>
                </TextBlock>
                <Heading headingLevel={3} appearance="h3">
                    &#34;Other Information&#34;
                </Heading>
                <TextBlock>
                    <p className="rec-connected-list-below">
                        Other Information is any information that does not reveal your
                        specific identity or does not directly relate to an identifiable
                        individual:
                    </p>

                    <ul>
                        <li>Browser and device information;</li>
                        <li>Application usage data;</li>
                        <li>
                            Information collected through cookies, and other technologies;
                        </li>
                        <li>
                            Demographic information and other information provided by you;
                        </li>
                        <li>Aggregated information.</li>
                    </ul>
                </TextBlock>
                <Heading headingLevel={2} appearance="h2">
                    Your IP Address
                </Heading>
                <TextBlock width="xl">
                    <p>
                        Like most e-commerce websites, each time you visit the Site, we
                        may automatically collect your IP address and the web page from
                        which you came. In order to administer and optimize the Site for
                        you and to diagnose problems with our Site, we may use your IP
                        address to help identify you, to gather broad demographic
                        information about you and for other purposes. Your IP address is a
                        number that is automatically assigned to the computer that you are
                        using by your Internet Service Provider. An IP address may be
                        identified and logged automatically in our server log files
                        whenever a user accesses the Site, along with the time of the
                        visit and the pages visited. We use IP addresses for purposes such
                        as calculating usage levels, diagnosing server problems, and
                        administering the Site. We may also derive your approximate
                        location from your IP address
                    </p>
                </TextBlock>
                <Heading headingLevel={2} appearance="h2">
                    Cookies
                </Heading>
                <TextBlock width="xl">
                    <p>
                        Like most websites, the Site uses cookies to keep track of your
                        purchases, reservations, physical location, information you submit
                        and other activity on the Site and to enhance your experience on
                        the Site. We may also use cookies to deliver content specific to
                        your interests, to save your password, and to save other personal
                        and financial information so you do not have to re-enter it each
                        time you visit the Site. If you wish not to allow or accept these
                        cookies, the &#34;help&#34; portion of the toolbar on most
                        browsers will tell you how. However, if you set your browser not
                        to allow or accept cookies, you may not be able to access certain
                        features or areas on the Site.
                    </p>
                </TextBlock>
                <Heading headingLevel={2} appearance="h2">
                    How Do I Manage Cookies
                </Heading>
                <TextBlock width="xl">
                    <p>
                        Unless you have set your browser to automatically refuse them,
                        cookies will be created on your system the second you visit our
                        site. You can refuse or accept cookies from the Site at any time
                        by changing the settings on your browser. Information on how to
                        enable or disable cookies can be found on your Internet browser
                        provider&#39;s website via the help menu. You can also learn more
                        about how to&nbsp;
                        <ExternalLink
                            url="https://www.aboutcookies.org/how-to-control-cookies/"
                            hasExternalIcon
                        >
                            control
                        </ExternalLink>
                        &nbsp;and&nbsp;
                        <ExternalLink
                            url="https://www.aboutcookies.org/how-to-delete-cookies/"
                            hasExternalIcon
                        >
                            delete
                        </ExternalLink>
                        &nbsp;cookies here:&nbsp;
                        <ExternalLink url="https://www.aboutcookies.org" hasExternalIcon>
                            https://www.aboutcookies.org
                        </ExternalLink>
                        . Please be aware that if cookies are disabled, not all features
                        of the Site may operate as intended, and your experience may be
                        hampered or diminished.
                    </p>

                    <p className="rec-connected-list-below">
                        If you want to clear all cookies left behind by the websites you
                        have visited, here are three options of programs that will clean
                        tracking cookies from your device:
                    </p>

                    <ul>
                        <li>
                            <ExternalLink
                                url="http://www.lavasoftusa.com/products/ad-aware_se_personal.php"
                                hasExternalIcon
                            >
                                http://www.lavasoftusa.com/products/ad-aware_se_personal.php
                            </ExternalLink>
                        </li>
                        <li>
                            <ExternalLink
                                url="http://www.spybot.info/en/download/index.html"
                                hasExternalIcon
                            >
                                http://www.spybot.info/en/download/index.html
                            </ExternalLink>
                        </li>
                        <li>
                            <ExternalLink
                                url="http://www.webroot.com/consumer/products/spysweeper/"
                                hasExternalIcon
                            >
                                http://www.webroot.com/consumer/products/spysweeper/
                            </ExternalLink>
                        </li>
                    </ul>

                    <p>
                        You can opt-out of having your anonymized browsing history within
                        our Site by analytics cookies. We use the following providers and
                        you can learn more about their privacy policies and how to opt-out
                        of their analytics cookies below.
                    </p>
                </TextBlock>
                <Heading headingLevel={3} appearance="h3">
                    Google Analytics:
                </Heading>
                <TextBlock width="xl">
                    <p>
                        We use Google Analytics, which uses cookies and similar
                        technologies to collect and analyze information about use of the
                        Site and report on activities and trends. This Site may also
                        collect information regarding the use of other websites, apps and
                        online resources. You can learn about Google&#39;s practices by
                        going to{' '}
                        <ExternalLink
                            url="https://policies.google.com/technologies/partner-sites"
                            hasExternalIcon
                        >
                            https://policies.google.com/technologies/partner-sites
                        </ExternalLink>
                        , and opt out of them by downloading the Google Analytics opt-out
                        browser add-on, available at{' '}
                        <ExternalLink
                            url="https://tools.google.com/dlpage/gaoptout"
                            hasExternalIcon
                        >
                            https://tools.google.com/dlpage/gaoptout
                        </ExternalLink>
                        .
                    </p>
                </TextBlock>
                <Heading headingLevel={3} appearance="h3">
                    Device Fingerprinting:
                </Heading>
                <TextBlock width="xl">
                    <p className="rec-connected-list-below">
                        We may also use other technologies that are similar to cookies in
                        order to best offer you content that will interest you based on
                        prior activity on the Site, to better prevent fraud, and for
                        authentication purposes. For example, we may gather device
                        attributes and combine information collected by most browsers or
                        automatically through your device to create a unique ID that
                        identifies your browser or device to us with reasonable certainty.
                        We use anonymous user behavior analysis to identify and implement
                        improvements to the user experience. In this context we may
                        collect, use, and combine the following information:
                    </p>

                    <ul>
                        <li>Number of visits</li>
                        <li>Last visit</li>
                        <li>First visit</li>
                        <li>External IP address</li>
                        <li>Internal IP address</li>
                        <li>Screen resolution</li>
                        <li>Geolocation (latitude/longitude based on IP address)</li>
                        <li>Device performance benchmark</li>
                        <li>Device bandwidth benchmark</li>
                        <li>Device user-agent (browser type, version)</li>
                        <li>
                            Device capabilities (Modernizer string for browser
                            capabilities)
                        </li>
                        <li>Device operating system</li>
                        <li>Device plugins installed</li>
                        <li>Browser language</li>
                        <li>Flash enabled or disabled</li>
                        <li>Device time on file</li>
                        <li>Keywords used if coming from search engine</li>
                        <li>Pages visited</li>
                        <li>Length of visit</li>
                        <li>Referral page</li>
                        <li>Mouse cursor position</li>
                        <li>Mouse clicks</li>
                        <li>Page scroll position</li>
                        <li>User ID</li>
                    </ul>
                </TextBlock>
                <Heading headingLevel={3} appearance="h3">
                    Physical Location:
                </Heading>
                <p>
                    Site analytics will collect the physical location of your device, for
                    example, using satellite, cell phone tower, or Wi-Fi signals. This can
                    be used to provide you with location-based services and content.
                </p>
                <Heading headingLevel={3} appearance="h3">
                    Through Your Browser or Device:
                </Heading>
                <TextBlock width="xl">
                    <p>
                        Certain information is collected by most browsers or automatically
                        through your device, such as your Media Access Control address,
                        computer type, screen resolution, operating system name and
                        version, device manufacturer and model, language, Internet browser
                        type and version, referring URL, and the name and version of the
                        Services (such as the mobile application) you are using. We use
                        this information to ensure that the Services function properly.
                    </p>

                    <p>
                        Google Analytics:{' '}
                        <ExternalLink
                            url="http://www.google.com/analytics/learn/privacy.html"
                            hasExternalIcon
                        >
                            http://www.google.com/analytics/learn/privacy.html
                        </ExternalLink>
                    </p>
                </TextBlock>
                <Heading headingLevel={2} appearance="h2">
                    How We Use Personal Information
                </Heading>
                <TextBlock width="xl">
                    <p>
                        We and our service providers may use Personal Information for
                        legitimate business purposes including:
                    </p>
                </TextBlock>
                <Heading headingLevel={3} appearance="h3">
                    Providing the functionality of the Services and fulfilling your
                    requests.
                </Heading>
                <TextBlock width="xl">
                    <ul>
                        <li>
                            To provide the Site&#39;s functionality to you, such as
                            arranging access to your registered account when searching for
                            a campsite and providing you with related customer service.
                        </li>
                        <li>
                            To respond to your inquiries and fulfill your requests, when
                            you contact us via one of our online contact forms or
                            otherwise, for example, when you send us questions,
                            suggestions, compliments or complaints, or when you request a
                            quote for or other information about our Site.
                        </li>
                        <li>
                            To complete your transactions and provide you with related
                            customer service.
                        </li>
                        <li>
                            To send administrative information to you, such as changes to
                            our terms, conditions and policies.
                        </li>
                    </ul>

                    <p>
                        We will engage in these activities to manage our contractual
                        relationship and/or to comply with a legal obligation.
                    </p>
                </TextBlock>
                <Heading headingLevel={3} appearance="h3">
                    Providing you with our newsletter and / or other marketing materials
                    and facilitating social sharing.
                </Heading>
                <TextBlock width="xl">
                    <ul>
                        <li>
                            To send you marketing related emails, with information about
                            our Site, new products and other news from our partners.
                        </li>
                        <li>
                            To facilitate social sharing functionality that you choose to
                            use.
                        </li>
                        <li>Online surveys to receive feedback and comments.</li>
                    </ul>

                    <p>
                        We will engage in this activity because we have a legitimate
                        interest or with your consent (where required).
                    </p>
                </TextBlock>
                <Heading headingLevel={3} appearance="h3">
                    Analysis of Personal Information for business reporting and providing
                    personalized services.
                </Heading>
                <TextBlock width="xl">
                    <ul>
                        <li>
                            To analyze or predict our users&#39; preferences in order to
                            prepare aggregated trend reports on how our digital content is
                            used, so we can improve our Site.
                        </li>
                        <li>
                            To better understand you, so that we can personalize our
                            interactions with you and provide you with information and/or
                            offers tailored to your interests.
                        </li>
                        <li>
                            To better understand your preferences so that we can deliver
                            content via our Site that we believe will be relevant and
                            interesting to you.
                        </li>
                    </ul>

                    <p>
                        We will provide personalized services either with your consent or
                        because we have a legitimate interest.
                    </p>
                </TextBlock>
                <Heading headingLevel={2} appearance="h2">
                    Information We Do Not Share with Others
                </Heading>
                <TextBlock width="xl">
                    <p>
                        We do not release personal information, credit card or financial
                        information for use by other companies. For both privacy and
                        security purposes, all transactional information communicated
                        between customers and our Web site is transmitted in an encrypted
                        format, and all credit card information is stored in a highly
                        secure computing environment.
                    </p>
                </TextBlock>
                <Heading headingLevel={2} appearance="h2">
                    How We Use Demographic Information
                </Heading>
                <TextBlock width="xl">
                    <p>
                        We use demographic information to tailor the Site and
                        communications to the interests of our users. We may share
                        anonymous, aggregated (grouped) demographic information. We will
                        not share your personal information, financial information or
                        public agency reservation information under this privacy policy.
                    </p>
                </TextBlock>
                <Heading headingLevel={2} appearance="h2">
                    Links to Other Sites
                </Heading>
                <TextBlock width="xl">
                    <p>
                        Registered users may opt to receive links to other sites we think
                        you will enjoy. These sites operate independently of
                        Recreation.gov and have established their own privacy and security
                        policies. For the best online experience, we strongly encourage
                        you to review these policies at any site you visit.
                    </p>
                </TextBlock>
                <Heading headingLevel={2} appearance="h2">
                    Secure Server
                </Heading>
                <TextBlock width="xl">
                    <p>
                        This Site uses the latest technology to ensure your personal and
                        payment information is handled with confidentiality and security.
                        Any personal information you provide through this Site is
                        encrypted through secure server technology using Secure Socket
                        Layers (SSL). Once encrypted, this information travels from your
                        computer to our servers. Only after your information reaches our
                        servers, is your information decrypted and transferred to the
                        Recreation One Stop (R1S) reservation system. This technology
                        ensures the security of your information.
                    </p>
                </TextBlock>
                <Heading headingLevel={2} appearance="h2">
                    Third-Party Payment Processing
                </Heading>
                <TextBlock width="xl">
                    <p>
                        Payments processed through this Site are done so by a third party
                        pursuant to a contractual agreement. Neither we nor our
                        third-party payment processor release personal information, credit
                        card or financial information for use by other companies. For both
                        privacy and security purposes, all transactional information
                        communicated between customers and our Site is transmitted in an
                        encrypted format, and we do not store personal credit card
                        information.
                    </p>
                </TextBlock>
                <Heading headingLevel={2} appearance="h2">
                    Jurisdiction
                </Heading>
                <TextBlock width="xl">
                    <p>
                        The Site is controlled and operated by the U.S. Department of
                        Agriculture, Forest Service, and other R1S participating agencies
                        from the United States and are not intended to subject us to the
                        laws or jurisdiction of any state, country, or territory other
                        than that of the United States.
                    </p>
                </TextBlock>
                <Heading headingLevel={2} appearance="h2">
                    Delete Your Account
                </Heading>
                <TextBlock width="xl">
                    <p className="rec-connected-list-below">
                        It is the policy of the U.S. Forest Service and the Recreation One
                        Stop program that all users of Recreation.gov can delete their
                        Recreation.gov account at any time, unless they have a current or
                        future reservation tied to their account. When you delete your
                        account, we will remove the following information from our account
                        records:
                    </p>

                    <ul>
                        <li>First and Last Name</li>
                        <li>Address (not including Zip Code)</li>
                        <li>Email</li>
                        <li>Password</li>
                        <li>Phone Number(s)</li>
                    </ul>

                    <p>
                        Zip Code will be maintained for any deleted accounts for location
                        usage purposes. Additionally, any personal information associated
                        with individual reservations will be preserved for the length of
                        applicable{' '}
                        <ExternalLink
                            url="https://www.fs.fed.us/cgi-bin/Directives/get_dirs/fsh?6209.11"
                            hasExternalIcon
                        >
                            Forest Service policies
                        </ExternalLink>
                        , only information directly in a user&apos;s account will be
                        deleted.
                    </p>

                    <p>
                        Refunds for reservations previously associated with a deleted
                        account will still process normally, and refund requests can still
                        be submitted and processed against any reservation previously
                        associated with a deleted account.
                    </p>

                    <p>
                        Any user has the right to sign up again with the same or a
                        different email, however all reservations from a deleted account
                        cannot be reassociated with a new account. If a user with a
                        current or future reservation wants to delete their account, you
                        must cancel those reservations or wait for them to pass to delete.
                    </p>

                    <p>
                        For how to delete your account, visit the Knowledge Base article{' '}
                        <a href="https://recreationonestopprod.servicenowservices.com/external?id=kb_article&sys_id=22e444ef1bab8510201beb9ce54bcb5e">
                            How Do I Delete My Account?
                        </a>
                    </p>
                </TextBlock>
                <Heading headingLevel={2} appearance="h2">
                    Updates to this Privacy Policy
                </Heading>
                <TextBlock width="xl">
                    <p>
                        We may change this Privacy Policy. The &quot;Last Updated&quot;
                        legend at the top of this page indicates when this Privacy Policy
                        was last revised. Any changes will become effective when we post
                        the revised Privacy Policy on the Site. Your use of the Site
                        following these changes means that you accept the revised Privacy
                        Policy.
                    </p>
                </TextBlock>
            </section>
        </SingleColumnContainer>
    );
}

// cSpell:ignore reassociated
